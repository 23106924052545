import axios from "axios";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../../src/AtlaskitControls/AtkLoader";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import FenrisLogo from "../../assets/images/Fenris digital logo.png";
import NcLogo from "../../assets/images/nc.png";
import CommonValues from "../../common/utils";
import FenrisFeedBack from ".././FeedBack/FenrisFeedback";
import FenrisData from "./FenrisData";
import ExistingData from "./ExistingData";

export default function ExistingAndFenrisData(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [isActive10, setIsActive10] = useState(false);
  const [isActive11, setIsActive11] = useState(false);
  const [isActive12, setIsActive12] = useState(false);
  const [isActive13, setIsActive13] = useState(false);
  const [isActive14, setIsActive14] = useState(false);
  const [isActive15, setIsActive15] = useState(false);
  const [isActive16, setIsActive16] = useState(false);
  const [isActive17, setIsActive17] = useState(false);
  const [isActive18, setIsActive18] = useState(false);
  const [isActive19, setIsActive19] = useState(false);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [employees, setEmployees] = useState("");
  const [estimatedEmployees, setEstimatedEmployees] = useState("");
  const [estimatedPayroll, setEstimatedPayroll] = useState("");
  const [revenue, setRevenue] = useState("");
  const [estimatedRevenue, setEstimatedRevenue] = useState("");
  const [areaSquareMeters, setAreaSquareMeters] = useState("");
  const [primaryNaicsCode, setPrimaryNaicsCode] = useState("");
  const [primarySICCode, setPrimarySICCode] = useState("");
  const [yearExtablished, setyearExtablished] = useState("");
  const [numberofLocation, setNumberOfLocation] = useState("");
  const [country, setCountry] = useState();
  const [addressType, setAddressType] = useState("");
  const [fenrisname, setFenrisName] = useState("");
  const [fenrisaddressLine1, setFenrisAddressLine1] = useState("");
  const [fenriscity, seFenristCity] = useState("");
  const [fenrisstate, seFenristState] = useState("");
  const [fenriszipCode, setFenrisZipCode] = useState("");
  const [fenrisphoneNumber, setFenrisPhoneNumber] = useState("");
  const [fenrisemployees, setFenrisEmployees] = useState("");
  const [fenrisestimatedEmployees, setFenrisEstimatedEmployees] = useState("");
  const [fenrisestimatedPayroll, setFenrisEstimatedPayroll] = useState("");
  const [fenrisrevenue, setFenrisRevenue] = useState("");
  const [fenrisestimatedRevenue, setFenrisEstimatedRevenue] = useState("");
  const [fenrisareaSquareMeters, setFenrisAreaSquareMeters] = useState("");
  const [fenrisprimaryNaicsCode, setFenrisPrimaryNaicsCode] = useState("");
  const [fenrisyearExtablished, setFenrisyearExtablished] = useState("");
  const [fenrisnumberofLocation, setFenrisNumberOfLocation] = useState("");
  const [fenrisaddressType, setFenrisaddressType] = useState("");
  const [fenrisprimarySICCode, setFenrisPrimarySICCode] = useState("");
  const [fenriscountry, setFenrisCountry] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateScreenLoading, setUpdateScreenLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transcationId, setTranscationId] = useState("");
  const [updateButtonActive, setUpdateButtonActive] = useState(false);
  const [feedbackButtonActive, setFeedBackButtonActive] = useState(false);
  const [showPopMessage, setShowPopMessage] = useState(false);
  const [hideAllData, setHideAllData] = useState(false);
  const [addressInfoRequiredMessageBox, setAddressInfoRequiredMessageBox] = useState(false);
  const [multipleMatchesResultList, setMultipleMatchesResultList] = useState<any>([]);
  const [status, setStatus] = useState("");
  const [nameList, setNameList] = useState<any>([]);
  const [selectedName, setSelectedName] = useState("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [description,setDescription]=useState("");


  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (props.fromHistoryPage == "fromHistoryPage") {
      getFenrisHistory(props.selectedData.transactionId);
      getNowCertsClientsById(props.selectedData.insuredId, false);
      getNowCertsExistingClientsById(props.selectedData.insuredId);
      setFeedBackButtonActive(true);
      setUpdateButtonActive(false);
    } else {
      getNowCertsClientsById(props.insuredId, true);
      getNowCertsExistingClientsById(props.insuredId);
      setUpdateButtonActive(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
      setIsActive4(false);
      setIsActive5(false);
      setIsActive6(false);
      setIsActive7(false);
      setIsActive8(false);
      setIsActive9(false);
      setIsActive10(false);
      setIsActive11(false);
      setIsActive12(false);
      setIsActive13(false);
      setIsActive14(false);
      setIsActive15(false);
      setIsActive16(false);
      setIsActive17(false);
    }
  }, [props.enrichDataFlag]);

  const getFenrisHistory = (transactionId: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/getfenrishistorybytransacionId?transactionId=${transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        let fenrisResponseData = response.data;
        let status = fenrisResponseData.status;
        setStatus(status);
        if (fenrisResponseData.status == "Multiple Matches Found") {
          response.data.matchCandidates.map((item: any) => {
            nameList.push({
              name: item.name
            })
          })
          setNameList(nameList);
          setSelectedName(nameList[0].name);
          let fenrisResponseData = response.data.matchCandidates
          setMultipleMatchesResultList(fenrisResponseData)
          if (fenrisResponseData[0].parent == null) {
            setFenrisNumberOfLocation("");
            setFenrisPrimaryNaicsCode("");
            setFenrisPrimarySICCode("");
            setFenrisyearExtablished("");
          } else {
            setFenrisPrimaryNaicsCode(
              fenrisResponseData[0].parent.primaryNAICSCode == null || fenrisResponseData[0].parent.primaryNAICSCode == ""
                ? ""
                : fenrisResponseData[0].parent.primaryNAICSCode
            );
            setFenrisPrimarySICCode(
              fenrisResponseData[0].parent.primarySICCode == null || fenrisResponseData[0].parent.primarySICCode == ""
                ? ""
                : fenrisResponseData[0].parent.primarySICCode.substring(0, 4)
            );
            setFenrisyearExtablished(
              fenrisResponseData[0].parent.yearEstablished == null || fenrisResponseData[0].parent.yearEstablished == ""
                ? ""
                : fenrisResponseData[0].parent.yearEstablished
            );
            setFenrisNumberOfLocation(
              fenrisResponseData[0].parent.numberOfLocations == null || fenrisResponseData[0].parent.numberOfLocations == ""
                ? ""
                : fenrisResponseData[0].parent.numberOfLocations
            );
          }

          setFenrisPhoneNumber(fenrisResponseData[0].phone == null ? "" : fenrisResponseData[0].phone);
          setFenrisName(fenrisResponseData[0].name);
          if (fenrisResponseData[0].address == null) {
            setFenrisAddressLine1("");
            seFenristCity("");
            setFenrisZipCode("");
            seFenristState("");
            setAddressType("");
          } else {
            setFenrisAddressLine1(fenrisResponseData[0].address.addressLine1);
            seFenristCity(fenrisResponseData[0].address.city);
            setFenrisZipCode(fenrisResponseData[0].address.zipCode);
            seFenristState(fenrisResponseData[0].address.state);
            setFenrisaddressType(fenrisResponseData[0].address.addressType);
          }

          setFenrisCountry(fenrisResponseData[0].country);
          setFenrisEmployees(
            fenrisResponseData[0].employees == null || fenrisResponseData[0].employees == "" ? "" : fenrisResponseData[0].employees.split("-")[1]
          );
          setFenrisEstimatedEmployees(
            fenrisResponseData[0].estimatedEmployees == null || fenrisResponseData[0].estimatedEmployees == ""
              ? ""
              : fenrisResponseData[0].estimatedEmployees
          );
          setFenrisEstimatedPayroll(
            fenrisResponseData[0].estimatedPayroll == null || fenrisResponseData[0].estimatedPayroll == "" ? "" : fenrisResponseData[0].estimatedPayroll
          );
          setFenrisEstimatedRevenue(
            fenrisResponseData[0].estimatedRevenue == null || fenrisResponseData[0].estimatedRevenue == "" ? "" : fenrisResponseData[0].estimatedRevenue
          );
          setFenrisRevenue(
            fenrisResponseData[0].estimatedRevenue == null || fenrisResponseData[0].estimatedRevenue == "" ? "" : fenrisResponseData[0].estimatedRevenue
          );
          setFenrisAreaSquareMeters(
            fenrisResponseData[0].areaSquareMeters == null || fenrisResponseData[0].areaSquareMeters == "" ? "" : fenrisResponseData[0].areaSquareMeters
          );
          setMultipleMatchesResultList(fenrisResponseData)
        }
        else {
          if (fenrisResponseData.parent == null) {
            setFenrisNumberOfLocation("");
            setFenrisPrimaryNaicsCode("");
            setFenrisPrimarySICCode("");
            setFenrisyearExtablished("");
          } else {
            setFenrisPrimaryNaicsCode(fenrisResponseData.parent.primaryNAICSCode);
            setFenrisPrimarySICCode(fenrisResponseData.parent.primarySICCode.substring(0, 4));
            setFenrisyearExtablished(fenrisResponseData.parent.yearEstablished);
            setFenrisNumberOfLocation(fenrisResponseData.parent.numberOfLocations);
          }

          setFenrisPhoneNumber(fenrisResponseData.phone == null ? "" : fenrisResponseData.phone);
          setFenrisName(fenrisResponseData.name);
          if (fenrisResponseData.address == null) {
            setFenrisAddressLine1("");
            seFenristCity("");
            setFenrisZipCode("");
            seFenristState("");
            setAddressType("");
          } else {
            setFenrisAddressLine1(fenrisResponseData.address.addressLine1);
            seFenristCity(fenrisResponseData.address.city);
            setFenrisZipCode(fenrisResponseData.address.zipCode);
            seFenristState(fenrisResponseData.address.state);
            setFenrisaddressType(fenrisResponseData.address.addressType);
          }
          setFenrisCountry(fenrisResponseData.country);
          setFenrisEmployees(fenrisResponseData.employees == "" || fenrisResponseData.employees == null ? "": fenrisResponseData.employees.split("-")[1]);
          setFenrisEstimatedEmployees(fenrisResponseData.estimatedEmployees);
          setFenrisEstimatedPayroll(fenrisResponseData.estimatedPayroll == null ? "" : fenrisResponseData.estimatedPayroll);
          setFenrisEstimatedRevenue(fenrisResponseData.estimatedRevenue);
          setFenrisRevenue(fenrisResponseData.estimatedRevenue);
          setFenrisAreaSquareMeters(fenrisResponseData.areaSquareMeters == null ? "" : fenrisResponseData.areaSquareMeters);
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while getting fenris history data.",
              life: 3000,
            });
          }
        }
      });
  };

  const getNowCertsExistingClientsById = (insuredDatabaseId: string) => {
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var config = {
      method: "Get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/getexistingdetails?insuredId=${insuredDatabaseId}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        setPrimaryNaicsCode(
          insuredInfo.primaryNaicCode == null ? "" : insuredInfo.primaryNaicCode
        );
        setPrimarySICCode(
          insuredInfo.primarySicCode == null ? "" : insuredInfo.primarySicCode
        );
        setyearExtablished(
          insuredInfo.yearEstablished == null ? "" : insuredInfo.yearEstablished
        );
        setEstimatedPayroll(
          insuredInfo.estimatedPayroll == null
            ? ""
            : insuredInfo.estimatedPayroll
        );
        setEmployees(insuredInfo.employee == null ? "" : insuredInfo.employee);
        setRevenue(insuredInfo.revenue == null ? "" : insuredInfo.revenue);
        setDescription(insuredInfo.description);
        if(insuredInfo.employee == null || insuredInfo.employee == ""){
          setIsActive8(true)
        }
        else{
          setIsActive8(false)
        }
        if(insuredInfo.primaryNaicCode == null || insuredInfo.primaryNaicCode == ""){
          setIsActive14(true)
        }else{
          setIsActive14(false)
        }
        if(insuredInfo.primarySicCode == null || insuredInfo.primarySicCode == ""){
          setIsActive15(true)
        }else{
          setIsActive15(false)
        }
        if(insuredInfo.yearEstablished == null || insuredInfo.yearEstablished == ""){
          setIsActive16(true)
        }else{
          setIsActive16(false)
        }
        if(insuredInfo.estimatedPayroll == null || insuredInfo.estimatedPayroll == ""){
          setIsActive10(true)
        }else{
          setIsActive10(false)
        }
        if(insuredInfo.revenue == null || insuredInfo.revenue == ""){
          setIsActive11(true)
        }else{
          setIsActive11(false)
        }
        setUpdateScreenLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while getting existing data.",
              life: 3000,
            });
          }
        }
        setUpdateScreenLoading(false);
      });
  };

  const getFenrisAgencyDetails = (name: any, address: any, state: any, city: any, zipcode: any) => {
    if (address == "" || address == null || state == "" || state == null || city == "" || city == null || zipcode == "" || zipcode == null) {
      setShowPopMessage(true);
      setHideAllData(false);
      setAddressInfoRequiredMessageBox(true);
    } else {
      setAddressInfoRequiredMessageBox(false);
      setShowPopMessage(false);
      setHideAllData(false);
      const token = CommonValues.GetToken();
      const data = {
        Target: "FENRIS",
        Data: {
          names: name,
          address: {
            addressLine1: address,
            state: state,
            city: city,
            zipCode: zipcode,
          },
          InsuredId: props.insuredId,
        },
      };
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/details`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          let nameList: any = []
          setStatus(response.data.status);
          if (response.data.status == "Multiple Matches Found") {
            setHideAllData(false);
            setTranscationId(response.data.fenrisResponse.transcationId);
            response.data.fenrisResponse.matchCandidates.map((item: any) => {
              nameList.push({
                name: item.name
              })
            })
            setNameList(nameList);
            setSelectedName(nameList[0].name);
            let fenrisResponseData = response.data.fenrisResponse.matchCandidates
            setMultipleMatchesResultList(fenrisResponseData)
            if (fenrisResponseData[0].parent == null) {
              setFenrisNumberOfLocation("");
              setFenrisPrimaryNaicsCode("");
              setFenrisPrimarySICCode("");
              setFenrisyearExtablished("");
            } else {
              setFenrisPrimaryNaicsCode(
                fenrisResponseData[0].parent.primaryNAICSCode == null || fenrisResponseData[0].parent.primaryNAICSCode == ""
                  ? ""
                  : fenrisResponseData[0].parent.primaryNAICSCode
              );
              setFenrisPrimarySICCode(
                fenrisResponseData[0].parent.primarySICCode == null || fenrisResponseData[0].parent.primarySICCode == ""
                  ? ""
                  : fenrisResponseData[0].parent.primarySICCode.substring(0, 4)
              );
              setFenrisyearExtablished(
                fenrisResponseData[0].parent.yearEstablished == null || fenrisResponseData[0].parent.yearEstablished == ""
                  ? ""
                  : fenrisResponseData[0].parent.yearEstablished
              );
              setFenrisNumberOfLocation(
                fenrisResponseData[0].parent.numberOfLocations == null || fenrisResponseData[0].parent.numberOfLocations == ""
                  ? ""
                  : fenrisResponseData[0].parent.numberOfLocations
              );
            }

            setFenrisPhoneNumber(fenrisResponseData[0].phone == null ? "" : fenrisResponseData[0].phone);
            setFenrisName(fenrisResponseData[0].name);
            if (fenrisResponseData[0].address == null) {
              setFenrisAddressLine1("");
              seFenristCity("");
              setFenrisZipCode("");
              seFenristState("");
              setAddressType("");
            } else {
              setFenrisAddressLine1(fenrisResponseData[0].address.addressLine1);
              seFenristCity(fenrisResponseData[0].address.city);
              setFenrisZipCode(fenrisResponseData[0].address.zipCode);
              seFenristState(fenrisResponseData[0].address.state);
              setFenrisaddressType(fenrisResponseData[0].address.addressType);
            }

            setFenrisCountry(fenrisResponseData[0].country);
            setFenrisEmployees(
              fenrisResponseData[0].employees == null || fenrisResponseData[0].employees == "" ? "" : fenrisResponseData[0].employees.split("-")[1]
            );
            setFenrisEstimatedEmployees(
              fenrisResponseData[0].estimatedEmployees == null || fenrisResponseData[0].estimatedEmployees == ""
                ? ""
                : fenrisResponseData[0].estimatedEmployees
            );
            setFenrisEstimatedPayroll(
              fenrisResponseData[0].estimatedPayroll == null || fenrisResponseData[0].estimatedPayroll == "" ? "" : fenrisResponseData[0].estimatedPayroll
            );
            setFenrisEstimatedRevenue(
              fenrisResponseData[0].estimatedRevenue == null || fenrisResponseData[0].estimatedRevenue == "" ? "" : fenrisResponseData[0].estimatedRevenue
            );
            setFenrisRevenue(
              fenrisResponseData[0].estimatedRevenue == null || fenrisResponseData[0].estimatedRevenue == "" ? "" : fenrisResponseData[0].estimatedRevenue
            );
            setFenrisAreaSquareMeters(
              fenrisResponseData[0].areaSquareMeters == null || fenrisResponseData[0].areaSquareMeters == "" ? "" : fenrisResponseData[0].areaSquareMeters
            );
            props.balanceCheck();
            setFeedBackButtonActive(false);
          }
          else if (response.data.status === "Success") {
            setHideAllData(false);
            let fenrisResponseData = response.data.fenrisResponse;
            if (fenrisResponseData.parent == null) {
              setFenrisNumberOfLocation("");
              setFenrisPrimaryNaicsCode("");
              setFenrisPrimarySICCode("");
              setFenrisyearExtablished("");
            } else {
              setFenrisPrimaryNaicsCode(
                fenrisResponseData.parent.primaryNAICSCode == null || fenrisResponseData.parent.primaryNAICSCode == ""
                  ? ""
                  : fenrisResponseData.parent.primaryNAICSCode
              );
              setFenrisPrimarySICCode(
                fenrisResponseData.parent.primarySICCode == null || fenrisResponseData.parent.primarySICCode == ""
                  ? ""
                  : fenrisResponseData.parent.primarySICCode.substring(0, 4)
              );
              setFenrisyearExtablished(
                fenrisResponseData.parent.yearEstablished == null || fenrisResponseData.parent.yearEstablished == ""
                  ? ""
                  : fenrisResponseData.parent.yearEstablished
              );
              setFenrisNumberOfLocation(
                fenrisResponseData.parent.numberOfLocations == null || fenrisResponseData.parent.numberOfLocations == ""
                  ? ""
                  : fenrisResponseData.parent.numberOfLocations
              );
            }

            setFenrisPhoneNumber(fenrisResponseData.phone == null ? "" : fenrisResponseData.phone);
            setFenrisName(fenrisResponseData.name);
            if (fenrisResponseData.address == null) {
              setFenrisAddressLine1("");
              seFenristCity("");
              setFenrisZipCode("");
              seFenristState("");
              setAddressType("");
            } else {
              setFenrisAddressLine1(fenrisResponseData.address.addressLine1);
              seFenristCity(fenrisResponseData.address.city);
              setFenrisZipCode(fenrisResponseData.address.zipCode);
              seFenristState(fenrisResponseData.address.state);
              setFenrisaddressType(fenrisResponseData.address.addressType);
            }

            setFenrisCountry(fenrisResponseData.country);
            setFenrisEmployees(
              fenrisResponseData.employees == null || fenrisResponseData.employees == "" ? "" : fenrisResponseData.employees.split("-")[1]
            );
            setFenrisEstimatedEmployees(
              fenrisResponseData.estimatedEmployees == null || fenrisResponseData.estimatedEmployees == ""
                ? ""
                : fenrisResponseData.estimatedEmployees
            );
            setFenrisEstimatedPayroll(
              fenrisResponseData.estimatedPayroll == null || fenrisResponseData.estimatedPayroll == "" ? "" : fenrisResponseData.estimatedPayroll
            );
            setFenrisEstimatedRevenue(
              fenrisResponseData.estimatedRevenue == null || fenrisResponseData.estimatedRevenue == "" ? "" : fenrisResponseData.estimatedRevenue
            );
            setFenrisRevenue(
              fenrisResponseData.estimatedRevenue == null || fenrisResponseData.estimatedRevenue == "" ? "" : fenrisResponseData.estimatedRevenue
            );
            setFenrisAreaSquareMeters(
              fenrisResponseData.areaSquareMeters == null || fenrisResponseData.areaSquareMeters == "" ? "" : fenrisResponseData.areaSquareMeters
            );
            setTranscationId(fenrisResponseData.transcationId);
            props.balanceCheck();
            setFeedBackButtonActive(false);
          }
          else if (response.data.status === "Failed" && response.data.errorMessage === "User does not have enough credits for Fenris API call.") {
            setHideAllData(true);
            toast.current.show({
              severity: "info", summary: 'Info',
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        })
        .catch((error: any) => {
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status === 429) {
              setShowPopMessage(true);
            } else if (error.response.status === 400) {
              setHideAllData(true);
              toast.current.show({
                severity: "warn", summary: 'Warning',
                detail: error.response.data.errorMessage,
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error", summary: 'Error',
                detail: "Error while getting fenris data.",
                life: 3000,
              });
            }
          }
        });
    }
  };

  const getNowCertsClientsById = (insuredDatabaseId: string, flag: boolean) => {
    setLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken")
      ? localStorage.getItem("NCToken")
      : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          let name = insuredInfo[0].firstName + insuredInfo[0].lastName;
          setCity(insuredInfo[0].city);
          setAddressLine1(insuredInfo[0].addressLine1);
          setName(insuredInfo[0].commercialName);
          setPhoneNumber(insuredInfo[0].phone);
          setState(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);
          if(insuredInfo[0].commercialName == null || insuredInfo[0].commercialName == ""){
            setIsActive1(true)
          }else{
            setIsActive1(false)
          } 
          if(insuredInfo[0].addressLine1 == null || insuredInfo[0].addressLine1 == ""){
            setIsActive2(true)
          }
          else{
            setIsActive2(false)
          }
          if(insuredInfo[0].city == null || insuredInfo[0].city == ""){
            setIsActive3(true)
          }
          else{
            setIsActive3(false)
          }
          if(insuredInfo[0].stateNameOrAbbreviation ==null || insuredInfo[0].stateNameOrAbbreviation ==""){
            setIsActive4(true)
          }
          else{
            setIsActive4(false)
          }
          if(insuredInfo[0].zipCode ==null || insuredInfo[0].zipCode ==""){
            setIsActive5(true)
          }
          else{
            setIsActive5(false)
          }
          if(insuredInfo[0].phone ==null || insuredInfo[0].phone ==""){
            setIsActive7(true)
          }
          else{
            setIsActive7(false)
          }
          if (flag == true) {
            getFenrisAgencyDetails(
              insuredInfo[0].commercialName,
              insuredInfo[0].addressLine1,
              insuredInfo[0].stateNameOrAbbreviation,
              insuredInfo[0].city,
              insuredInfo[0].zipCode
            );
          }
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while getting insured data.",
              life: 3000,
            });
          }
        }
        setLoading(false);
      });
  };

  const handleSelectChange  = (e:any) => {
    const selectedItemIndex=parseInt(e.target.value);
      multipleMatchesResultList.map((item: any,index:any) => {
        if (selectedItemIndex == index) {
          if (item.parent == null) {
            setFenrisNumberOfLocation("");
            setFenrisPrimaryNaicsCode("");
            setFenrisPrimarySICCode("");
            setFenrisyearExtablished("");
          } else {
            setFenrisPrimaryNaicsCode(
              item.parent.primaryNAICSCode == null || item.parent.primaryNAICSCode == ""
                ? ""
                : item.parent.primaryNAICSCode
            );
            setFenrisPrimarySICCode(
              item.parent.primarySICCode == null || item.parent.primarySICCode == ""
                ? ""
                : item.parent.primarySICCode.substring(0, 4)
            );
            setFenrisyearExtablished(
              item.parent.yearEstablished == null || item.parent.yearEstablished == ""
                ? ""
                : item.parent.yearEstablished
            );
            setFenrisNumberOfLocation(
              item.parent.numberOfLocations == null || item.parent.numberOfLocations == ""
                ? ""
                : item.parent.numberOfLocations
            );
          }

          setFenrisPhoneNumber(item.phone == null ? "" : item.phone);
          setFenrisName(item.name);
          if (item.address == null) {
            setFenrisAddressLine1("");
            seFenristCity("");
            setFenrisZipCode("");
            seFenristState("");
            setAddressType("");
          } else {
            setFenrisAddressLine1(item.address.addressLine1);
            seFenristCity(item.address.city);
            setFenrisZipCode(item.address.zipCode);
            seFenristState(item.address.state);
            setFenrisaddressType(item.address.addressType);
          }

          setFenrisCountry(item.country);
          setFenrisEmployees(
            item.employees == null || item.employees == "" ? "" : item.employees.split("-")[1]
          );
          setFenrisEstimatedEmployees(
            item.estimatedEmployees == null || item.estimatedEmployees == ""
              ? ""
              : item.estimatedEmployees
          );
          setFenrisEstimatedPayroll(
            item.estimatedPayroll == null || item.estimatedPayroll == "" ? "" : item.estimatedPayroll
          );
          setFenrisEstimatedRevenue(
            item.estimatedRevenue == null || item.estimatedRevenue == "" ? "" : item.estimatedRevenue
          );
          setFenrisRevenue(
            item.estimatedRevenue == null || item.estimatedRevenue == "" ? "" : item.estimatedRevenue
          );
          setFenrisAreaSquareMeters(
            item.areaSquareMeters == null || item.areaSquareMeters == "" ? "" : item.areaSquareMeters
          );
          setFenrisName(item.name);
        }
      })   
  }

  const onNameChange = (e: any) => {
    if (e.value == true) {
      setIsActive1(e.value);
    } else {
      setIsActive1(e.value);
    }
  };

  const onAddressLine1Change = (e: any) => {
    if (e.value == true) {
      setIsActive2(e.value);
    } else {
      setIsActive2(e.value);
    }
  };

  const onCityChange = (e: any) => {
    if (e.value == true) {
      setIsActive3(e.value);
    } else {
      setIsActive3(e.value);
    }
  };

  const onStateChange = (e: any) => {
    if (e.value == true) {
      setIsActive4(e.value);
    } else {
      setIsActive4(e.value);
    }
  };

  const onZipCodeChange = (e: any) => {
    if (e.value == true) {
      setIsActive5(e.value);
    } else {
      setIsActive5(e.value);
    }
  };

  const onAddresstypeChange = (e: any) => {
    if (e.value == true) {
      setIsActive6(e.value);
    } else {
      setIsActive6(e.value);
    }
  };

  const onPhoneNumberChange = (e: any) => {
    if (e.value == true) {
      setIsActive7(e.value);
    } else {
      setIsActive7(e.value);
    }
  };

  const onEmployeeChange = (e: any) => {
    if (e.value == true) {
      setIsActive8(e.value);
    } else {
      setIsActive8(e.value);
    }
  };

  const OnEstimatedEmployeeChange = (e: any) => {
    if (e.value == true) {
      setIsActive9(e.value);
    } else {
      setIsActive9(e.value);
    }
  };

  const onEstimatedPayrollChange = (e: any) => {
    if (e.value == true) {
      setIsActive10(e.value);
    } else {
      setIsActive10(e.value);
    }
  };

  const onRevenueChange = (e: any) => {
    if (e.value == true) {
      setIsActive11(e.value);
    } else {
      setIsActive11(e.value);
    }
  };

  const onEstimatedRevenueChange = (e: any) => {
    if (e.value == true) {
      setIsActive12(e.value);
    } else {
      setIsActive12(e.value);
    }
  };

  const onAreaSquareMetersChange = (e: any) => {
    if (e.value == true) {
      setIsActive13(e.value);
    } else {
      setIsActive13(e.value);
    }
  };

  const onPrimaryNaicsCodeChange = (e: any) => {
    if (e.value == true) {
      setIsActive14(e.value);
    } else {
      setIsActive14(e.value);
    }
  };

  const onPrimarySiccCodeChange = (e: any) => {
    if (e.value == true) {
      setIsActive15(e.value);
    } else {
      setIsActive15(e.value);
    }
  };

  const onYearExtblishedChange = (e: any) => {
    if (e.value == true) {
      setIsActive16(e.value);
    } else {
      setIsActive16(e.value);
    }
  };

  const onNumberOfLocationChange = (e: any) => {
    if (e.value == true) {
      setIsActive17(e.value);
    } else {
      setIsActive17(e.value);
    }
  };

  const onUpdateClick = () => {
    if (checkActiveToggal()) {
      let insuerdDatabaseId=props.insuredId == undefined || props.insuredId == "" ? props.selectedData.insuredId :props.insuredId;
      onUpdateInsuredDetails(insuerdDatabaseId);
      onUpdateInsuredCLRatingDetails(insuerdDatabaseId);
      onUpdateFenrisHistory();
    }
  };

  const onUpdateFenrisHistory = () => {
    let updatedResponseJson = {};
    updatedResponseJson = {
      name: fenrisname,
      address: {
        addressLine1: fenrisaddressLine1,
        city: fenriscity,
        state: fenrisstate,
        zipCode: fenriszipCode,
      },
      phone: fenrisphoneNumber,
      estimatedEmployees:
        fenrisemployees === null
          ? 0
          : fenrisemployees.length === 0
            ? 0
            : parseInt(fenrisemployees),
      estimatedpayroll:
        fenrisestimatedPayroll === null
          ? 0
          : fenrisestimatedPayroll.length === 0
            ? 0
            : parseInt(fenrisestimatedPayroll),
      estimatedRevenue:
        fenrisrevenue === null
          ? 0
          : fenrisrevenue.length === 0
            ? 0
            : parseInt(fenrisrevenue),
      areaSquareMeters:
        areaSquareMeters === null
          ? 0
          : areaSquareMeters.length === 0
            ? 0
            : parseInt(areaSquareMeters),
      parent: {
        primaryNAICSCode: fenrisprimaryNaicsCode,
        primarySICCode: fenrisprimarySICCode,
        yearEstablished: fenrisyearExtablished,
        numberOfLocations:
          fenrisnumberofLocation === null
            ? 0
            : fenrisnumberofLocation.length === 0
              ? 0
              : parseInt(fenrisnumberofLocation),
      },
      TranscationId: transcationId == null || transcationId == ""|| transcationId == undefined ? props.selectedData.transactionId:transcationId,
    };
    const UpadtedResponseJson = JSON.stringify(updatedResponseJson);

    var config = {
      data: UpadtedResponseJson,
      method: "POST",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/updatehistorybytransacionId`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response: any) => { })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while updating fenris history data.",
              life: 3000,
            });
          }
        }
      });
  };

  const onUpdateInsuredDetails = (insuerdDatabaseId:any) => {
    const ncToken = CommonValues.GetNCToken();
    setUpdateLoading(true);
    setUpdateScreenLoading(true);
    var data = {
      DatabaseId: insuerdDatabaseId,
      insuredCommercialName: isActive1 == true ? fenrisname : name,
      AddressLine1: isActive2 == true ? fenrisaddressLine1 : addressLine1,
      City: isActive3 == true ? fenriscity : city,
      State: isActive4 == true ? fenrisstate : state,
      ZipCode: isActive5 == true ? fenriszipCode : zipCode,
      PhoneNumber: isActive7 == true ? fenrisphoneNumber : phoneNumber,
    };
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/updateinsureddetails?databaseId=${insuerdDatabaseId}&ncToken=${ncToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setTimeout(() => {
          setUpdateLoading(false);
          getNowCertsClientsById(insuerdDatabaseId, false);
          getNowCertsExistingClientsById(insuerdDatabaseId);
          toast.current.show({
            severity: "success", summary: 'Success',
            detail: "Updated the record in Momentum.",
            life: 3000,
          });
        }, 1000);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while updating insured details.",
              life: 3000,
            });
            setUpdateLoading(false);
          }
        }
      });
  };

  const onUpdateInsuredCLRatingDetails = (insuerdDatabaseId:any) => {
    const ncToken = CommonValues.GetNCToken();
    var token = CommonValues.GetToken();
    var data = {
      DatabaseId: insuerdDatabaseId,
      sic: isActive15 == true ? fenrisprimarySICCode : primarySICCode,
      yearBizStarted:
        isActive16 == true
          ? fenrisyearExtablished.toString()
          : yearExtablished.toString(),
      numberOfFullTimeEmployees: isActive8 ? fenrisemployees : employees,
      totalPayroll: isActive10
        ? parseInt(fenrisestimatedPayroll)
        : parseInt(estimatedPayroll),
      naic: isActive14 ? fenrisprimaryNaicsCode : primaryNaicsCode,
      annualSales: isActive11 ? parseFloat(fenrisrevenue) : parseFloat(revenue),
      description: description,
      isActiveSicCode: isActive15 ? true :false
    };
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/updateinsuredclratingdetails?ncToken=${ncToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => { })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Error while updating insured clrating details.",
              life: 3000,
            });
            // setUpdateLoading(false);
          }
        }
      });
  };

  // const onFeedBackButtonClick = (e: any) => {
  //   skipBtnRef.current?.toggle(e);
  // };

  const handleSkip = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  };

  const checkActiveToggal = () => {
    let activeToggalIsValid = true;
    if (
      isActive1 == true ||
      isActive2 == true ||
      isActive3 == true ||
      isActive4 == true ||
      isActive5 == true ||
      isActive7 == true ||
      isActive8 == true ||
      isActive11 == true ||
      isActive13 == true ||
      isActive14 == true ||
      isActive15 == true ||
      isActive16 == true ||
      isActive17 == true
    ) {
      activeToggalIsValid = true;
    } else {
      activeToggalIsValid = false;
      toast.current.show({
        severity: "warn", summary: 'Warning',
        detail: "Please enable at least one toggle button.",
        life: 3000,
      });
    }
    return activeToggalIsValid;
  };

  const onbackbuttonclicked = () => {
    props.FenrisBack();
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="row subpolicy-block">
        {loading || updateScreenLoading ? (
          <div className="d-flex justify-content-center my-2 client-loader-div">
            <AtkLoader />
          </div>
        ) : (
          <>
            {hideAllData ? null : (
              <>
                {showPopMessage ? (
                  <>
                    <Dialog
                      visible={showPopMessage}
                      position={"center"}
                      draggable={false}
                      resizable={false}
                      onHide={() => {
                        setShowPopMessage(false);
                        setHideAllData(true);
                      }}
                    >
                      {addressInfoRequiredMessageBox ? (
                        <AtkMessage
                          appearance="information"
                          messageText={
                            "Please add address information in NowCerts AMS."
                          }
                        />
                      ) : (
                        <AtkMessage
                          appearance="information"
                          messageText={
                            "Please wait 20 seconds before submitting another Prefill request."
                          }
                        />
                      )}
                    </Dialog>{" "}
                  </>
                ) : (
                  <>
                    <div className="col-6 ">
                      <span className="policy-type-heading ">
                        <img src={NcLogo} className="fenris-logo"></img>Existing
                        Data
                      </span>
                      <label className="policy-type-heading position-absolute use-this-value"></label>
                      <ExistingData
                        name={name}
                        addressLine1={addressLine1}
                        city={city}
                        state={state}
                        zipCode={zipCode}
                        phoneNumber={phoneNumber}
                        employees={employees}
                        estimatedPayroll={estimatedPayroll}
                        revenue={revenue}
                        areaSquareMeters={areaSquareMeters}
                        primaryNaicsCode={primaryNaicsCode}
                        primarySICCode={primarySICCode}
                        yearExtablished={yearExtablished}
                        numberofLocation={numberofLocation}
                      />
                    </div>
                    <div className="col-6 position-relative">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <span className="policy-type-heading ">
                            <img src={FenrisLogo} className="fenris-logo"></img>
                            Fenris Data
                            {" "}
                          </span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                        {status == "Multiple Matches Found" ? <select className="fenris-multi-dropdown"  onChange={handleSelectChange}>
                            {multipleMatchesResultList.map((item: any, index: any) => (  
                              <option key={index} value={index}  >
                                {item.name}
                              </option>
                            ))}
                            </select>
                            : null}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          {props.fromHistoryPage == "fromHistoryPage" ? null : (
                            <label className="policy-type-heading position-absolute use-this-value">Use This Value</label>
                          )}
                        </div>
                      </div>

                      <div className="row mt-3 d-flex align-items-center text-12">
                        {status == "Multiple Matches Found" || "Success" ?
                          <FenrisData
                            fenrisname={fenrisname}
                            fenrisaddressLine1={fenrisaddressLine1}
                            fenriscity={fenriscity}
                            fenrisstate={fenrisstate}
                            fenriszipCode={fenriszipCode}
                            fenrisphoneNumber={fenrisphoneNumber}
                            fenrisemployees={fenrisemployees}
                            fenrisestimatedPayroll={fenrisestimatedPayroll}
                            fenrisrevenue={fenrisrevenue}
                            fenrisareaSquareMeters={fenrisareaSquareMeters}
                            fenrisprimaryNaicsCode={fenrisprimaryNaicsCode}
                            fenrisprimarySICCode={fenrisprimarySICCode}
                            fenrisyearExtablished={fenrisyearExtablished}
                            fenrisnumberofLocation={fenrisnumberofLocation}
                            name={name}
                            addressLine1={addressLine1}
                            city={city}
                            state={state}
                            zipCode={zipCode}
                            phoneNumber={phoneNumber}
                            fromHistoryPage={props.fromHistoryPage}
                            transcationId={transcationId}
                            isActive1={isActive1}
                            onNameChange={onNameChange}
                            isActive2={isActive2}
                            onAddressLine1Change={onAddressLine1Change}
                            isActive3={isActive3}
                            onCityChange={onCityChange}
                            isActive4={isActive4}
                            onStateChange={onStateChange}
                            isActive5={isActive5}
                            onZipCodeChange={onZipCodeChange}
                            isActive7={isActive7}
                            onPhoneNumberChange={onPhoneNumberChange}
                            isActive8={isActive8}
                            onEmployeeChange={onEmployeeChange}
                            isActive10={isActive10}
                            onEstimatedPayrollChange={onEstimatedPayrollChange}
                            isActive11={isActive11}
                            onRevenueChange={onRevenueChange}
                            isActive13={isActive13}
                            onAreaSquareMetersChange={onAreaSquareMetersChange}
                            isActive14={isActive14}
                            onPrimaryNaicsCodeChange={onPrimaryNaicsCodeChange}
                            isActive15={isActive15}
                            onPrimarySiccCodeChange={onPrimarySiccCodeChange}
                            isActive16={isActive16}
                            onYearExtblishedChange={onYearExtblishedChange}
                            isActive17={isActive17}
                            onNumberOfLocationChange={onNumberOfLocationChange}
                            getNowCertsClientsById={getNowCertsClientsById}
                            getNowCertsExistingClientsById={getNowCertsExistingClientsById}
                          />
                          : null
                        }
                        <div className="col-lg-6 col-md-6 mt-2 text-end update-ams-data">
                        {props.fromHistoryPage == "fromHistoryPage" ? null : (
                          <>
                            <AtkButtonIconBefore
                              label="Feedback"
                              className="pf-secondary-btn me-2"
                              onClick={(e: any) => feedbackRef.current?.toggle(e)}
                              isDisabled={feedbackButtonActive || props.fromHistoryPage == "fromHistoryPage" ? true : false}
                            />
                            <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                              <FenrisFeedBack transcationId={transcationId} onHideFeedbackPopup={handleSkip} />
                            </OverlayPanel>
                            </>
                            )}
                           
                            {updateLoading ? (
                              <div className="d-flex justify-content-center align-items-center p-2">
                                <AtkLoader></AtkLoader>
                              </div>
                            ) : (
                              <AtkButtonIconBefore
                                label="Update the record in Momentum"
                                className="pf-secondary-btn "
                                onClick={() => {
                                  onUpdateClick();
                                }}
                                isDisabled={updateButtonActive ? true : false}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
