import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { Guid } from "js-guid";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import MomentumGPTAnswer from "../RTG/MomentumGPT/MomentumGPTAnswer";
import AnswerIcon from "../assets/images/Momentum AI Icon-bk.png";
import PolicySummaryIcon from "../assets/images/PolicySummaryReport.svg";
import SupportLoader from "../common/SupportLoader";
import CommonValues from "../common/utils";
import AIMenuFooter from "./AIMenuFooter";
import MomentumAISidebar from "./MomentumAISidebar";
import { marked } from "marked";

interface ChatResponse {
  conversationId: string;
  companyId: number;
  response: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
  ai_tool: string;
  insured_id: string;
  policy_id: string;
}

interface UploadFileResponse {
  fileURL: string;
  fileName: string;
}

export default function AiScansLandingPage() {
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [uploadFileResponses, setUploadFileResponses] = useState<UploadFileResponse[]>([]);
  const [uploadFile, setUploadFile] = useState<File[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [chatBotEmbeded, setChatBotEmbeded] = useState(true);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const [firstFileURL, setFirstFileURL] = useState("");
  const [secondFileURL, setSecondtFileURL] = useState("");
  const [transcribingAudio, setTranscribingAudio] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(null);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [uploadFileToBlobLoading, setUploadFileToBlobLoading] = useState(false);
  const [latestQuestion, setLatestQuestion] = useState({});
  const [dragOver, setDragOver] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const toast: any = useRef("");
  const navigate = useNavigate();

  const suggestedQuestions = [
    {
      title: "Driving License Scan",
      description: "Captures data from PDF/IMAGE and converts it into smart forms.",
      question: "I want to scan driving license and add a driver to the AMS. Can you help me with that?",
    },
    {
      title: "Invoice Scan",
      description: "Captures data from invoices and creates endorsements in AMS.",
      question: "I want to scan invoice PDF and import billing endorsement in AMS.",
    },
    {
      title: "Commission Scan",
      description: "Captures data from PDF and extracts data.",
      question: "I want to scan commission PDF and import commissions statement in AMS.",
    },
    {
      title: "Policy Summarize",
      description: "Upload your policy document and summarize it.",
      question: "I want to create summary of this policy dec page",
    },
    {
      title: "Policy Compare",
      description: "Upload your policy documents and compare them.",
      question: "I want to compare these two policy dec pages",
    },
  ];
  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");
    getConversationHistory(window.location.href.split("/")[4]);
    setUploadFile([]);
    setUploadFileResponses([]);
    setQuestion("");
    setAvatarName(localStorage.getItem("AvatarName"));
  }, [window.location.href.split("/")[4]]);

  const getConversationHistory = (conversationId: string | null) => {
    if (conversationId === undefined || conversationId === null || conversationId === "") {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      // url: `https://localhost:55723/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data.ncMomentumGPTHistoryConversation;
        const fileUrl = response.data.pdfUrl;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            companyId: conversation.companyId,
            response: conversation.response,
            sources: [],
            imageURLs: fileUrl == '' ? [] : [fileUrl],
            videoURLs: [],
            imageSidebar: false,
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike: conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            textToAction: "",
            responseStreaming: false,
            ai_tool: conversation.aI_Tool,
            insured_id: conversation.insuredId,
            policy_id: conversation.policyId,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-answer-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop + 5000 : 0;
      }
    }
  };
  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-question-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const uploadFileToBlob = (file: File) => {
    const data = new FormData();
    let conversationId = window.location.href.split("/")[4];
    data.append("formFile", file);

    const token = CommonValues.GetToken();

    const config = {
      method: "post",
      // url: `https://localhost:55723/api/NCMomentumGPT/upload-file/${conversationId}`,
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/upload-file/${conversationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        const uploadFileResponse: UploadFileResponse = {
          fileName: response.data.fileName,
          fileURL: response.data.fileUrl,
        };
        setUploadFileResponses((prevResponses) => [...prevResponses, uploadFileResponse]);
        setUploadFile((prevResponses) => [...prevResponses, file]);
        setUploadFileToBlobLoading(false);
      })
      .catch((error: any) => {
        setUploadFileToBlobLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else if (error.response.status == 500) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "Unknown error occured while uploading file. Please try again.",
            life: 3000,
          });
        }
      });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && uploadFileResponses.length < 2) {
      setUploadFileToBlobLoading(true);
      if (event.target.files.length > 2) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: `You can upload a maximum of two files at once.`,
          life: 3000,
        });
        setUploadFileToBlobLoading(false);
      } else {
        if (event.target.files.length === 1 && uploadFile.length <= 1) {
          uploadFileToBlob(event.target.files[0]);
        } else if (event.target.files.length === 2 && uploadFile.length === 0) {
          uploadFileToBlob(event.target.files[0]);
          uploadFileToBlob(event.target.files[1]);
        } else {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: `Maximum of 2 files can be uploaded for Momentum GPT`,
            life: 3000,
          });
          setUploadFileToBlobLoading(false);
        }
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: `You can upload a maximum of two files per conversation. To upload additional files, please start a new conversation.`,
        life: 3000,
      });
    }
    event.target.value = "";
  };

  const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    const files = Array.from(e.dataTransfer.files);

    if (files.length > 2) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "You can upload a maximum of two files at once.",
        life: 3000,
      });
      return;
    }

    if (uploadFileResponses.length >= 2) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "You can upload a maximum of two files per conversation. To upload additional files, please start a new conversation.",
        life: 3000,
      });
      return;
    }

    setUploadFileToBlobLoading(true);

    if (files.length === 1 && uploadFile.length <= 1) {
      uploadFileToBlob(files[0]);
    } else if (files.length === 2 && uploadFile.length === 0) {
      uploadFileToBlob(files[0]);
      uploadFileToBlob(files[1]);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Maximum of 2 files can be uploaded for Momentum GPT",
        life: 3000,
      });
      setUploadFileToBlobLoading(false);
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSendQuestionClick(question);
    }
  };
  const onSendQuestionClick = async (que: string) => {
    if (!que.trim() || uploadFileToBlobLoading) return;

    setUploadFile([]);
    const conversationId = window.location.href.split("/")[4]; // Ensure correct conversation ID

    let latestQue = {
      conversationId: conversationId,
      question: que,
      type: "ai-scans",
    };
    setLatestQuestion(latestQue);
    const newQuestion: ChatResponse = {
      conversationId: conversationId,
      companyId: 0,
      question: que,
      response: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      textToAction: "",
      responseStreaming: false,
      ai_tool: "",
      insured_id: "",
      policy_id: "",
    };

    let updatedChatResponses = [...chatResponses, { ...newQuestion }];
    setChatResponses(updatedChatResponses);
    setQuestion("");
    // scrollToBottom();
    scrollToBottomForAnswer(true);

    setQuestionLoading(true);

    const formData = new FormData();
    formData.append("human_prompt", `${que}`);
    if (uploadFileResponses.length === 1) {
      formData.append("file_path", uploadFileResponses[0].fileURL);
    } else if (uploadFileResponses.length === 2) {
      formData.append("file_path", uploadFileResponses[0].fileURL);
      formData.append("second_file_path", uploadFileResponses[1].fileURL);
    }
    formData.append("nc_token", localStorage.getItem("NCToken") || "");
    formData.append("conversationId", conversationId);

    const token = CommonValues.GetToken();

    try {
      const ctrl = new AbortController();
      await fetchEventSource(
        // `http://127.0.0.1:8000/chat`,
        `${process.env.REACT_APP_MomentumGPT}/chat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          signal: ctrl.signal,
          openWhenHidden: true,
          onopen(response: Response): Promise<void> {
            if (response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (response.status !== 200) {
              setQuestionLoading(false);
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while sending question",
                life: 3000,
              });
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let responseObject = JSON.parse(event.data);
            if (responseObject.response !== "") {
              setQuestionLoading(false);
            }

            updatedChatResponses = updatedChatResponses.map((chatResponse, index) => {
              if (index === updatedChatResponses.length - 1) {
                return {
                  ...chatResponse,
                  response: (chatResponse.response || "") + responseObject.response,
                  chatId: responseObject.chatId,
                  imageURLs: responseObject.file_url ? [responseObject.file_url] : [],
                  ai_tool: responseObject.ai_tool,
                  insured_id: responseObject.insuredClientId,
                  policy_id: responseObject.policyId,
                  responseStreaming: responseObject.responseStreaming,
                };
              }
              return chatResponse;
            });

            if (responseObject.error_message !== "") {
              let lastIndex = updatedChatResponses.length - 1;
              updatedChatResponses[lastIndex].response = responseObject.error_message;
              ctrl.abort()
            }

            setChatResponses(updatedChatResponses);

            // scrollToBottomForAnswer(true);
          },
          onclose() {
            setQuestionLoading(false);
          },
          onerror(error: any) {
            setQuestionLoading(false);
          },
        }
      );
    } catch (error: any) { }

    // const config = {
    //   method: "post",
    //   // url: `http://127.0.0.1:8000/chat`,
    //   url: `${process.env.REACT_APP_MomentumGPT}/chat`,
    //   headers: { Authorization: `Bearer ${token}` },
    //   data: formData,
    // };

    // try {
    //   setUploadFile([]);
    //   const response = await axios(config);
    //   setQuestionLoading(false);

    //   updatedChatResponses = updatedChatResponses.map((chatResponse, index) =>
    //     index === updatedChatResponses.length - 1
    //       ? {
    //           ...chatResponse,
    //           response: (chatResponse.response || "") + response.data.response,
    //           chatId: response.data.chatId,
    //           imageURLs: response.data.file_url ? [response.data.file_url] : [],
    //           ai_tool: response.data.ai_tool,
    //           insured_id: response.data.insuredClientId,
    //           policy_id: response.data.policyId,
    //         }
    //       : chatResponse
    //   );
    //   setChatResponses(updatedChatResponses);
    // } catch (error) {
    //   setQuestionLoading(false);
    //   toast.current.show({
    //     severity: "error",
    //     detail: "Error fetching response",
    //     life: 3000,
    //   });
    // }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const onNewConversionlick = () => {
    setChatResponses([]);
    setUploadFile([]);
    navigate(`/ai-scans/${Guid.newGuid()}`);
  };

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: any[] = [];

      setRecordingAudio(true);
      setAudioRecorder(recorder);

      recorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        transcribeAudio(audioBlob);
      };

      recorder.start();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      setTranscribingAudio(true);
      const data = new FormData();
      var audioFile = new File([audioBlob], "recording.wav", {
        type: audioBlob.type,
      });
      data.append("audioFile", audioFile);

      const token = CommonValues.GetToken();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_PolicyAgent_Url}/process_audio`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setTranscribingAudio(false);
          setQuestion(response.data.result);
        })
        .catch((error: any) => {
          setTranscribingAudio(false);
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 500) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: "Unknown error occured while processing audio. Please try again.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const stopRecordingAudio = () => {
    if (audioRecorder) {
      audioRecorder.stop();
    }

    setRecordingAudio(false);
  };

  const removeFile = (fileName: string) => {
    setUploadFile((uploadFiles: File[]) => uploadFiles.filter((file: File) => file.name !== fileName));
    setUploadFileResponses((uploadFileRespones: UploadFileResponse[]) =>
      uploadFileRespones.filter((file: UploadFileResponse) => file.fileName !== fileName)
    );
  };

  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };
  const handleDragOver = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!dragOver) {
      setDragOver(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => setDragOver(false), 100);
  };

  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
            ...response,
            like: like,
            disLike: disLike,
          }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onThumbsDownClick = (
    chatId: string,
    like: boolean,
    disLike: boolean
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
            ...response,
            like: like,
            disLike: disLike,
          }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateFeedback(chatId, feedBack);
  };

  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };

  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };

  const onCopyResponseClick = async (copiedData: string) => {
    const htmlContent = await marked(copiedData);
    const plainTextContent = htmlToPlainTextWithFormatting(htmlContent);

    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const textBlob = new Blob([plainTextContent], { type: "text/plain" });

    const clipboardItem = new ClipboardItem({
      "text/plain": textBlob,
      "text/html": htmlBlob,
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied Successfully!",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error occurred while copying data",
          life: 3000,
        });
      });
  };
  const htmlToPlainTextWithFormatting = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const elementsToReplace = tempDiv.querySelectorAll("br, p, h1, h2, h3, h4, h5, h6");
    elementsToReplace.forEach((element: any) => {
      const lineBreak = document.createTextNode("\n");
      element.parentNode.insertBefore(lineBreak, element);
    });

    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/nc-ai-scan-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => { })
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };

  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/nc-ai-scan-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                  ...response,
                  feedback: "",
                  showFeedback: false,
                  feedbackLoading: false,
                }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.chatId === chatId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };

  return (
    <div className="ai-support-main-page">
      <Toast ref={toast} />

      <div className="ai-wrapper min-vh-100 ">
        <div className="ai-wrapper-container  position-relative z-1">
          <div className="row g-4 mx-auto">
            <div
              className="col-2 px-0 border-end"
              style={{ paddingTop: "2%" }}
            >
              <MomentumAISidebar
                pagename="ai-scans"
                latestQuestion={latestQuestion}
              />
            </div>
            <div className="col-10 px-0" style={{ paddingTop: "2%" }}>
              <div className="container p-3 d-flex flex-column h-100">
                <div className="ai-main-content-wrapper" ref={chatContainerRef}>
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const isLastAnswer = index === chatResponses.length - 1;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${isLastAnswer && !questionLoading ? "last-answer-block-nc-chat-bot" : "question-answer-block"}`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {chatBotEmbeded && <span className="me-1 avtar border-rounded">{avatarName}</span>}
                            <div className="mb-1 text-14 ai-question-block">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {chatBotEmbeded && (
                              <span className="me-1">
                                <img alt="NowCerts Logo" src={AnswerIcon} className="me-2" height={30} />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${isLastAnswer && questionLoading ? "last-question-block-nc-chat-bot" : "question-answer-block"
                                  }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="70%" className="mb-2 d-block"></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton width="40%" className="mb-2 d-block"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="20%" className="mb-2 d-block"></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <MomentumGPTAnswer
                                chatResponse={response}
                                index={index}
                                chatBotEmbeded={false}
                                onFeedbackSubmit={onFeedbackSubmit}
                                onFeedbackChange={onFeedbackChange}
                                toggleFeedbackVisibility={
                                  toggleFeedbackVisibility
                                }
                                onCopyResponseClick={onCopyResponseClick}
                                onThumbsUpClick={onThumbsUpClick}
                                onThumbsDownClick={onThumbsDownClick}
                                showImageSidebar={showImageSidebar}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="row chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <>
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 text-16 text-center mb-4" style={{ color: "#381576" }}>
                              <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                                <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                                  <span className="ai-content-heading">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="lucide lucide-search"
                                    >
                                      <circle cx="11" cy="11" r="8"></circle>
                                      <path d="m21 21-4.3-4.3"></path>
                                    </svg>
                                  </span>
                                  <span style={{ color: "#381574" }}>
                                    <h4 className="mb-0">AI-Powered Scans</h4>
                                    <p className="small mb-0">AI services workflow assistance</p>
                                  </span>
                                </div>
                              </div>{" "}
                            </div>
                            <div className="d-flex flex-nowrap justify-content-center gap-3 overflow-auto w-100 text-center">
                              {suggestedQuestions.map((suggestedQuestion: any, index: number) => {
                                let isLast = index === suggestedQuestions.length - 1;
                                return (
                                  <>
                                    <span
                                      className="ai-border-radius  p-3 text-muted small bg-momentum-light d-inline  shadow-sm mb-2 me-2"
                                      onClick={() => {
                                          setQuestion(suggestedQuestion.question);
                                          textAreaRef.current?.focus();
                                      }}
                                    >
                                      <h6 className="fw-bold">{suggestedQuestion.title}</h6>
                                      <span>{suggestedQuestion.description}</span>
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                            <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                              <span className="ai-content-heading">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="lucide lucide-search"
                                >
                                  <circle cx="11" cy="11" r="8"></circle>
                                  <path d="m21 21-4.3-4.3"></path>
                                </svg>
                              </span>
                              <span style={{ color: "#381574" }}>
                                <h4 className="mb-0">AI-Powered Scans</h4>
                                <p className="small mb-0">AI services workflow assistance</p>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>

                <div className="p-3 mt-auto">
                  {uploadFileToBlobLoading ? (
                    <div className="d-flex align-items-center justify-content-end">
                      <SupportLoader />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-end">
                      {uploadFile !== null &&
                        uploadFile.map((file: File) => {
                          let fileUrl = "";
                          uploadFileResponses.forEach((item: UploadFileResponse) => {
                            if (item.fileName === file.name) {
                              fileUrl = item.fileURL;
                            }
                          });

                          return (
                            <>
                              <div className="d-flex align-items-center justify-content-between border bg-white p-1 mb-1 rounded text-14 float-end position-relative me-1 " title={file.name}>
                                {file.name.toLowerCase().endsWith(".pdf") ? (
                                  <img className="mx-1" src={PolicySummaryIcon} width={20} />
                                ) : (
                                  <img className="mx-1" src={fileUrl === undefined ? "" : fileUrl} width={20} />
                                )}
                                <span className="text-truncate me-3 text-muted">
                                  {file.name.length > 30 ? file.name.substring(0, 30) + "....." : file.name}
                                </span>
                                <span className="ai-close-icon text-danger text-12" title="Remove" onClick={() => removeFile(file.name)}>
                                  ✖
                                </span>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  )}

                  <div className="position-relative">
                    <div
                      className={`d-flex align-items-center  ai-border-radius bg-white shadow-sm transition-all overflow-hidden ${isFocused ? "ai-textarea " : "border"
                        }`}
                    >
                      {dragOver && (
                        <div className="drop-overlay">
                          <span>Drop your file here</span>
                        </div>
                      )}
                      <textarea
                        ref={textAreaRef}
                        placeholder="Message Momentum AI..."
                        name="question"
                        value={question}
                        className={`form-control border-0 py-3 px-4 w-100 bg-transparent text-dark resize-none `}
                        onChange={onQuestionChange}
                        rows={1}
                        onKeyDown={onEnterClick}
                        style={{ minHeight: "56px", maxHeight: "200px" }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      ></textarea>

                      <div className="d-flex align-items-center gap-2 px-3">
                        <input
                          className=" custom-file-input"
                          type="file"
                          id="firstFileInput"
                          name="filefield"
                          accept="application/pdf, image/png, image/jpeg"
                          onChange={handleFileUpload}
                          multiple
                        />
                        <label className="" htmlFor="firstFileInput">
                          <span className="me-2 show-cursor">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-paperclip"
                              style={{ opacity: 0.5 }}
                            >
                              <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                            </svg>
                          </span>
                        </label>

                        {transcribingAudio ? (
                          <div className="d-inline-flex align-items-center me-2">
                            <AtkLoader />
                          </div>
                        ) : recordingAudio ? (
                          <span onClick={stopRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        ) : (
                          <span onClick={startRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                              style={{ opacity: 0.5 }}
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        )}
                        <span className="show-cursor" onClick={() => onSendQuestionClick(question)} title="Send">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke={question.length > 0 ? "#6b44bd" : "currentColor"}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-send"
                            style={{ opacity: question.length > 0 ? 1 : 0.5 }}
                          >
                            <path d="M14.536 21.686a.5.5 0 0 0 .937-.024l6.5-19a.496.496 0 0 0-.635-.635l-19 6.5a.5.5 0 0 0-.024.937l7.93 3.18a2 2 0 0 1 1.112 1.11z"></path>
                            <path d="m21.854 2.147-10.94 10.939"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <AIMenuFooter pagename="ai-scans" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
