import { useEffect, useState, useRef } from "react";
import ShowMore from "./ShowMore";
import SubpolicyDetails from "./SubpolicyDetails";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkCheckbox from "../../AtlaskitControls/AtkCheckbox";
import CommonValues from "../../common/utils";
import axios from "axios";
import CapitalResponse from "./CapitalResponse";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import IpfsResponse from "./IpfsResponse";
import GotoPFResponse from "./GotoPFResponse";
import Peoples from "./Peoples";
import AtkProgressBar from "../../AtlaskitControls/AtkProgressBar";
import AddPolicyIcon from "../../assets/images/plus.svg";
import moment from "moment";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Toast } from "primereact/toast";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import { Navigate, useNavigate } from "react-router-dom";
import FirstInsuranceFunding from "./FirstInsuranceFunding";
import UsPremiumFinance from "./UsPremiumFinance";

export default function ExistingPolicyDetails(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [termCondition, setTermCondition] = useState(false);
  const [dBId, setDBId] = useState("");
  const [coverageCode, setCoverageCode] = useState<any>([]);
  const [updatedLob, setUpdatedLob] = useState<any>([]);
  const [carrier, setCarrier] = useState<any>([]);
  const [checkboxError, setCheckboxError] = useState("");
  const [carrierCodeError, setCarrierCodeError] = useState("");
  const [coverageCodeError, setCoverageCodeError] = useState([""]);
  const [invalidPolicyError, setInvalidPolicyError] = useState([""]);
  const [carrierCityError, setCarrierCityError] = useState("");
  const [carrierStateError, setCarrierStateError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [GotoPfCodeList, setGotoPfCodeList] = useState(Array().fill([]));
  const [carrierList, setCarrierList] = useState([]);
  const [generalAgencyList, setGeneralAgencyList] = useState([]);
  const [GotoPfLoading, setGotoPfLoading] = useState(false);
  const [carrierCodeLoading, setCarrierCodeLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDisableForm, setShowDisableForm] = useState(true);
  const [wholePolicyData, setWholePolicyData] = useState<any>({});
  const [sstate, setSstate] = useState("");
  const [term, setTerm] = useState<any>([""]);
  const [effectiveDate, setEffectiveDate] = useState<any>([""]);
  const [currentPremium, setCurrentPremium] = useState("");
  const [policyNumber, setPolicyNumber] = useState([""]);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [businessPhoneNumberError, setBusinessPhoneNumberError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successMessageForPolicy, setSuccessMessageForPolicy] = useState("");
  const [iPFSShowResponse, setIPFSShowResponse] = useState(false);
  const [capitalShowResponse, setCapitalShowResponse] = useState(false);
  const [peoplesShowResponse, setPeoplesShowResponse] = useState(false);
  const [firstInsuranceFundingShowResponse, setFirstInsuranceFundingShowResponse] = useState(false);
  const [usPfShowResponse, setUsPfShowResponse] = useState(false);
  const [particularPremiumSettings, setParticularPremiumSettings] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [clientId, setClientId] = useState("");
  const [lastName, setLastName] = useState("");
  const [search, setSearch] = useState([""]);
  const [carrierSearch, setCarrierSearch] = useState<any>([]);
  const [generalAgencySearch, setGeneralAgencySearch] = useState([""]);
  const [inputValue, setInputValue] = useState<any>({});
  const [carrierValue, setCarrierValue] = useState<any>({});
  const [searchCoverageCodeLoading, setSearchCoverageCodeLoading] = useState(false);
  const [carrierName, setCarrierName] = useState([""]);
  const [carrierAddress, setCarrierAddress] = useState([""]);
  const [carrierCity, setCarrierCity] = useState([""]);
  const [carrierState, setCarrierState] = useState([""]);
  const [carrierZip, setCarrierZip] = useState([""]);
  const [carrierCountry, setCarrierCountry] = useState(["USA"]);
  const [gaName, setGaName] = useState([""]);
  const [gaAddress, setGaAddress] = useState([""]);
  const [gaCity, setGaCity] = useState([""]);
  const [gaState, setGaState] = useState([""]);
  const [gaZip, setGaZip] = useState([""]);
  const [gaSearchCode, setGaSearchCode] = useState([""]);
  const [gaCountry, setGaCountry] = useState([""]);
  const [policyIndex, setPolicyIndex] = useState("");
  const [businessProfileError, setBusinessProfileError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [carrierNameError, setCarrierNameError] = useState([""]);
  const [carrierZipCodeError, setCarrierZipCodeError] = useState([""]);
  const [termError, setTermError] = useState(Array().fill([]));
  const [currentPremiumError, setCurrentPremiumError] = useState(Array().fill([]));
  const [agentName, setAgentName] = useState("");
  const [agentAddress, setAgentAddress] = useState("");
  const [agentCity, setAgentCity] = useState("");
  const [agentState, setAgentState] = useState("");
  const [agentZip, setAgentZip] = useState("");
  const [agentCountry, setAgentCountry] = useState("");
  const [agentPhone, setAgentPhone] = useState("");
  const [brokerFee, setBrokerFee] = useState<any>([]);
  const [carrierLoading, setCarrierLoading] = useState(false);
  const [generalAgencyLoading, setGeneralAgencySearchLoading] = useState(false);
  const [capitalLogoUrl, setCapitalLogoUrl] = useState("");
  const [iPFSLogoUrl, setIpfsLogoUrl] = useState("");
  const [peoplesLogoUrl, setPeoplesLogoUrl] = useState("");
  const [firstInsuranceFundingLogoUrl, setFirstInsuranceFundingLogoUrl] = useState("");
  const [usPfLogoUrl, setUsPfLogoUrl] = useState("");
  const [subPolicyLobs, setSubPolicyLobs] = useState<any>([]);
  const [nCPolicyId, setNCPolicyId] = useState("");
  const [nCInsuredId, setNCInsuredId] = useState("");
  const [currentPremiumsArray, setCurrentPremiumsArray] = useState<any>([""]);
  const [activePanel, setActivePanel] = useState<any>([]);
  const [progressLoading, setProgressLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(2);
  const [taxes, setTaxes] = useState<any>([]);
  const [fees, setFees] = useState<any>([]);
  const [totalCurrentPremium, setTotalCurrentPremium] = useState("");
  const [showTrashIcon, setShowTrashIcon] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [effectiveDateError, setEffectiveDateError] = useState(Array().fill([]));
  const [policyNumberError, setPolicyNumberError] = useState(Array().fill([]));
  const [gotoShowResponse, setGotoShowResponse] = useState(false);
  const [gotoLogoUrl, setGotoLogoUrl] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedSubPolicy, setSelectedSubPolicy] = useState(0);
  const [showPolicyMessage, setShowPolicyMessage] = useState("");
  const [minimumEarned, setminimumEarned] = useState<any>([]);
  const [capitalTarget, setCapitalTarget] = useState("CAPITAL");
  const hostname = window.location.hostname;
  const [momentumLoading, setMomentumLoading] = useState(false);
  const [ncCombiningPoliciesLoading, setNcCombiningPoliciesLoading] = useState(false);
  const [dataMappingLoading, setDataMappingLoading] = useState<any>([]);
  const [nowCertsClientsByIdLoading, setNowCertsClientsByIdLoading] = useState(false);

  useEffect(() => {
    getPremiumFinanceSettingsById();
    if (props.reuseSavedQuoteId != null && props.reuseSavedQuoteId != "") {
      getSavedQuoteDetails(props.reuseSavedQuoteId);
    }
    else if (props.momentumPolicyId != null && props.momentumPolicyId != "" && props.enableQuickQuote === true) {
      let momentumPolicyId=props.momentumPolicyId;
      navigate("/quickquoteconfirmation", { state: { momentumPolicyId } });
    }
    else
      if (localStorage.getItem("IsIndicative") == null || localStorage.getItem("IsIndicative") == "false") {
        if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
          let policyIdsString = localStorage.getItem("policyIds");

          setTimeout(() => {
            if (isFirstLoad) {
              setIsFirstLoad(false);
              setProgressLoading(true);
            }
            getAmsAgencySettings();
            setNCPolicyId(props.selectedPolicyId);
            let lobArray: any = [];
            let newPolicyNum: any = [];
            let newEffDt: any = [];
            let newPrem: any = [];
            let newCoverageCodes: any = [];
            let newCarrierValues: any = [];
            let newCarrierName: any = [];
            let newCarrierAddress: any = [];
            let newCarrierCity: any = [];
            let newCarrierState: any = [];
            let newCarrierZip: any = [];
            let newGa: any = [];
            let newTerm: any = [];
            let newTaxes: any = [];
            let newFees: any = [];
            if (props.momentumPolicyId != null && props.momentumPolicyId != "") {
              getPolicyDetails(
                props.momentumPolicyId,
                0,
                lobArray,
                newPolicyNum,
                newEffDt,
                newPrem,
                newCoverageCodes,
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip,
                newTerm,
                newTaxes,
                newFees
              );
            } else if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
              try {
                const policyIds = JSON.parse(policyIdsString);
                const processPolicyIds = async (index: number) => {
                  setNcCombiningPoliciesLoading(true);
                  if (index < policyIds.length) {
                    const policy = policyIds[index];
                    await getPolicyDetails(
                      policy.id,
                      policy.index,
                      lobArray,
                      newPolicyNum,
                      newEffDt,
                      newPrem,
                      newCoverageCodes,
                      newCarrierValues,
                      newCarrierName,
                      newCarrierAddress,
                      newCarrierCity,
                      newCarrierState,
                      newCarrierZip,
                      newTerm,
                      newTaxes,
                      newFees
                    );
                    await processPolicyIds(index + 1);
                    //await getPolicyEndorsementDetailList(props.selectedPolicyId);
                  } else {
                    setNcCombiningPoliciesLoading(false);
                  }
                };
                setNCPolicyId(policyIds[0].id);
                let storedData = localStorage.getItem("client");
                let clianttlist = storedData ? JSON.parse(storedData) : null;
                getNowCertsClientsById(clianttlist.clientId);
                setClientId(clianttlist.clientId);
                setNCInsuredId(clianttlist.clientId);
                processPolicyIds(0);
                policyIds.forEach((item: any, index: any) => {
                  newCarrierName.push(item.ncCarrierName);
                  newGa.push(item.ncGaName);
                  getCoverageCodeMapping(item.ncCoverageCode, "code", index, newCoverageCodes, [], []);
                });
                getCarrierCodeMappingForHawksoftBundle(newCarrierName, []);
                getGeneralAgencyMappingForHawksoftBundle(newGa, []);
              } catch (error) {
                console.error("Error parsing policyIds:", error);
                setNcCombiningPoliciesLoading(false);
              }
            } else {
              getPolicyDetails(
                props.selectedPolicyId,
                0,
                lobArray,
                newPolicyNum,
                newEffDt,
                newPrem,
                newCoverageCodes,
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip,
                newTerm,
                newTaxes,
                newFees
              );
              //getPolicyEndorsementDetailList(props.selectedPolicyId)
            }
          }, 10);
        } else if (CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          let policyDataString = localStorage.getItem("policyData");
          let policyData = policyDataString ? JSON.parse(policyDataString) : {};
          let LocalHawksoftSettings: any = localStorage.getItem("HawksoftSettings");
          let HawksoftSettings = LocalHawksoftSettings ? JSON.parse(LocalHawksoftSettings) : {};

          setShowTrashIcon(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length);
          let autoMapCoverageCode = "";
          let currentPremiumsArrayForErrorLoop = [];

          let policyIdsString = localStorage.getItem("policyIds");
          if ((HawksoftSettings.isBundled == null || HawksoftSettings.isBundled == true) && policyIdsString !== null) {
            let lobArray: any = [];
            const newValueAutomap: any = [];
            let coverageCodeErrorLoop: any = [];
            let invalidPolicyErrorLoop: any = [];
            let newEffDt: any = [];
            let effectiveDateerror: any = [];
            let newPolicyNum: any = [];
            let policyerror: any = [];
            let newTaxes: any = [];
            let newFees: any = [];
            let newTerm: any = [];
            let termerror: any = [];
            let newPremiumValues: any = [];
            newPremiumValues[0] = 0;

            let carrierNames: any = [];
            let gaNames: any = [];

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != null
            ) {
              newPolicyNum[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber;
            } else {
              policyerror[0] = "Please enter policy number";
            }
            policyData.PolicyData.policies.forEach((policy: any) => {
              policy.linesOfBusiness.forEach((element: any, index: any) => {
                newPremiumValues[0] = element.calculatedPremium + newPremiumValues[0];
              });
            });
            if (newPremiumValues[0] === 0) {
              policyData.PolicyData.policies.forEach((element: any) => {
                if (element.currentPremium === null || element.currentPremium === "" || element.currentPremium === 0) {
                  newPremiumValues[0] = element.quotedPremium + newPremiumValues[0];
                } else {
                  newPremiumValues[0] = element.currentPremium + newPremiumValues[0];
                }
              });
            }
            newPremiumValues[0] = parseFloat(newPremiumValues[0].toFixed(2));

            if (policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length === 1 && newPremiumValues[0] === 0) {
              if (newPremiumValues[0] === 0) {
                policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((lob: any, index: any) => {
                  lob.coverages.forEach((coverage: any) => {
                    newPremiumValues[0] = newPremiumValues[0] + coverage.premium;
                  });
                });
              }

              let newToggleValues = [];
              newToggleValues[0] = 1;
              setActivePanel(newToggleValues);
              setCurrentPremiumsArray(newPremiumValues);
              upDateTotalCurrentPremiuim(newPremiumValues, true);
            }
            lobArray.push({
              code: policyData.PolicyData.policies[policyData.SelectedPolicyIndex].title,
              calculatedPremium: newPremiumValues[0],
            });
            setShowTrashIcon(lobArray.length);

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != null
            ) {
              newEffDt[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate;
            } else {
              effectiveDateerror[0] = "Please enter effective date";
            }
            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != null
            ) {
              newTerm[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term.replace(/\D/g, "");
            } else {
              termerror[0] = "Please enter term";
            }
            newTaxes[0] = 0;
            newFees[0] = 0;
            getCoverageCodeMapping(
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].title,
              "code",
              0,
              newValueAutomap,
              coverageCodeErrorLoop,
              invalidPolicyErrorLoop
            );
            policyData.PolicyData.policies.forEach((policy: any, index: any) => {
              policy.linesOfBusiness.forEach((element: any, index: any) => {
                carrierNames[0] = policy[HawksoftSettings.carrier == null || HawksoftSettings.carrier == "" ? "program" : HawksoftSettings.carrier];
                gaNames[0] =
                  policy[HawksoftSettings.generalAgency == null || HawksoftSettings.generalAgency == "" ? "carrier" : HawksoftSettings.generalAgency];
              });
            });

            getCarrierCodeMappingForHawksoftBundle(carrierNames, []);
            getGeneralAgencyMappingForHawksoftBundle(gaNames, []);

            setSubPolicyLobs(lobArray);
            setPolicyNumber(newPolicyNum);
            setPolicyNumberError(policyerror);
            setTerm(newTerm);
            setTermError(termerror);
            setEffectiveDate(newEffDt);
            setEffectiveDateError(effectiveDateerror);
            setTaxes(newTaxes);
            setFees(newFees);
            setCurrentPremiumsArray(newPremiumValues);
            setTotalCurrentPremium(newPremiumValues[0]);
          } else if ((HawksoftSettings.isBundled == null || HawksoftSettings.isBundled == true) && policyIdsString == null) {
            let lobArray: any = [];
            const newValueAutomap: any = [];
            let coverageCodeErrorLoop: any = [];
            let invalidPolicyErrorLoop: any = [];
            let newEffDt: any = [];
            let effectiveDateerror: any = [];
            let newPolicyNum: any = [];
            let policyerror: any = [];
            let newTaxes: any = [];
            let newFees: any = [];
            let newPremiumValues: any = [];
            newPremiumValues[0] = 0;
            let newTerm: any = [];
            let termerror: any = [];
            newTaxes[0] = 0;
            newFees[0] = 0;
            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != null
            ) {
              newEffDt[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate;
            } else {
              effectiveDateerror[0] = "Please enter effective date";
            }
            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != null
            ) {
              newPolicyNum[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber;
            } else {
              policyerror[0] = "Please enter policy number";
            }
            policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((element: any, index: any) => {
              newPremiumValues[0] = element.calculatedPremium + newPremiumValues[0];
            });
            newPremiumValues[0] = parseFloat(newPremiumValues[0].toFixed(2));

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != null
            ) {
              newTerm[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term.replace(/\D/g, "");
            } else {
              termerror[0] = "Please enter term";
            }
            if (policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length === 1 && newPremiumValues[0] === 0) {
              if (
                policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === null ||
                policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === "" ||
                policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === 0
              ) {
                newPremiumValues[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].quotedPremium;
              } else {
                newPremiumValues[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium;
              }

              if (newPremiumValues[0] === 0) {
                policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((lob: any, index: any) => {
                  lob.coverages.forEach((coverage: any) => {
                    newPremiumValues[0] = newPremiumValues[0] + coverage.premium;
                  });
                });
              }

              let newToggleValues = [];
              newToggleValues[0] = 1;
              setActivePanel(newToggleValues);
              setCurrentPremiumsArray(newPremiumValues);
              upDateTotalCurrentPremiuim(newPremiumValues, true);
            }
            lobArray.push({
              code: policyData.PolicyData.policies[policyData.SelectedPolicyIndex].title,
              calculatedPremium: newPremiumValues[0],
            });
            setShowTrashIcon(lobArray.length);

            getCoverageCodeMapping(
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].title,
              "code",
              0,
              newValueAutomap,
              coverageCodeErrorLoop,
              invalidPolicyErrorLoop
            );
            let newCarrierValues: any = [];
            let newCarrierName: any = [];
            let newCarrierAddress: any = [];
            let newCarrierCity: any = [];
            let newCarrierState: any = [];
            let newCarrierZip: any = [];

            getCarrierCodeMapping(
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex][
              HawksoftSettings.carrier == null || HawksoftSettings.carrier == "" ? "program" : HawksoftSettings.carrier
              ],
              "",
              0,
              1,
              invalidPolicyErrorLoop,
              newCarrierValues,
              newCarrierName,
              newCarrierAddress,
              newCarrierCity,
              newCarrierState,
              newCarrierZip
            );
            getGeneralAgencyMapping(
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex][
              HawksoftSettings.generalAgency == null || HawksoftSettings.generalAgency == "" ? "carrier" : HawksoftSettings.generalAgency
              ],
              "",
              0,
              1,
              invalidPolicyErrorLoop
            );

            setSubPolicyLobs(lobArray);
            setEffectiveDate(newEffDt);
            setEffectiveDateError(effectiveDateerror);
            setPolicyNumber(newPolicyNum);
            setTerm(newTerm);
            setTaxes(newTaxes);
            setFees(newFees);
            setCurrentPremiumsArray(newPremiumValues);
            setTotalCurrentPremium(newPremiumValues[0]);
            setPolicyNumberError(policyerror);
            setTermError(termerror);
          } else if (policyIdsString !== null) {
            let lobArray: string[] = [];
            const newValues = [...currentPremiumsArray];
            let newTaxes: any = [];
            let newFees: any = [];
            const newValueAutomap: any = [];
            let coverageCodeErrorLoop: any = [];
            let invalidPolicyErrorLoop: any = [];
            let newEffDt: any = [];
            let newPolicyNum: any = [];
            let policyerror: any = [];
            let effectiveDateerror: any = [];
            let pindex = -1;
            let carrierNames: any = [];
            let gaNames: any = [];
            let newTerm: any = [];
            let termerror: any = [];
            policyData.PolicyData.policies.forEach((policy: any, index: any) => {
              if (policy.linesOfBusiness.length > 0) {
                policy.linesOfBusiness.forEach((element: any, index: any) => {
                  pindex = pindex + 1;
                  lobArray.push(element);
                  if (element.calculatedPremium === 0) {
                    if (element.coverages.length > 0) {
                      element.coverages.forEach((coverage: any) => {
                        newValues[pindex] = newValues[index] + coverage.premium;
                      });
                    } else {
                      newValues[pindex] = 0;
                    }
                  } else {
                    newValues[pindex] = element.calculatedPremium;
                  }

                  getCoverageCodeMapping(element.code, "code", pindex, newValueAutomap, coverageCodeErrorLoop, invalidPolicyErrorLoop);
                  newTaxes[pindex] = 0;
                  newFees[pindex] = 0;
                  carrierNames[pindex] =
                    policy[HawksoftSettings.carrier == null || HawksoftSettings.carrier == "" ? "program" : HawksoftSettings.carrier];
                  gaNames[pindex] =
                    policy[HawksoftSettings.generalAgency == null || HawksoftSettings.generalAgency == "" ? "carrier" : HawksoftSettings.generalAgency];

                  if (policyData.PolicyData.policies.length > 0 && policy.effectiveDate != "" && policy.effectiveDate != null) {
                    newEffDt[pindex] = policy.effectiveDate;
                    effectiveDateerror[pindex] = "";
                  } else {
                    effectiveDateerror[pindex] = "Please enter effective date";
                    newEffDt[pindex] = "";
                  }
                  if (policyData.PolicyData.policies.length > 0 && policy.policyNumber != "" && policy.policyNumber != null) {
                    newPolicyNum[pindex] = policy.policyNumber;
                    policyerror[pindex] = "";
                  } else {
                    policyerror[pindex] = "Please enter policy number";
                    newPolicyNum[pindex] = "";
                  }
                  if (policyData.PolicyData.policies.length > 0 && policy.term != "" && policy.term != null) {
                    newTerm[pindex] = policy.term.replace(/\D/g, "");
                  } else {
                    termerror[pindex] = "Please enter term";
                  }
                  if (policy.linesOfBusiness.length === 1) {
                    if (policy.currentPremium === null || policy.currentPremium === "" || policy.currentPremium === 0) {
                      newValues[pindex] = policy.quotedPremium;
                    }
                    let newToggleValues = [];
                    newToggleValues[0] = 1;
                    setActivePanel(newToggleValues);
                    lobArray.forEach((element: any, index: number) => {
                      element.calculatedPremium = newValues[index];
                    });
                    upDateTotalCurrentPremiuim(newValues, true);
                  } else {
                    upDateTotalCurrentPremiuim(lobArray, false);
                  }
                });
              } else {
                setLoadingCount(0);
              }
              setPolicyNumber(newPolicyNum);
              setPolicyNumberError(policyerror);
              setEffectiveDate(newEffDt);
              setEffectiveDateError(effectiveDateerror);
              setTaxes(newTaxes);
              setFees(newFees);
              setTerm(newTerm);
              setTermError(termerror);
              currentPremiumsArrayForErrorLoop = newValues;
              setCurrentPremiumsArray(newValues);

              setSubPolicyLobs(lobArray);
              upDateTotalCurrentPremiuim(lobArray, false);
            });

            getCarrierCodeMappingForHawksoftBundle(carrierNames, []);
            getGeneralAgencyMappingForHawksoftBundle(gaNames, []);
          } else {
            if (policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length > 0) {
              autoMapCoverageCode = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness[0].code;
              let lobArray: string[] = [];
              const newValues = [...currentPremiumsArray];
              let newTaxes: any = [];
              let newFees: any = [];
              const newValueAutomap: any = [];
              let coverageCodeErrorLoop: any = [];
              let invalidPolicyErrorLoop: any = [];
              let newEffDt: any = [];
              let newPolicyNum: any = [];
              let policyerror: any = [];
              let effectiveDateerror: any = [];
              let newTerm: any = [];
              let termerror: any = [];
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((element: any, index: any) => {
                lobArray.push(element);

                if (element.calculatedPremium === 0) {
                  if (element.coverages.length > 0) {
                    element.coverages.forEach((coverage: any) => {
                      newValues[index] = newValues[index] + coverage.premium;
                    });
                  } else {
                    newValues[index] = 0;
                  }
                } else {
                  newValues[index] = element.calculatedPremium;
                }

                getCoverageCodeMapping(element.code, "code", index, newValueAutomap, coverageCodeErrorLoop, invalidPolicyErrorLoop);
                newTaxes[index] = 0;
                newFees[index] = 0;

                if (
                  policyData.PolicyData.policies.length > 0 &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != "" &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != null
                ) {
                  newEffDt[index] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate;
                } else {
                  effectiveDateerror[index] = "Please enter effective date";
                }
                if (
                  policyData.PolicyData.policies.length > 0 &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != "" &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != null
                ) {
                  newPolicyNum[index] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber;
                  policyerror[index] = "";
                } else {
                  policyerror[index] = "Please enter policy number";
                  newPolicyNum[index] = "";
                }
                if (
                  policyData.PolicyData.policies.length > 0 &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != "" &&
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != null
                ) {
                  newTerm[index] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term.replace(/\D/g, "");
                } else {
                  termerror[index] = "Please enter term";
                }
              });
              setPolicyNumber(newPolicyNum);
              setPolicyNumberError(policyerror);
              setEffectiveDate(newEffDt);
              setEffectiveDateError(effectiveDateerror);
              setTaxes(newTaxes);
              setFees(newFees);
              setTerm(newTerm);
              setTermError(termerror);
              let newCarrierValues: any = [];
              let newCarrierName: any = [];
              let newCarrierAddress: any = [];
              let newCarrierCity: any = [];
              let newCarrierState: any = [];
              let newCarrierZip: any = [];

              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((element: any, index: any) => {
                getCarrierCodeMapping(
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex][
                  HawksoftSettings.carrier == null || HawksoftSettings.carrier == "" ? "program" : HawksoftSettings.carrier
                  ],
                  "",
                  index,
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length,
                  invalidPolicyErrorLoop,
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip
                );

                getGeneralAgencyMapping(
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex][
                  HawksoftSettings.generalAgency == null || HawksoftSettings.generalAgency == "" ? "carrier" : HawksoftSettings.generalAgency
                  ],
                  "",
                  index,
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length,
                  invalidPolicyErrorLoop
                );
              });
              if (policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length === 1) {
                if (
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === null ||
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === "" ||
                  policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium === 0
                ) {
                  newValues[0] = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].quotedPremium;
                }
                let newToggleValues = [];
                newToggleValues[0] = 1;
                setActivePanel(newToggleValues);
                lobArray.forEach((element: any, index: number) => {
                  element.calculatedPremium = newValues[index];
                });
                upDateTotalCurrentPremiuim(newValues, true);
              } else {
                upDateTotalCurrentPremiuim(lobArray, false);
              }
              currentPremiumsArrayForErrorLoop = newValues;
              setCurrentPremiumsArray(newValues);

              setSubPolicyLobs(lobArray);
              upDateTotalCurrentPremiuim(lobArray, false);
            } else {
              setProgressLoading(false);
              let lobArray = [];
              const newPremiumValues = [];

              lobArray.push({ code: "N/A" });
              setShowTrashIcon(lobArray.length);

              newPremiumValues[0] = 0;
              currentPremiumsArrayForErrorLoop = newPremiumValues;
              setCurrentPremiumsArray(newPremiumValues);

              setSubPolicyLobs(lobArray);
              upDateTotalCurrentPremiuim(lobArray, false);
              setCoverageCode(lobArray);
              setInputValue(lobArray);
              let newToggleValues = [];
              newToggleValues[0] = 1;
              setActivePanel(newToggleValues);
              let newTaxes: any = [];
              let newFees: any = [];
              newTaxes[0] = 0;
              newFees[0] = 0;
              setTaxes(newTaxes);
              setFees(newFees);
            }
            let arrayValues = [...currentPremiumError];
            currentPremiumsArrayForErrorLoop.forEach((element: any, index: any) => {
              const newValue = [...arrayValues];
              if (element.toString() == "" || element.toString() == null) {
                newValue[index] = "Please enter premium amount";
                if (window.innerWidth > 767) {
                  window.scroll(0, 0);
                }
              } else if (element <= 0) {
                newValue[index] = "Please enter a valid current premium amount above 0";
                if (window.innerWidth > 767) {
                  window.scroll(0, 0);
                }
              } else {
                newValue[index] = "";
              }
              arrayValues = newValue;
              setCurrentPremiumError(newValue);
            });
          }

          getAmsAgencySettings();

          setWholePolicyData(policyData);
          localStorage.setItem("currentPage", "PREMIUMFINANCEDETAILS");
          if (policyData != undefined && policyData != null) {
            // setPolicyId(policyData.PolicyId);
            setPolicyIndex(policyData.HsPolicyIndex);
          }
          if (policyData.PolicyData != undefined && policyData.PolicyData != null) {
            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].state != "" &&
              policyData.PolicyData.addresses[0].state != null
            ) {
              setSstate(policyData.PolicyData.addresses[0].state);
            }
            if (
              (policyData.PolicyData.addresses.length > 0 && policyData.PolicyData.addresses[0].state == "") ||
              policyData.PolicyData.addresses[0].state == null
            ) {
              setShowDisableForm(true);
              setStateError("Please enter state");
            } else if (!/^[a-zA-Z\s]+$/.test(policyData.PolicyData.addresses[0].state)) {
              setShowDisableForm(true);
              setStateError("State can only contain alphabetic characters");
            }
            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].city != "" &&
              policyData.PolicyData.addresses[0].city != null
            ) {
              setCity(policyData.PolicyData.addresses[0].city);
            }
            if (
              (policyData.PolicyData.addresses.length > 0 && policyData.PolicyData.addresses[0].city == "") ||
              policyData.PolicyData.addresses[0].city == null
            ) {
              setShowDisableForm(true);
              setCityError("Please enter city");
            }

            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].country != "" &&
              policyData.PolicyData.addresses[0].country != null
            ) {
              setCountry(policyData.PolicyData.addresses[0].country);
            } else {
              setCountry("USA");
            }
            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].line1 != "" &&
              policyData.PolicyData.addresses[0].line1 != null
            ) {
              setAddLine1(policyData.PolicyData.addresses[0].line1);
            }
            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].line2 != "" &&
              policyData.PolicyData.addresses[0].line2 != null
            ) {
              setAddLine2(policyData.PolicyData.addresses[0].line2);
            }

            if (
              (policyData.PolicyData.addresses.length > 0 && policyData.PolicyData.addresses[0].line1 == "") ||
              policyData.PolicyData.addresses[0].line1 == null
            ) {
              setShowDisableForm(true);
              setAddressError("Please enter address");
            }
            if (
              policyData.PolicyData.addresses.length > 0 &&
              policyData.PolicyData.addresses[0].zipCode != "" &&
              policyData.PolicyData.addresses[0].zipCode != null
            ) {
              setZipCode(policyData.PolicyData.addresses[0].zipCode);
            }
            if (
              (policyData.PolicyData.addresses.length > 0 && policyData.PolicyData.addresses[0].zipCode == "") ||
              policyData.PolicyData.addresses[0].zipCode == null
            ) {
              setShowDisableForm(true);
              setZipCodeError("Please enter zip code");
            } else if (!/^[0-9-]*$/.test(zipCode)) {
              setZipCodeError("Zip code can only contain numbers and '-'");
            }

            if (policyData.PolicyData.policies.length > 0) {
              const selectedPolicy = policyData.PolicyData.policies[policyData.SelectedPolicyIndex];
              if (typeof policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber === "string") {
              } else if (Array.isArray(selectedPolicy.policyNumber) && selectedPolicy.policyNumber.length > 0) {
                let policyArrayValues = [...policyNumberError];
                selectedPolicy.policyNumber.forEach((element: any, index: any) => {
                  const newValue = [...policyArrayValues];
                  if (element == null || element.toString() === "") {
                    newValue[index] = "Please enter policy number";
                    if (window.innerWidth > 767) {
                      window.scroll(0, 0);
                    }
                  } else {
                    newValue[index] = "";
                  }
                  policyArrayValues = newValue;
                });
                setPolicyNumberError(policyArrayValues);
              } else {
                const newValue = ["Please enter policy number"];
                setPolicyNumberError(newValue);
                if (window.innerWidth > 767) {
                  window.scroll(0, 0);
                }
              }
            }

            if (
              policyData.PolicyData.businessProfiles.length > 0 &&
              policyData.PolicyData.businessProfiles[0].name != "" &&
              policyData.PolicyData.businessProfiles[0].name != null
            ) {
              setBusinessProfile(policyData.PolicyData.businessProfiles[0].name);
            }

            if (
              policyData.PolicyData.businessProfiles == "" ||
              (policyData.PolicyData.businessProfiles.length > 0 &&
                (policyData.PolicyData.businessProfiles[0].name == "" ||
                  policyData.PolicyData.businessProfiles[0].name == null ||
                  policyData.PolicyData.businessProfiles[0].name == undefined))
            ) {
              setShowDisableForm(true);
              setBusinessProfileError("Please enter business name");
            }

            let businessPhoneNum = "";
            policyData.PolicyData.personalProfiles.forEach((personalProfile: any) => {
              personalProfile.contacts.forEach((contact: any) => {
                if ((contact.contactType === "WorkPhone" || contact.contactType === "CellPhone") && businessPhoneNum == "") {
                  businessPhoneNum = contact.data;
                }
              });
            });
            setBusinessPhoneNumber(businessPhoneNum);

            let businessEmailId = "";
            policyData.PolicyData.personalProfiles.forEach((personalProfile: any) => {
              personalProfile.contacts.forEach((contact: any) => {
                if ((contact.contactType === "WorkEmail" || contact.contactType === "HomeEmail") && businessEmailId == "") {
                  businessEmailId = contact.data;
                }
              });
            });
            setBusinessEmail(businessEmailId);

            if (
              policyData.PolicyData.personalProfiles.length > 0 &&
              policyData.PolicyData.personalProfiles[0].firstName != "" &&
              policyData.PolicyData.personalProfiles[0].firstName != null
            ) {
              setFirstName(policyData.PolicyData.personalProfiles[0].firstName);
            }
            if (
              policyData.PolicyData.personalProfiles.length > 0 &&
              policyData.PolicyData.personalProfiles[0].lastName != "" &&
              policyData.PolicyData.personalProfiles[0].lastName != null
            ) {
              setLastName(policyData.PolicyData.personalProfiles[0].lastName);
            }
            if (policyData.PolicyData != null && policyData.PolicyData.id != "" && policyData.PolicyData.id != null) {
              setClientId(policyData.PolicyData.id);
            }

            if (
              (policyData.PolicyData.policies.length > 0 && policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier == "") ||
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier == null
            ) {
              let carrierNameErrorLoop: any = [];
              carrierNameErrorLoop = "Please enter carrier name";
              setCarrierNameError(carrierNameErrorLoop);
            }
          }
        } else if (CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id) {
          let policyIdsString = localStorage.getItem("policyIds");
          let lobArray: any = [];
          let newCoverageCodes: any = [];
          let newPolicyNum: any = [];
          let newEffDt: any = [];
          let newPrem: any = [];
          let newCarrierValues: any = [];
          let newCarrierName: any = [];
          let newCarrierAddress: any = [];
          let newCarrierCity: any = [];
          let newCarrierState: any = [];
          let newCarrierZip: any = [];
          let newTerm: any = [];
          if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
            try {
              const policyIds = JSON.parse(policyIdsString);

              policyIds.forEach((policy: any, index: number) => {
                getPolicyDetailsVertafore(
                  policy.id,
                  index,
                  lobArray,
                  newCoverageCodes,
                  newPolicyNum,
                  newEffDt,
                  newPrem,
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip,
                  newTerm
                );
                setFirstName(policy.clientName);
              });
            } catch (error) {
              console.error("Error parsing policyIds:", error);
            }
          } else {
            let policyDataString = localStorage.getItem("policyData");
            let policyData = policyDataString ? JSON.parse(policyDataString) : null;
            if (policyData != null) {
              getPolicyDetailsVertafore(
                policyData.PolicyId,
                0,
                lobArray,
                newCoverageCodes,
                newPolicyNum,
                newEffDt,
                newPrem,
                newCarrierValues,
                newCarrierName,
                newCarrierAddress,
                newCarrierCity,
                newCarrierState,
                newCarrierZip,
                newTerm
              );
              setFirstName(policyData.PolicyData[policyData.SelectedPolicyIndex + 1].clientName);
            }
          }

          let newToggleValues = [];
          newToggleValues[0] = 0;
          setActivePanel(newToggleValues);
          getAmsAgencySettings();
        }
      } else {
        if (localStorage.getItem("isNewPolicy") != "" && localStorage.getItem("isNewPolicy") != null && localStorage.getItem("isNewPolicy") == "true") {
          let newToggleValues = [];
          newToggleValues[0] = 1;
          setActivePanel(newToggleValues);

          let newTaxes: any = [];
          let newFees: any = [];
          let newEarnedFees: any = [];
          newTaxes[0] = 0;
          newFees[0] = 0;
          newEarnedFees[0] = 0;
          setTaxes(newTaxes);
          setFees(newFees);
          setminimumEarned(newEarnedFees);

          let policyDataString = localStorage.getItem("policyData");
          let policyData = policyDataString ? JSON.parse(policyDataString) : {};

          let autoMapCoverageCode = "";
          if (
            policyData &&
            policyData.PolicyData &&
            policyData.PolicyData.policies &&
            policyData.PolicyData.policies[policyData.SelectedPolicyIndex] &&
            policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness &&
            policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.length > 0
          ) {
            autoMapCoverageCode = policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness[0].code;

            let lobArray: string[] = [];
            const newValues = [...currentPremiumsArray];
            let newEffDt: any = [];

            const newValueAutomap: any = [];
            policyData.PolicyData.policies[policyData.SelectedPolicyIndex].linesOfBusiness.forEach((element: any, index: any) => {
              lobArray.push(element);
              newValues[index] = element.calculatedPremium;
              newEffDt[index] = element.effectiveDate;
              getCoverageCodeMapping(element.code, "code", index, newValueAutomap, [], []);
            });
            setEffectiveDate(newEffDt);
            setCurrentPremiumsArray(newValues);

            const newValue = [];
            newValue[0] = "Please enter premium amount";
            setCurrentPremiumError(newValue);

            setSubPolicyLobs(lobArray);
            upDateTotalCurrentPremiuim(lobArray, false);
            if (
              CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id &&
              policyData.PolicyData.businessProfiles[0].clientid != "" &&
              policyData.PolicyData.businessProfiles[0].clientid != ""
            ) {
              setClientId(policyData.PolicyData.businessProfiles[0].clientid);
              getNowCertsClientsById(policyData.PolicyData.businessProfiles[0].clientid);
              setNCInsuredId(policyData.PolicyData.businessProfiles[0].clientid);
            } else if (
              CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id &&
              policyData.PolicyData.businessProfiles[0].clientid != "" &&
              policyData.PolicyData.businessProfiles[0].clientid != ""
            ) {
              getCustomerDetailsAndPolicyDetailsByCustomerId(policyData.PolicyData.businessProfiles[0].clientid);
            }
          } else {
            let lobArray: string[] = [];
            const newPremiumValues = [];
            const newEffDt = [];
            lobArray.push("");
            newPremiumValues[0] = 0;
            newEffDt[0] = new Date();
            setCurrentPremiumsArray(newPremiumValues);
            setEffectiveDate(newEffDt);

            setSubPolicyLobs(lobArray);
            upDateTotalCurrentPremiuim(lobArray, false);
            setCoverageCode(lobArray);
            setInputValue(lobArray);
          }
          getAmsAgencySettings();

          const selectedPolicyIndex = policyData?.SelectedPolicyIndex ?? 0;
          const policy = policyData?.PolicyData?.policies?.[selectedPolicyIndex];
          let newCarrierValues: any = [];
          let newCarrierName: any = [];
          let newCarrierAddress: any = [];
          let newCarrierCity: any = [];
          let newCarrierState: any = [];
          let newCarrierZip: any = [];

          if (policy) {
            getCarrierCodeMapping(
              policy.carrier,
              "",
              0,
              1,
              [],
              newCarrierValues,
              newCarrierName,
              newCarrierAddress,
              newCarrierCity,
              newCarrierState,
              newCarrierZip
            );
          }
          setWholePolicyData(policyData);
          localStorage.setItem("currentPage", "PREMIUMFINANCEDETAILS");
          if (policyData != undefined && policyData != null) {
            // setPolicyId(policyData.PolicyId);
            setPolicyIndex(policyData.HsPolicyIndex);
          }

          if (policyData && policyData.PolicyData && policyData.PolicyData.addresses && policyData.PolicyData.addresses.length > 0) {
            const address = policyData.PolicyData.addresses[0];
            if (address.state !== "" && address.state !== null) {
              setSstate(address.state);
            } else if (!/^[a-zA-Z\s]+$/.test(address.state)) {
              setStateError("State can only contain alphabetic characters");
            } else {
              setShowDisableForm(true);
              setStateError("Please enter state");
            }

            if (address.city !== "" && address.city !== null) {
              setCity(address.city);
            } else {
              setShowDisableForm(true);
              setCityError("Please enter city");
            }
            if (address.country !== "" && address.country !== null) {
              setCountry(address.country);
            } else {
              setCountry("USA");
            }

            if (address.line1 !== "" && address.line1 !== null) {
              setAddLine1(address.line1);
            } else {
              setShowDisableForm(true);
              setAddressError("Please enter address");
            }
            if (address.line2 !== "" && address.line2 !== null) {
              setAddLine2(address.line2);
            }

            if (address.zipCode !== "" && address.zipCode !== null) {
              setZipCode(address.zipCode);
            } else if (!/^[0-9-]*$/.test(zipCode)) {
              setZipCodeError("Zip code can only contain numbers and '-'");
            } else {
              setShowDisableForm(true);
              setZipCodeError("Please enter zip code");
            }

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term != null
            ) {
              setTerm(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].term);
            }

            const newTerm = [];
            newTerm[0] = 12;
            setTerm(newTerm);

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate != null
            ) {
              setEffectiveDate(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].effectiveDate);
            }

            if (policyData.PolicyData.policies.length > 0) {
              const selectedPolicy = policyData.PolicyData.policies[policyData.SelectedPolicyIndex];
              if (Array.isArray(selectedPolicy.linesOfBusiness) && selectedPolicy.linesOfBusiness.length > 0) {
                let policyArrayValues = [...policyNumberError];
                selectedPolicy.linesOfBusiness.forEach((element: any, index: any) => {
                  const newValue = [...policyArrayValues];
                  if (element.policyNumber == null || element.policyNumber.toString() === "") {
                    newValue[index] = "Please enter policy number";
                    if (window.innerWidth > 767) {
                      window.scroll(0, 0);
                    }
                  } else {
                    newValue[index] = "";
                  }
                  policyArrayValues = newValue;
                });
                setPolicyNumberError(policyArrayValues);
              } else {
                const newValue = ["Please enter policy number"];
                setPolicyNumberError(newValue);
                if (window.innerWidth > 767) {
                  window.scroll(0, 0);
                }
              }
            }

            if (policyData.PolicyData.policies.length > 0) {
              const selectedPolicy = policyData.PolicyData.policies[policyData.SelectedPolicyIndex];
              if (Array.isArray(selectedPolicy.linesOfBusiness) && selectedPolicy.linesOfBusiness.length > 0) {
                let effectiveDateArrayValues = [...policyNumberError];
                selectedPolicy.linesOfBusiness.forEach((element: any, index: any) => {
                  const newValue = [...effectiveDateArrayValues];
                  if (element.effectiveDate == null || element.effectiveDate.toString() === "") {
                    newValue[index] = "Please enter effective date";
                    if (window.innerWidth > 767) {
                      window.scroll(0, 0);
                    }
                  } else {
                    newValue[index] = "";
                  }
                  effectiveDateArrayValues = newValue;
                });
                setEffectiveDateError(effectiveDateArrayValues);
              } else {
                const newValue = ["Please enter effective date"];
                setEffectiveDateError(newValue);
                if (window.innerWidth > 767) {
                  window.scroll(0, 0);
                }
              }
            }
            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium != null &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium != 0
            ) {
              setCurrentPremium(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].currentPremium);
            } else {
              setCurrentPremium(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].quotedPremium);
            }
            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber != null
            ) {
              setPolicyNumber(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].policyNumber);
            }
            if (
              policyData.PolicyData.businessProfiles.length > 0 &&
              policyData.PolicyData.businessProfiles[0].name != "" &&
              policyData.PolicyData.businessProfiles[0].name != null
            ) {
              setBusinessProfile(policyData.PolicyData.businessProfiles[0].name);
            }
            if (
              policyData.PolicyData.businessProfiles == "" ||
              (policyData.PolicyData.businessProfiles.length > 0 &&
                (policyData.PolicyData.businessProfiles[0].name == "" ||
                  policyData.PolicyData.businessProfiles[0].name == null ||
                  policyData.PolicyData.businessProfiles[0].name == undefined))
            ) {
              setShowDisableForm(true);
              setBusinessProfileError("Please enter business name");
            }
            let businessPhoneNum = "";
            let businessEmailId = "";
            if (policyData.PolicyData.contacts != undefined) {
              policyData.PolicyData.contacts.forEach((personalProfile: any) => {
                personalProfile.contacts.forEach((contact: any) => {
                  if ((contact.contactType === "WorkPhone" || contact.contactType === "CellPhone") && businessPhoneNum == "") {
                    businessPhoneNum = contact.data;
                  }
                });
              });

              policyData.PolicyData.contacts.forEach((personalProfile: any) => {
                personalProfile.contacts.forEach((contact: any) => {
                  if ((contact.contactType === "WorkEmail" || contact.contactType === "HomeEmail") && businessEmailId == "") {
                    businessEmailId = contact.data;
                  }
                });
              });
            }

            setBusinessPhoneNumber(businessPhoneNum);
            setBusinessEmail(businessEmailId);
            if (
              policyData.PolicyData.personalProfiles.length > 0 &&
              policyData.PolicyData.personalProfiles[0].firstName != "" &&
              policyData.PolicyData.personalProfiles[0].firstName != null
            ) {
              setFirstName(policyData.PolicyData.personalProfiles[0].firstName);
            }
            if (
              policyData.PolicyData.personalProfiles.length > 0 &&
              policyData.PolicyData.personalProfiles[0].lastName != "" &&
              policyData.PolicyData.personalProfiles[0].lastName != null
            ) {
              setLastName(policyData.PolicyData.personalProfiles[0].lastName);
            }
            if (policyData.PolicyData != null && policyData.PolicyData.id != "" && policyData.PolicyData.id != null) {
              setClientId(policyData.PolicyData.id);
            }

            if (
              policyData.PolicyData.policies.length > 0 &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier != "" &&
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier != null
            ) {
              setCarrierName(policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier);
            }

            if (
              (policyData.PolicyData.policies.length > 0 && policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier == "") ||
              policyData.PolicyData.policies[policyData.SelectedPolicyIndex].carrier == null
            ) {
              // setCarrierNameError("Please enter carrier name");
            }
          }
        } else if (
          localStorage.getItem("IsIndicative") != "" &&
          localStorage.getItem("IsIndicative") != null &&
          localStorage.getItem("IsIndicative") == "true"
        ) {
          let policyDataString = localStorage.getItem("policyData");
          let policyData = policyDataString ? JSON.parse(policyDataString) : {};
          let autoMapCoverageCode = "";
          let currentPremiumsArrayForErrorLoop = [];
          let LocalHawksoftSettings: any = localStorage.getItem("HawksoftSettings");
          let HawksoftSettings = LocalHawksoftSettings ? JSON.parse(LocalHawksoftSettings) : {};

          if (policyData.PolicyData.linesOfBusiness.length > 0) {
            autoMapCoverageCode = policyData.PolicyData.linesOfBusiness[0].code;

            let lobArray: string[] = [];
            const newValues = [...currentPremiumsArray];

            const newValueAutomap: any = [];
            let coverageCodeErrorLoop: any = [];
            let newTaxes: any = [];
            let newFees: any = [];
            let newPolicyNum: any = [];
            let newEffDt: any = [];
            let invalidPolicyErrorLoop: any = [];
            let newTerm: any = [];
            let newBrokerFees: any = [];

            if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
              policyData.PolicyData.linesOfBusiness.forEach((element: any, index: any) => {
                let searchBy = "";
                if (element && element.code) {
                  if (element.code.includes(" ")) {
                    searchBy = "desc";
                  } else {
                    searchBy = "code";
                  }
                  if (element.code == "" || element.code == null) {
                    element.code = "*";
                  }
                }
                // element.calculatedPremium = policyData.PolicyData.currentPremium;
                lobArray.push(element);

                getCoverageCodeMapping(element.code, searchBy, index, newValueAutomap, [], invalidPolicyErrorLoop);
                newValues[index] = element.calculatedPremium; //policyData.PolicyData.currentPremium;
                newTaxes[index] =
                  policyData.PolicyData.taxes[index] == undefined ||
                    policyData.PolicyData.taxes[index] == "" ||
                    policyData.PolicyData.taxes[index] == null
                    ? 0
                    : policyData.PolicyData.taxes[index];
                newFees[index] =
                  policyData.PolicyData.fees[index] == undefined || policyData.PolicyData.fees[index] == "" || policyData.PolicyData.fees[index] == null
                    ? 0
                    : policyData.PolicyData.fees[index];
                newTerm[index] =
                  policyData.PolicyData.terms[index] == undefined ||
                    policyData.PolicyData.terms[index] == "" ||
                    policyData.PolicyData.terms[index] == null
                    ? 0
                    : policyData.PolicyData.terms[index];

                newBrokerFees[index] =
                  policyData.PolicyData.brokerFees == null ||
                    policyData.PolicyData.brokerFees[index] == undefined ||
                    policyData.PolicyData.brokerFees[index] == "" ||
                    policyData.PolicyData.brokerFees[index] == null
                    ? 0
                    : policyData.PolicyData.brokerFees[index];

                minimumEarned[index] =
                  policyData.PolicyData.earnedPercent[index] == undefined ||
                    policyData.PolicyData.earnedPercent[index] == "" ||
                    policyData.PolicyData.earnedPercent[index] == null
                    ? 0
                    : policyData.PolicyData.earnedPercent[index];
                newPolicyNum[index] = element.policyNumber;
                newEffDt[index] = element.effectiveDate;
              });
            } else {
              policyData.PolicyData.linesOfBusiness.forEach((element: any, index: any) => {
                if (element.code == "" || element.code == null) {
                  element.code = "*";
                }
                lobArray.push(element);
                // element.calculatedPremium=policyData.PolicyData.currentPremium
                //newValues[index] = element.calculatedPremium;
                getCoverageCodeMapping(element.code, "code", index, newValueAutomap, [], invalidPolicyErrorLoop);
                newValues[index] = element.calculatedPremium;
                newTaxes[index] =
                  policyData.PolicyData.taxes[index] == undefined ||
                    policyData.PolicyData.taxes[index] == "" ||
                    policyData.PolicyData.taxes[index] == null
                    ? 0
                    : policyData.PolicyData.taxes[index];
                newFees[index] =
                  policyData.PolicyData.fees[index] == undefined || policyData.PolicyData.fees[index] == "" || policyData.PolicyData.fees[index] == null
                    ? 0
                    : policyData.PolicyData.fees[index];

                newTerm[index] =
                  policyData.PolicyData.terms[index] == undefined ||
                    policyData.PolicyData.terms[index] == "" ||
                    policyData.PolicyData.terms[index] == null
                    ? 0
                    : policyData.PolicyData.terms[index];

                newBrokerFees[index] =
                  policyData.PolicyData.brokerFees[index] == undefined ||
                    policyData.PolicyData.brokerFees[index] == "" ||
                    policyData.PolicyData.brokerFees[index] == null
                    ? 0
                    : policyData.PolicyData.brokerFees[index];
                minimumEarned[index] =
                  policyData.PolicyData.earnedPercent[index] == undefined ||
                    policyData.PolicyData.earnedPercent[index] == "" ||
                    policyData.PolicyData.earnedPercent[index] == null
                    ? 0
                    : policyData.PolicyData.earnedPercent[index];
                newPolicyNum[index] = element.policyNumber;
                newEffDt[index] = element.effectiveDate;
              });
            }
            setTerm(newTerm);
            setBrokerFee(newBrokerFees);
            setFees(newFees);
            setTaxes(newTaxes);
            setPolicyNumber(newPolicyNum);
            setEffectiveDate(newEffDt);
            if (policyData.PolicyData.linesOfBusiness.length == 1) {
              let newToggleValues = [];
              newToggleValues[0] = 0;
              setActivePanel(newToggleValues);
            }
            let newCarrierValues: any = [];
            let newCarrierName: any = [];
            let newCarrierAddress: any = [];
            let newCarrierCity: any = [];
            let newCarrierState: any = [];
            let newCarrierZip: any = [];

            policyData.PolicyData.linesOfBusiness.forEach((element: any, index: any) => {
              policyData.PolicyData.carrier[index] != undefined
                ? getCarrierCodeMapping(
                  policyData.PolicyData.carrier[index].description != null ? policyData.PolicyData.carrier[index].description : "",
                  "",
                  index,
                  policyData.PolicyData.linesOfBusiness.length,
                  invalidPolicyErrorLoop,
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip
                )
                : getCarrierCodeMapping(
                  "",
                  "",
                  index,
                  policyData.PolicyData.linesOfBusiness.length,
                  invalidPolicyErrorLoop,
                  newCarrierValues,
                  newCarrierName,
                  newCarrierAddress,
                  newCarrierCity,
                  newCarrierState,
                  newCarrierZip
                );
            });

            policyData.PolicyData.generalAgency.forEach((element: any, index: any) => {
              getGeneralAgencyMapping(element.name, "", index, policyData.PolicyData.linesOfBusiness.length, invalidPolicyErrorLoop);
            });
            currentPremiumsArrayForErrorLoop = newValues;
            setCurrentPremiumsArray(newValues);

            setSubPolicyLobs(lobArray);
            setUpdatedLob(lobArray);
            upDateTotalCurrentPremiuim(lobArray, false);
          } else {
            let lobArray: string[] = [];
            const newPremiumValues = [];
            lobArray.push("");
            newPremiumValues[0] = 0;
            currentPremiumsArrayForErrorLoop = newPremiumValues;
            setCurrentPremiumsArray(newPremiumValues);
            setSubPolicyLobs(lobArray);
            upDateTotalCurrentPremiuim(lobArray, false);
            setCoverageCode(lobArray);
            setInputValue(lobArray);
            setUpdatedLob(lobArray);

            let newTaxes: any = [];
            let newFees: any = [];
            newTaxes[0] = 0;
            newFees[0] = 0;
            setTaxes(newTaxes);
            setFees(newFees);
            let newToggleValues = [];
            newToggleValues[0] = 1;
            setActivePanel(newToggleValues);
          }

          let arrayValues = [...currentPremiumError];
          currentPremiumsArrayForErrorLoop.forEach((element: any, index: any) => {
            const newValue = [...arrayValues];
            if (element == null || element.toString() == "") {
              newValue[index] = "Please enter premium amount";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else if (element <= 0) {
              newValue[index] = "Please enter a valid current premium amount above 0";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            arrayValues = newValue;
            setCurrentPremiumError(newValue);
          });
          getAmsAgencySettings();

          let newValues: any = [];
          let newCarrierAddress: any = [];
          let newCarrierCity: any = [];
          let newCarrierState: any = [];
          let newCarrierZip: any = [];
          let invalidPolicyErrorLoop: any = [];

          setLoadingCount((count) => count - 1);

          setWholePolicyData(policyData);
          localStorage.setItem("currentPage", "PREMIUMFINANCEDETAILS");
          if (policyData != undefined && policyData != null) {
            setPolicyIndex(policyData.HsPolicyIndex);
          }
          if (policyData.PolicyData != undefined && policyData.PolicyData != null) {
            if (policyData.PolicyData.businessAddress.state != "" && policyData.PolicyData.businessAddress.state != null) {
              setSstate(policyData.PolicyData.businessAddress.state);
            }
            if (policyData.PolicyData.businessAddress.state == "" || policyData.PolicyData.businessAddress.state == null) {
              setShowDisableForm(true);
              setStateError("Please enter state");
            } else if (!/^[a-zA-Z\s]+$/.test(policyData.PolicyData.businessAddress.state)) {
              setShowDisableForm(true);
              setStateError("State can only contain alphabetic characters");
            }
            if (policyData.PolicyData.businessAddress.city != "" && policyData.PolicyData.businessAddress.city != null) {
              setCity(policyData.PolicyData.businessAddress.city);
            }
            if (policyData.PolicyData.businessAddress.city == "" || policyData.PolicyData.businessAddress.city == null) {
              setShowDisableForm(true);
              setCityError("Please enter city");
            }

            if (policyData.PolicyData.businessAddress.country != "" && policyData.PolicyData.businessAddress.country != null) {
              setCountry(policyData.PolicyData.businessAddress.country);
            } else {
              setCountry("USA");
            }
            if (policyData.PolicyData.businessAddress.line1 != "" && policyData.PolicyData.businessAddress.line1 != null) {
              setAddLine1(policyData.PolicyData.businessAddress.line1);
            }
            if (policyData.PolicyData.businessAddress.line2 != "" && policyData.PolicyData.businessAddress.line2 != null) {
              setAddLine2(policyData.PolicyData.businessAddress.line2);
            }
            if (policyData.PolicyData.businessAddress.line1 == "" || policyData.PolicyData.businessAddress.line1 == null) {
              setShowDisableForm(true);
              setAddressError("Please enter address");
            }

            if (policyData.PolicyData.businessAddress.zipCode != "" && policyData.PolicyData.businessAddress.zipCode != null) {
              setZipCode(policyData.PolicyData.businessAddress.zipCode);
            }
            if (policyData.PolicyData.businessAddress.zipCode == "" || policyData.PolicyData.businessAddress.zipCode == null) {
              setShowDisableForm(true);
              setZipCodeError("Please enter zip code");
            } else if (!/^[0-9-]*$/.test(zipCode)) {
              setZipCodeError("Zip code can only contain numbers and '-'");
            }
            if (policyData.PolicyData.term != "" && policyData.PolicyData.term != null) {
              setTerm(policyData.PolicyData.term);
            }

            let policyArrayValues = [...policyNumberError];

            if (Array.isArray(policyData.PolicyData.linesOfBusiness) && policyData.PolicyData.linesOfBusiness.length > 0) {
              policyData.PolicyData.linesOfBusiness.forEach((element: any, index: any) => {
                const newValue = [...policyArrayValues];
                if (element.policyNumber == null || element.policyNumber.toString() == "") {
                  newValue[index] = "Please enter policy number";
                  if (window.innerWidth > 767) {
                    window.scroll(0, 0);
                  }
                } else {
                  newValue[index] = "";
                }
                policyArrayValues = newValue;
              });
            }

            // setPolicyNumberError(policyArrayValues);

            let effectiveDateValues = [...policyNumberError];
            if (Array.isArray(policyData.PolicyData.linesOfBusiness) && policyData.PolicyData.linesOfBusiness.length > 0) {
              policyData.PolicyData.linesOfBusiness.forEach((element: any, index: any) => {
                const newValue = [...effectiveDateValues];
                if (element.effectiveDate == null || element.effectiveDate.toString() == "") {
                  newValue[index] = "Please enter effective date";
                  if (window.innerWidth > 767) {
                    window.scroll(0, 0);
                  }
                } else {
                  newValue[index] = "";
                }
                effectiveDateValues = newValue;
              });
            }

            // setEffectiveDateError(effectiveDateValues);

            if (
              policyData.PolicyData.currentPremium != "" &&
              policyData.PolicyData.currentPremium != null &&
              policyData.PolicyData.currentPremium != 0
            ) {
              setCurrentPremium(policyData.PolicyData.currentPremium);
            } else {
              setCurrentPremium(policyData.PolicyData.quotedPremium);
            }

            if (policyData.PolicyData.businessName != "" && policyData.PolicyData.businessName != null) {
              setBusinessProfile(policyData.PolicyData.businessName);
            }
            if (
              policyData.PolicyData.businessName == "" ||
              policyData.PolicyData.businessName == null ||
              policyData.PolicyData.businessName == undefined
            ) {
              setShowDisableForm(true);
              setBusinessProfileError("Please enter business name");
            }
            if (policyData.PolicyData.businessEmail != "" && policyData.PolicyData.businessEmail != null) {
              setBusinessEmail(policyData.PolicyData.businessEmail);
            }
            if (policyData.PolicyData.businessPhoneNumber != "" && policyData.PolicyData.businessPhoneNumber != null) {
              setBusinessPhoneNumber(policyData.PolicyData.businessPhoneNumber);
            }
            if (policyData.PolicyData != null && policyData.PolicyData.id != "" && policyData.PolicyData.id != null) {
              setClientId(policyData.PolicyData.id);
            }
            if (policyData.PolicyData != null && policyData.PolicyData.brokerFee != "" && policyData.PolicyData.brokerFee != null) {
              setBrokerFee(policyData.PolicyData.brokerFee);
            }
            if (policyData.PolicyData.length > 0 && policyData.PolicyData.carrier != "" && policyData.PolicyData.carrier != null) {
              setCarrierName(policyData.PolicyData.carrier);
            }
            if ((policyData.PolicyData.length > 0 && policyData.PolicyData.carrier == "") || policyData.PolicyData.carrier == null) {
              // setCarrierNameError("Please enter carrier name");
            }
          }
        }
      }
  }, []);
  useEffect(() => {
    checkValidation();
  }, [
    policyNumber,
    currentPremiumsArray,
    coverageCode,
    carrier,
    carrierZip,
    businessProfile,
    addLine1,
    city,
    sstate,
    country,
    zipCode,
    term,
    effectiveDate,
    termCondition,
    carrierList,
    invalidPolicyError,
  ]);
  const getSavedQuoteDetails = (savedQuoteId: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/reuse/${savedQuoteId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios(config)
      .then((response: any) => {
        let policyData = response.data;
        let newPrem: any = [];
        let newPolicyNum: any = [];
        let newEffDt: any = [];
        let newTerm: any = [];
        let lobArray: any = [];
        let newTaxes: any = [];
        let newFees: any = [];
        let newMinimunEarnedPercent: any = [];
        let newBrokerFee: any = [];
        let newCarrierName: any = [];
        let newGa: any = [];
        let newCoverageCode: any = [];
        policyData.policy.forEach((element: any, index: any) => {
          newPrem.push(parseFloat(element.premium).toFixed(2));
          newPolicyNum.push(element.number);
          newEffDt.push(moment.utc(element.effectiveDate).format("YYYY-MM-DD"));
          newTerm.push(element.term);
          newTaxes.push(element.otherTaxes);
          newFees.push(element.otherFees);
          newMinimunEarnedPercent.push(element.minimumEarnedPercent);
          newBrokerFee.push(element.brokerFee);
          newCarrierName.push(element.carrier.name);
          newGa.push(element.generalAgency.name);
          getCoverageCodeForSavedQuotes(savedQuoteId, element.coverageCode, index, newCoverageCode, lobArray, policyData.policy.length);
        });
        setSubPolicyLobs(lobArray);
        setCurrentPremiumsArray(newPrem);
        setPolicyNumber(newPolicyNum);
        setEffectiveDate(newEffDt);
        setTerm(newTerm);
        setTaxes(newTaxes);
        setFees(newFees);
        setminimumEarned(newMinimunEarnedPercent);
        setBrokerFee(newBrokerFee);
        upDateTotalCurrentPremiuim(newPrem, true);

        setNCInsuredId(policyData.insured.insuredId);
        setNCPolicyId(policyData.policy[0].policyId);
        setFirstName(policyData.clientName)

        setBusinessProfile(policyData.insured.name);
        setAddLine1(policyData.insured.address.address1);
        setAddLine2(policyData.insured.address.address2);
        setCity(policyData.insured.address.city);
        setSstate(policyData.insured.address.state);
        setCountry(policyData.insured.address.country);
        setZipCode(policyData.insured.address.zip);
        setBusinessEmail(policyData.insured.email);
        setBusinessPhoneNumber(policyData.insured.phoneNumber);

        getCarrierCodeMappingForHawksoftBundle(newCarrierName, []);
        getGeneralAgencyMappingForHawksoftBundle(newGa, []);


      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while Get Saved Quote Details";
          }
        }
        setPremiumLoading(false);
        setErrorMessage(errorMessage);
      })
  };
  const getCoverageCodeForSavedQuotes = (transactionId: any, code: any, index: any, newValue: any, lobArray: any, subPolicyCount: any) => {
    var token = CommonValues.GetToken();

    var data = {
      search: code,
      transactionId: transactionId,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/reusecoverage`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        lobArray.push({ "code": response.data.description });
        newValue[index] = response.data;
        setCoverageCode(newValue);
        setInputValue(newValue);
        if (subPolicyCount === index + 1) {
          setTimeout(() => {
            setProgressLoading(false);
          }, 1000);
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while Get Coverage Code For Saved Quotes";
          }
        }
        setPremiumLoading(false);
        setErrorMessage(errorMessage);
      })
  }
  const labelElement = (
    <span>
      Accept
      <a className="text-12" href="/terms" target="_blank">
        {" "}
        Terms and Conditions
      </a>
    </span>
  );
  useEffect(() => {
    if (loadingCount <= 0) {
      setTimeout(() => {
        setProgressLoading(false);
      }, 500);

    }
  }, [loadingCount]);

  useEffect(() => {
    if (carrierSearch.length == 0 || carrierSearch[selectedSubPolicy] == "") return;
    let newCarrierValues: any = carrierValue != null && carrierValue != undefined ? carrierValue : [];
    let newCarrierName: any = carrierName != null && carrierName != undefined ? carrierName : [];
    let newCarrierAddress: any = [];
    let newCarrierCity: any = [];
    let newCarrierState: any = [];
    let newCarrierZip: any = [];

    if (localStorage.getItem("IsIndicative") == "true") {
      getCarrierCodeMapping(
        "",
        carrierSearch[selectedSubPolicy],
        0,
        1,
        [],
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip
      );
    }

    if (isFirstLoad) {
      setIsFirstLoad(false);
      //setProgressLoading(true);
    }
    const searchTimeout = setTimeout(() => {
      getCarrierCodeMapping(
        "",
        carrierSearch[selectedSubPolicy],
        0,
        1,
        [],
        newCarrierValues,
        newCarrierName,
        newCarrierAddress,
        newCarrierCity,
        newCarrierState,
        newCarrierZip
      );
    }, 10);

    return () => clearTimeout(searchTimeout);
  }, [carrierSearch]);

  useEffect(() => {
    if (!props.isQuotePage) {
      onBackButonClick();
    }
  }, [props.isQuotePage])
  const getPremiumFinanceSettingsById = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setPremiumLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setParticularPremiumSettings(response.data);
        setPremiumLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details by id";
          }
        }
        setPremiumLoading(false);
        setErrorMessage(errorMessage);
      });
  };
  const onSaveClicked = () => {
    if (checkValidation()) {
      if (localStorage.getItem("IsIndicative") == "true" && localStorage.getItem("isNewPolicy") == null) {
        duplicateIndicativePolicy(false);
      }
      if (
        localStorage.getItem("isNewPolicy") == "true" ||
        (localStorage.getItem("FromUploadFileResponse") == "true" && localStorage.getItem("savePdfIndicative") == "false")
      ) {
        createNewPolicy(false);
      }
      checkOtherSettings();
      props.pageStateForBackButton()
    }
  };
  const checkOtherSettings = () => {
    if (particularPremiumSettings.length > 0) {
      particularPremiumSettings.forEach((item: any) => {
        switch (item.pfServiceId) {
          case 1:
            setGotoShowResponse(true);
            setGotoLogoUrl(item.logoUrl);
            break;
          case 2:
            setCapitalShowResponse(true);
            setCapitalLogoUrl(item.logoUrl);
            break;
          case 4:
            setIPFSShowResponse(true);
            setIpfsLogoUrl(item.logoUrl);
            break;
          case 5:
            setPeoplesShowResponse(true);
            setPeoplesLogoUrl(item.logoUrl);
            break;
          case 6:
            setFirstInsuranceFundingShowResponse(true);
            setFirstInsuranceFundingLogoUrl(item.logoUrl);
            break;
          case 7:
            setUsPfShowResponse(true);
            setUsPfLogoUrl(item.logoUrl);
            break;
          default:
            break;
        }
      });
    }
  };
  const checkValidation = () => {
    let formIsValid = true;
    setCarrierCodeError("");
    setCoverageCodeError([]);
    setCheckboxError("");
    setBusinessProfileError("");
    setBusinessEmailError("");
    setBusinessPhoneNumberError("");
    setAddressError("");
    setCityError("");
    setStateError("");
    setCountryError("");
    setZipCodeError("");
    setPolicyNumberError([]);
    setTermError([]);
    setCurrentPremiumError([]);
    setEffectiveDateError([]);
    setCarrierNameError([]);
    setCarrierZipCodeError([]);
    setCarrierCityError("");
    setCarrierStateError("");

    let invalidPolicyErrorLoop = [...invalidPolicyError];
    const newCoverageCodeError = [...coverageCodeError];
    const newTermError = [...termError];
    const newCarrierNameError = [...carrierNameError];
    const newCarrierZipCodeError = [...carrierZipCodeError];
    const newCurrentPremiumError = [...currentPremiumError];
    let effectiveDateArrayValues = [...effectiveDateError];
    let policyArrayValues = [...policyNumberError];

    // Coverage Code Validation
    if (coverageCode == null || coverageCode.length == 0) {
      formIsValid = false;
      for (let i = 0; i < subPolicyLobs.length; i++) {
        newCoverageCodeError[i] = "Please select coverage code ";
        invalidPolicyErrorLoop[i] = "true";
      }
      setCoverageCodeError(newCoverageCodeError);
      setInvalidPolicyError(invalidPolicyErrorLoop);
    } else {
      for (let i = 0; i < subPolicyLobs.length; i++) {
        if (coverageCode[i] == undefined || coverageCode[i] == null || coverageCode[i] == "" || coverageCode[i].description == null) {
          newCoverageCodeError[i] = "Please select coverage code ";
          formIsValid = false;
          invalidPolicyErrorLoop[i] = "true";
        } else {
          newCoverageCodeError[i] = "";
          invalidPolicyErrorLoop[i] = "";
        }
      }
      setCoverageCodeError(newCoverageCodeError);
      setInvalidPolicyError(invalidPolicyErrorLoop);
    }

    // Term Validation
    for (let i = 0; i < subPolicyLobs.length; i++) {
      if (term[i] == undefined || term[i] === "") {
        formIsValid = false;
        setShowDisableForm(true);
        newTermError[i] = "Please enter term ";
        invalidPolicyErrorLoop[i] = "true";
      } else if (parseInt(term[i]) < 9) {
        formIsValid = false;
        setShowDisableForm(true);
        newTermError[i] = "Term cannot be less than 9 months";
        invalidPolicyErrorLoop[i] = "true";
      } else if (!/\d/.test(term[i])) {
        formIsValid = false;
        setShowDisableForm(true);
        newTermError[i] = "Please enter at least one number in the term";
        invalidPolicyErrorLoop[i] = "true";
      } else {
        newTermError[i] = "";
        invalidPolicyErrorLoop[i] = "";
      }
    }
    setTermError(newTermError);
    setInvalidPolicyError(invalidPolicyErrorLoop);

    // Carrier Name and Zip Code Validation
    let policyIdsString = localStorage.getItem("policyIds");
    if ((carrierName == null || carrierName.length == 0) && policyIdsString == null) {
      formIsValid = false;
      for (let i = 0; i < subPolicyLobs.length; i++) {
        newCarrierNameError[i] = "Please select carrier ";
        newCarrierZipCodeError[i] = "Please enter carrier zip";
        invalidPolicyErrorLoop[i] = "true";
      }
      setCarrierNameError(newCarrierNameError);
      setCarrierZipCodeError(newCarrierZipCodeError);
      setInvalidPolicyError(invalidPolicyErrorLoop);
    } else {
      for (let i = 0; i < subPolicyLobs.length; i++) {
        if (carrier[i] == undefined || carrier[i] == null || carrier[i] == "" || carrier[i].description == null || carrier[i].description == "") {
          newCarrierNameError[i] = "Please select carrier";
          formIsValid = false;
          invalidPolicyErrorLoop[i] = "true";
        } else {
          newCarrierNameError[i] = "";
        }
        if (carrierZip[i] == undefined || carrierZip[i] == null || carrierZip[i] == "") {
          newCarrierZipCodeError[i] = "Please enter carrier zip";
          formIsValid = false;
          invalidPolicyErrorLoop[i] = "true";
        } else {
          newCarrierZipCodeError[i] = "";
        }
      }
      setCarrierNameError(newCarrierNameError);
      setCarrierZipCodeError(newCarrierZipCodeError);
      setInvalidPolicyError(invalidPolicyErrorLoop);
    }

    // Current Premium Validation
    currentPremiumsArray.forEach((element: any, index: number) => {
      if (element === undefined || element === null || element.toString() === "" || element <= 0) {
        newCurrentPremiumError[index] = element <= 0 ? "Please enter a valid current premium amount above 0" : "Please enter premium amount";
        formIsValid = false;
        invalidPolicyErrorLoop[index] = "true";
      } else {
        newCurrentPremiumError[index] = "";
      }
      setInvalidPolicyError(invalidPolicyErrorLoop);
    });
    setCurrentPremiumError(newCurrentPremiumError);

    // Effective Date Validation
    if (Array.isArray(effectiveDate)) {
      if (effectiveDate.length === 0) {
        effectiveDateArrayValues = ["Please enter effective date"];
        formIsValid = false;
      } else {
        effectiveDate.forEach((element, index) => {
          if (element == null || element.toString() == "") {
            effectiveDateArrayValues[index] = "Please enter effective date";
            formIsValid = false;
          } else {
            effectiveDateArrayValues[index] = "";
          }
        });
      }
    } else if (typeof effectiveDate === "string") {
      if (effectiveDate === "" || effectiveDate == null) {
        effectiveDateArrayValues = ["Please enter effective date"];
        formIsValid = false;
      } else {
        effectiveDateArrayValues = [""];
      }
    }
    setEffectiveDateError(effectiveDateArrayValues);

    // Policy Number Validation
    if (policyNumber.length === 0) {
      policyArrayValues = ["Please enter policy number"];
      formIsValid = false;
    } else {
      policyNumber.forEach((element, index) => {
        if (element == null || element.toString() == "") {
          policyArrayValues[index] = "Please enter policy number";
          formIsValid = false;
          invalidPolicyErrorLoop[index] = "true";
        } else {
          policyArrayValues[index] = "";
        }
        setInvalidPolicyError(invalidPolicyErrorLoop);
      });
    }
    setPolicyNumberError(policyArrayValues);

    // Other Validations
    if (termCondition == false) {
      formIsValid = false;
      setCheckboxError("Please accept terms and condition");
    }
    if (businessProfile == "" || businessProfile == null) {
      setShowDisableForm(true);
      formIsValid = false;
      setBusinessProfileError("Please enter business name");
    }
    if (businessEmail == "" || businessEmail == null) {
      formIsValid = false;
      setBusinessEmailError("Please enter business email");
    }
    if (businessEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(businessEmail.toLowerCase())) {
      formIsValid = false;
      setBusinessEmailError("Please enter a valid email address");
    }
    if (businessPhoneNumber == "" || businessPhoneNumber == null) {
      formIsValid = false;
      setBusinessPhoneNumberError("Please enter business phone number");
    }
    if (businessPhoneNumber && businessPhoneNumber.length < 10) {
      formIsValid = false;
      setBusinessPhoneNumberError("Please enter a valid phone number");
    }
    if (addLine1 == "" || addLine1 == null) {
      setShowDisableForm(true);
      formIsValid = false;
      setAddressError("Please enter address");
    }
    if (city == "" || city == null) {
      setShowDisableForm(true);
      formIsValid = false;
      setCityError("Please enter city");
    }
    if (sstate == "" || sstate == null || !/^[a-zA-Z\s]+$/.test(sstate)) {
      setShowDisableForm(true);
      formIsValid = false;
      setStateError(sstate == "" ? "Please enter state" : "State can only contain alphabetic characters");
    }
    if (country == "" || country == null) {
      setShowDisableForm(true);
      formIsValid = false;
      setCountryError("Please enter country");
    }
    if (zipCode == "" || zipCode == null || !/^[0-9-]*$/.test(zipCode)) {
      setShowDisableForm(true);
      formIsValid = false;
      setZipCodeError(zipCode == "" ? "Please enter zip code" : "Zip code can only contain numbers and '-'");
    }

    return formIsValid;
  };

  const checkValidationForSavePolicy = () => {
    let formIsValid = true;
    setBusinessProfileError("");
    setPolicyNumberError([]);

    let invalidPolicyErrorLoop: any = [];
    invalidPolicyErrorLoop[0] = "";
    setInvalidPolicyError(invalidPolicyErrorLoop);

    if (businessProfile == "" || businessProfile == null) {
      setShowDisableForm(true);
      formIsValid = false;
      setBusinessProfileError("Please enter business name");
    }
    let policyArrayValues = [...policyNumberError];

    if (policyNumber.length === 0) {
      const newValue = ["Please enter policy number"];
      policyArrayValues = newValue;
      formIsValid = false;
      if (window.innerWidth > 767) {
        window.scroll(0, 0);
      }
    } else {
      policyNumber.forEach((element, index) => {
        const newValue = [...policyArrayValues];
        if (element == null || element.toString() == "") {
          newValue[index] = "Please enter policy number";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
          formIsValid = false;
        } else {
          newValue[index] = "";
        }
        policyArrayValues = newValue;
      });
    }

    setPolicyNumberError(policyArrayValues);

    let effectiveDateArrayValues = [...effectiveDateError];

    if (effectiveDate.length === 0) {
      const newValue = ["Please enter effective date"];
      effectiveDateArrayValues = newValue;
      formIsValid = false;
      if (window.innerWidth > 767) {
        window.scroll(0, 0);
      }
    } else {
      effectiveDate.forEach((element: any, index: any) => {
        const newValue = [...effectiveDateArrayValues];
        if (element == null || element.toString() == "") {
          newValue[index] = "Please enter effective date";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
          formIsValid = false;
        } else {
          newValue[index] = "";
        }
        effectiveDateArrayValues = newValue;
      });
    }

    setEffectiveDateError(effectiveDateArrayValues);
    return formIsValid;
  };
  const onCheckboxChange = () => {
    setTermCondition(!termCondition);
  };
  const onCarrierNameChange = (e: any) => {
    let newcarrierSearch = [];
    newcarrierSearch[selectedSubPolicy] = e.target.value;
    setCarrierSearch(newcarrierSearch);
  };
  const onGeneralAgencySearchChange = (e: any) => {
    const newValues = [...gaName];
    newValues[selectedSubPolicy] = e.target.value;

    setGaName(newValues);
    getGeneralAgencyMapping("", newValues[selectedSubPolicy], 0, 1, []);
    // setCarrierSearch(value);
  };
  const onGeneralAgencySelect = (item: any, e: any) => {
    setGeneralAgencySearch([]);
    const newValues = [...gaName];
    newValues[selectedSubPolicy] = item.description;
    setGaName(newValues);

    let newGaAddress = [...gaAddress];
    newGaAddress[selectedSubPolicy] = item.address1;
    setGaAddress(newGaAddress);

    let newGaCity = [...gaCity];
    newGaCity[selectedSubPolicy] = item.city;
    setGaCity(newGaCity);

    let newGaState = [...gaState];
    newGaState[selectedSubPolicy] = item.state;
    setGaState(newGaState);

    let newGaZip = [...gaZip];
    newGaZip[selectedSubPolicy] = item.zipCode;
    setGaZip(newGaZip);

    let newGaCountry = [...gaCountry];
    newGaCountry[selectedSubPolicy] = item.country;
    setGaCountry(newGaCountry);

    let newGaSearchCode = [...gaSearchCode];
    newGaSearchCode[selectedSubPolicy] = item.searchCode;
    setGaSearchCode(newGaSearchCode);

    setGeneralAgencyList([]);
    e.preventDefault();
  };
  const onCarrierselect = (item: any, e: any) => {
    setCarrierList([]);
    setCarrierSearch([]);

    if (item) {
      let newCarrierName = carrierName == null ? [] : [...carrierName];
      newCarrierName[selectedSubPolicy] = item.description;
      setCarrierName(newCarrierName);

      const newValues = [...carrier];
      newValues[selectedSubPolicy] = item;
      setCarrier(newValues);
      setCarrierValue(newValues);

      let newCarrierAddress = [...carrierAddress];
      newCarrierAddress[selectedSubPolicy] = item.address1 == undefined ? "" : item.address1;
      setCarrierAddress(newCarrierAddress);

      let newCarrierCity = [...carrierCity];
      newCarrierCity[selectedSubPolicy] = item.city == undefined ? "" : item.city;
      setCarrierCity(newCarrierCity);

      let newCarrierState = [...carrierState];
      newCarrierState[selectedSubPolicy] = item.state == undefined ? "" : item.state;
      setCarrierState(newCarrierState);

      let newCarrierZip = [...carrierZip];
      newCarrierZip[selectedSubPolicy] = item.zipCode == undefined ? "" : item.zipCode;
      setCarrierZip(newCarrierZip);
    }

    e.preventDefault();
  };

  const onCoverageCodeChange = (e: any) => {
    if (e.target.value !== "") {
      let newCoverageCode = [...search];
      newCoverageCode[selectedSubPolicy] = e.target.value;
      setSearch(newCoverageCode);
      getGotoPfCodeList(e.target.value);
      //updateLobs(selectedSubPolicy, "coverageCode", e.target.value);
    } else {
      setSearch([]);
      setGotoPfCodeList([]);
    }
  };
  const onCovergaeCodeselect = (e: any, event: any) => {
    setGotoPfCodeList([]);
    let newCoverageCode = [...coverageCode];
    newCoverageCode[selectedSubPolicy] = e;
    setCoverageCode(newCoverageCode);
    setInputValue(newCoverageCode);
    setSearch([]);
    const updatedLob = [...subPolicyLobs];
    updatedLob[selectedSubPolicy].code = e.description;
    setUpdatedLob(updatedLob);
    event.preventDefault();
  };

  const onCustomizeClick = () => {
    setCapitalShowResponse(false);
    setCapitalTarget("CAPITAL");
  };
  const onMultiPolicyClick = () => {
    setCapitalTarget("CAPITAL");
    props.onMultiPolicyClick(clientId);
  };
  const onTaxesChange = (e: any) => {
    let newValues = [...taxes];
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue) || inputValue === "") {
      newValues[selectedSubPolicy] = inputValue;
      setTaxes(newValues);
    }
  };

  const onFeesChange = (e: any) => {
    let newValues = [...fees];
    const inputValue = e.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;

    if (regex.test(inputValue) || inputValue === "") {
      newValues[selectedSubPolicy] = inputValue;
      setFees(newValues);
    }
  };

  const onMinimumEarnedChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (inputValue === "" || (regex.test(inputValue) && parseFloat(inputValue) < 100)) {
      let newValues = [...minimumEarned];
      newValues[selectedSubPolicy] = inputValue;
      setminimumEarned(newValues);
    }
  };

  function updateLobs(index: number, field: string, value: any) {
    const updatedLob = [...subPolicyLobs];
    if (field === "currentPremium") {
      currentPremiumsArray[index] = value;
      updatedLob[index].calculatedPremium = parseFloat(value).toFixed(2);
    }
    if (field === "coverageCode") {
      inputValue[index] = value;
      updatedLob[index].code = value.code != undefined && value.code != null ? value.code : value;
    }
    if (field === "policyNumber") {
      updatedLob[index].policyNumber = value;
    }
    if (field === "effectiveDate") {
      updatedLob[index].effectiveDate = value;
    }

    setUpdatedLob(updatedLob);
  }
  const onBrokerFeeChange = (e: any) => {
    const newValues = [...brokerFee];
    newValues[selectedSubPolicy] = e.target.value;
    setBrokerFee(newValues);
  };
  const onBusinessProfileNameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setBusinessProfile(e.target.value);
  };
  const onBusinessEmailChange = (e: any) => {
    const limitedValue = e.target.value.slice(0, 64);
    setBusinessEmail(limitedValue);
  };
  const onBusinessPhoneNumberChange = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const limitedValue = numericValue.slice(0, 10);
    setBusinessPhoneNumber(limitedValue);

    // addPhoneDashes(limitedValue);
  };
  const onAddressChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAddLine1(e.target.value);
  };
  const onAddressLine2Change = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAddLine2(e.target.value);
  };
  const onCityChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCity(e.target.value);
  };
  const onStateChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setSstate(e.target.value);
  };
  const onCountryChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setCountry(e.target.value);
  };
  const onZipcodeChange = (e: any) => {
    setZipCode(e.target.value);
    // addZipCodeDashes(e.target.value);
  };
  const onGeneralAgnecyAdressChange = (e: any) => {
    const newValues = [...gaAddress];
    newValues[selectedSubPolicy] = e.target.value;
    setGaAddress(newValues);
  };
  const onGeneralAgnecyCityChange = (e: any) => {
    const newValues = [...gaCity];
    newValues[selectedSubPolicy] = e.target.value;
    setGaCity(newValues);
  };
  const onGeneralAgnecyStateChange = (e: any) => {
    const newValues = [...gaState];
    newValues[selectedSubPolicy] = e.target.value;
    setGaState(newValues);
  };
  const onGeneralAgnecyZipChange = (e: any) => {
    const newValues = [...gaZip];
    newValues[selectedSubPolicy] = e.target.value;
    setGaZip(newValues);
  };
  const onGeneralAgnecyCountryChange = (e: any) => {
    const newValues = [...gaCountry];
    newValues[selectedSubPolicy] = e.target.value;
    setGaCountry(newValues);
  };
  const onCarrierAdressChange = (e: any) => {
    let newValue = [...carrierAddress];
    newValue[selectedSubPolicy] = e.target.value;
    setCarrierAddress(newValue);
  };
  const onCarrierCityChange = (e: any) => {
    let newValue = [...carrierCity];
    newValue[selectedSubPolicy] = e.target.value;
    setCarrierCity(newValue);
  };
  const onCarrierStateChange = (e: any) => {
    let newValue = [...carrierState];
    newValue[selectedSubPolicy] = e.target.value;
    setCarrierState(newValue);
  };
  const onCarrierZipChange = (e: any) => {
    let newValue = [...carrierZip];
    newValue[selectedSubPolicy] = e.target.value;
    setCarrierZip(newValue);
  };
  const onCarrierCountryChange = (e: any) => {
    let newValue = [...carrierCountry];
    newValue[selectedSubPolicy] = e.target.value;
    setCarrierCountry(newValue);
  };
  const onPolicyNumberChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    let policyNum = [...policyNumber];
    policyNum[selectedSubPolicy] = e.target.value;
    setPolicyNumber(policyNum);
    updateLobs(selectedSubPolicy, "policyNumber", e.target.value);
  };
  const onEffectiveDateChange = (e: any) => {
    let newEffDt = [...effectiveDate];
    newEffDt[selectedSubPolicy] = e.target.value;
    setEffectiveDate(newEffDt);
    updateLobs(selectedSubPolicy, "effectiveDate", e.target.value);
  };

  const onTermChange = (e: any) => {
    let newTerm = [...term];
    if (/^\d*$/.test(e.target.value)) {
      newTerm[selectedSubPolicy] = e.target.value;
      setTerm(newTerm);
    }
  };
  const createNewPolicy = (showToast: any) => {
    setSaveLoading(true);
    let locations: any = [];
    let linesOfBusiness: any = [];
    if (updatedLob.length > 0) {
      linesOfBusiness = updatedLob;
    } else {
      let lobArray: any = [];
      lobArray.push({
        id: 0,
        calculatedPremium: 0,
        formType: "",
        policyId: 0,
        code: "",
        status: "",
      });
      linesOfBusiness = lobArray;
    }

    let coverages: any = [];
    let storedData = localStorage.getItem("client");
    let clianttlist = storedData ? JSON.parse(storedData) : null;

    let SavedPdfFile = localStorage.getItem("savedPdfFile");
    let isNewPolicy = localStorage.getItem("isNewPolicy");
    let TransactionId = null;
    if (!(isNewPolicy == "true")) {
      if (SavedPdfFile == "true" && SavedPdfFile != null) {
        let storedPolicyData = localStorage.getItem("policyData");
        let policyData = storedPolicyData ? JSON.parse(storedPolicyData) : null;
        TransactionId = wholePolicyData.transactionId;
      } else {
        let storedPolicyData = localStorage.getItem("UploadFileData");
        let UploadFileData = storedPolicyData ? JSON.parse(storedPolicyData) : null;
        TransactionId = UploadFileData.transactionId;
      }
    }
    let cId = clianttlist.clientId;

    let businessAddress = {};
    businessAddress = {
      id: 0,
      addressType: "Mailing",
      line1: addLine1,
      line2: addLine2,
      city: city,
      state: sstate,
      zipCode: zipCode,
      country: country,
    };

    let policyTitle = "";
    if (linesOfBusiness.length > 0) {
      policyTitle = linesOfBusiness[0].code ? linesOfBusiness[0].code : "";
    }

    let premium = 0.0;
    if (updatedLob.length > 0 && updatedLob[0].calculatedPremium !== undefined) {
      premium = updatedLob[0].calculatedPremium.toString();
    }

    if (dBId !== null && dBId !== "") {
      wholePolicyData.sqlId = dBId;
    }
    let sourceInfo = "";
    if (localStorage.getItem("FromUploadFileResponse") == "true") {
      sourceInfo = "fileupload";
    }

    let generalAgencyList: any = [];

    for (let index = 0; index < Math.max(gaName.length, gaAddress.length, gaCity.length, gaState.length, gaZip.length, gaCountry.length); index++) {
      let agencyObject: any = {};

      if (gaName[index] != null && gaName[index] !== undefined) {
        agencyObject.name = gaName[index];
      } else {
        agencyObject.name = "";
      }

      if (gaAddress[index] != null && gaAddress[index] !== undefined) {
        agencyObject.address = gaAddress[index];
      } else {
        agencyObject.address = "";
      }

      if (gaCity[index] != null && gaCity[index] !== undefined) {
        agencyObject.city = gaCity[index];
      } else {
        agencyObject.city = "";
      }

      if (gaState[index] != null && gaState[index] !== undefined) {
        agencyObject.state = gaState[index];
      } else {
        agencyObject.state = "";
      }

      if (gaZip[index] != null && gaZip[index] !== undefined) {
        agencyObject.zip = gaZip[index];
      } else {
        agencyObject.zip = "";
      }

      if (gaCountry[index] != null && gaCountry[index] !== undefined) {
        agencyObject.country = gaCountry[index];
      } else {
        agencyObject.country = "";
      }

      generalAgencyList.push(agencyObject);
    }
    let data = JSON.stringify({
      Id: wholePolicyData.sqlId ? wholePolicyData.sqlId : 0,
      clientId: cId.toString(),
      businessName: businessProfile,
      businessEmail: businessEmail,
      businessPhoneNumber: businessPhoneNumber,
      taxes: taxes,
      fees: fees,
      terms: term,
      brokerFees: brokerFee,
      earnedpercent: minimumEarned,
      policyTitle: policyTitle,
      accountNumber: "",
      agentCode: "",
      applicationType: "",
      billingPlan: "",
      carrier: carrier,
      cancellationDate: "",
      currentPremium: totalCurrentPremium == "NaN" ? 0 : totalCurrentPremium,
      daysLapsed: 0,
      // effectiveDate: effectiveDate,
      inceptionDate: "",
      isArchived: false,
      naic: wholePolicyData.PolicyData ? wholePolicyData.PolicyData.naic : "",
      officeId: 0,
      paymentPlan: "",
      policyNumber: policyNumber[0],
      program: "",
      quotedPremium: wholePolicyData.PolicyData ? wholePolicyData.PolicyData.quotedPremium : 0,
      rater: 0,
      source: sourceInfo,
      state: "",
      type: "",
      status: "",
      statusDate: "",
      subStatus: "",
      term: term,
      title: policyTitle,
      displayOrder: 0,
      typeName: "",
      coverages: coverages,
      linesOfBusiness: linesOfBusiness,
      locations: locations,
      businessAddress: businessAddress,
      transactionId: TransactionId,
      generalAgency: generalAgencyList,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data !== null && response.data !== "") {
          setDBId(response.data);
        }
        setSuccessMessageForPolicy("");
        setTimeout(() => setSuccessMessageForPolicy(""), 5000);
        if (showToast) {
          if (wholePolicyData.sqlId > 0) {
            toast.current.show({
              severity: "success", summary: 'Success',
              detail: "Policy information saved successfully.",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success", summary: 'Success',
              detail: "New policy created successfully.",
              life: 3000,
            });
          }
        }

        setSaveLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while adding new policy";
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while adding new policy",
              life: 3000,
            });
          }
        }
        setErrorMessage(errorMessage);
        setSaveLoading(false);
      });
  };
  const duplicateIndicativePolicy = (showToast: any) => {
    setSaveLoading(true);
    let locations: any = [];
    let linesOfBusiness: any = [];
    if (updatedLob.length > 0) {
      linesOfBusiness = updatedLob;
    } else {
      let policyData = props.indicativedata;

      let lobArray: any = [];
      policyData.PolicyData.linesOfBusiness.forEach((element: any) => {
        lobArray.push({
          id: element.id,
          calculatedPremium: element.calculatedPremium,
          formType: element.formType,
          policyId: element.policyId,
          code: element.code,
          status: element.status,
        });
      });
      linesOfBusiness = lobArray;
    }

    let generalAgencyList: any = [];

    for (let index = 0; index < Math.max(gaName.length, gaAddress.length, gaCity.length, gaState.length, gaZip.length, gaCountry.length); index++) {
      let agencyObject: any = {};

      if (gaName[index] != null && gaName[index] !== undefined) {
        agencyObject.name = gaName[index];
      } else {
        agencyObject.name = "";
      }

      if (gaAddress[index] != null && gaAddress[index] !== undefined) {
        agencyObject.address = gaAddress[index];
      } else {
        agencyObject.address = "";
      }

      if (gaCity[index] != null && gaCity[index] !== undefined) {
        agencyObject.city = gaCity[index];
      } else {
        agencyObject.city = "";
      }

      if (gaState[index] != null && gaState[index] !== undefined) {
        agencyObject.state = gaState[index];
      } else {
        agencyObject.state = "";
      }

      if (gaZip[index] != null && gaZip[index] !== undefined) {
        agencyObject.zip = gaZip[index];
      } else {
        agencyObject.zip = "";
      }

      if (gaCountry[index] != null && gaCountry[index] !== undefined) {
        agencyObject.country = gaCountry[index];
      } else {
        agencyObject.country = "";
      }

      generalAgencyList.push(agencyObject);
    }

    let coverages: any = [];
    let storedData = localStorage.getItem("client");
    let clianttlist = storedData ? JSON.parse(storedData) : null;
    let cId = clianttlist.clientId;
    if (clianttlist) {
      setClientId(clianttlist.clientId);
      setFirstName(clianttlist.clientName);
    }

    let businessAddress = {};

    if (wholePolicyData.businessAddress) {
      businessAddress = {
        id: wholePolicyData.businessAddress.id,
        addressType: wholePolicyData.businessAddress.addressType,
        line1: wholePolicyData.businessAddress.line1 || addLine1,
        line2: wholePolicyData.businessAddress.line2 || addLine2,
        city: city,
        state: sstate,
        zipCode: zipCode,
        country: country,
      };
    } else {
      businessAddress = {
        line1: addLine1,
        line2: addLine2,
        city: city,
        state: sstate,
        zipCode: zipCode,
        country: country,
      };
    }

    let newTaxes: any = [];
    taxes.forEach((tax: any, index: any) => {
      newTaxes[index] = tax != "" ? tax : 0;
    });

    let newFees: any = [];
    fees.forEach((fee: any, index: any) => {
      newFees[index] = fee != "" ? fee : 0;
    });

    let newEarnedPercent: any = [];
    minimumEarned.forEach((earnedPercent: any, index: any) => {
      newEarnedPercent[index] = earnedPercent != "" ? earnedPercent : 0;
    });

    let SavedPdfFile = localStorage.getItem("savedPdfFile");
    let TransactionId = null;
    if (SavedPdfFile == "true" || localStorage.getItem("isNewPolicy") == null) {
      TransactionId = wholePolicyData.PolicyData.transactionId;
    } else {
      let storedPolicyData = localStorage.getItem("UploadFileData");
      let UploadFileData = storedPolicyData ? JSON.parse(storedPolicyData) : null;
      TransactionId = UploadFileData.transactionId;
    }
    var policyTitle = "";

    if (wholePolicyData.PolicyData.linesOfBusiness && wholePolicyData.PolicyData.linesOfBusiness.length > 0) {
      policyTitle = wholePolicyData.PolicyData.linesOfBusiness[0].code;
    } else {
      policyTitle = "";
    }
    let data = JSON.stringify({
      Id: wholePolicyData.sqlId,
      clientId: cId.toString(),
      businessName: businessProfile,
      businessEmail: businessEmail,
      businessPhoneNumber: businessPhoneNumber,
      taxes: newTaxes,
      fees: newFees,
      earnedPercent: newEarnedPercent,
      policyTitle: wholePolicyData.PolicyData.linesOfBusiness[0].code,
      accountNumber: wholePolicyData.PolicyData.accountNumber,
      agentCode: wholePolicyData.PolicyData.agentCode,
      applicationType: wholePolicyData.PolicyData.applicationType,
      billingPlan: wholePolicyData.PolicyData.billingPlan,
      carrier: carrier,
      cancellationDate: wholePolicyData.PolicyData.cancellationDate,
      // currentPremium: wholePolicyData.PolicyData.currentPremium,
      currentPremium: totalCurrentPremium == "NaN" ? 0 : totalCurrentPremium,
      daysLapsed: wholePolicyData.PolicyData.daysLapsed,
      // effectiveDate: effectiveDate,
      inceptionDate: wholePolicyData.PolicyData.inceptionDate,
      isArchived: wholePolicyData.PolicyData.isArchived,
      naic: wholePolicyData.PolicyData.naic,
      officeId: wholePolicyData.PolicyData.officeId,
      paymentPlan: wholePolicyData.PolicyData.paymentPlan,
      policyNumber: policyNumber[0],
      program: wholePolicyData.PolicyData.program,
      quotedPremium: wholePolicyData.PolicyData.quotedPremium,
      rater: wholePolicyData.PolicyData.rater,
      source: wholePolicyData.PolicyData.source,
      state: wholePolicyData.PolicyData.state,
      type: wholePolicyData.PolicyData.type,
      status: wholePolicyData.PolicyData.status,
      statusDate: wholePolicyData.PolicyData.statusDate,
      subStatus: wholePolicyData.PolicyData.subStatus,
      terms: term,
      title: wholePolicyData.PolicyData.linesOfBusiness[0].code,
      displayOrder: wholePolicyData.PolicyData.displayOrder,
      typeName: wholePolicyData.PolicyData.typeName,
      coverages: coverages,
      linesOfBusiness: linesOfBusiness,
      locations: locations,
      businessAddress: businessAddress,
      brokerFees: brokerFee,
      transactionId: TransactionId,
      generalAgency: generalAgencyList,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSuccessMessageForPolicy("");
        setTimeout(() => setSuccessMessageForPolicy(""), 5000);
        if (showToast) {
          toast.current.show({
            severity: "success", summary: 'Success',
            detail: "Policy information saved successfully.",
            life: 3000,
          });
        }
        setSaveLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while duplicating the policy",
              life: 3000,
            });
            errorMessage = "Unknown error while duplicating the policy";
          }
        }
        setErrorMessage(errorMessage);
        setSaveLoading(false);
      });
  };
  const onSaveClick = () => {
    let storedData = localStorage.getItem("client");
    let clianttlist = storedData ? JSON.parse(storedData) : null;
    setClientId(clianttlist.clientId);
    setCarrierCodeError("");
    setCoverageCodeError([]);
    setCheckboxError("");
    setBusinessProfileError("");
    setBusinessEmailError("");
    setBusinessPhoneNumberError("");
    setAddressError("");
    setCityError("");
    setStateError("");
    setCountryError("");
    setZipCodeError("");
    setPolicyNumberError([]);
    setEffectiveDateError([]);
    setTermError([]);
    setCurrentPremiumError([]);
    setCarrierNameError([]);
    setCarrierZipCodeError([]);
    setCarrierCityError("");
    setCarrierStateError("");
    if (checkValidationForSavePolicy()) {
      if (localStorage.getItem("IsIndicative") == "true" && localStorage.getItem("isNewPolicy") == null) {
        duplicateIndicativePolicy(true);
      }
      if (
        localStorage.getItem("isNewPolicy") == "true" ||
        (localStorage.getItem("FromUploadFileResponse") == "true" && localStorage.getItem("savePdfIndicative") == "false")
      ) {
        createNewPolicy(true);
      }
    }
  };
  const onClearGACLicked = () => {
    const newValues = [...gaName];
    newValues[selectedSubPolicy] = "";
    setGaName(newValues);

    let newGaAddress = [...gaAddress];
    newGaAddress[selectedSubPolicy] = "";
    setGaAddress(newGaAddress);

    let newGaCity = [...gaCity];
    newGaCity[selectedSubPolicy] = "";
    setGaCity(newGaCity);

    let newGaState = [...gaState];
    newGaState[selectedSubPolicy] = "";
    setGaState(newGaState);

    let newGaZip = [...gaZip];
    newGaZip[selectedSubPolicy] = "";
    setGaZip(newGaZip);

    let newGaCountry = [...gaCountry];
    newGaCountry[selectedSubPolicy] = "";
    setGaCountry(newGaCountry);

    let newGaSearchCode = [...gaSearchCode];
    newGaSearchCode[selectedSubPolicy] = "";
    setGaSearchCode(newGaSearchCode);
  };
  const onPremiumChange = (event: any, index: any) => {
    if (event.target.value.length > 255) {
      return;
    }
    const newValues = [...currentPremiumsArray];
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (/^[0-9]*(\.[0-9]*)?$/.test(event.target.value) && regex.test(event.target.value)) {
      newValues[selectedSubPolicy] = event.target.value;
    }
    const value = event.target.value;
    updateLobs(selectedSubPolicy, "currentPremium", value);
    setCurrentPremiumsArray(newValues);
    upDateTotalCurrentPremiuim(newValues, true);
  };
  const getAmsAgencySettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setAgentName(response.data.agencyName);
        setAgentAddress(response.data.address1);
        setAgentCity(response.data.city);
        setAgentState(response.data.state);
        setAgentZip(response.data.zip);
        setAgentPhone(response.data.phone)
        if (response.data.country != null && response.data.country != "") {
          setCountry(response.data.country);
        } else {
          setCountry("USA");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the AMS Agency settings";
          }
        }
      });
  };
  const getCarrierCodeMapping = (
    carrierName: any,
    search: any,
    index: any,
    subPolicyCount: any,
    invalidPolicyErrorLoop: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any
  ) => {
    setCarrierCodeLoading(true);
    let carrierData: any = [];

    var token = CommonValues.GetToken();
    var data = {
      search: search,
      hscarrier: carrierName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/carriercodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setCarrierCodeLoading(false);
        if (search == "") {
          if (response.data.length > 0) {
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierName[i] = response.data[0].description;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = response.data[0];
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);

                newCarrierAddress[i] = response.data[0].address1;
                setCarrierAddress(newCarrierAddress);

                newCarrierCity[i] = response.data[0].city;
                setCarrierCity(newCarrierCity);

                newCarrierState[i] = response.data[0].state;
                setCarrierState(newCarrierState);

                newCarrierZip[i] = response.data[0].zipCode;
                setCarrierZip(newCarrierZip);
              }
            }
          } else {
            invalidPolicyErrorLoop[index] = "";
            let newCarrierCountry = [];
            let newcarrierSearch = [];

            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierCountry[i] = "USA";
                setCarrierCountry(newCarrierCountry);

                newCarrierName[i] = carrierName;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = { description: carrierName };
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);
              }
            }
          }
        } else {
          setCarrierList(response.data);
          invalidPolicyErrorLoop[index] = "";
        }
        if (response.data == "" || response.data.length == 0) {
          if (carrierSearch.length == 0) {
            let carrierNameErrorLoop: any = [];
            carrierNameErrorLoop[index] = "Please enter carrier name";
            setCarrierNameError(carrierNameErrorLoop);
          }

          let carrieZipErrorLoop: any = [];
          carrieZipErrorLoop[index] = "Please enter zip code";
          invalidPolicyErrorLoop[index] = "true";
          setCarrierZipCodeError(carrieZipErrorLoop);
        } else {
          setCarrierNameError([]);
          setCarrierZipCodeError([]);
        }
        if (props.type == "1") {
          setLoadingCount((count) => count - 2);
        } else {
          setLoadingCount((count) => count - 1);
        }
      })
      .catch((error: any) => {
        setCarrierCodeLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the carrier details";
          }
        } else {
          errorMessage = "Unknown error while loading the carrier details";
        }
        setErrorMessage(errorMessage);
        setLoadingCount((count) => count - 1);
      });
  };
  const getGeneralAgencyMapping = (generalAgencyName: any, search: any, index: any, subPolicyCount: any, invalidPolicyErrorLoop: any) => {
    setGeneralAgencySearchLoading(true);
    var token = CommonValues.GetToken();
    var data = {
      search: search,
      generalagency: generalAgencyName,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/generalagencycodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setLoadingCount((count) => count - 1);
        setGeneralAgencySearchLoading(false);
        if (search == "") {
          if (response.data.length > 0) {
            let newGaNames = [...gaName];
            let newGaAddress = [];
            let newGaCity = [];
            let newGaState = [];
            let newGaZip = [];
            let newGaCountry = [];
            let newGaSearchCode = [];
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newGaNames[i] = response.data[0].description;
                setGaName(newGaNames);

                newGaAddress[i] = response.data[0].address1;
                setGaAddress(newGaAddress);

                newGaCity[i] = response.data[0].city;
                setGaCity(newGaCity);

                newGaState[i] = response.data[0].state;
                setGaState(newGaState);

                newGaZip[i] = response.data[0].zipCode;
                setGaZip(newGaZip);

                newGaCountry[i] = "USA";
                setGaCountry(newGaCountry);

                newGaSearchCode[i] = response.data[0].searchCode;
                setGaSearchCode(newGaSearchCode);
              }
            }
          } else {
            let newGaName = [];
            for (let i = 0; i < subPolicyCount; i++) {
              newGaName[i] = generalAgencyName;
            }
            setGaName(newGaName);
          }
        } else {
          const updatedGeneralAgencyList = response.data.map((agency: any) => {
            return {
              ...agency,
              country: "USA",
            };
          });
          setGeneralAgencyList(updatedGeneralAgencyList);
        }
      })
      .catch((error: any) => {
        setGeneralAgencySearchLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the general agency details";
          }
        } else {
          errorMessage = "Unknown error while loading the general agency details";
        }
        setErrorMessage(errorMessage);
        setLoadingCount((count) => count - 1);
      });
  };
  const getGotoPfCodeList = (search: any) => {
    setSearchCoverageCodeLoading(true);
    var token = CommonValues.GetToken();
    var data = {
      Search: search,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/coveragecodes`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setGotoPfCodeList(response.data);
        setSearchCoverageCodeLoading(false);
        setErrorMessage("");
        setLoadingCount((count) => count - 1);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the coverage code list details";
          }
        } else {
          errorMessage = "Unknown error while loading the coverage code list details";
        }
        setSearchCoverageCodeLoading(false);
        setErrorMessage(errorMessage);
        setLoadingCount((count) => count - 1);
      });
  };
  const getCarrierCodeMappingForHawksoftBundle = async (carrierNames: any, invalidPolicyErrorLoop: any) => {
    setCarrierCodeLoading(true);
    var token = CommonValues.GetToken();
    let carrierData: any = [];
    let newValues: any = [];
    let newCarrierAddress: any = [];
    let newCarrierCity: any = [];
    let newCarrierState: any = [];
    let newCarrierZip: any = [];

    const fetchData = async (carrierName: any) => {
      try {
        var data = {
          search: "",
          hscarrier: carrierName,
        };
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/carriercodes`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);
        setLoadingCount((count) => count - 1);

        if (response.data.length > 0) {
          carrierData.push(response.data[0]);
        } else {
          carrierData.push({ description: carrierName });
        }

        // Check if all responses have been received
        if (carrierData.length === carrierNames.length) {
          carrierData.forEach((carrierResponse: any, index: any) => {
            if (carrierResponse != null) {
              setCarrierName(carrierResponse.description);
              newValues[index] = carrierResponse;
              setCarrier(newValues);
              setCarrierValue(newValues);

              newCarrierAddress[index] = carrierResponse.address1;
              setCarrierAddress(newCarrierAddress);

              newCarrierCity[index] = carrierResponse.city;
              setCarrierCity(newCarrierCity);

              newCarrierState[index] = carrierResponse.state;
              setCarrierState(newCarrierState);

              newCarrierZip[index] = carrierResponse.zipCode;
              setCarrierZip(newCarrierZip);
            }
          });

          setCarrierCodeLoading(false);
          setLoadingCount((count) => count - 1);
        }
        // else{
        //   let newCarrierValue = [];
        //   newCarrierValue.push({ "": "" });
        //   setCarrierValue(newCarrierValue);
        // }
      } catch (error: any) {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the carrier details";
          }
        } else {
          errorMessage = "Unknown error while loading the carrier details";
        }
        setErrorMessage(errorMessage);
        setLoadingCount((count) => count - 1);
      }
    };

    const fetchDataSequentially = async (index: number) => {
      if (index < carrierNames.length) {
        await fetchData(carrierNames[index]);
        await fetchDataSequentially(index + 1);
      }
    };

    await fetchDataSequentially(0);
  };

  const getGeneralAgencyMappingForHawksoftBundle = async (generalAgencyNames: any, invalidPolicyErrorLoop: any) => {
    setGeneralAgencySearchLoading(true);
    let generalAgencyData: any = [];
    let newGaNames: any = [];
    let newGaAddress: any = [];
    let newGaCity: any = [];
    let newGaState: any = [];
    let newGaZip: any = [];
    let newGaCountry: any = [];
    let newGaSearchCode: any = [];

    const fetchData = async (generalAgencyName: any) => {
      try {
        var token = CommonValues.GetToken();

        var data = {
          search: "",
          generalagency: generalAgencyName,
        };
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/generalagencycodes`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);
        setLoadingCount((count) => count - 1);

        if (response.data.length > 0) {
          generalAgencyData.push(response.data[0]);
        } else {
          generalAgencyData.push({ description: generalAgencyName });
        }

        if (generalAgencyData.length === generalAgencyNames.length) {
          generalAgencyData.forEach((gaResponse: any, index: any) => {
            if (gaResponse != null) {
              newGaNames[index] = gaResponse.description;
              setGaName(newGaNames);

              newGaAddress[index] = gaResponse.address1;
              setGaAddress(newGaAddress);

              newGaCity[index] = gaResponse.city;
              setGaCity(newGaCity);

              newGaState[index] = gaResponse.state;
              setGaState(newGaState);

              newGaZip[index] = gaResponse.zipCode;
              setGaZip(newGaZip);

              newGaCountry[index] = "USA";
              setGaCountry(newGaCountry);

              newGaSearchCode[index] = gaResponse.searchCode;
              setGaSearchCode(newGaSearchCode);
            }
          });

          setGeneralAgencySearchLoading(false);
          setLoadingCount((count) => count - 1);
        }
      } catch (error: any) {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the general agency details";
          }
        } else {
          errorMessage = "Unknown error while loading the general agency details";
        }
        setErrorMessage(errorMessage);
        setLoadingCount((count) => count - 1);
      }
    };

    const fetchDataSequentially = async (index: number) => {
      if (index < generalAgencyNames.length) {
        await fetchData(generalAgencyNames[index]);
        await fetchDataSequentially(index + 1);
      }
    };

    await fetchDataSequentially(0);
  };

  const getCoverageCodeMapping = (code: any, matchFlag: any, index: any, newValue: any, coverageCodeErrorLoop: any, invalidPolicyErrorLoop: any) => {
    var token = CommonValues.GetToken();
    matchFlag = code != null && code != undefined ? (code.includes(" ") ? "desc" : "code") : "code";

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/coveragecodesbyhscode?HsCode=${code}&MatchFlag=${matchFlag}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        newValue[index] = response.data;
        if (response.data == null || response.data == "") {
          coverageCodeErrorLoop[index] = "Please select coverage code";
          invalidPolicyErrorLoop[index] = "true";
        }
        setCoverageCode(newValue);
        setInputValue(newValue);
        setInvalidPolicyError(invalidPolicyErrorLoop);
        setCoverageCodeError(coverageCodeErrorLoop);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the coverage code details";
          }
        } else {
          errorMessage = "Unknown error while loading the coverage code details";
        }
        setErrorMessage(errorMessage);
      });
  };
  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    setNowCertsClientsByIdLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setTimeout(() => {
          setNowCertsClientsByIdLoading(false);
        }, 500);

        let insuredInfo = response.data;
        if (insuredInfo != null && insuredInfo.length > 0) {
          setBusinessProfile(insuredInfo[0].commercialName);
          setBusinessEmail(insuredInfo[0].eMail);
          setBusinessPhoneNumber(insuredInfo[0].phone);
          setAddLine1(insuredInfo[0].addressLine1);
          setAddLine2(insuredInfo[0].addressLine2);
          setCity(insuredInfo[0].city);
          setSstate(insuredInfo[0].stateNameOrAbbreviation);
          setZipCode(insuredInfo[0].zipCode);
          setClientId(insuredInfo[0].databaseId);
          setFirstName(insuredInfo[0].commercialName);
          setClientId(insuredInfo[0].databaseId);

          let clianttlist = {
            clientName: insuredInfo[0].commercialName,
            clientId: insuredInfo[0].databaseId,
          };
          props.onClientNameChange(insuredInfo[0].commercialName);
          localStorage.setItem("client", JSON.stringify(clianttlist));
          if (insuredInfo[0].zipCode == "" || insuredInfo[0].zipCode == null) {
            setShowDisableForm(true);
            setZipCodeError("Please enter zip code");
          } else if (!/^[0-9-]*$/.test(zipCode)) {
            setZipCodeError("Zip code can only contain numbers and '-'");
          }
          if (insuredInfo[0].stateNameOrAbbreviation == "" || insuredInfo[0].stateNameOrAbbreviation == null) {
            setShowDisableForm(true);
            setStateError("Please enter state");
          } else if (!/^[a-zA-Z\s]+$/.test(insuredInfo[0].stateNameOrAbbreviation)) {
            setShowDisableForm(true);
            setStateError("State can only contain alphabetic characters");
          }
          if (insuredInfo[0].city == "" || insuredInfo[0].city == null) {
            setShowDisableForm(true);
            setCityError("Please enter city");
          }

          if (insuredInfo[0].addressLine1 == "" || insuredInfo[0].addressLine1 == null) {
            setShowDisableForm(true);
            setAddressError("Please enter address");
          }
          if (insuredInfo[0].commercialName == "" || insuredInfo[0].commercialName == null || insuredInfo[0].commercialName == undefined) {
            setShowDisableForm(true);
            setBusinessProfileError("Please enter business name");
          }
        }

        setShowForm(true);
      })
      .catch((error: any) => {
        setTimeout(() => {
          setNowCertsClientsByIdLoading(false);
        }, 500);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
          }
        }
        setShowForm(true);
      });
  };
  function upDateTotalCurrentPremiuim(lobArray: any, isValueChnaged: boolean) {
    let premiuim = 0;
    if (isValueChnaged) {
      lobArray.forEach((element: any) => {
        let prem = element != "" && element != null ? parseFloat(element) : 0;
        premiuim = premiuim + prem;
      });
      setTotalCurrentPremium(premiuim.toString());
    } else {
      lobArray.forEach((element: any) => {
        premiuim = premiuim + element.calculatedPremium;
      });
      setTotalCurrentPremium(premiuim.toString());
    }
  }

  const getPolicyDetails = async (
    policyId: any,
    indexx: any,
    lobArray: any,
    newPolicyNum: any,
    newEffDt: any,
    newPrem: any,
    newCoverageCodes: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    newTerm: any,
    newTaxes: any,
    newFees: any
  ) => {
    setCarrierLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydetails?databaseId=${policyId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setShowForm(true);
      let policyInfo = response.data;
      if (policyInfo != null && policyInfo.length > 0) {
        newPrem.push(parseFloat(response.data[0].totalPremium).toFixed(2));
        setCurrentPremiumsArray(newPrem);
        upDateTotalCurrentPremiuim(newPrem, true);

        let invalidPolicyErrorLoop: any = [];
        let policyIdsString = localStorage.getItem("policyIds");
        if (policyIdsString !== null && policyIdsString.trim() !== "[]") {
          lobArray.push({ code: policyInfo[0].lineOfBusinesses[0].lineOfBusinessName });
          newPolicyNum[lobArray.length - 1] = response.data[0].number;
          newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
          newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
          getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);

          setTerm(newTerm);
          setSubPolicyLobs(lobArray);
          setPolicyNumber(newPolicyNum);
          setEffectiveDate(newEffDt);
        } else {
          if (policyInfo[0].lineOfBusinesses != null && policyInfo[0].lineOfBusinesses.length > 0) {
            if (policyInfo[0].lineOfBusinesses.length > 1) {
              lobArray.push({ code: "Commercial Package" });
              newPolicyNum[lobArray.length - 1] = response.data[0].number;
              newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
              newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
              GetDataMappingForPF(
                "Commercial Package", lobArray.length - 1, newCoverageCodes, [], [], //for coverage code mapping
                policyInfo[0].carrierName, "", lobArray.length, [], newCarrierValues, newCarrierName, newCarrierAddress, newCarrierCity, newCarrierState, newCarrierZip
              );
              getPolicyEndorsementDetailList(policyInfo[0].lineOfBusinesses[0].policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
            } else {
              policyInfo[0].lineOfBusinesses.forEach((item: any, index: any) => {
                lobArray.push({ code: item.lineOfBusinessName });
                newPolicyNum[lobArray.length - 1] = response.data[0].number;
                newEffDt[lobArray.length - 1] = moment.utc(response.data[0].effectiveDate).format("YYYY-MM-DD");
                newTerm[lobArray.length - 1] = policyInfo[0].policyTerm;
                GetDataMappingForPF(
                  item.lineOfBusinessName, lobArray.length - 1, newCoverageCodes, [], [], //for coverage code mapping
                  policyInfo[0].carrierName, "", lobArray.length, [], newCarrierValues, newCarrierName, newCarrierAddress, newCarrierCity, newCarrierState, newCarrierZip
                );
                getPolicyEndorsementDetailList(item.policyDatabaseId, lobArray.length - 1, newTaxes, newFees);
              });
            }
            setTerm(newTerm);
            setSubPolicyLobs(lobArray);
            setPolicyNumber(newPolicyNum);
            setEffectiveDate(newEffDt);
          }
          if (policyInfo[0].insuredDatabaseId != null || policyInfo[0].insuredDatabaseId != "") {
            let insuredDatabaseId = policyInfo[0].insuredDatabaseId;
            setClientId(insuredDatabaseId);
            getNowCertsClientsById(insuredDatabaseId);
            setNCInsuredId(insuredDatabaseId);
          }
        }
        if (props.momentumPolicyId != null && props.momentumPolicyId != "" && props.enableQuickQuote === true) {
          setCapitalTarget("CAPITALNC");
          setMomentumLoading(true);
          setTimeout(() => {
            getCapitalQuoteFromPopup();
            setMomentumLoading(false);
          }, 2500);
        } else {
          setCapitalTarget("CAPITAL");
        }

        const newErrorValue = [];
        if (response.data[0].totalPremium.toString() == "" || response.data[0].totalPremium.toString() == null) {
          newErrorValue[indexx] = "Please enter premium amount";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else if (response.data[0].totalPremium <= 0) {
          newErrorValue[indexx] = "Please enter a valid current premium amount above 0";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          newErrorValue[indexx] = "";
        }
        setCurrentPremiumError(newErrorValue);

        let effectiveDateArrayValues = [...effectiveDateError];
        if (response.data[0].effectiveDate.length === 0) {
          const newValue = ["Please enter effective date"];
          effectiveDateArrayValues = newValue;
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          response.data[0].effectiveDate.forEach((element: any, index: any) => {
            const newValue = [...effectiveDateArrayValues];
            if (element.toString() == "" || element.toString() == null) {
              newValue[index] = "Please enter effective date";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            effectiveDateArrayValues = newValue;
          });
        }
        setEffectiveDateError(effectiveDateArrayValues);

        let policyArrayValues = [...policyNumberError];
        if (response.data[0].number.length === 0) {
          const newValue = ["Please enter policy number"];
          policyArrayValues = newValue;
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          response.data[0].number.forEach((element: any, index: any) => {
            const newValue = [...policyArrayValues];
            if (element.toString() == "" || element.toString() == null) {
              newValue[index] = "Please enter policy number";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            policyArrayValues = newValue;
          });
        }
        setPolicyNumberError(policyArrayValues);

        setCarrierLoading(false);
      }
    } catch (error: any) {
      setLoadingCount(0);
      if (error.response != null) {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          if (props.momentumPolicyId != null && props.momentumPolicyId != "") {
            setShowPolicyMessage("Unknown error while loading the policy details");
          } else {
            setShowPolicyMessage("");
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while loading the policy details",
              life: 3000,
            });
          }
        }

        setCarrierLoading(false);
      }
    }
  };

  const getPolicyEndorsementDetailList = (policyId: any, index: number, newTaxes: any, newFees: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/getpolicyendorsementdetailslist?policyId=${policyId}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        newFees[index] = parseFloat(response.data[0]).toFixed(2);
        newTaxes[index] = parseFloat(response.data[1]).toFixed(2);
        setTaxes(newTaxes);
        setFees(newFees);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting policyendorsementdetaillist ",
              life: 3000,
            });
          }
        }
      });
  };
  const getCapitalQuoteFromPopup = () => {
    setCapitalShowResponse(true);
    setCapitalLogoUrl("https://d1u42a3y69tca1.cloudfront.net/companydata/765/logo/CapitalPremiunFinanceLogo.jpg");
    props.pageStateForBackButton()
  };
  const onAddPolicyClicked = () => {
    let lobArray = [...subPolicyLobs];
    lobArray.push({
      code: "***",
      effectiveDate: moment(new Date()).format("YYYY-MM-DD"),
    });
    setSubPolicyLobs(lobArray);
    let newEffDt = [...effectiveDate];
    newEffDt[subPolicyLobs.length] = moment(new Date()).format("YYYY-MM-DD");
    setEffectiveDate(newEffDt);
    let newPolicyNumber = [...policyNumber];
    newPolicyNumber[subPolicyLobs.length] = "";
    setPolicyNumber(newPolicyNumber);
    let newCarrierCountry = [...carrierCountry];
    newCarrierCountry[subPolicyLobs.length] = "USA";
    setCarrierCountry(newCarrierCountry);
    carrierValue.push({ "": "" });
    coverageCode.push({ description: null });

    let newCurrentPremium = [...currentPremiumsArray];
    newCurrentPremium[subPolicyLobs.length] = "";
    setCurrentPremiumsArray(newCurrentPremium);
    setSelectedSubPolicy(lobArray.length - 1);
    setInputValue(lobArray);
    let newTaxes: any = [...taxes];
    let newFees: any = [...fees];
    newTaxes[subPolicyLobs.length] = 0;
    newFees[subPolicyLobs.length] = 0;
    minimumEarned[subPolicyLobs.length] = 0;
    setTaxes(newTaxes);
    setFees(newFees);
  };
  const onSubPolicySelect = (index: any) => {
    setSelectedSubPolicy(index);
  };
  const onRemoveOkClicked = (e: any, index: any) => {
    let newArray = [...subPolicyLobs];
    newArray.splice(index, 1);
    setSubPolicyLobs(newArray);
    coverageCode.splice(index, 1);
    currentPremiumsArray.splice(index, 1);
    // inputValue.splice(index, 1);
    carrierValue.splice(index, 1);
    taxes.splice(index, 1);
    fees.splice(index, 1);
    carrierAddress.splice(index, 1);
    carrierCity.splice(index, 1);
    carrierState.splice(index, 1);
    carrierZip.splice(index, 1);
    setShowConfirmation(false);
    updatedLob.splice(index, 1);
    policyNumber.splice(index, 1);
    effectiveDate.splice(index, 1);
    gaName.splice(index, 1);
    gaAddress.splice(index, 1);
    gaCity.splice(index, 1);
    gaState.splice(index, 1);
    gaZip.splice(index, 1);
    upDateTotalCurrentPremiuim(currentPremiumsArray, true);
    setSelectedSubPolicy(index - 1);
    e.stopPropagation();
  };
  const getPolicyDetailsVertafore = (
    policyId: any,
    indexx: any,
    lobArray: any,
    newCoverageCodes: any,
    newPolicyNum: any,
    newEffDt: any,
    newPrem: any,
    newCarrierValues: any,
    newCarrierName: any,
    newCarrierAddress: any,
    newCarrierCity: any,
    newCarrierState: any,
    newCarrierZip: any,
    newTerm: any
  ) => {
    const token = CommonValues.GetToken();
    setProgressLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/policy?policyid=${policyId}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setProgressLoading(false);
        let newTaxes: any = [];
        let newFees: any = [];
        let newGeneralAgency: any = [];
        let newTerm: any = [];
        newTaxes[indexx] = 0;
        newFees[indexx] = 0;
        setTaxes(newTaxes);
        setFees(newFees);
        response.data.LineOfBusiness.forEach((item: any, index: any) => {
          lobArray.push({ code: item });
          getCoverageCodeMapping(item, "code", lobArray.length - 1, newCoverageCodes, [], []);
          newPolicyNum[lobArray.length - 1] = response.data.PolicyNumber;
          newEffDt[lobArray.length - 1] = moment(response.data.EffectiveDate, "MM-DD-YYYY hh:mm:ss A").format("YYYY-MM-DD");
          newTerm[lobArray.length - 1] = response.data.Term;
        });
        setShowTrashIcon(response.data.LineOfBusiness.length);

        setSubPolicyLobs(lobArray);
        setPolicyNumber(newPolicyNum);
        setEffectiveDate(newEffDt);
        setTerm(newTerm);

        getCarrierCodeMapping(
          response.data.Carrier,
          "",
          lobArray.length - 1,
          lobArray.length,
          [],
          newCarrierValues,
          newCarrierName,
          newCarrierAddress,
          newCarrierCity,
          newCarrierState,
          newCarrierZip
        );
        getGeneralAgencyMapping(response.data.GeneralAgency, "", lobArray.length - 1, lobArray.length, []);

        response.data.CurrentPremium.forEach((item: any) => {
          newPrem.push(parseFloat(item).toFixed(2));
        });
        setCurrentPremiumsArray(newPrem);
        upDateTotalCurrentPremiuim(newPrem, true);
        setAddLine1(response.data.Address1);
        setAddLine2(response.data.Address2);

        setCity(response.data.City);
        setSstate(response.data.State);
        setZipCode(response.data.ZipCode);
        // setTerm(response.data.Term);

        if (response.data.Country != null && response.data.Country != "") {
          setCountry(response.data.Country);
        } else {
          setCountry("USA");
        }
        setBusinessProfile(response.data.BusinessName);
        setBusinessEmail(response.data.BusinessEmail);
        setBusinessPhoneNumber(response.data.BusinessPhoneNumber);

        setCurrentPremium(response.data.CurrentPremium.toFixed(2));

        // setTerm(response.data.Term);

        setClientId("0");
        setPolicyIndex("0");

        const newErrorValue = [];
        if (response.data.CurrentPremium.toString() == "" || response.data.CurrentPremium.toString() == null) {
          newErrorValue[indexx] = "Please enter premium amount";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else if (response.data.CurrentPremium <= 0) {
          newErrorValue[indexx] = "Please enter a valid current premium amount above 0";
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          newErrorValue[indexx] = "";
        }
        setCurrentPremiumError(newErrorValue);

        // if (response.data.Term == "" || response.data.Term == null) {
        //   setShowDisableForm(true);
        //   setTermError("Please enter term ");
        // } else if (parseInt(response.data.Term) < 9) {
        //   setShowDisableForm(true);
        //   setTermError("Term cannot be less than 9 months");
        // } else {
        //   setTermError("");
        // }

        let policyArrayValues = [...policyNumberError];
        if (response.data.PolicyNumber.length === 0) {
          const newValue = ["Please enter policy number"];
          policyArrayValues = newValue;
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          response.data.PolicyNumber.forEach((element: any, index: any) => {
            const newValue = [...policyArrayValues];
            if (element.toString() == "" || element.toString() == null) {
              newValue[index] = "Please enter policy number";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            policyArrayValues = newValue;
          });
        }
        setPolicyNumberError(policyArrayValues);

        let effectiveDateArrayValues = [...effectiveDateError];
        if (response.data.EffectiveDate.length === 0) {
          const newValue = ["Please enter effective date"];
          effectiveDateArrayValues = newValue;
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          response.data.EffectiveDate.forEach((element: any, index: any) => {
            const newValue = [...effectiveDateArrayValues];
            if (element.toString() == "" || element.toString() == null) {
              newValue[index] = "Please enter effective date";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            effectiveDateArrayValues = newValue;
          });
        }

        setEffectiveDateError(effectiveDateArrayValues);

        let termArrayValues = [...termError];
        if (response.data.Term.length === 0) {
          const newValue = ["Please enter term"];
          termArrayValues = newValue;
          if (window.innerWidth > 767) {
            window.scroll(0, 0);
          }
        } else {
          response.data.Term.forEach((element: any, index: any) => {
            const newValue = [...termArrayValues];
            if (element.toString() == "" || element.toString() == null) {
              newValue[index] = "Please enter term";
              if (window.innerWidth > 767) {
                window.scroll(0, 0);
              }
            } else {
              newValue[index] = "";
            }
            termArrayValues = newValue;
          });
        }

        setTermError(termArrayValues);

        if (response.data.ZipCode == "" || response.data.ZipCode == null) {
          setShowDisableForm(true);
          setZipCodeError("Please enter zip code");
        } else if (!/^[0-9-]*$/.test(zipCode)) {
          setZipCodeError("Zip code can only contain numbers and '-'");
        }
        if (response.data.State == "" || response.data.State == null) {
          setShowDisableForm(true);
          setStateError("Please enter state");
        } else if (!/^[a-zA-Z\s]+$/.test(response.data.State)) {
          setShowDisableForm(true);
          setStateError("State can only contain alphabetic characters");
        }
        if (response.data.City == "" || response.data.City == null) {
          setShowDisableForm(true);
          setCityError("Please enter city");
        }

        if (response.data.Address1 == "" || response.data.Address1 == null) {
          setShowDisableForm(true);
          setAddressError("Please enter address");
        }
        if (response.data.BusinessName == "" || response.data.BusinessName == null || response.data.BusinessName == undefined) {
          setShowDisableForm(true);
          setBusinessProfileError("Please enter business name");
        }
      })
      .catch((error: any) => {
        // setInput1Loading(false);
        let errorMessage = "";
        setProgressLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the policy details";
          }
        } else {
          errorMessage = "Unknown error while loading the policy details";
        }
      });
  };
  const getCustomerDetailsAndPolicyDetailsByCustomerId = (customerId: any) => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/customerpolicies?customerid=${customerId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setBusinessProfile(response.data.customer.Customer.FirmName);
        setAddLine1(response.data.customer.Customer.AddressLine1);
        setAddLine2(response.data.customer.Customer.AddressLine2);
        setCity(response.data.customer.Customer.City);
        setSstate(response.data.customer.Customer.State);
        setZipCode(response.data.customer.Customer.ZipCode);
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400 && error.response.data.Message != undefined && error.response.data.Message != null) {
            // setErrorMessageInvalidCreds(error.response.data.Message);
          } else {
            errorMessage = "Unknown error while loading the vertafore client details";
          }
        }
      });
  };

  const onActivePanelChanged = (index: any) => {
    let newValues = [];
    if (activePanel[index] === 0 || activePanel[index] === undefined || activePanel.length === 0) {
      newValues[index] = 1;
    } else {
      newValues[index] = 0;
    }

    setActivePanel(newValues);
    setShowConfirmation(false);
    setSelectedSubPolicy(index);
  };
  const onSetCarrierNameRuntime = () => {
    setCarrierValue({ description: carrierSearch[selectedSubPolicy] });

    let List: any = [];

    List.push({ description: carrierSearch[selectedSubPolicy] });
    setCarrierList(List);
  };
  const GetDataMappingForPF = (
    coveragecode: any, index: any, newValue: any, coverageCodeErrorLoop: any, invalidPolicyErrorLoop: any,
    carrierName: any, search: any, subPolicyCount: any, [], newCarrierValues: any, newCarrierName: any, newCarrierAddress: any, newCarrierCity: any, newCarrierState: any, newCarrierZip: any
  ) => {
    let newLoadingValue: any = []
    newLoadingValue[index] = true;
    setDataMappingLoading(newLoadingValue);
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      "HsCode": coveragecode,
      "MatchFlag": coveragecode.includes(" ") ? "desc" : "code",
      "hscarrier": carrierName,
      "generalAgency": ""
    });

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/datamapping`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then((response: any) => {

        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);

        newValue[index] = response.data.coverageCodeMapping;
        if (response.data == null || response.data == "") {
          coverageCodeErrorLoop[index] = "Please select coverage code";
          invalidPolicyErrorLoop[index] = "true";
        }
        setCoverageCode(newValue);
        setInputValue(newValue);
        setInvalidPolicyError(invalidPolicyErrorLoop);
        setCoverageCodeError(coverageCodeErrorLoop);
        if (search == "") {
          if (response.data.carrierCodeMappings != null) {
            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierName[i] = response.data.carrierCodeMappings.description;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = response.data.carrierCodeMappings;
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);

                newCarrierAddress[i] = response.data.carrierCodeMappings.address1;
                setCarrierAddress(newCarrierAddress);

                newCarrierCity[i] = response.data.carrierCodeMappings.city;
                setCarrierCity(newCarrierCity);

                newCarrierState[i] = response.data.carrierCodeMappings.state;
                setCarrierState(newCarrierState);

                newCarrierZip[i] = response.data.carrierCodeMappings.zipCode;
                setCarrierZip(newCarrierZip);
              }
            }
          } else {
            invalidPolicyErrorLoop[index] = "";
            let newCarrierCountry = [];
            let newcarrierSearch = [];

            if (subPolicyCount === index + 1) {
              for (let i = 0; i < subPolicyCount; i++) {
                newCarrierCountry[i] = "USA";
                setCarrierCountry(newCarrierCountry);

                newCarrierName[i] = carrierName;
                setCarrierName(newCarrierName);
                newCarrierValues[i] = { description: carrierName };
                setCarrier(newCarrierValues);
                setCarrierValue(newCarrierValues);
              }
            }
          }
        }

        if (props.type == "1") {
          setLoadingCount((count) => count - 2);
        } else {
          setLoadingCount((count) => count - 1);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setTimeout(() => {
          newLoadingValue[index] = false;
          setDataMappingLoading(newLoadingValue);
        }, 500);
      });
  }
  const onBackButonClick = () => {
    setGotoShowResponse(false);
    setCapitalShowResponse(false);
    setIPFSShowResponse(false);
    setPeoplesShowResponse(false);
    setFirstInsuranceFundingShowResponse(false);
    setUsPfShowResponse(false);
  }
  return (
    <div className="row  text-12 position-relative">
      <Toast ref={toast} />
      {progressLoading ? (
        <>
          <div className="col-12 progress-bar">
            <AtkProgressBar />
          </div>
        </>
      ) : showPolicyMessage.length > 0 ? (
        <div className="col-12 mt-5">
          <AtkMessage appearance="warning" messageText={showPolicyMessage} />
        </div>
      ) : (
        <>
          {momentumLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              <AtkLoader />
            </div>
          ) : (
            <>
              {gotoShowResponse ||
                capitalShowResponse ||
                iPFSShowResponse ||
                peoplesShowResponse ||
                firstInsuranceFundingShowResponse ||
                usPfShowResponse ? (
                <>
                  {premiumLoading ? (
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <AtkLoader />
                    </div>
                  ) : (
                    <div className="row mt-4 mx-auto p-0">
                      {capitalShowResponse ? (
                        <>
                          <div className="form-group  col-lg-4 col-md-6 col-12  mb-2">
                            <CapitalResponse
                              businessProfile={businessProfile}
                              businessEmail={businessEmail}
                              businessPhoneNumber={businessPhoneNumber}
                              addLine1={addLine1}
                              addLine2={addLine2}
                              city={city}
                              sstate={sstate}
                              zipCode={zipCode}
                              country={country}
                              policyNumber={policyNumber}
                              coverageCode={coverageCode}
                              effectiveDate={effectiveDate}
                              term={term}
                              currentPremium={currentPremium}
                              currentPremiumsArray={currentPremiumsArray}
                              firstName={firstName}
                              lastName={lastName}
                              clientId={clientId}
                              carrier={carrier}
                              carrierName={carrierName}
                              carrierAddress={carrierAddress}
                              carrierCity={carrierCity}
                              carrierState={carrierState}
                              carrierZip={carrierZip}
                              selectedPolicyIndex={policyIndex}
                              agentName={agentName}
                              agentAddress={agentAddress}
                              agentCity={agentCity}
                              agentState={agentState}
                              agentZip={agentZip}
                              agentCountry={agentCountry}
                              agentPhoneNumber={agentPhone}
                              brokerFee={brokerFee}
                              taxes={taxes}
                              fees={fees}
                              minimumEarned={minimumEarned}
                              nCPolicyId={nCPolicyId}
                              nCInsuredId={nCInsuredId}
                              ncPolicyNumber={policyNumber}
                              gaSearchCode={gaSearchCode}
                              gaName={gaName}
                              gaAddress={gaAddress}
                              gaCity={gaCity}
                              gaState={gaState}
                              gaZip={gaZip}
                              gaCountry={gaCountry}
                              capitalLogoUrl={capitalLogoUrl}
                              onCustomizeClick={onCustomizeClick}
                              onMultiPolicyClick={onMultiPolicyClick}
                              target={capitalTarget}
                            />
                          </div>
                        </>
                      ) : null}
                      {iPFSShowResponse ? (
                        <div className="form-group  col-lg-4 col-md-6 col-12 mb-2">
                          <IpfsResponse
                            businessProfile={businessProfile}
                            businessEmail={businessEmail}
                            businessPhoneNumber={businessPhoneNumber}
                            addLine1={addLine1}
                            addLine2={addLine2}
                            city={city}
                            sstate={sstate}
                            zipCode={zipCode}
                            country={country}
                            policyNumber={policyNumber}
                            coverageCode={coverageCode}
                            effectiveDate={effectiveDate}
                            term={term}
                            currentPremium={currentPremium}
                            currentPremiumsArray={currentPremiumsArray}
                            firstName={firstName}
                            lastName={lastName}
                            clientId={clientId}
                            carrierId={carrierValue.inputCode}
                            carrier={carrier}
                            carrierName={carrierName}
                            carrierAddress={carrierAddress}
                            carrierCity={carrierCity}
                            carrierState={carrierState}
                            carrierZip={carrierZip}
                            selectedPolicyIndex={policyIndex}
                            agentName={agentName}
                            agentAddress={agentAddress}
                            agentCity={agentCity}
                            agentState={agentState}
                            agentZip={agentZip}
                            agentCountry={agentCountry}
                            agentPhoneNumber={agentPhone}
                            brokerFee={brokerFee}
                            taxes={taxes}
                            fees={fees}
                            minimumEarned={minimumEarned}
                            nCPolicyId={nCPolicyId}
                            nCInsuredId={nCInsuredId}
                            ncPolicyNumber={policyNumber}
                            gaSearchCode={gaSearchCode}
                            gaName={gaName}
                            gaAddress={gaAddress}
                            gaCity={gaCity}
                            gaState={gaState}
                            gaZip={gaZip}
                            gaCountry={gaCountry}
                            iPFSLogoUrl={iPFSLogoUrl}
                          />
                        </div>
                      ) : null}
                      {gotoShowResponse ? (
                        <div className="form-group  col-lg-4 col-md-6 col-12 mb-2">
                          <GotoPFResponse
                            businessProfile={businessProfile}
                            businessEmail={businessEmail}
                            businessPhoneNumber={businessPhoneNumber}
                            addLine1={addLine1}
                            addLine2={addLine2}
                            city={city}
                            sstate={sstate}
                            zipCode={zipCode}
                            country={country}
                            policyNumber={policyNumber}
                            coverageCode={coverageCode}
                            effectiveDate={effectiveDate}
                            term={term}
                            currentPremium={currentPremium}
                            currentPremiumsArray={currentPremiumsArray}
                            firstName={firstName}
                            lastName={lastName}
                            clientId={clientId}
                            carrierId={carrierValue.inputCode}
                            carrierName={carrierName}
                            carrier={carrier}
                            carrierAddress={carrierAddress}
                            carrierCity={carrierCity}
                            carrierState={carrierState}
                            carrierZip={carrierZip}
                            selectedPolicyIndex={policyIndex}
                            agentName={agentName}
                            agentAddress={agentAddress}
                            agentCity={agentCity}
                            agentState={agentState}
                            agentZip={agentZip}
                            agentCountry={agentCountry}
                            agentPhoneNumber={agentPhone}
                            brokerFee={brokerFee}
                            taxes={taxes}
                            fees={fees}
                            minimumEarned={minimumEarned}
                            nCPolicyId={nCPolicyId}
                            nCInsuredId={nCInsuredId}
                            ncPolicyNumber={policyNumber}
                            gaSearchCode={gaSearchCode}
                            gaName={gaName}
                            gaAddress={gaAddress}
                            gaCity={gaCity}
                            gaState={gaState}
                            gaZip={gaZip}
                            gaCountry={gaCountry}
                            gotoLogoUrl={gotoLogoUrl}
                          />
                        </div>
                      ) : null}
                      {peoplesShowResponse ? (
                        <div className="form-group  col-lg-4 col-md-6 col-12 mb-2">
                          <Peoples
                            businessProfile={businessProfile}
                            businessEmail={businessEmail}
                            businessPhoneNumber={businessPhoneNumber}
                            addLine1={addLine1}
                            addLine2={addLine2}
                            city={city}
                            sstate={sstate}
                            zipCode={zipCode}
                            country={country}
                            policyNumber={policyNumber}
                            coverageCode={coverageCode}
                            effectiveDate={effectiveDate}
                            term={term}
                            currentPremium={currentPremium}
                            currentPremiumsArray={currentPremiumsArray}
                            firstName={firstName}
                            lastName={lastName}
                            clientId={clientId}
                            carrierId={carrierValue.inputCode}
                            carrierName={carrierName}
                            carrier={carrier}
                            carrierAddress={carrierAddress}
                            carrierCity={carrierCity}
                            carrierState={carrierState}
                            carrierZip={carrierZip}
                            selectedPolicyIndex={policyIndex}
                            agentName={agentName}
                            agentAddress={agentAddress}
                            agentCity={agentCity}
                            agentState={agentState}
                            agentZip={agentZip}
                            agentCountry={agentCountry}
                            agentPhoneNumber={agentPhone}
                            brokerFee={brokerFee}
                            taxes={taxes}
                            fees={fees}
                            minimumEarned={minimumEarned}
                            nCPolicyId={nCPolicyId}
                            nCInsuredId={nCInsuredId}
                            ncPolicyNumber={policyNumber}
                            gaSearchCode={gaSearchCode}
                            gaName={gaName}
                            gaAddress={gaAddress}
                            gaCity={gaCity}
                            gaState={gaState}
                            gaZip={gaZip}
                            gaCountry={gaCountry}
                            peoplesLogoUrl={peoplesLogoUrl}
                          />
                        </div>
                      ) : null}

                      {firstInsuranceFundingShowResponse ? (
                        <div className="form-group  col-lg-4 col-md-6 col-12 mb-2">
                          <FirstInsuranceFunding
                            businessProfile={businessProfile}
                            businessEmail={businessEmail}
                            businessPhoneNumber={businessPhoneNumber}
                            addLine1={addLine1}
                            addLine2={addLine2}
                            city={city}
                            sstate={sstate}
                            zipCode={zipCode}
                            country={country}
                            policyNumber={policyNumber}
                            coverageCode={coverageCode}
                            effectiveDate={effectiveDate}
                            term={term}
                            currentPremium={currentPremium}
                            currentPremiumsArray={currentPremiumsArray}
                            firstName={firstName}
                            lastName={lastName}
                            clientId={clientId}
                            carrierId={carrierValue.inputCode}
                            carrierName={carrierName}
                            carrier={carrier}
                            carrierAddress={carrierAddress}
                            carrierCity={carrierCity}
                            carrierState={carrierState}
                            carrierZip={carrierZip}
                            selectedPolicyIndex={policyIndex}
                            agentName={agentName}
                            agentAddress={agentAddress}
                            agentCity={agentCity}
                            agentState={agentState}
                            agentZip={agentZip}
                            agentCountry={agentCountry}
                            agentPhoneNumber={agentPhone}
                            brokerFee={brokerFee}
                            taxes={taxes}
                            fees={fees}
                            minimumEarned={minimumEarned}
                            nCPolicyId={nCPolicyId}
                            nCInsuredId={nCInsuredId}
                            ncPolicyNumber={policyNumber}
                            gaSearchCode={gaSearchCode}
                            gaName={gaName}
                            gaAddress={gaAddress}
                            gaCity={gaCity}
                            gaState={gaState}
                            gaZip={gaZip}
                            gaCountry={gaCountry}
                            firstInsuranceFundingLogoUrl={firstInsuranceFundingLogoUrl}
                          />
                        </div>
                      ) : null}

                      {usPfShowResponse ? (
                        <div className="form-group  col-lg-4 col-md-6 col-12 mb-2">
                          <UsPremiumFinance
                            businessProfile={businessProfile}
                            businessEmail={businessEmail}
                            businessPhoneNumber={businessPhoneNumber}
                            addLine1={addLine1}
                            addLine2={addLine2}
                            city={city}
                            sstate={sstate}
                            zipCode={zipCode}
                            country={country}
                            policyNumber={policyNumber}
                            coverageCode={coverageCode}
                            effectiveDate={effectiveDate}
                            term={term}
                            currentPremium={currentPremium}
                            currentPremiumsArray={currentPremiumsArray}
                            firstName={firstName}
                            lastName={lastName}
                            clientId={clientId}
                            carrierId={carrierValue.inputCode}
                            carrierName={carrierName}
                            carrier={carrier}
                            carrierAddress={carrierAddress}
                            carrierCity={carrierCity}
                            carrierState={carrierState}
                            carrierZip={carrierZip}
                            selectedPolicyIndex={policyIndex}
                            agentName={agentName}
                            agentAddress={agentAddress}
                            agentCity={agentCity}
                            agentState={agentState}
                            agentZip={agentZip}
                            agentCountry={agentCountry}
                            agentPhoneNumber={agentPhone}
                            brokerFee={brokerFee}
                            taxes={taxes}
                            fees={fees}
                            minimumEarned={minimumEarned}
                            nCPolicyId={nCPolicyId}
                            nCInsuredId={nCInsuredId}
                            ncPolicyNumber={policyNumber}
                            gaSearchCode={gaSearchCode}
                            gaName={gaName}
                            gaAddress={gaAddress}
                            gaCity={gaCity}
                            gaState={gaState}
                            gaZip={gaZip}
                            gaCountry={gaCountry}
                            usPfLogoUrl={usPfLogoUrl}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {window.innerWidth > 767 ? (
                    <div className="col policy-name-tab  ">
                      <span className="policy-type">Policy Type</span>
                      {subPolicyLobs != undefined && subPolicyLobs.length > 0 ? (
                        subPolicyLobs.map((code: any, index: any) => {
                          return (
                            <span
                              className={selectedSubPolicy === index ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                              onClick={() => onSubPolicySelect(index)}
                            >
                              {code.code}
                              {index + 1 > showTrashIcon ? (
                                <b className="remove-policy-icon" onClick={(e) => onRemoveOkClicked(e, index)}>
                                  x
                                </b>
                              ) : null}
                            </span>
                          );
                        })
                      ) : (
                        <span className="policy-name-span">Invalid Type</span>
                      )}
                      {ncCombiningPoliciesLoading ? (
                        <span className="add-policy-loding">
                          <AtkLoader />
                        </span>
                      ) : (
                        <img
                          src={AddPolicyIcon}
                          onClick={onAddPolicyClicked}
                          height={20}
                          width={20}
                          title="Add Policy"
                          className="show-cursor ms-2 plus-icon"
                        />
                      )}
                    </div>
                  ) : null}

                  <div className="col-12 my-3">
                    {subPolicyLobs !== undefined && subPolicyLobs.length > 0 ? (
                      window.innerWidth > 767 ? (
                        subPolicyLobs.map((code: any, index: any) => {
                          return (
                            <>
                              {index === selectedSubPolicy ? (
                                <SubpolicyDetails
                                  onSetCarrierNameRuntime={onSetCarrierNameRuntime}
                                  dataMappingLoading={dataMappingLoading}
                                  index={selectedSubPolicy}
                                  search={search}
                                  inputValue={
                                    coverageCode !== undefined && coverageCode != null && coverageCode.length > 0
                                      ? coverageCode[selectedSubPolicy] !== undefined && coverageCode[selectedSubPolicy] != null
                                        ? coverageCode[selectedSubPolicy].description
                                        : ""
                                      : ""
                                  }
                                  carrierName={carrierName}
                                  gaName={gaName}
                                  term={term}
                                  currentPremium={currentPremium}
                                  currentPremiumsArray={currentPremiumsArray}
                                  effectiveDate={effectiveDate}
                                  policyNumber={policyNumber}
                                  taxes={taxes}
                                  fees={fees}
                                  minimumEarned={minimumEarned}
                                  carrierAddress={carrierAddress}
                                  carrierCity={carrierCity}
                                  carrierState={carrierState}
                                  carrierZip={carrierZip}
                                  carrierCountry={carrierCountry}
                                  generalAgency={generalAgencyList}
                                  generalAgencyName={gaName}
                                  generalAgencyAddress={gaAddress}
                                  generalAgencyCity={gaCity}
                                  generalAgencyState={gaState}
                                  generalAgencyZip={gaZip}
                                  generalAgencyCountry={gaCountry}
                                  onPremiumChange={onPremiumChange}
                                  onCarrierNameChange={onCarrierNameChange}
                                  onCarrierAdressChange={onCarrierAdressChange}
                                  onCarrierselect={onCarrierselect}
                                  carrierList={carrierList}
                                  carrierSearch={carrierSearch}
                                  onCoverageCodeChange={onCoverageCodeChange}
                                  onCovergaeCodeselect={onCovergaeCodeselect}
                                  GotoPfCodeList={GotoPfCodeList}
                                  onPolicyNumberChange={onPolicyNumberChange}
                                  onEffectiveDateChange={onEffectiveDateChange}
                                  onFeesChange={onFeesChange}
                                  onMinimumEarnedChange={onMinimumEarnedChange}
                                  onTaxesChange={onTaxesChange}
                                  onGeneralAgencySearchChange={onGeneralAgencySearchChange}
                                  onGeneralAgencySelect={onGeneralAgencySelect}
                                  onCarrierCityChange={onCarrierCityChange}
                                  onCarrierStateChange={onCarrierStateChange}
                                  onCarrierZipChange={onCarrierZipChange}
                                  onCarrierCountryChange={onCarrierCountryChange}
                                  onGeneralAgnecyAdressChange={onGeneralAgnecyAdressChange}
                                  onGeneralAgnecyCityChange={onGeneralAgnecyCityChange}
                                  onGeneralAgnecyStateChange={onGeneralAgnecyStateChange}
                                  onGeneralAgnecyZipChange={onGeneralAgnecyZipChange}
                                  onGeneralAgnecyCountryChange={onGeneralAgnecyCountryChange}
                                  onClearGACLicked={onClearGACLicked}
                                  policyNumberError={policyNumberError}
                                  effectiveDateError={effectiveDateError}
                                  coverageCodeError={coverageCodeError}
                                  currentPremiumError={currentPremiumError}
                                  carrierNameError={carrierNameError}
                                  carrierZipCodeError={carrierZipCodeError}
                                  carrierStateError={carrierStateError}
                                  carrierCityError={carrierCityError}
                                  carrierValue={carrierValue}
                                  termError={termError}
                                  brokerFee={brokerFee}
                                  onBrokerFeeChange={onBrokerFeeChange}
                                  onTermChange={onTermChange}
                                />
                              ) : null}
                            </>
                          );
                        })
                      ) : (
                        subPolicyLobs.map((code: any, index: any) => (
                          <div className="accordion mt-2 mb-2">
                            <div
                              className={
                                invalidPolicyError[index] === "true"
                                  ? `accordion__panel__invalid ${activePanel[index] ? "active" : ""}`
                                  : `accordion__panel ${activePanel[index] ? "active" : ""}`
                              }
                            >
                              <div
                                className={`accordion__panel-title ${activePanel[index] ? "active position-relative" : "position-relative"}`}
                                onClick={() => onActivePanelChanged(index)}
                              >
                                <span>
                                  <b>Policy Type </b>- {code.code === undefined || code.code === null ? code : code.code}
                                </span>
                                {activePanel[index] ? (
                                  <i className="fa fa-solid fa-chevron-up me-3"></i>
                                ) : (
                                  <i className="fa fa-solid fa-chevron-down me-3"></i>
                                )}
                                {index + 1 > showTrashIcon ? (
                                  <b className="remove-policy-icon" onClick={(e) => onRemoveOkClicked(e, index)}>
                                    x
                                  </b>
                                ) : null}
                              </div>

                              <div className={`accordion__panel-content ${activePanel[index] ? "active" : ""}`}>
                                {index === selectedSubPolicy && (
                                  <SubpolicyDetails
                                    dataMappingLoading={dataMappingLoading}
                                    index={index}
                                    search={search}
                                    inputValue={
                                      coverageCode !== undefined && coverageCode != null && coverageCode.length > 0
                                        ? coverageCode[index] !== undefined
                                          ? coverageCode[index].description
                                          : ""
                                        : ""
                                    }
                                    carrierName={carrierName}
                                    gaName={gaName}
                                    term={term}
                                    currentPremium={currentPremium}
                                    currentPremiumsArray={currentPremiumsArray}
                                    effectiveDate={effectiveDate}
                                    policyNumber={policyNumber}
                                    taxes={taxes}
                                    fees={fees}
                                    minimumEarned={minimumEarned}
                                    carrierAddress={carrierAddress}
                                    carrierCity={carrierCity}
                                    carrierState={carrierState}
                                    carrierZip={carrierZip}
                                    carrierCountry={carrierCountry}
                                    generalAgency={generalAgencyList}
                                    generalAgencyName={gaName}
                                    generalAgencyAddress={gaAddress}
                                    generalAgencyCity={gaCity}
                                    generalAgencyState={gaState}
                                    generalAgencyZip={gaZip}
                                    generalAgencyCountry={gaCountry}
                                    onPremiumChange={onPremiumChange}
                                    onCarrierNameChange={onCarrierNameChange}
                                    onCarrierAdressChange={onCarrierAdressChange}
                                    onCarrierselect={onCarrierselect}
                                    carrierList={carrierList}
                                    carrierSearch={carrierSearch}
                                    onCoverageCodeChange={onCoverageCodeChange}
                                    onCovergaeCodeselect={onCovergaeCodeselect}
                                    GotoPfCodeList={GotoPfCodeList}
                                    onPolicyNumberChange={onPolicyNumberChange}
                                    onEffectiveDateChange={onEffectiveDateChange}
                                    onFeesChange={onFeesChange}
                                    onMinimumEarnedChange={onMinimumEarnedChange}
                                    onTaxesChange={onTaxesChange}
                                    onGeneralAgencySearchChange={onGeneralAgencySearchChange}
                                    onGeneralAgencySelect={onGeneralAgencySelect}
                                    onCarrierCityChange={onCarrierCityChange}
                                    onCarrierStateChange={onCarrierStateChange}
                                    onCarrierZipChange={onCarrierZipChange}
                                    onCarrierCountryChange={onCarrierCountryChange}
                                    onGeneralAgnecyAdressChange={onGeneralAgnecyAdressChange}
                                    onGeneralAgnecyCityChange={onGeneralAgnecyCityChange}
                                    onGeneralAgnecyStateChange={onGeneralAgnecyStateChange}
                                    onGeneralAgnecyZipChange={onGeneralAgnecyZipChange}
                                    onGeneralAgnecyCountryChange={onGeneralAgnecyCountryChange}
                                    onClearGACLicked={onClearGACLicked}
                                    policyNumberError={policyNumberError}
                                    effectiveDateError={effectiveDateError}
                                    coverageCodeError={coverageCodeError}
                                    currentPremiumError={currentPremiumError}
                                    carrierNameError={carrierNameError}
                                    carrierZipCodeError={carrierZipCodeError}
                                    carrierStateError={carrierStateError}
                                    carrierCityError={carrierCityError}
                                    carrierValue={carrierValue}
                                    termError={termError}
                                    brokerFee={brokerFee}
                                    onBrokerFeeChange={onBrokerFeeChange}
                                    onTermChange={onTermChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <div className="text-center text-14 fw-bold">
                        <p>No data found</p>
                      </div>
                    )}

                    {window.innerWidth < 767 ? (
                      <>
                        {ncCombiningPoliciesLoading ? (
                          <span className="add-policy-loding d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </span>
                        ) : (
                          <img
                            src={AddPolicyIcon}
                            onClick={onAddPolicyClicked}
                            height={20}
                            width={20}
                            title="Add Policy"
                            className="show-cursor ms-2 plus-icon"
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="col-12 divider "></div>

                  <div className="col-12">
                    <ShowMore
                      nowCertsClientsByIdLoading={nowCertsClientsByIdLoading}
                      businessProfile={businessProfile}
                      addLine1={addLine1}
                      addLine2={addLine2}
                      city={city}
                      state={sstate}
                      country={country}
                      zipCode={zipCode}
                      businessEmail={businessEmail}
                      phone={businessPhoneNumber}
                      onBusinessProfileNameChange={onBusinessProfileNameChange}
                      onBusinessEmailChange={onBusinessEmailChange}
                      onBusinessPhoneNumberChange={onBusinessPhoneNumberChange}
                      onAddressChange={onAddressChange}
                      onAddressLine2Change={onAddressLine2Change}
                      onCityChange={onCityChange}
                      onStateChange={onStateChange}
                      onZipcodeChange={onZipcodeChange}
                      onCountryChange={onCountryChange}
                      onPremiumChange={onPremiumChange}
                      businessProfileError={businessProfileError}
                      businessEmailError={businessEmailError}
                      businessPhoneNumberError={businessPhoneNumberError}
                      addressError={addressError}
                      addressLine2Error={addressLine2Error}
                      cityError={cityError}
                      stateError={stateError}
                      countryError={countryError}
                      zipCodeError={zipCodeError}
                      policyNumberError={policyNumberError}
                      effectiveDateError={effectiveDateError}
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-4 col-sm-12 d-flex align-items-center pb-2 mt-2 ">
                    <p className="total-premium-div">
                      Total Premium{" "}
                      <span className="ms-2">
                        {" "}
                        {totalCurrentPremium != "" && totalCurrentPremium != "NaN" && totalCurrentPremium != null
                          ? parseFloat(totalCurrentPremium).toLocaleString("en-US", { style: "currency", currency: "USD" })
                          : "0.0"}{" "}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="form-group col-lg-8 col-md-8 col-sm-12  mt-2  ">
                    <div className="row ">
                      <div className="col-lg-9 col-md-8  col-sm-9 col-12 text-end accept-checkbox">
                        <div className="d-flex align-items-center justify-content-end">
                          <AtkCheckbox label={labelElement} className="" name={termCondition} isChecked={termCondition} onChange={onCheckboxChange} />
                        </div>
                        <span className="text-danger text-12 ">{checkboxError}</span>
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-3 col-12 d-flex align-items-center justify-content-end">
                        {(localStorage.getItem("IsIndicative") != null && localStorage.getItem("IsIndicative") == "true") ||
                          localStorage.getItem("FromUploadFileResponse") == "true" ? (
                          <>
                            {saveLoading ? (
                              <div className="me-2">
                                <AtkLoader />
                              </div>
                            ) : (
                              <div className="save-btn me-1 ">
                                <AtkButton label="Save" onClick={onSaveClick} className="w-100" />
                              </div>
                            )}
                          </>
                        ) : null}
                        <AtkButtonIconBefore
                          label="Submit"
                          // iconBefore={<AtkIcon icon={SAveIcon} />}
                          className="pf-secondary-btn"
                          onClick={() => {
                            onSaveClicked();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
