import React, { useEffect, useRef, useState } from "react";
import MomentumAISidebar from "./MomentumAISidebar";
import { useNavigate } from "react-router-dom";
import AnswerIcon from "../assets/images/Momentum AI Icon-bk.png";
import { Skeleton } from "primereact/skeleton";
import SupportLoader from "../common/SupportLoader";
import CommonValues from "../common/utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import MomentumGPTAnswer from "../RTG/MomentumGPT/MomentumGPTAnswer";
import axios from "axios";
import { marked } from "marked";
import { Toast } from "primereact/toast";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AIMenuFooter from "./AIMenuFooter";

interface ChatResponse {
  conversationId: string;
  companyId: number;
  response: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  textToAction: string;
  responseStreaming: boolean;
  ai_tool: string;
  insured_id: string;
  policy_id: string;
}

class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}

export default function AICallLandingPage() {
  const toast: any = useRef("");
  const [showQuestionAnswer, setShowQuestionAnswer] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [showConversationHistory, setShowConversationHistory] = useState(false);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const [chatBotEmbeded, setChatBotEmbeded] = useState(true);
  const [transcribingAudio, setTranscribingAudio] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [latestQuestion, setLatestQuestion] = useState({});
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const suggestedQuestions = ["Please call my client for policy renewal", "Please call my insured for policy renewal"];
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");
    getConversationHistory(window.location.href.split("/")[4]);
    setAvatarName(localStorage.getItem("AvatarName"));
    setQuestion("");
  }, [window.location.href.split("/")[4]]);

  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);

  const getConversationHistory = (conversationId: string | null) => {
    if (conversationId === undefined || conversationId === null || conversationId === "") {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      // url: `https://localhost:55723/api/ncmomentumgpt/nc-momentum-gpt-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data.ncMomentumGPTHistoryConversation;
        const fileUrl = response.data.pdfUrl;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            companyId: conversation.companyId,
            response: conversation.response,
            sources: [],
            imageURLs: [fileUrl],
            videoURLs: [],
            imageSidebar: false,
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike: conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            textToAction: "",
            responseStreaming: false,
            ai_tool: conversation.aI_Tool,
            insured_id: conversation.insuredId,
            policy_id: conversation.policyId,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-question-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };
  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(".last-answer-block-nc-chat-bot");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop + 5000 : 0;
      }
    }
  };

  const onSendQuestionClick = async (que: any) => {
    try {
      if (que === "") {
        return;
      }
      const newQuestion: ChatResponse = {
        conversationId: window.location.href.split("/")[4],
        companyId: 0,
        question: que,
        response: "",
        sources: [],
        imageURLs: [],
        videoURLs: [],
        imageSidebar: false,
        chatId: "",
        like: false,
        disLike: false,
        feedback: "",
        showFeedback: false,
        feedbackLoading: false,
        plotlyData: { data: "", layout: "" },
        textToAction: "",
        responseStreaming: false,
        ai_tool: "",
        insured_id: "",
        policy_id: "",
      };

      var FormData = require("form-data");
      var formData = new FormData();
      formData.append("human_prompt", que);
      formData.append("nc_token", localStorage.getItem("NCToken"));
      formData.append("conversationId", window.location.href.split("/")[4]);

      let latestQue = {
        conversationId: window.location.href.split("/")[4],
        question: que,
        type: "ai-call-assistant",
      };
      setLatestQuestion(latestQue);

      let updatedChatResponses = [...chatResponses, { ...newQuestion }];
      setChatResponses(updatedChatResponses);
      setQuestion("");
      // scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);

      setQuestionLoading(true);
      const token = CommonValues.GetToken();

      try {
        const ctrl = new AbortController();
        await fetchEventSource(
          // `http://127.0.0.1:8000/chat2`,
          `${process.env.REACT_APP_MomentumGPT}/chat2`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
            signal: ctrl.signal,
            openWhenHidden: true,
            onopen(response: Response): Promise<void> {
              if (response.status === 401) {
                CommonValues.Logout(navigate);
              } else if (response.status !== 200) {
                setQuestionLoading(false);
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Unknown error while sending question",
                  life: 3000,
                });
              }
              return Promise.resolve();
            },
            onmessage(event: any) {
              let responseObject = JSON.parse(event.data);
              if (responseObject.response !== "") {
                setQuestionLoading(false);
              }

              updatedChatResponses = updatedChatResponses.map((chatResponse, index) => {
                if (index === updatedChatResponses.length - 1) {
                  return {
                    ...chatResponse,
                    response: (chatResponse.response || "") + responseObject.response,
                    chatId: responseObject.chatId,
                    imageURLs: responseObject.file_url ? [responseObject.file_url] : [],
                    ai_tool: responseObject.ai_tool,
                    insured_id: responseObject.insuredClientId,
                    policy_id: responseObject.policyId,
                    responseStreaming: responseObject.responseStreaming,
                  };
                }
                return chatResponse;
              });

              if (responseObject.error_message !== "") {
                let lastIndex = updatedChatResponses.length - 1;
                updatedChatResponses[lastIndex].response = responseObject.error_message;
                ctrl.abort();
              }

              setChatResponses(updatedChatResponses);

              // scrollToBottomForAnswer(true);
            },
            onclose() {
              setQuestionLoading(false);
            },
            onerror(error: any) {
              setQuestionLoading(false);
            },
          }
        );
      } catch (error: any) {}

      // const config = {
      //   method: "post",
      //   url: `http://127.0.0.1:8000/chat2`,
      //   // url: `${process.env.REACT_APP_MomentumGPT}/chat2`,
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      //   data: formData,
      // };

      // axios(config)
      //   .then((response: any) => {
      //     setQuestionLoading(false);
      //     updatedChatResponses = updatedChatResponses.map((chatResponse, index) => {
      //       if (index === updatedChatResponses.length - 1) {
      //         return {
      //           ...chatResponse,
      //           response: (chatResponse?.response || "") + response.data.response,
      //           chatId: response.data.chatId,
      //           sources: [],
      //           imageURLs: response.data.file_url === "" ? [] : [response.data.file_url],
      //           videoURLs: [],
      //           textToAction: "",
      //           responseStreaming: false,
      //           ai_tool: response.data.ai_tool,
      //           insured_id: response.data.insuredClientId,
      //         };
      //       }
      //       return chatResponse;
      //     });

      //     setChatResponses(updatedChatResponses);
      //     // scrollToBottomForAnswer(true);
      //   })
      //   .catch((error: any) => {
      //     setQuestionLoading(false);
      //     if (error.response.status == 401) {
      //       CommonValues.Logout(navigate);
      //     } else if (error.response.status == 500) {
      //       toast.current.show({
      //         severity: "error",
      //         detail: "Unknown error occured while getting response. Please try again.",
      //         life: 3000,
      //       });
      //     }
      //   });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };
  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSendQuestionClick(question);
    }
  };

  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedbackLoading: true } : response))
    );
    updateFeedback(chatId, feedBack);
  };

  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/nc-ai-scan-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                    ...response,
                    feedback: "",
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedbackLoading: false } : response))
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };

  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, feedback: newFeedback } : response))
    );
  };
  const onCopyResponseClick = async (copiedData: string) => {
    const htmlContent = await marked(copiedData);
    const plainTextContent = htmlToPlainTextWithFormatting(htmlContent);

    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const textBlob = new Blob([plainTextContent], { type: "text/plain" });

    const clipboardItem = new ClipboardItem({
      "text/plain": textBlob,
      "text/html": htmlBlob,
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Copied Successfully!",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unknown error occurred while copying data",
          life: 3000,
        });
      });
  };
  const htmlToPlainTextWithFormatting = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const elementsToReplace = tempDiv.querySelectorAll("br, p, h1, h2, h3, h4, h5, h6");
    elementsToReplace.forEach((element: any) => {
      const lineBreak = document.createTextNode("\n");
      element.parentNode.insertBefore(lineBreak, element);
    });

    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) => (response.chatId === chatId ? { ...response, showFeedback: !response.showFeedback } : response))
    );
  };

  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };

  const onThumbsDownClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };
  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCMomentumGPT/nc-ai-scan-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };
  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };
  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      setTranscribingAudio(true);
      const data = new FormData();
      var audioFile = new File([audioBlob], "recording.wav", {
        type: audioBlob.type,
      });
      data.append("audioFile", audioFile);

      const token = CommonValues.GetToken();

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_PolicyAgent_Url}/process_audio`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setTranscribingAudio(false);
          setQuestion(response.data.result);
        })
        .catch((error: any) => {
          setTranscribingAudio(false);
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 500) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error occured while processing audio. Please try again.",
              life: 3000,
            });
          }
        });
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks: any[] = [];

      setRecordingAudio(true);
      setAudioRecorder(recorder);

      recorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        transcribeAudio(audioBlob);
      };

      recorder.start();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecordingAudio = () => {
    if (audioRecorder) {
      audioRecorder.stop();
    }

    setRecordingAudio(false);
  };
  return (
    <div className="ai-support-main-page">
      <Toast ref={toast} />

      <div className="ai-wrapper min-vh-100 ">
        <div className="ai-wrapper-container  position-relative z-1">
          <div className="row g-4 mx-auto">
            <div className="col-2 px-0 border-end" style={{ paddingTop: "2%" }}>
              <MomentumAISidebar pagename="ai-call-assistant" latestQuestion={latestQuestion} />
            </div>
            <div className="col-10 px-0" style={{ paddingTop: "2%" }}>
              <div className="container p-3 d-flex flex-column h-100">
                <div className="ai-main-content-wrapper" ref={chatContainerRef}>
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const isLastAnswer = index === chatResponses.length - 1;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${isLastAnswer && !questionLoading ? "last-answer-block-nc-chat-bot" : "question-answer-block"}`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {chatBotEmbeded && <span className="me-1 avtar border-rounded">{avatarName}</span>}
                            <div className="mb-1 text-14 ai-question-block">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {chatBotEmbeded && (
                              <span className="me-1">
                                <img alt="NowCerts Logo" src={AnswerIcon} className="me-2" height={30} />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${
                                  isLastAnswer && questionLoading ? "last-question-block-nc-chat-bot" : "question-answer-block"
                                }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="70%" className="mb-2 d-block"></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton width="40%" className="mb-2 d-block"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton width="20%" className="mb-2 d-block"></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <MomentumGPTAnswer
                                chatResponse={response}
                                index={index}
                                chatBotEmbeded={chatBotEmbeded}
                                // sendQuestion={onSendQuestionClick}
                                onFeedbackSubmit={onFeedbackSubmit}
                                onFeedbackChange={onFeedbackChange}
                                toggleFeedbackVisibility={toggleFeedbackVisibility}
                                onCopyResponseClick={onCopyResponseClick}
                                onThumbsUpClick={onThumbsUpClick}
                                onThumbsDownClick={onThumbsDownClick}
                                showImageSidebar={showImageSidebar}
                                type="AICall"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="row chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <>
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 text-16 text-center mb-4" style={{ color: "#381576" }}>
                              <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                                <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                                  <span className="ai-content-heading">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="lucide lucide-search"
                                    >
                                      <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z"></path>
                                    </svg>
                                  </span>{" "}
                                  <span style={{ color: "#381574" }}>
                                    <h4 className="mb-0">Calls by AI Agent</h4>
                                    <p className=" small mb-0">
                                      Unlock the Full AI Call Experience with Sonant! Subscribe to access <br /> call recaps, follow-ups, and seamless
                                      AI-driven communication.
                                      <br /> Try it below in a test scenario to see how our AI Call Assistant performs!
                                    </p>{" "}
                                  </span>
                                </div>
                              </div>{" "}
                            </div>
                            <div className="d-flex flex-nowrap justify-content-center gap-3 overflow-auto w-100 text-center">
                              {suggestedQuestions.map((suggestedQuestion: string, index: number) => {
                                let isLast = index === suggestedQuestions.length - 1;
                                return (
                                  <>
                                    <span
                                      className="ai-border-radius p-3 text-muted small bg-momentum-light d-inline  shadow-sm mb-2 me-2"
                                      onClick={() => {
                                        setQuestion(suggestedQuestion);
                                        textAreaRef.current?.focus();
                                      }}
                                    >
                                      {suggestedQuestion}
                                    </span>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column text-center ">
                            <div className="mb-2 rounded p-2 d-flex align-items-center text-start">
                              <span className="ai-content-heading">
                                <i className="fa fa-phone" />
                              </span>{" "}
                              <span style={{ color: "#381574" }}>
                                <h4 className="mb-0">Calls by AI Agent</h4>
                                <p className=" small mb-0">
                                  Unlock the Full AI Call Experience with Sonant! Subscribe to access <br /> call recaps, follow-ups, and seamless
                                  AI-driven communication.
                                  <br /> Try it below in a test scenario to see how our AI Call Assistant performs!
                                </p>{" "}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
                {/* ) : ( */}

                {/* )} */}

                <div className="p-3 mt-auto">
                  <div className="position-relative">
                    <div
                      className={`d-flex align-items-center  ai-border-radius bg-white shadow-sm transition-all overflow-hidden ${
                        isFocused ? "ai-textarea " : "border"
                      }`}
                    >
                      <textarea
                        ref={textAreaRef}
                        placeholder="Message Momentum AI..."
                        name="question"
                        value={question}
                        className="form-control border-0 py-3 px-4 w-100 bg-transparent text-dark resize-none "
                        onChange={onQuestionChange}
                        rows={1}
                        onKeyDown={onEnterClick}
                        style={{ minHeight: "56px", maxHeight: "200px" }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      ></textarea>

                      <div className="d-flex align-items-center gap-2 px-3">
                        {transcribingAudio ? (
                          <div className="d-inline-flex align-items-center me-2">
                            <AtkLoader />
                          </div>
                        ) : recordingAudio ? (
                          <span onClick={stopRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        ) : (
                          <span onClick={startRecordingAudio} className="me-2 show-cursor" title="Microphone">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-mic"
                              style={{ opacity: 0.5 }}
                            >
                              <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" x2="12" y1="19" y2="22"></line>
                            </svg>
                          </span>
                        )}
                        <span onClick={() => onSendQuestionClick(question)} className="show-cursor" title="Send">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke={question.length > 0 ? "#6b44bd" : "currentColor"}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-send"
                            style={{ opacity: question.length > 0 ? 1 : 0.5 }}
                          >
                            <path d="M14.536 21.686a.5.5 0 0 0 .937-.024l6.5-19a.496.496 0 0 0-.635-.635l-19 6.5a.5.5 0 0 0-.024.937l7.93 3.18a2 2 0 0 1 1.112 1.11z"></path>
                            <path d="m21.854 2.147-10.94 10.939"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  <AIMenuFooter pagename="ai-call-assistant" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
