import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import BillingMiddleHeader from "./BillingMiddleHeader";
import BillingSidebar from "./BillingSidebar";
import BackgroundVideo from "../common/BackgroundVideo";

export default function PaymentHistory({ refreshPaymentHistoryFlag }: any) {
  const [stripePaymentHistoryData, setStripePaymentHistoryData] = useState([]);
  const [subscriptionHistoryData, setSubscriptionHistoryData] = useState([]);
  const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(false);
  const [paymentHistoryTab, setPaymentHistoryTab] = useState(true);
  const [subscriptionHistoryTab, setSubscriptionHistoryTab] = useState(false);
  const toast: any = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    getPaymentHistory();
    getSubscriptionHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getPaymentHistory = () => {
    setPaymentHistoryLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setStripePaymentHistoryData(response.data.stripePaymentHistoryData);
        setPaymentHistoryLoading(false);
      })
      .catch((error) => {
        setPaymentHistoryLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting payment history",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting payment history",
            life: 3000,
          });
        }
      });
  };
  const getSubscriptionHistory = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/getallsubscriptionhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSubscriptionHistoryData(response.data.subscriptions);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleViewReceipt = (paymentIntentId: string) => {
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/getpaymentreceipt/${paymentIntentId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          window.open(response.data.receiptURL, "_blank");
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting payment receipt",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting payment receipt",
            life: 3000,
          });
        }
      });
  };

  const viewReceiptBodyTemplate = (rowData: any) => {
    return rowData.paymentStatus === "succeeded" ?
      <div className="text-center">
        <AtkButton
          className="pf-primary-btn w-auto"
          type="button"
          label="Receipt"
          onClick={() => {
            handleViewReceipt(rowData.paymentIntentId);
          }}
        />
      </div> 
      : 
      null
  };
  const onPaymentHistoryTabClicked = () => {
    setPaymentHistoryTab(true);
    setSubscriptionHistoryTab(false);
  };
  const onSubscriptionHistoryTabClicked = () => {
    setPaymentHistoryTab(false);
    setSubscriptionHistoryTab(true);
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-history-main-page">
        <Header pagename="billing-payment-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <BillingMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <BillingSidebar pagename="billing-payment-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <ul className="nav nav-tabs mt-2">
                  <li className="nav-item mt-1">
                    <span
                      className={
                        paymentHistoryTab
                          ? "nav-link show-cursor active "
                          : "nav-link show-cursor"
                      }
                      onClick={onPaymentHistoryTabClicked}
                    >
                      Payment History
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={
                        subscriptionHistoryTab
                          ? "nav-link show-cursor active"
                          : "nav-link show-cursor"
                      }
                      onClick={onSubscriptionHistoryTabClicked}
                    >
                      Subscription History
                    </span>
                  </li>
                </ul>
                {paymentHistoryTab ? (
                  <>
                    {paymentHistoryLoading ? (
                      <>
                        <div className="text-center mt-4">
                          <AtkLoader />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row mt-3">
                          {stripePaymentHistoryData.length > 0 ? (
                            <>
                              <div className="col-12 history-table mt-2">
                                <DataTable
                                  value={stripePaymentHistoryData}
                                  stripedRows
                                  paginator
                                  rows={15}
                                  dataKey="created"
                                  showGridlines
                                  scrollable
                                >
                                  <Column
                                    field="created"
                                    header="Transaction Date"
                                    body={(rowData) => {
                                      const utcdate = moment.utc(
                                        rowData.created
                                      );
                                      var localDate = moment(utcdate).local();
                                      return (
                                        <>
                                          <span>
                                            {localDate.format("DD/MM/YYYY")}
                                          </span>{" "}
                                          &nbsp; &nbsp;
                                          <span className="m-0 payment-history-time">
                                            {localDate
                                              .format("hh:mm a")
                                              .replace("am", "AM")
                                              .replace("pm", "PM")}
                                          </span>
                                        </>
                                      );
                                    }}
                                  ></Column>
                                  <Column
                                    field="agentName"
                                    header="Agent Name"
                                  ></Column>
                                  <Column
                                    field="amountInCent"
                                    header="Credit Amount"
                                    body={(rowData) => <span>$ {(rowData.amountInCent / 100).toFixed(2)}</span>}

                                  ></Column>
                                  <Column
                                    header="View Receipt"
                                    body={viewReceiptBodyTemplate}
                                  ></Column>
                                  <Column
                                    field="paymentStatus"
                                    header="Credit Type"
                                    body={(rowData) => (
                                      <span>
                                        {rowData.paymentStatus ===
                                          "succeeded"
                                          ? "Paid"
                                          : rowData.paymentStatus}
                                      </span>
                                    )}
                                  ></Column>
                                </DataTable>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="text-center">
                                <AtkMessage
                                  appearance="info"
                                  messageText="No payment history available"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                {subscriptionHistoryTab ? (
                  <div className="row">
                    {subscriptionHistoryData.length > 0 ? (
                      <div className="col-12 history-table mt-3">
                        <DataTable
                          value={subscriptionHistoryData}
                          stripedRows
                          paginator
                          rows={15}
                          dataKey="created"
                          showGridlines
                          scrollable
                        >
                          <Column
                            field="subscriptionName"
                            header="Name"
                          ></Column>
                          <Column
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.startDate);
                              var localDate = moment(utcdate).local();
                              return (
                                <span>{localDate.format("DD/MM/YYYY")}</span>
                              );
                            }}
                            field="startDate"
                            header="Start Date"
                          ></Column>
                          <Column
                            field="frequency"
                            header="Frequency"
                            body={(rowData) => {
                              return (
                                <span>
                                  {rowData.frequency === "month"
                                    ? "Monthly"
                                    : rowData.frequency}
                                </span>
                              );
                            }}
                          ></Column>
                          <Column
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.nextInvoice);
                              var localDate = moment(utcdate).local();
                              return (
                                <span>{localDate.format("DD/MM/YYYY")}</span>
                              );
                            }}
                            field="nextInvoice"
                            header="Next Invoice"
                          ></Column>
                        </DataTable>
                      </div>
                    ) : (
                      <div className="text-center">
                        <AtkMessage
                          appearance="info"
                          messageText="No active subscription available"
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </>
  );
}
