import React from "react";
import FinalQuotes from "./FinalQuotes";
import ResponseButton from "./ResponseButton";
import CommonValues from "../../common/utils";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Skeleton } from "primereact/skeleton";
import { Navigate, useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";

export default function CapitalResponse(props: any) {
  const navigate = useNavigate();
  const [capitalResponse, setCapitalResponse] = useState<any>({});
  const [capitalLoading, setCapitalLoading] = useState(false);
  const [capitalShowResponse, setCapitalShowResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [saveQuoteErrorMessage, setSaveQuoteErrorMessage] = useState("");
  const [saveQuoteSuccessMessage, setSaveQuoteSuccessMessage] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [rowData, setRowData] = useState({});
  useEffect(() => {
    setTimeout(() => {
      capitalSubmitClick();
    }, 5);
  }, []);

  const capitalSubmitClick = () => {
    let subPolicies: any = [];
    let term = "";
    Array.from(props.coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: props.policyNumber[index],
        coverageCode: code.capitalCode == null ? "*" : code.capitalCode,
        policyId: props.nCPolicyId,
        effectiveDate: moment(props.effectiveDate[index]).format("YYYY-MM-DD"),
        expirationDate: "",
        term: props.term[index],
        BrokerFee: props.brokerFee[index] == null || props.brokerFee[index] === "" ? "0" : props.brokerFee[index],
        premium: props.currentPremiumsArray[index] ? props.currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees: props.fees[index] != undefined ? props.fees[index].toString() : "0",
        otherTaxes: props.taxes[index] != undefined ? props.taxes[index].toString() : "0",
        minimumEarnedPercent: props.minimumEarned[index] != undefined ? props.minimumEarned[index] : "0",
        policyType: "commercial",
        index: props.selectedPolicyIndex ? props.selectedPolicyIndex : 0,
        carrier: {
          id: props.carrier[index] ? props.carrier[index].capitalCode : "",
          name: props.carrier[index] ? props.carrier[index].description : "",
          address: {
            address1: props.carrier[index] ? props.carrier[index].address1 : "",
            address2: "",
            city: props.carrier[index] ? props.carrier[index].city : "",
            state: props.carrier[index] ? props.carrier[index].state : "",
            zip: props.carrier[index] ? props.carrier[index].zipCode : "",
            country: props.carrierCountry,
            unit: "",
          },
        },

        generalAgency: {
          name: props.gaName[index],
          searchCode: props.gaSearchCode[index],
          address: {
            address1: props.gaAddress[index],
            address2: "",
            city: props.gaCity[index],
            state: props.gaState[index],
            zip: props.gaZip[index],
            country: props.gaCountry[index],
            unit: "",
          },
        },
      });
    });
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      target: props.target,
      data: {
        ClientName: props.firstName + " " + props.lastName,
        ClientId: props.clientId.toString(),
        IsIndicative: localStorage.getItem("IsIndicative"),
        insured: {
          insuredId: props.nCInsuredId,
          name: props.businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber: props.businessPhoneNumber != null ? props.businessPhoneNumber : "",
          email: props.businessEmail,
          address: {
            address1: props.addLine1,
            address2: "",
            city: props.city,
            state: props.sstate,
            zip: props.zipCode,
            country: props.country,
            unit: "",
          },
        },
        agent: {
          id: "",
          searchCode: localStorage.getItem("NCAgencyId"),
          firstName: props.agentName,
          lastName: "",
          email: "",
          phoneNumber: props.agentPhoneNumber,
          address: {
            address1: props.agentAddress,
            address2: "",
            city: props.agentCity,
            state: props.agentState,
            zip: props.agentZip,
            country: props.agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setCapitalLoading(true);
    axios(config)
      .then((response: any) => {
        setCapitalResponse(response.data);
        window.scroll(0, 0);
        let responseData = {
          transactionId: response.data.transactionId,
          url: "https://www.capitalpremium.net/contact/",
          provider: "CapitalPremiumFinance",
          targetPartner: "Capital Premium Financing",
          isSaved: false,
          quoteNumber: response.data.quoteNumber,
          ncPolicyNumber: props.ncPolicyNumber,
          policyId: props.nCPolicyId,
          insuredId: props.nCInsuredId,
          policyNumber: props.policyNumber,
          clientId: props.clientId,
          selectedPolicyIndex: props.selectedPolicyIndex,
          responseMessage: response.data.message,
          premiumresponse: response.data,
          tenantId: localStorage.getItem("tenantId"),
        };
        setRowData(responseData);

        if (response.data.quoteNumber == null || response.data.quoteNumber == "" || response.data.quoteNumber == "0" || response.status == 204) {
          setCapitalShowResponse(true);
          setResponseMessage(response.data.message);
        } else {
          setCapitalShowResponse(false);
        }
        setCapitalLoading(false);
        setSuccessMessage("Details submitted successfully..");
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while submitting the details for capital PF";
          }
        } else {
          errorMessage = "Unknown error while submitting  details for capital PF";
        }
        setCapitalShowResponse(true);
        setCapitalLoading(false);
        setErrorMessage(errorMessage);
        setResponseMessage(errorMessage);
      });
  };

  return (
    <div className=" quote-response-block">
      <div className="row d-flex align-items-center mx-auto quote-heading">
      <div className="col-12">
        <h6 className="mb-0">
            <img src={props.capitalLogoUrl} width="25" height="25" /> Capital Premium Financing Quote
          </h6>
        </div>
      </div>

      <div className="quote-middle-block">
        {capitalLoading ? (
          <Skeleton width="100%" height="15rem"></Skeleton>
        ) : (
          <>
            {capitalShowResponse ? (
              <div className="my-2 p-2">
                <AtkMessage appearance="warning" messageText={responseMessage} />{" "}
              </div>
            ) : (
              <>
                <FinalQuotes premiumresponse={capitalResponse} />
                <div className="response-btn-div   ">
                  <ResponseButton rowData={rowData} agencyname={props.agentName}/>
                </div>
              </>
            )}
          </>
        )}

        {saveQuoteErrorMessage.length > 0 ? (
          <div className="my-2 p-2">
            <AtkMessage appearance="error" messageText={saveQuoteErrorMessage} />
          </div>
        ) : null}
        {saveQuoteSuccessMessage.length > 0 ? (
          <div className="my-2 p-2">
            <AtkMessage appearance="success" messageText={saveQuoteSuccessMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
