
import Toggle from "@atlaskit/toggle";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkButton from "../../AtlaskitControls/AtkButton";

export default function MTMScanOptionSelectPage (props:any) {
    return(
        <div className="row scan-search-row mx-auto d-flex  align-items-center">
        <div className="col-10">
          <div className="row">
            <div className="col-3 ">
              <div className="row text-14 mb-2 ">
                <p className="text-16 fw-bold">Client</p>
                <div className="col-12">
                  <div className="p-field-checkbox d-flex align-items-center">
                    <label htmlFor="createNew">Create New</label>
                    <Toggle id="createNew" isChecked={props.isCreateNewClientSelected} onChange={(e) => props.handleOptionSelectionChange(e, "createNew")}/>
                  </div>
                  <div className="p-field-checkbox  d-flex align-items-center">
                    <label htmlFor="useExisting">Use Existing</label>
                    <Toggle id="useExisting" isChecked={props.isExistingClientSelected} onChange={(e) => props.handleOptionSelectionChange(e, "useExisting")}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="row">
                <p className="text-16 fw-bold mb-1">Policy</p>

                <div className="col-12">
                  <div className="p-field-checkbox d-flex align-items-center">
                    <label htmlFor="createNew">Create New</label>
                    <Toggle id="createNew" isChecked={props.isCreateNewPolicySelected}/>
                  </div>
                  
                </div>
              </div>
            </div>

            {props.selectedOptionName == "useExisting" ? (
                  <>
                    <div className="col-3 position-relative mb-2 p-0">
                      <p className="text-16 fw-bold mb-1 ">Client Name  <span className="text-danger "> *</span> </p>
                     
                      <div className={`search-bar flex-column ${props.clientNameError.length > 0 ? " validation-div " : " "}`}>
                        <AtkTextField
                          type="text"
                          id="client-name"
                          className={` ${props.clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                          name="search"
                          onChange={(e) => {
                            props.onClientSearchChange(e.target.value);
                          }}
                          value={props.search}
                          placeholder={props.clientName.length > 0 ? props.clientName : "Enter client name"}
                          isDisabled={props.selectedOptionName !== "useExisting"}
                        />
                      </div>
                      {props.search.replaceAll(" ", "").length > 0 && (
                        <div id="client-search" className=" position-absolute  ">
                          {props.searchClientLoading ? (
                            <div className="d-flex justify-content-center my-2 client-loader-div">
                              <AtkLoader />
                            </div>
                          ) : props.searchClientList.length == 0 ? (
                            <div style={{ padding: "5px", background: "white" }}>
                              <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                            </div>
                          ) : (
                            props.searchClientList.map((item: any) => (
                              <div
                                style={{ cursor: "pointer", width: "100%" }}
                                onClick={() => {
                                  props.onClientSelect(item);
                                }}
                              >
                                <a
                                  href="#"
                                  style={{
                                    color: "black",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.name}
                                </a>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                      <span className="text-danger text-12">{props.clientNameError}</span>
                    </div>
                  </>
                ) : null}
            </div>
            </div>
            <div className="col-2 text-end">
              <AtkButton label="Next" className="pf-secondary-btn w-100 " onClick={props.onClickNextButton} />
            </div>
            </div>
    )
}