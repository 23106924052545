import React from "react";
import { useEffect, useState, useRef } from "react";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import InfoIcon from "@atlaskit/icon/glyph/info";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Navigate, useNavigate } from "react-router-dom";

export default function PasswordDetails() {
  const [changedUserName, setChangedUserName] = useState("");
  const [changedNewPassword, setChangedNewPassword] = useState("");
  const [newPasswordInputType, setNewPasswordInputType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
  const [errorMessageNewPassword, setErrorMessageNewPassword] = useState("");
  const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [successMessagePassword, setSuccessMessagePassword] = useState("");
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const toast: any = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    let userName = localStorage.getItem("userName");
    if (userName != null) {
      setChangedUserName(userName);
    }
  }, []);
  const onUserNameChange = (e: any) => {
    setChangedUserName(e.target.value);
  };
  const onNewPasswordChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 16);
    setChangedNewPassword(limitedValue);
  };
  const onConfirmPasswordChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 16);
    setConfirmPassword(limitedValue);
  };
  const onShowNewPassword = () => {
    if (newPasswordInputType === "password") {
      setNewPasswordInputType("text");
      return;
    }
    setNewPasswordInputType("password");
  };
  const onShowConfirmPassword = () => {
    if (confirmPasswordInputType === "password") {
      setConfirmPasswordInputType("text");
      return;
    }
    setConfirmPasswordInputType("password");
  };
  const checkPasswordValidation = () => {
    let formIsValid = true;
    setErrorMessageNewPassword("");
    setErrorMessageConfirmPassword("");
    setErrorMessagePassword("");
    setSuccessMessagePassword("");

    if (changedNewPassword == "") {
      formIsValid = false;
      setErrorMessageNewPassword("Please enter new password");

      return formIsValid;
    }
    const regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    if (regix.test(changedNewPassword) == false) {
      formIsValid = false;
      setErrorMessageNewPassword("Please enter a valid password");

      return formIsValid;
    }
    if (confirmPassword == "") {
      formIsValid = false;
      setErrorMessageNewPassword("");
      setErrorMessageConfirmPassword("Please enter confirm password");

      return formIsValid;
    }
    if (changedNewPassword != confirmPassword) {
      formIsValid = false;
      setErrorMessageConfirmPassword("Password did not match");

      return formIsValid;
    }

    return formIsValid;
  };
  const onChangePasswordClick = () => {
    if (checkPasswordValidation()) {
      setUpdatePasswordLoading(true);
      var token = CommonValues.GetToken();
      var data = new FormData();
      data.append("Password", changedNewPassword);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/updateuserpassword`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          toast.current.show({ severity: "success", summary: 'Success', detail: "Password changed successfully", life: 3000 });
          setUpdatePasswordLoading(false);
          setChangedNewPassword("");
          setConfirmPassword("");
        })
        .catch((error) => {
          setUpdatePasswordLoading(false);

          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the password", life: 3000 });
            }
          }
        });
    }
  };
  const onEnterPasswordClicked = (e: any) => {
    if (e.keyCode == 13) {
      onChangePasswordClick();
    }
  };
  return (
    <>
      <Toast ref={toast} />

      <div className="row form-group text-start mx-auto ">
        <div className="col-12 profile-title">
          <span>Change Password</span>
        </div>
      </div>
      <div className="row form-group text-start  mb-2  d-flex align-items-center">
        <label className="col-sm-4 col-form-label">User Name</label>
        <div className="col-sm-8">
          <AtkTextField
            className="input-field"
            type="text"
            id="changedUserName"
            name="changedUserName"
            onChange={onUserNameChange}
            value={changedUserName}
            placeholder="Enter user name"
            isDisabled={true}
          />
        </div>
      </div>
      <div
        className={
          errorMessageNewPassword.length > 0
            ? "row form-group text-start   validation-div mb-2  d-flex align-items-center"
            : "row form-group text-start  mb-2  d-flex align-items-center"
        }
      >
        <label className="col-sm-4 col-form-label ">New Password</label>
        <div className="col-sm-8 position-relative">
          <AtkTextField
            className="input-field"
            type={newPasswordInputType}
            id="changedNewPassword"
            name="changedNewPassword"
            onChange={onNewPasswordChange}
            value={changedNewPassword}
            placeholder="Enter new password"
            autoComplete="new-password"
            onKeyDown={onEnterPasswordClicked}
          />
          <span className="change-password-new-eye-icon" onClick={onShowNewPassword}>
            {newPasswordInputType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
          </span>
        </div>
        <div className="col-sm-4"></div>
        <div className="col-sm-8">
          <span className="text-danger small">{errorMessageNewPassword}</span>
        </div>
        <div className="col-sm-4"></div>
        <div className="col-sm-8 profile-info-icon">
          <div className="row">
            <div className="col-lg-1 col-md-2  col-sm-3 col-2">
              <InfoIcon label="" />
            </div>
            <div className="col-lg-11 col-md-10  col-sm-9 col-10 p-0">
              <span className="d-block">Minimum Length 8 Characters</span>
              <span className="d-block">1 Special Character | 1 Lower Case Letter</span>
              <span className="d-block">1 Upper Case Letter | 1 Number</span>
              <span className="d-block">e.g: Abc#15@6</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          errorMessageConfirmPassword.length > 0
            ? "row form-group text-start  validation-div mb-2  d-flex align-items-center"
            : "row form-group text-start   mb-2  d-flex align-items-center"
        }
      >
        <label className="col-sm-4 col-form-label">Confirm Password</label>
        <div className="col-sm-8  position-relative">
          <AtkTextField
            className="input-field"
            type={confirmPasswordInputType}
            id="confirmPassword"
            name="confirmPassword"
            onChange={onConfirmPasswordChange}
            value={confirmPassword}
            placeholder="Enter confirm password"
            onKeyDown={onEnterPasswordClicked}
          />
          <span className="change-password-confirm-eye-icon" onClick={onShowConfirmPassword}>
            {confirmPasswordInputType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
          </span>
        </div>
        <div className="col-sm-4"></div>
        <div className="col-sm-8">
          <span className="text-danger small">{errorMessageConfirmPassword}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 text-end">
          {updatePasswordLoading ? (
            <AtkLoader />
          ) : (
            <AtkButton label="Change Password" onClick={onChangePasswordClick} className="pf-primary-btn w-auto me-1   my-1 " />
          )}
        </div>
      </div>
    </>
  );
}