import React, { useEffect, useRef, useState } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import { Navigate, useNavigate } from "react-router-dom";
import AtkMessage from "../AtlaskitControls/AtkMessage";

export default function PeoplesPfSettings() {
  const navigate = useNavigate();
  const [peoplesLoading, setPeoplesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const toast: any = useRef("");
  const naviagte = useNavigate();
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);

  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsername(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setPassword(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const CheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");

    if (username == "" || username == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }
    if (password == "" || password == null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    return formIsValid;
  };
  const onDeleteButtonClick = () => {
    setDeleteLoading(true);
    let token = CommonValues.GetToken();

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({ severity: "success", summary: 'Success', detail: "Peoples Premium Finance Settings deleted successfully", life: 3000 });
        setDeleteLoading(false);
        getPeopleCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            naviagte("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Peoples Premium Finance Settings" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the Peoples Premium Finance Settings" });
        }
        setDeleteLoading(false);
      });
  };
  const onPeoplesUpdateClick = () => {
    if (CheckValidation()) {
      setPeoplesLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: username,
        Password: password,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setPeoplesLoading(false);
          getPeopleCreds();
          toast.current.show({ severity: "success", summary: 'Success', detail: "Peoples Premium Finance Settings updated successfully", life: 3000 });
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              naviagte("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error", summary: 'Error',
                detail: "It look likes you are not registered with Peoples Premium Finance, Please register first.",
              });
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Peoples Premium Finance Settings" });
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the Peoples Premium Finance Settings" });
          }
          setPeoplesLoading(false);
        });
    }
  };
  const getPeopleCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setPassword("");
          setUsername("");
          setShowIGCredsMsg(true);
        } else {
          setLoading(false);
          setShowIGCredsMsg(false);
          setUsername(response.data.username);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Peoples premium finance setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the Peoples premium finance setting details" });
        }
        setLoading(false);
      });
  };
  const onRegisterClick = () => {
    window.open("https://www.peoplesbancorp.com/media/user/premium-finance/2023_1_PPF_Agency%20Profile.pdf", "_blank");
  };
  const onLoginClick = () => {
    getPeoplepfDetailsForLogin();
  };
  const getPeoplepfDetailsForLogin = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplesportal`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the gotoPremiumFinance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the gotoPremiumFinance Setting details";
        }
        setLoginLoading(false);
      });
  };
  return (
    <div className="row  mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          <div className="form-group col-4  mb-2 ">
            <label className="form-label">
              Username
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessageUsername.length > 0 ? "col-8  mb-2 validation-div" : "col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="username"
              name="username"
              placeholder="Enter user name"
              onChange={onUsernameChange}
              value={username}
            />
            <span className="text-danger text-12">{errorMessageUsername}</span>
          </div>

          <div className="form-group col-4  ">
            <label className="form-label">
              Password
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessagePassword.length > 0 ? "col-8   validation-div position-relative" : " col-8  mb-2 position-relative"}>
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="password"
              name="peoplesSetupPassword"
              onChange={onPasswordChange}
              value={password}
              placeholder="Enter password"
              autoComplete="new-password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div>
          {errorMessagePassword.length > 0 ? (
            <>
              <div className="col-4  mb-2"></div>
              <div className=" col-8  mb-2">
                <span className="text-danger text-12">{errorMessagePassword}</span>
              </div>
            </>
          ) : null}
        </>
      )}
      <div className="col-12  text-end mb-2 mt-2">
        {deleteLoading ? (
          <AtkLoader></AtkLoader>
        ) : (
          <AtkButton label="Reset" onClick={onDeleteButtonClick} className="pf-secondary-btn w-auto me-2 mb-2" />
        )}

        {peoplesLoading ? (
          <AtkLoader></AtkLoader>
        ) : (
          <AtkButton label="Save" onClick={onPeoplesUpdateClick} className="pf-secondary-btn w-auto me-2 mb-2" />
        )}

        <AtkButton label="Register" onClick={onRegisterClick} className="pf-secondary-btn w-auto me-2 mb-2" />

        {loginLoading ? (
          <>
            <AtkLoader></AtkLoader>
          </>
        ) : (
          <AtkButton label="Login" onClick={onLoginClick} className="pf-primary-btn w-auto mb-2" />
        )}
      </div>
      <div className="col-12 mb-2 ">
        {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using Momentum Credentials" /> : null}
      </div>
    </div>
  );
}