import React from 'react'
import IFSLogo from "../../assets/images/IFS ICON_TH.svg";

export default function SmartScanMiddleHeader() {
  return (
    <div className="row middle-header-row">
      <div className="col-12">
        <div className="coi-scan-middle-header-col active ms-2">
          <img src={IFSLogo} className="landing-page-img" />
          <span className="middle-header-title">Acord and Dec pages Scan</span>
        </div>
      </div>{" "}
    </div>
  )
} 