import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackIcon from "../../assets/images/Back Button.svg";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import { Toast } from "primereact/toast";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import FenrisSidebar from "./FenrisSidebar";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function FenrisMainPage(props: any) {
  const toast: any = useRef("");
  const [search, setSearch] = useState("");
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const navigate = useNavigate();
  const [insuredDatabaseId, setInsuredDataBaseId] = useState("");
  const [existingData, setExistingData] = useState(false);
  const [clientName, setClientName] = useState("");
  const [enrichDataFlag, setEnrichDataFlag] = useState(false);
  const [showFenrisHistory, setShowFenrisHistory] = useState(false);
  const [selectedtab, setSelectedTab] = useState(1);
  const [enrichDataDisbaled, setEnrichDataDisbaled] = useState(true);
  const [searchTextBoxDisbaled, setSearchTextBoxDisbaled] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] = useState(false);

  const location = useLocation();
  const momentumPolicyId = location.state?.policyid || "";
  const momentumInsuredDatabaseId = location.state?.insuredid || "";
  const hostname = window.location.hostname;

  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (momentumInsuredDatabaseId != null && momentumInsuredDatabaseId != "") {
      getNowCertsClientsById(momentumInsuredDatabaseId);
      setInsuredDataBaseId(momentumInsuredDatabaseId);
      setEnrichDataDisbaled(false);
    }
  }, []);

  useEffect(() => {
    getCardDetails();
  }, [currentBalanceUpdateFlag]);

  const onNewPrefillClick = () => {
    setSearchTextBoxDisbaled(false);
    setEnrichDataDisbaled(true);
    setClientName("");
    setExistingData(false);
    setShowFenrisHistory(false);
  };
  const onBackButtonClick = () => {
    navigate("/landingPage");
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onClientSearchChange = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setSearch(value);
    setClientName(value);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    if (search == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const getNowCertsClientsById = (insuredDatabaseId: string) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDatabaseId,
    };
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${insuredDatabaseId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        let insuredInfo = response.data;
        setClientName(insuredInfo[0].commercialName);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Error while getting insured data.",
              life: 3000,
            });
          }
        }
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the insureds data.Please try again later.",
              life: 3000,
            });
          }
        }
        // setErrorMessage(errorMessage);
        setSearchClientLoading(false);
      });
  };

  const onClientSelect = (client: any) => {
    let insuredDatabaseId = client.databaseId;
    setInsuredDataBaseId(insuredDatabaseId);
    setSearch("");
    setClientName(client.name);
    setEnrichDataDisbaled(false);
    localStorage.setItem("like", "false");
    localStorage.setItem("dislike", "false");
  };

  const onClickEnrich = () => {
    // setLoading(true);
    // setTimeout(() => setLoading(false), 1000);
    setExistingData(true);
    setEnrichDataFlag(!enrichDataFlag);
  };

  const onFenrisHistoryClick = () => {
    setClientName("");
    setShowFenrisHistory(true);
    setExistingData(false);
    setSearchTextBoxDisbaled(true);
    setEnrichDataDisbaled(true);
    localStorage.removeItem("like");
    localStorage.removeItem("dislike");
  };

  const onTabSelect = (index: any) => {
    if (index == 1) {
      setSelectedTab(index);
      onNewPrefillClick();
    } else {
      onFenrisHistoryClick();
      setSelectedTab(index);
    }
  };

  const clientNameSet = (clientName: any, textDisbaled: boolean, enrichBuutonDisbaled: boolean) => {
    setClientName(clientName);
    setSearchTextBoxDisbaled(textDisbaled);
    setEnrichDataDisbaled(enrichBuutonDisbaled);
  };
  const enrichRatingDataButtonDisbaled = () => {
    setEnrichDataDisbaled(true);
  };

  const updateCurrentBalanceUpdateFlag = () => {
    setCurrentBalanceUpdateFlag(!currentBalanceUpdateFlag);
  };

  const balanceCheck = () => {
    getCardDetails();
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="fenris-history-main-page">
        <Header pagename="fenris-landing-page" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <FenrisMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <FenrisSidebar pagename="fenris-landing-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row client-search-row d-flex align-items-center">
                  <div className="col-lg-1 col-md-1 col-sm-1 my-1 ">
                    <img src={BackIcon} className="show-cursor" height={20} width={20} onClick={onBackButtonClick} />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0 my-1 client-search-input-col position-relative mx-auto">
                    <div className="search-bar ">
                      <AtkTextField
                        type="text"
                        id="text"
                        className="search-area "
                        name="clientName"
                        onChange={(e) => {
                          onClientSearchChange(e.target.value);
                        }}
                        value={clientName}
                        //   onKeyDown={onKeyClick}
                        placeholder={"Enter client name"}
                        isDisabled={searchTextBoxDisbaled ? true : false}
                        autoComplete="off"
                      />
                    </div>
                    {search.length > 0 && (
                      <div id="client-search" className=" position-absolute  ">
                        {searchClientLoading ? (
                          <div className="d-flex justify-content-center my-2 client-loader-div">
                            <AtkLoader />
                          </div>
                        ) : searchClientList.length == 0 ? (
                          <div style={{ padding: "5px", background: "white" }}>
                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                          </div>
                        ) : (
                          searchClientList.map((item: any) => (
                            <div
                              style={{ cursor: "pointer", width: "100%" }}
                              onClick={() => {
                                onClientSelect(item);
                              }}
                            >
                              <a
                                href="#"
                                style={{
                                  color: "black",
                                  width: "100%",
                                  fontSize: "12px",
                                }}
                              >
                                {item.name}
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-4">
                    <AtkButtonIconBefore
                      label="Enrich Rating Data"
                      className="pf-secondary-btn"
                      onClick={() => onClickEnrich()}
                      isDisabled={enrichDataDisbaled ? true : false}
                    />
                  </div>
                  <div className="col-3 d-flex justify-content-end">
                    <div className="balance-span">
                      <label>Current Balance</label>
                      {currentBalanceLoading ? (
                        <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                          <AtkLoader />
                        </span>
                      ) : (
                        <span className=" d-block w-auto ">${currentBalance}</span>
                      )}
                      <small className="d-block w-auto text-danger mt-0">80 cent will be charged per usage</small>
                    </div>
                  </div>
                </div>
                <div className="row p-2 mx-auto">
                  <>
                    {existingData ? (
                      <div className="col-12">
                        <ExistingAndFenrisData
                          insuredId={insuredDatabaseId}
                          clientnameset={clientNameSet}
                          enrichDataFlag={enrichDataFlag}
                          updateCurrentBalanceUpdateFlag={updateCurrentBalanceUpdateFlag}
                          balanceCheck={balanceCheck}
                        />
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
