import React, { FC, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonValues from "../../common/utils";
import PfdIcon from "../../assets/images/file.png";
import ImageIcon from "../../assets/images/ImageIcon.png"
import PlusSign  from "../../assets/images/PlusSing1.webp"
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import axios from "axios";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Toast } from "primereact/toast";
import DriverLicenseScanDataExtractPage from "./DriverLicenseScanDataExtractPage";
import BackIcon from "../../assets/images/Back Button.svg";

export default function DriverLicenseScanLandingPage(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;
    const toast: any = useRef("");
    const [loading, setLoading] = useState(false);
    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState("0");
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showExtractedData, setShowExtractedData] = useState(false);
    const [uploadFile, setUploadFile] = useState<any>([]);
    const [uploadFileLimit, setUploadFileLimit] = useState(1);
    const [fileNameTitle, setFileNameTitle] = useState("");
    const [fileName, setFileName] = useState("");
    const [uploadComplete, setUploadComplete] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [uploadfileUrl, setUploadFileUrl] = useState("")
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [drivingLicenseNumber, setDrivingLicenseNumber] = useState("");
    const [issueDate, setIssueDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [state, setState] = useState("");
    const [addressOfLicenseHolder, setAddressOfLicenseHolder] = useState("");
    const [drivingLicenseClass, setDrivingLicenseClass] = useState("");
    const [endorsements, setEndorsements] = useState("");
    const [restrictions, setRestrictions] = useState("");
    const [sex, setSex] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [eyeColor, setEyeColor] = useState("");
    const [hairColor, setHairColor] = useState("")
    const [addressLine1, setAddressLine1] = useState("")
    const [city, setCity] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [submitLoading, setSubmitLoading] = useState(false)
    const [transactionId, setTransactionId] = useState("");
    const [isUploadAgain, setIsUploadAgain] = useState(false)
    const [firstNameErrorMessage,setFirstNameErrorMessage]=useState("")


    useEffect(() => {
        getCurrentBalance()
    }, [])


    const onChangeFirstName = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z ]/g, "");
        const Value = inputValue.slice(0, 255);
        setFirstName(Value);
        setFirstNameErrorMessage("");
    };

    const onChangeLastName = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z ]/g, "");
        const Value = inputValue.slice(0, 255);
        setLastName(Value);
    };

    const onChangeDateOfBirth = (value: any) => {
        setDateOfBirth(value);
    };

    const onChangeDrivingLicenseNumber = (value: any) => {
        setDrivingLicenseNumber(value);
    };

    const onChangeIssueDate = (value: any) => {
        setIssueDate(value);
    };

    const onChangeExpirationDate = (value: any) => {
        setExpirationDate(value);
    };

    const onChangeState = (value: any) => {
        setState(value);
    };

    const onChangeAddressOfLicenseHolder = (value: any) => {
        setAddressOfLicenseHolder(value);
    };

    const onChangeDrivingLicenseClass = (value: any) => {
        setDrivingLicenseClass(value);
    };

    const onChangeEndorsements = (value: any) => {
        setEndorsements(value);
    };

    const onChangeRestrictions = (value: any) => {
        setRestrictions(value);
    };

    const onChangeSex = (value: any) => {
        setSex(value);
    };

    const onChangeHeight = (value: any) => {
        setHeight(value);
    };

    const onChangeWeight = (value: any) => {
        setWeight(value);
    };

    const onChangeEyeColor = (value: any) => {
        setEyeColor(value);
    };

    const onChangeHairColor = (value: any) => {
        setHairColor(value);
    };

    const onChangeAddressLine1 = (value: any) => {
        setAddressLine1(value);
    };

    const onChangeCity = (value: any) => {
        setCity(value);
    };

    const onChangeZipCode = (value: any) => {
        setZipCode(value);
    };

    const onDropHandler = (event: any) => {
        event.preventDefault();
        onFileSelectHandler(event.dataTransfer.files);
    };

    const onFileSelectHandler = (files: any) => {
        setFileName("");
        setFileNameTitle("");
        const isValidated = validateSelectedFiles(files);
        if (!isValidated) {
            setErrorMessage("Please select PDF,JPEG,PNG,JPG files only");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
            return false;
        }

        let fileList = [];
        let totalSize = 0;
        let currentFiles = uploadFile;
        let filename = files[0].name;
        if (filename.length > 50) {
            let shortenString = filename.substring(0, 50).concat("...");
            setFileName(shortenString);
            setFileNameTitle(filename);
        } else {
            setFileName(filename);
            setFileNameTitle(filename);
        }
        for (let i = 0, len = files.length; i < len; i++) {
            fileList.push({
                file: files[i],
                name: files[i].name,
                size: files[i].size,
            });
            totalSize = totalSize + files[i].size;
        }

        if (fileList.length > 0) {
            if (currentFiles.length > 0) {
                for (var i = 0, l = currentFiles.length; i < l; i++) {
                    for (var j = 0; j < fileList.length; j++) {
                        if (currentFiles[i].name === fileList[j].name) {
                            fileList.splice(j, 1);
                        }
                    }
                }
            }
            let allFiles = currentFiles.concat(fileList);
            if (allFiles.length > uploadFileLimit) {
                allFiles = allFiles.slice(0, uploadFileLimit);
                setErrorMessage("Multiple files cannot be uploaded at the same time; please upload a single file");
                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);
            }
            setUploadFile(allFiles);
        }
    };

    const validateSelectedFiles = (files: any) => {
        var regularExpression = /(?:\.([^.]+))?$/;
        var isValid = true;

        for (let index = 0, len = files.length; index < len; index++) {
            const fileExtension: any = regularExpression.exec(files[index].name);
            if (fileExtension === undefined || fileExtension[0] == "") {
                isValid = false;
                break;
            }
            if (fileExtension?.[1].toLowerCase().includes("pdf") || fileExtension?.[1].toLowerCase().includes("jpg") || fileExtension?.[1].toLowerCase().includes("jpeg") || fileExtension?.[1].toLowerCase().includes("png")) {
                isValid = true;
            } else {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const removeFileFromList = () => {
        setUploadFile([]);
        setShowExtractedData(false);
    };

    const createUploadRequest = () => {
        setLoading(true);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("drivingLicense", uploadFile[0].file);
        const selectionOptionsJson = {
            useExistingInsured: true,
            insuredClientId: props.insuredClientId,
            clientName: props.clientName
        }
        data.append("selectedOption", JSON.stringify(selectionOptionsJson));
        let token = CommonValues.GetToken();

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_PolicyAgent_Url}/scan_driving_license`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };
        axios(config)
            .then((response: any) => {
                setLoading(false)
                if (response.data.success) {
                    const drivingLicenseResponse = JSON.parse(response.data.drivingLicenseResponse)
                    const height = drivingLicenseResponse.heightFeet + '.' + drivingLicenseResponse.heightInch
                    setShowExtractedData(true);
                    setIsFileUpload(true);
                    setTransactionId(drivingLicenseResponse.transactionId);
                    setFirstName(drivingLicenseResponse.firstName);
                    setLastName(drivingLicenseResponse.lastName);
                    setDateOfBirth(drivingLicenseResponse.dateOfBirth);
                    setDrivingLicenseNumber(drivingLicenseResponse.drivingLicenseNumber);
                    setIssueDate(drivingLicenseResponse.issueDate);
                    setExpirationDate(drivingLicenseResponse.expirationDate);
                    setAddressLine1(drivingLicenseResponse.addressLine1);
                    setCity(drivingLicenseResponse.city);
                    setState(drivingLicenseResponse.state);
                    setZipCode(drivingLicenseResponse.zipCode);
                    setAddressOfLicenseHolder(drivingLicenseResponse.addressOfLicenseHolder);
                    setDrivingLicenseClass(drivingLicenseResponse.drivingLicenseClass);
                    setEndorsements(drivingLicenseResponse.endorsements);
                    setRestrictions(drivingLicenseResponse.restrictions);
                    setSex(drivingLicenseResponse.sex);
                    setHeight(height);
                    setWeight(drivingLicenseResponse.weight);
                    setEyeColor(drivingLicenseResponse.eyeColor);
                    setHairColor(drivingLicenseResponse.hairColor);
                    setUploadFileUrl(drivingLicenseResponse.fileUrl)
                    getCurrentBalance();
                } else {
                    toast.current.show({
                        severity: "info", summary: 'Info',
                        detail: response.data.errorMessage,
                        life: 3000,
                    });
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    toast.current.show({
                        severity: "error", summary: 'Error',
                        detail: "Error while creating the request.",
                        life: 3000,
                    });
                    setLoading(false);
                }
            });
    };

    const getCurrentBalance = () => {
        setCurrentBalanceLoading(true);
        var token = CommonValues.GetToken();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success == true) {
                    setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error", summary: 'Error',
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error", summary: 'Error',
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };

    const checkValidation=()=>{
       let isValid=true
       if(firstName ==""|| firstName==null|| firstName =="None"){
        isValid=false
        setFirstNameErrorMessage("Please enter first name.");
       }
       return isValid
    }

    const OnSubmit = () => {
        if(checkValidation()){
        addDriverData(props.insuredClientId)
        downloadFileFromAzure();
        }
    }

    const addDriverData = (insuredDatabaseId: any) => {
        setSubmitLoading(true)
        let date = issueDate.split("/")
        let name = props.clientName.split(" ")
        let requestDataList = {
            insured_database_id: insuredDatabaseId,
            first_name: firstName,
            last_name: lastName,
            insured_commercial_name: props.clientName,
            license_number: drivingLicenseNumber,
            driver_license_class: drivingLicenseClass,
            date_of_birth: dateOfBirth,
            gender: sex,
            license_state: state,
            termination_date: expirationDate,
            cdl_date: date[0],
            cdl_year: date[2],
            license_year: date[2],
            active: true
        }
        const data = JSON.stringify(requestDataList);

        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/adddriver?&ncToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                toast.current.show({ severity: "success", summary: 'Success', detail: "Driver data updated in NowCerts", life: 3000 });
                setSubmitLoading(false)
                updateDrivingLicenseScanHistory()
                setIsUploadAgain(true)
            })
            .catch((error) => {
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else if (error.response.status === 400) {
                        toast.current.show({
                            severity: "error", summary: 'Error',
                            detail: "Bad Request",
                            life: 3000,
                        });
                    } else {
                        toast.current.show({
                            severity: "error", summary: 'Error',
                            detail: "Unknown error while adding single. driver data in nowcerts",
                            life: 3000,
                        });
                    }
                }
                setSubmitLoading(false);
            });
    }

    const updateDrivingLicenseScanHistory = () => {
        let token = CommonValues.GetToken();
        const submittedRequestJson = {
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
            drivingLicenseNumber: drivingLicenseNumber,
            issueDate: issueDate,
            expirationDate: expirationDate,
            addressLine1: addressLine1,
            city: city,
            state: state,
            zipCode: zipCode,
            drivingLicenseClass: drivingLicenseClass,
            endorsements: endorsements,
            restrictions: restrictions,
            sex: sex,
            height: height,
            weight: weight,
            eyeColor: eyeColor,
            hairColor: hairColor
        };
        const selectionOptionsJson = {
            useExistingInsured: true,
            insuredClientId: props.insuredClientId,
            clientName: props.clientName
        }
        var data = {
            TransactionId: transactionId,
            submittedRequestJson: JSON.stringify(submittedRequestJson),
            selectionOptionsJson: JSON.stringify(selectionOptionsJson)
        };
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/updatehistory`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };
        axios(config)
            .then((response: any) => { console.log(response) })
            .catch((error: any) => {
                console.log(error);
                localStorage.removeItem("currentPage");
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    toast.current.show({
                        severity: "error", summary: 'Error',
                        detail: "Error while driver license scan updating the request.",
                        life: 3000,
                    });
                }
            });
    };

    const downloadFileFromAzure = () => {
        let token = CommonValues.GetToken();
        axios({
            url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/downloadfile?fileName=${fileName}&transactionId=${transactionId}`,
            method: "GET",
            responseType: "blob", // important
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response: any) => {
                const file = new Blob([response.data], { type: response.data.type });
                const fileReader = new FileReader();

                fileReader.onload = function (evt) {
                    var binary = "";
                    var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    sendToNowCertsAMSAPI(btoa(binary));
                };
                fileReader.readAsArrayBuffer(file);
            })
            .catch((error) => {
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status == 401) {
                        navigate("/");
                        CommonValues.Logout(navigate);
                    } else {
                        errorMessage = "Unknown error while saving file to AMS ";
                        toast.current.show({
                            severity: "error", summary: 'Error',
                            detail: { errorMessage },
                            life: 3000,
                        });
                    }
                }
            });
    }

    const sendToNowCertsAMSAPI = (base64String: any) => {
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var data = JSON.stringify({
            TransactionId: transactionId,
            FileName: fileName,
            NCPolicyId: "",
            NCInsuredId: props.insuredClientId,
            FileData: base64String,
            FolderName: "Driver License",
            NowCertsToken: nowCertsToken,
        });
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/upload`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response: any) => {

            })

            .catch((error: any) => {
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status == 401) {
                        navigate("/");
                        CommonValues.Logout(navigate);
                    } else {
                        errorMessage = "Unknown error while saving file to AMS ";
                        toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while saving file to AMS", life: 3000 });
                    }
                }
            });
    };



    return (
        <>
            <Toast ref={toast} />
            <div className="row  d-flex  align-items-end text-12 position-relative">
                <div className="col-12  p-0">
                    <div className="row d-flex justify-content-end scan-search-row mx-auto ">
                        {" "}
                        <div className="col-9 d-flex  align-items-center">

                            <span>
                                {" "}
                                <img src={BackIcon} className="show-cursor me-3" height={20} width={20} title="Back" onClick={props.onBackButtonClick}/>
                            </span>
                            <span className=" me-3">
                                <label>
                                    <b>Client Name: </b> &nbsp;
                                </label>
                                {props.clientName}
                            </span>

                        </div>
                        <div className="col-3 d-flex justify-content-end ">
                        <div className="balance-span">                            <label>Current Balance</label>
                            {currentBalanceLoading ? (
                                <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                                    <AtkLoader />
                                </span>
                            ) : (
                                <span className=" d-block w-auto ">${currentBalance}</span>
                            )}
                            <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row  d-flex justify-content-end align-items-center text-12 position-relative mx-auto mt-2">
                <div className="col-12">
                    <div className="row m-3">
                        {isFileUpload ? (
                            <div className="col">
                                <iframe
                                    src={uploadfileUrl}
                                    style={{
                                        overflow: "scroll",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                ></iframe>

                            </div>
                        ) : (
                            <>
                                <div
                                    className="uploadNowcertscoi my-3"
                                    onDrop={(e) => {
                                        onDropHandler(e);
                                    }}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="row w-100 ">
                                        {uploadFile.length > 0 ? (
                                            <div className="col-12">
                                                {uploadFile.map((file: any, index: any) => (
                                                    <div className="show-pdf-icon">
                                                        <div className="scan-file-name">
                                                        <div className="preview-icon">
                                <img src={PreviewIcon} />
                              </div>
                              <div>
                                <div title={fileNameTitle}>{fileName}</div>
                                <div>
                                  {(parseInt(file.size) / 1000).toFixed(0)}
                                  KB
                                </div>
                              </div>
                                                        </div>
                                                        <div onClick={() => removeFileFromList()} className="show-cursor">
                                                            <img src={RemoveIcon} title="Remove file" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <>
                                                <>
                                                    <div className="row" style={{ textAlign: "center" }}>
                                                        <div className="col-12">
                                                            <img src={PfdIcon} alt="" width="70" />
                                                            <img src={PlusSign} alt="" width="70" />
                                                            <img src={ImageIcon} alt="" width="70" />
                                                        </div>

                                                        <div className="col-12 mt-4">
                                                            <div className="col-12">
                                                                <span className="drag-and-drop">Drag & Drop file here</span>
                                                            </div>
                                                            <div className="col-12 my-2">
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontSize: "large",
                                                                    }}
                                                                >
                                                                    or
                                                                </span>
                                                            </div>
                                                            <label className="mb-0 show-cursor">
                                                                <span className="browse">Browse PDF/JPG/JPEG</span>
                                                                <input
                                                                    type="file"
                                                                    name="filefield"
                                                                    accept="image/*"
                                                                    id="bulkFileUploader"
                                                                    style={{ visibility: "hidden", display: "none" }}
                                                                    onChange={(e) => {
                                                                        onFileSelectHandler(e.target.files);
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            </>
                                        )}
                                        <div className="col-12 text-center">
                                            {uploadFile.length > 0 && (
                                                <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                                                    {loading == false ? (
                                                        <>
                                                            <AtkButtonIconBefore
                                                                label="Upload"
                                                                className="pf-secondary-btn"
                                                                onClick={() => {
                                                                    createUploadRequest();
                                                                }}
                                                                title="Upload file"
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="d-flex justify-content-center align-items-center p-2">
                                                            <AtkLoader></AtkLoader>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {errorMessage.length > 0 ? (
                                                <div
                                                    className="d-flex justify-content-center Acord25-upload-file-error"
                                                    style={{ width: "100%", padding: "20px 20px 0px" }}
                                                >
                                                    <AtkMessage appearance="error" messageText={errorMessage} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {showExtractedData ?
                            <>
                                <DriverLicenseScanDataExtractPage
                                    firstName={firstName}
                                    onChangeFirstName={onChangeFirstName}
                                    firstNameErrorMessage={firstNameErrorMessage}
                                    lastName={lastName}
                                    onChangeLastName={onChangeLastName}
                                    dateOfBirth={dateOfBirth}
                                    onChangeDateOfBirth={onChangeDateOfBirth}
                                    drivingLicenseNumber={drivingLicenseNumber}
                                    onChangeDrivingLicenseNumber={onChangeDrivingLicenseNumber}
                                    issueDate={issueDate}
                                    onChangeIssueDate={onChangeIssueDate}
                                    expirationDate={expirationDate}
                                    onChangeExpirationDate={onChangeExpirationDate}
                                    state={state}
                                    onChangeState={onChangeState}
                                    addressLine1={addressLine1}
                                    onChangeAddressLine1={onChangeAddressLine1}
                                    city={city}
                                    onChangeCity={onChangeCity}
                                    zipCode={zipCode}
                                    onChangeZipCode={onChangeZipCode}
                                    drivingLicenseClass={drivingLicenseClass}
                                    onChangeDrivingLicenseClass={onChangeDrivingLicenseClass}
                                    endorsements={endorsements}
                                    onChangeEndorsements={onChangeEndorsements}
                                    restrictions={restrictions}
                                    onChangeRestrictions={onChangeRestrictions}
                                    sex={sex}
                                    onChangeSex={onChangeSex}
                                    height={height}
                                    onChangeHeight={onChangeHeight}
                                    weight={weight}
                                    onChangeWeight={onChangeWeight}
                                    eyeColor={eyeColor}
                                    onChangeEyeColor={onChangeEyeColor}
                                    hairColor={hairColor}
                                    onChangeHairColor={onChangeHairColor}
                                    formHistoryPage={false}
                                    onSubmit={OnSubmit}
                                    submitLoading={submitLoading}
                                    isUploadAgain={isUploadAgain}
                                    onUploadAgain={props.onBackButtonClick}
                                    transactionId={transactionId}
                                />
                            </>
                            : null}
                    </div>
                </div>
            </div>

        </>

    )
}