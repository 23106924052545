import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ValchoiceMiddleHeader from "./ValchoiceMiddleHeader";
import ValchoiceSidebar from "./ValchoiceSidebar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Dropdown } from "primereact/dropdown";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import { OverlayPanel } from "primereact/overlaypanel";
import Footer from "../../common/Footer";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function ValchoiceLandingPage() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [insuredSearch, setInsuredSearch] = useState("");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [nowcertsPolicyLoading, setNowcertsPolicyLoading] = useState(false);
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [insuredClientName, setInsuredClientName] = useState("");
  const [insuredClientNameError, setInsuredClientNameError] = useState("");
  const [insuredClientId, setInsuredClientId] = useState("");

  const [carrierNameError, setCarrierNameError] = useState([""]);
  const [carrierName, setCarrierName] = useState([""]);
  const [carrierValue, setCarrierValue] = useState<any>({});
  const [carrierSearch, setCarrierSearch] = useState<any>([]);
  const [selectedSubPolicy, setSelectedSubPolicy] = useState(0);
  const [carrierList, setCarrierList] = useState([]);
  const [showCarrierDropDown, setShowCarrierDropDown] = useState(false);
  const [carrier, setCarrier] = useState<any>([]);
  const [carrierCodeLoading, setCarrierCodeLoading] = useState(false);
  const [isCarrierSelected, setIsCarrierSelected] = useState(true);
  const [ispolicySelected, setIspolicySelected] = useState(false);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<{ id: any; index: any; value: any; clientName: any }[]>([]);
  const [policyNumber, setPolicyNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const [agencyName, setAgencyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [naicsNumber, setnaicsNumber] = useState(0);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerNameError, setCustomerNameError] = useState("");
  const [showContactErrorPopup, setShowContactErrorPopup] = useState(false);
  const [insuredContactLoading, setInsuredContactLoading] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const feedbackRef = useRef<OverlayPanel>(null);
  const [feedbackId, setFeedbackId] = useState("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [threshold, setThreshold] = useState("65");
  const [thresholdError, setThresholdError] = useState("");
  const [customerFirstNameError, setCustomerFirstNameError] = useState("");
  const [customerLastNameError, setCustomerLastNameError] = useState("");

  const Allstate = [
    { label: "Select state", value: "" },
    { label: "Alaska", value: "AK" },
    { label: "Alabama", value: "AL" },
    { label: "Arkansas", value: "AR" },
    { label: "Arizona", value: "AZ" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "District of Columbia", value: "DC" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Iowa", value: "IA" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Massachusetts", value: "MA" },
    { label: "Maryland", value: "MD" },
    { label: "Maine", value: "ME" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Missouri", value: "MO" },
    { label: "Mississippi", value: "MS" },
    { label: "Montana", value: "MT" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Nebraska", value: "NE" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "Nevada", value: "NV" },
    { label: "New York", value: "NY" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Virginia", value: "VA" },
    { label: "Vermont", value: "VT" },
    { label: "Washington", value: "WA" },
    { label: "Wisconsin", value: "WI" },
    { label: "West Virginia", value: "WV" },
    { label: "Wyoming", value: "WY" },
  ];

  const hostname = window.location.hostname;
  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);

  useEffect(() => {
    if (insuredSearch.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(insuredSearch);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [insuredSearch]);

  useEffect(() => {
    if (carrierSearch.length == 0 || carrierSearch[selectedSubPolicy] == "") return;

    if (localStorage.getItem("IsIndicative") == "true") {
      getCarrierCodeMappingForCPF(carrierSearch[selectedSubPolicy], 0);
    }

    const searchTimeout = setTimeout(() => {
      getCarrierCodeMappingForCPF(carrierSearch[selectedSubPolicy], 0);
    }, 10);

    return () => clearTimeout(searchTimeout);
  }, [carrierSearch]);

  useEffect(() => {
    getAmsAgencySettings();
    getCardDetails();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const getAmsAgencySettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        setAgencyName(response.data.agencyName);
        setPhoneNumber(response.data.phone);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while loading the AMS Agency settings", life: 3000 });
          }
        }
      });
  };
  const getNowCertsPoliciesList = (insuredId: string) => {
    setNowcertsPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          setNowCertPolicyData(response.data);
        } else {
          setNowCertPolicyData([]);
        }
        setNowcertsPolicyLoading(false);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the client policy. Please try again later.",
                life: 3000,
              });
            }
          }
        }
        setNowcertsPolicyLoading(false);
      });
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
        setSearchClientLoading(false);
      });
  };
  const getCarrierCodeMappingForCPF = (search: any, index: any) => {
    setCarrierCodeLoading(true);
    let carrierData: any = [];

    var token = CommonValues.GetToken();
    var data = {
      search: search,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoicecarriercodes`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setCarrierCodeLoading(false);
        setCarrierList(response.data);
      })
      .catch((error: any) => {
        setCarrierCodeLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while loading the carrier details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while loading the carrier details",
            life: 3000,
          });
        }
      });
  };
  const getInsuredContact = (insuredId: string) => {
    setInsuredContactLoading(true);
    var data = {
      insuredDatabaseId: insuredId,
    };

    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/insuredcontact?NCtoken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setInsuredContactLoading(false);
        if (response.data.length > 0) {
          if (response.data[0].firstName == "" || response.data[0].lastName == "") {
            setCustomerNameError("No contact details found!");
            setCustomerFirstName("");
            setCustomerLastName("");
            setShowContactErrorPopup(true);
          } else {
            setCustomerNameError("");
            setCustomerFirstName(response.data[0].firstName);
            setCustomerLastName(response.data[0].lastName);
            setShowContactErrorPopup(false);
            if (response.data[0].firstName != "" && response.data[0].firstName != null) {
              setCustomerFirstNameError("");
            }
          }
        } else {
          setCustomerNameError("No contact details found!");
          setCustomerFirstName("");
          setCustomerLastName("");
          // setCustomerFirstNameError("Please enter customer first name");
          // setCustomerLastNameError("Please enter customer last name");
          setShowContactErrorPopup(true);
        }
      })
      .catch((error) => {
        setInsuredContactLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the insured contact details.Please try again later",
                life: 3000,
              });
            }
          }
        }
      });
  };
  const onCarrierNameChange = (e: any) => {
    let newcarrierSearch = [];
    newcarrierSearch[selectedSubPolicy] = e.target.value;
    setCarrierSearch(newcarrierSearch);
  };
  const onCustomerFirstNameChange = (e: any) => {
    setCustomerFirstName(e.target.value);
    setCustomerFirstNameError("");
  };
  const onCustomerLastNameChange = (e: any) => {
    setCustomerLastName(e.target.value);
  };
  const onThresholdChange = (e: any) => {
    const value = e.target.value;
    const re = /^\d*$/;
    if (re.test(value)) {
      setThreshold(e.target.value);
    }
  };
  const onClientSelectForInsured = (client: any) => {
    setInsuredSearch("");
    setInsuredClientName(client.name);
    setInsuredClientId(client.databaseId);
    setInsuredClientNameError("");
    getInsuredContact(client.databaseId);
    if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && ispolicySelected) {
      getNowCertsPoliciesList(client.databaseId);
    }
    setInsuredClientNameError("");
  };
  const onClientSearchChangeForInsured = (value: any) => {
    if (value.length > 255) {
      return;
    }
    setInsuredSearch(value);
  };
  const onCarrierselect = (item: any, e: any) => {
    setCarrierList([]);
    setCarrierSearch([]);
    if (item) {
      let newCarrierName = carrierName == null ? [] : [...carrierName];
      newCarrierName[selectedSubPolicy] = item.name;
      setCarrierName(newCarrierName);

      const newValues = [...carrier];
      newValues[selectedSubPolicy] = item;
      setCarrier(newValues);
      setCarrierValue(newValues);

      newValues[selectedSubPolicy] = item;
    }
    setShowCarrierDropDown(false);
    setnaicsNumber(item.naicNumber);
    setCarrierNameError([]);
    e.preventDefault();
  };

  const checkValidation = () => {
    let formIsValid = true;
    const newCarrierNameError = [];

    if (insuredClientName.length == 0) {
      setInsuredClientNameError("Please select client name");
      formIsValid = false;
    } else {
      setInsuredClientNameError("");
    }
    if (threshold == null || threshold == "") {
      setThresholdError("Please enter threshold");
      formIsValid = false;
    } else {
      setThresholdError("");
    }
    if (customerFirstName == null || customerFirstName == "") {
      setCustomerFirstNameError("Please enter customer first name");
      formIsValid = false;
    } else {
      setCustomerFirstNameError("");
    }

    if (!isCarrierSelected && !ispolicySelected) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select Carrier or Policy action",
        life: 3000,
      });
      formIsValid = false;
    }
    if (isCarrierSelected) {
      if (carrierValue[0] == null || carrierValue[0] == undefined) {
        formIsValid = false;
        newCarrierNameError[0] = "Please select carrier ";
        setCarrierNameError(newCarrierNameError);
      } else {
        setCarrierNameError([]);
      }
    }
    if (isCarrierSelected) {
      if (state == "" || state == null) {
        formIsValid = false;
        setStateError("Please select state");
      } else {
        setStateError("");
      }
    }
    if (ispolicySelected) {
      if (policyNumber == "" && insuredClientName.length > 0) {
        if (toast.current) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Please select existing policy",
            life: 3000,
          });
        }
        formIsValid = false;
      }
    }
    if (insuredClientName.length > 0 && customerFirstName == "") {
      setCustomerNameError("No contact details found!");
      setShowContactErrorPopup(true);

      formIsValid = false;
    } else {
      setCustomerNameError("");
      setShowContactErrorPopup(false);
    }

    return formIsValid;
  };
  const onNextButtonClick = () => {
    if (checkValidation()) {
      setLoading(true);
      var token = CommonValues.GetToken();
      var data = {
        state: state,
        naic: naicsNumber,
        report_type: "autohome",
        lob: "",
        customer_first_name: customerFirstName,
        customer_last_name: customerLastName != "" && customerLastName != null ? customerLastName : "NA",
        threshold: parseInt(threshold),
        agency_name: agencyName,
        agent_phone: phoneNumber,
        insuredName: insuredClientName,
        carrierName: carrierName[0],
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoicereport`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          setFeedbackId(response.data.id);
          if (response.data.url != null && response.data.url != "") {
            setPdfUrl(response.data.url);
            setShowPopUp(true);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.success,
              life: 3000,
            });
          } else {
            setPdfUrl("");
            setShowPopUp(false);
            if (response.data.error.error == "User does not have enough credits for Valchoice") {
              toast.current.show({
                severity: "info",
                summary: "Info",
                detail: response.data.error.error,
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response.data.error.error,
                life: 3000,
              });
            }
          }
          setLoading(false);
          getCardDetails();
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while getting valchoice reports",
                life: 3000,
              });
            }
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting valchoice reports",
              life: 3000,
            });
          }
        });
    }
  };

  const stateDropdownClicked = (e: any) => {
    setState(e.value);
    setStateError("");
  };
  const onBackClick = () => {
    setShowPopUp(false);
    setPdfUrl("");
    setState("");
    setCarrierName([]);
    setInsuredClientName("");
    setCarrierValue([]);
    setThreshold("65");
    setCustomerFirstName("");
    setCustomerLastName("");
  };
  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback, id: feedbackId };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, id: feedbackId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, id: feedbackId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else if (rowData.feedback.length > 250) {
      setFeedBackError("Please provide your feedback within 250 character");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      Id: rowData.id,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoiceratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Rating submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      Id: rowData.id,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoicefeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="valchoice-main-page">
        <Header pagename="valchoice" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <ValchoiceMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <ValchoiceSidebar pagename="valchoice" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row d-flex   justify-content-end scan-search-row mx-auto ">
                      <div className="col-7"></div>
                      <div className="col-3 d-flex justify-content-end">
                        <div className="balance-span">
                          <label>Current Balance</label>
                          {currentBalanceLoading ? (
                            <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                              <AtkLoader />
                            </span>
                          ) : (
                            <span className=" d-block w-auto ">${currentBalance}</span>
                          )}
                          <small className="d-block w-auto text-danger mt-0">1 dollar will be charged per usage</small>
                        </div>
                      </div>

                      <div className="col-10">
                        <div className="row d-flex justify-content-start">
                          <div className="col-3 position-relative mb-2 p-0">
                            <p className=" fw-bold mb-1 ">
                              Select Client <span className="text-danger "> *</span>
                            </p>

                            <div className={`search-bar flex-column  ${insuredClientNameError.length > 0 ? " validation-div " : " "}`}>
                              <AtkTextField
                                type="text"
                                id="client-name"
                                className={` ${insuredClientName.length > 0 ? "clientname-placeholder" : ""} input-field search-area w-100 `}
                                name="insuredSearch"
                                onChange={(e) => {
                                  onClientSearchChangeForInsured(e.target.value);
                                }}
                                value={insuredSearch}
                                placeholder={insuredClientName.length > 0 ? insuredClientName : "Enter client name"}
                              />
                            </div>
                            {insuredSearch.replaceAll(" ", "").length > 0 && (
                              <div id="client-search" className=" position-absolute  ">
                                {searchClientLoading ? (
                                  <div className="d-flex justify-content-center my-2 client-loader-div">
                                    <AtkLoader />
                                  </div>
                                ) : searchClientList.length == 0 ? (
                                  <div style={{ padding: "5px", background: "white" }}>
                                    <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                  </div>
                                ) : (
                                  searchClientList.map((item: any) => (
                                    <div
                                      style={{ cursor: "pointer", width: "100%" }}
                                      onClick={() => {
                                        onClientSelectForInsured(item);
                                      }}
                                    >
                                      <a
                                        href="#"
                                        style={{
                                          color: "black",
                                          width: "100%",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.name}
                                      </a>
                                    </div>
                                  ))
                                )}
                              </div>
                            )}
                            <span className="text-danger text-12">{insuredClientNameError}</span>
                          </div>

                          {isCarrierSelected ? (
                            <>
                              <div className={`col-3 ${stateError.length > 0 ? " validation-div " : " "}`}>
                                <p className="fw-bold mb-1 ">
                                  Select State <span className="text-danger "> *</span>
                                </p>

                                <Dropdown
                                  type="text"
                                  id="text"
                                  className="finance-company-dropdown input-field"
                                  value={state}
                                  placeholder="Select state"
                                  onChange={(e: any) => stateDropdownClicked(e)}
                                  options={Allstate}
                                />
                                <span className="text-12 text-danger">{stateError}</span>
                              </div>
                              <div className=" col-3 position-relative mb-2  p-0">
                                <p className="fw-bold mb-1 ">
                                  Select Carrier <span className="text-danger "> *</span>
                                </p>
                                <div
                                  className={`search-bar flex-column ${
                                    carrierNameError[0] != undefined && carrierNameError[0].length > 0 ? " validation-div " : " "
                                  }`}
                                >
                                  <AtkTextField
                                    id="carrier-name"
                                    className={` ${
                                      carrierValue[0] != undefined &&
                                      carrierValue[0] != null &&
                                      carrierValue[0].name != undefined &&
                                      carrierValue[0].name != null &&
                                      carrierValue[0].name.trim() != ""
                                        ? ""
                                        : "carrier-placeholder"
                                    } input-field policydetails `}
                                    type="text"
                                    value={carrierSearch.length > 0 ? carrierSearch[0] : ""}
                                    onChange={onCarrierNameChange}
                                    placeholder={
                                      carrierValue[0] != undefined &&
                                      carrierValue[0] != null &&
                                      carrierValue[0].name != undefined &&
                                      carrierValue[0].name != null &&
                                      carrierValue[0].name.trim() != ""
                                        ? carrierValue[0].name
                                        : "Search and select carrier name"
                                    }
                                    isDisabled={ispolicySelected ? true : false}
                                  />
                                </div>
                                {carrierCodeLoading ? (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <AtkLoader />
                                  </div>
                                ) : (
                                  <>
                                    {carrierSearch[0] != undefined && carrierSearch[0] != null && carrierSearch.length > 0 && (
                                      <>
                                        {carrierList.length > 0 ? (
                                          <div id="hs-client-search" className="canopy-clientselector-dropdown w-100">
                                            {carrierList.map((item: any) => (
                                              <div
                                                className="show-cursor w-100"
                                                onClick={(e) => {
                                                  onCarrierselect(item, e);
                                                }}
                                              >
                                                <a href="#" className="carrier-link">
                                                  {item.name}
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              background: "#ddd",
                                            }}
                                            className="text-center text-12 w-100 p-2 "
                                          >
                                            <span className="">Oops Carrier name not found</span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    <span className="text-12 text-danger">{carrierNameError}</span>
                                  </>
                                )}
                              </div>
                            </>
                          ) : null}

                          <div className={`col-3 ${thresholdError.length > 0 ? " validation-div " : " "}`}>
                            <p className="fw-bold mb-1 ">
                              Threshold <span className="text-danger "> *</span>
                            </p>
                            <AtkTextField
                              type="text"
                              id="txtThreshold"
                              name="threshold"
                              onChange={onThresholdChange}
                              value={threshold}
                              className="input-field"
                              placeholder="Enter threshold"
                            />
                            <span className="text-12 text-danger">{thresholdError}</span>
                          </div>
                          <div className={`col-3  p-0 ${customerFirstNameError.length > 0 ? " validation-div " : " "}`}>
                            <p className="fw-bold mb-1 ">
                              Customer First Name <span className="text-danger "> *</span>
                            </p>
                            <AtkTextField
                              type="text"
                              id="txtCustomerFirstName"
                              name="customerFirstName"
                              onChange={onCustomerFirstNameChange}
                              value={customerFirstName}
                              className="input-field"
                              placeholder="Enter customer first name"
                            />
                            <span className="text-12 text-danger">{customerFirstNameError}</span>
                          </div>
                          <div className={`col-3 `}>
                            <p className="fw-bold mb-1 ">Customer Last Name</p>
                            <AtkTextField
                              type="text"
                              id="txtCustomerLastName"
                              name="customerLastName"
                              onChange={onCustomerLastNameChange}
                              value={customerLastName}
                              className="input-field"
                              placeholder="Enter customer last name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-2 text-center  mt-3">
                        <div className="row">
                          {loading ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <AtkLoader />
                            </div>
                          ) : (
                            //  : showContactErrorPopup ? (
                            //   <div className="col-12">
                            //     <h5 className="mb-1 text-danger text-14">{customerNameError}</h5>
                            //     To add contact in AMS &nbsp;
                            //     <a
                            //       className="text-14"
                            //       href={`https://newtest.nowcerts.com/AMSINS/Insureds/Details/${insuredClientId}/Information`}
                            //       target="_self"
                            //     >
                            //       <b>Click Here</b>
                            //     </a>
                            //   </div>
                            // ) :
                            // customerNameError.length > 0 ? null : (
                            <>
                              <div className="col-12 ">
                                <AtkButton label="Get Report" onClick={onNextButtonClick} className="pf-secondary-btn w-100 mt-2" />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {showPopUp ? (
                    <>
                      <div className="col-12 text-center">
                        <iframe src={pdfUrl} style={{ overflow: "scroll", height: "62vh", width: "99%" }} className="my-2 "></iframe>
                      </div>

                      <div className="col-12 text-end my-2 ">
                        <AtkButtonIconBefore label="Upload Again" onClick={onBackClick} className="pf-secondary-btn w-auto me-2 " />

                        <AtkButtonIconBefore
                          label="Feedback"
                          onClick={(e: any) => {
                            onFeedbackClick(e);
                          }}
                          className="pf-secondary-btn w-auto me-2 "
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
          <div className="row mx-auto d-flex align-items-center mb-2">
            <div className="col-12 feedback-header">
              <h4>Feedback</h4>
            </div>
            <div className={"form-group col-12 mb-2 text-12"}>
              <textarea
                name="feedback"
                onChange={(e) => onFeedbackChange(e.target.value)}
                value={feedBackData.feedback}
                className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                rows={4}
                placeholder="Enter your feedback here"
              />
              <span className="text-danger  text-12 mt-1">{feedBackError}</span>
            </div>
            <div className="col-4 d-flex justify-content-evenly">
              <i
                className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
                aria-hidden="true"
                onClick={(e) => onThumbsUpClick(feedBackData)}
              ></i>
              <i
                className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
                aria-hidden="true"
                onClick={(e) => onThumbDownClick(feedBackData)}
              ></i>
            </div>
            <div className="col-4 text-end p-2 pe-0  ">
              <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
            </div>
            <div className="col-4 text-end p-2  ">
              {feedbackLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <AtkLoader />
                </div>
              ) : (
                <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
              )}
            </div>
          </div>
        </OverlayPanel>
      </div>
      {/* <Footer /> */}
    </>
  );
}
