import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewDetailsIcon from "../../../src/assets/images/ViewDetailsIcon.png";
import CommonValues from "../../common/utils";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import FenrisSidebar from "./FenrisSidebar";
import Header from "../../common/Header";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import { Toast } from "primereact/toast";
import feedbackImg from "../../assets/images/feedback.svg";
import FenrisFeedBack from "../FeedBack/FenrisFeedback";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackgroundVideo from "../../common/BackgroundVideo";
import AtkButton from "../../AtlaskitControls/AtkButton";
export default function FenrisHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [fenrishistorydata, setFenrisHistoryData] = useState<any>([]);
  const [fenrisHistoryLoading, setFenrisHistoryLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [showExistingandFenrisPage, setShowExistingandFenrisPage] = useState(false);
  const [selectRowData, setSelectRowData] = useState("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [transactionId, setTransactionId] = useState("");
  const [isLike, setIsLike] = useState(false);
  const [isDisLike, setIsDisLike] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    getFenrisHistory(true);
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);
  useEffect(() => {}, [transactionId]);
  const getFenrisHistory = (loading: any) => {
    setFenrisHistoryLoading(loading);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/getfenrishistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setFenrisHistoryData(response.data);
        setFenrisHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting fenris history",
              life: 3000,
            });
          }
        }
        setFenrisHistoryLoading(false);
      });
  };

  const showDetailsAction = (rowData: any) => {
    if (rowData.status == "Success" || rowData.status == "Multiple Matches Found") {
      return (
        <>
          {actionLoading ? (
            <div className="d-flex">
              <AtkLoader />{" "}
            </div>
          ) : (
            <span className="coi-download-icon">
              <img
                src={ViewDetailsIcon}
                height="20"
                width="20"
                className="me-2 show-cursor"
                title="View Details"
                onClick={() => showDetails(rowData)}
              />
            </span>
          )}
        </>
      );
    } else {
      return <span className=" text-12 quote-not-span">Not Available</span>;
    }
  };
  const feedBackPopup = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const showDetails = (rowData: any) => {
    setShowPopUp(true);
    let rowData1 = rowData;
    setSelectRowData(rowData1);
    clientNameSet(rowData1.insuredName, true, true);
    setShowExistingandFenrisPage(true);
  };
  const clientNameSet = (clientName: any, textDisbaled: boolean, enrichBuutonDisbaled: boolean) => {
    // setClientName(clientName);
    // setSearchTextBoxDisbaled(textDisbaled);
    // setEnrichDataDisbaled(enrichBuutonDisbaled);
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setIsLike(rowData.isLike);
    setIsDisLike(rowData.isDisLike);
    setTransactionId(rowData.transactionId);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
    getFenrisHistory(false);
  };
  const FenrisBack = (e: any) => {
    setShowExistingandFenrisPage(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    getFenrisHistory(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="fenris-history-main-page">
        <Header pagename="fenris-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <FenrisMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <FenrisSidebar pagename="fenris-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-3 d-flex  align-items-end text-12 position-relative">
                  <div className="row">
                    <div className="col-6 mb-2">
                      <span className="p-input-icon-left w-50">
                        <i className="fa fa-search grid-search-icon" />
                        <AtkTextField
                          type="text"
                          id="txtfirstName"
                          name="globalFilter"
                          onChange={(e) => setGlobalFilter(e.target.value)}
                          value={globalFilter}
                          className="input-field"
                          placeholder="Search..."
                        />
                      </span>
                    </div>
                    <div className="col-6 text-end mb-2">
                      <AtkButton label="Refresh" onClick={() => getFenrisHistory(true)} className=" pf-primary-btn w-auto" />
                    </div>
                    {fenrisHistoryLoading ? (
                      <div className="d-flex justify-content-center align-items-center mt-5">
                        <AtkLoader />{" "}
                      </div>
                    ) : (
                      <div className="col-12  history-table">
                        <DataTable
                          value={fenrishistorydata}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          dataKey="id"
                          globalFilter={globalFilter}
                          emptyMessage="No data available."
                        >
                          <Column header="Insured Name" field="insuredName" sortable></Column>
                          <Column
                            header="Submitted Date"
                            field="created"
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.created);
                              var localDate = moment(utcdate).local();
                              return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                            }}
                            sortable
                          ></Column>
                          <Column header="Status" field="status" sortable></Column>
                          <Column header="Update In AMS" field="updatedInAMS" sortable></Column>
                          <Column header="Preview" body={showDetailsAction}></Column>
                          <Column header="Feedback" body={feedBackPopup}></Column>
                        </DataTable>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                <FenrisFeedBack transcationId={transactionId} onHideFeedbackPopup={onHideFeedbackPopup} isLike={isLike} isDisLike={isDisLike} />
              </OverlayPanel>

              <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                <ExistingAndFenrisData selectedData={selectRowData} FenrisBack={FenrisBack} fromHistoryPage="fromHistoryPage"></ExistingAndFenrisData>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
