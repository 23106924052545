import React, { useEffect, useRef, useState } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function IpfsSettings() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [accesscode, setAccesscode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessageLogin, setErrorMessageLogin] = useState("");
  const [errorMessageAccesscode, setErrorMessageAccesscode] = useState("");
  const [ipfsLoading, setIpfsLoading] = useState(false);
  const [deleteIpfsLoading, setDeleteIpfsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const toast: any = useRef("");
  const navigate = useNavigate();

  const onLoginChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setLogin(e.target.value);
  };
  const onAccesscodeChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAccesscode(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setPassword(e.target.value);
  };

  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessageLogin("");
    setErrorMessageAccesscode("");

    if (login == "" || login == null) {
      formIsValid = false;
      setErrorMessageLogin("Please enter login");
    }

    if (accesscode == "" || accesscode == null) {
      formIsValid = false;
      setErrorMessageAccesscode("Please enter accesscode");
    }

    return formIsValid;
  };
  const onIpfsResetClick = () => {
    setErrorMessageAccesscode("");
    setErrorMessageLogin("");
    setDeleteIpfsLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({ severity: "success", summary: 'Success', detail: "IPFS Settings deleted successfully", life: 3000 });
        setDeleteIpfsLoading(false);
        getIpfsCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the IPFS Settings" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the IPFS Settings" });
        }
        setDeleteIpfsLoading(false);
      });
  };
  const onIpfsUpdateClick = () => {
    if (checkValidation()) {
      setIpfsLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Login: login,
        Password: password,
        AccessCode: accesscode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setIpfsLoading(false);
          getIpfsCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error", summary: 'Error',

                detail: "It look likes you are not registered with ipfs, Please register first",
              });
            } else {
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the ipfs Settings" });
            }
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the ipfs Settings" });
          }
          setIpfsLoading(false);
        });
    }
  };
  const getIpfsCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.status == 204) {
          setLogin("");
          setPassword("");
          setAccesscode("");
        } else {
          setLogin(response.data.login);
          setPassword(response.data.password);
          setAccesscode(response.data.accessCode);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the ipfs Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the ipfs Setting details" });
        }

        setLoading(false);
      });
  };
  const onRegisterClick = () => {
    window.open("https://www.ipfs.com/contact?utm_campaign=InsuranceGIG&utm_source=browserextension&utm_term=partnership", "_blank");
  };
  const onFindAccessCodeClick = () => {
    window.open("https://www.ipfs.com/account-help-contact-form#web-access-code", "_blank");
  };
  return (
    <div className="row mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          <div className="form-group  col-4  mb-2">
            <label className="form-label">
              Login
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessageLogin.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="login"
              name="ipfsLogin"
              placeholder="Enter login"
              onChange={onLoginChange}
              value={login}
            />
            <span className="text-danger text-12">{errorMessageLogin}</span>
          </div>

          {/* <div className="form-group  col-4 mb-2 ">
            <label className="form-label">Password</label>
          </div>
          <div className=" col-8 position-relative mb-2">
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="password"
              name="ipfsSetupPassword"
              placeholder="Enter password"
              onChange={onPasswordChange}
              value={password}
              autoComplete="new-password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div> */}

          <div className="form-group  col-4  mb-2">
            <label className="form-label">
              AccessCode
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessageAccesscode.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="accesscode"
              name="accesscode"
              placeholder="Enter access code"
              onChange={onAccesscodeChange}
              value={accesscode}
            />
            <span className="text-danger text-12">{errorMessageAccesscode}</span>
          </div>
        </>
      )}

      <>
        <div className="col-12  text-end mb-2 mt-2">
          {deleteIpfsLoading ? (
            <AtkLoader></AtkLoader>
          ) : (
            <AtkButton label="Reset" onClick={onIpfsResetClick} className="pf-secondary-btn w-auto me-2 mb-2" />
          )}

          {ipfsLoading ? (
            <AtkLoader></AtkLoader>
          ) : (
            <AtkButton label="Save" onClick={onIpfsUpdateClick} className="pf-secondary-btn w-auto me-2 mb-2" />
          )}

          <AtkButton label="Register" onClick={onRegisterClick} className="pf-secondary-btn w-auto me-2 mb-2" />

          <AtkButton label="Find Access Code" onClick={onFindAccessCodeClick} className="pf-primary-btn w-auto mb-2" />
        </div>
      </>
    </div>
  );
}
