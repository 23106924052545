import { ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

function AddPaymentCredits(props: any) {
  const toast: any = useRef("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);
  const [userHasPaymentMethod, setUserHasPaymentMethod] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [errorPaymentAmount, setErrorPaymentAmount] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [servicesListLoading, setServicesListLoading] = useState(false);
  const [servicesList, setServicesList] = useState([]);

  const { stripe, elements } = props;
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentBalance();
    getCardDetails();
    getServicesPerCallList();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getServicesPerCallList = () => {
    var token = CommonValues.GetToken();
    setServicesListLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/services-percall`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setServicesList(response.data.apiBilling);
        setServicesListLoading(false);
      })
      .catch((error: any) => {
        setServicesListLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting services list",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting services list",
            life: 3000,
          });
        }
      });
  };
  const getCurrentBalance = () => {
    setCardDetailsLoading(true);

    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const getCardDetails = () => {
    setCardDetailsLoading(true);
    setErrorPaymentAmount("");
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/card-details`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setPaymentMethodId(response.data.stripeCardDetails.paymentMethodId);
          if (
            response.data.stripeCardDetails.cardNumber === null &&
            response.data.stripeCardDetails.expiryMonth === null &&
            response.data.stripeCardDetails.expiryYear === null
          ) {
            setUserHasPaymentMethod(false);
          } else {
            setUserHasPaymentMethod(true);
          }
        }

        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while getting card details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error while getting card details",
            life: 3000,
          });
        }
      });
  };
  const confirmPaymentIntent = async (clientSecret: string) => {
    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
      .then((response: any) => {
        if (response.paymentIntent) {
          saveStripePaymentHistory(response);
        } else if (response.error) {
          saveFailedStripePayment(response);
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
      });
  };
  const createPaymentIntent = async (paymentAmount: any) => {
    var token = CommonValues.GetToken();
    let data = JSON.stringify({
      Amount: parseInt(String(Number(paymentAmount) * 100)),
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-intent`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        confirmPaymentIntent(response.data.clientSecret);
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error occured while creating payment intent.",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error occured while creating payment intent.",
            life: 3000,
          });
        }
      });
  };
  const saveFailedStripePayment = ({ error }: any) => {
    let token = CommonValues.GetToken();
    let data = JSON.stringify({
      amount: parseInt(String(error.payment_intent.amount)),
      paymentIntentId: error.payment_intent.id,
      status: error.payment_intent.status,
      failureMessage: error.message,
      responseJSON: JSON.stringify(error),
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error", summary: 'Error',
          detail: error.message,
          life: 3000,
        });
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error occured while saving failed stripe payment history",
            life: 3000,
          });
        }
      });
  };
  const saveStripePaymentHistory = ({ paymentIntent }: any) => {
    let token = CommonValues.GetToken();
    let data = JSON.stringify({
      amount: parseInt(String(paymentIntent.amount)),
      paymentIntentId: paymentIntent.id,
      status: paymentIntent.status,
      responseJSON: JSON.stringify(paymentIntent),
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        setSubmitLoading(false);
        if (paymentIntent.status === "succeeded") {
          getCurrentBalance();
          setPaymentAmount("");
          toast.current.show({
            severity: "success", summary: 'Success',
            detail: "Credit amount has been added successfully",
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error", summary: 'Error',
            detail: "Unknown error occured while saving successfull stripe payment history",
            life: 3000,
          });
        }
      });
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (paymentAmount) {
      setErrorPaymentAmount("");
      if (!stripe || !elements) {
        return;
      }
      if (parseFloat(paymentAmount) <= 0) {
        setSubmitLoading(false);
        setErrorPaymentAmount("Credit amount should be greater than 0 dollars.");
      } else if (parseFloat(paymentAmount) > 100) {
        setSubmitLoading(false);
        setErrorPaymentAmount("Credit amount should be less than 100 dollars.");
      } else {
        createPaymentIntent(paymentAmount);
      }
      setPaymentAmount("");
    } else {
      setSubmitLoading(false);
      setErrorPaymentAmount("Please enter credit amount");
    }
  };

  const onPaymentAmountChange = (e: any) => {
    setPaymentAmount(e.target.value);
  };

  const handleAddPaymentMethod = () => {
    navigate("/paymentmethod");
  };
  const isAvailableTemplate = () => {
    return (
      <div className="round">
        <input type="checkbox" checked id="checkbox" />
        <label></label>
      </div>
    );
  };
  const costTemplate = (rowData: any) => {
    return rowData.costInCent == 0 ? (
      <span className="text-info">
        <b>Free</b>
      </span>
    ) : (
      <span>{(rowData.costInCent / 100).toFixed(2)}</span>
    );
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-details">
        <div className="row client-search-row ">
          <div className="col-12 d-flex align-items-center justify-content-end">
            <div className="row w-100 d-flex align-items-center justify-content-end">
              <div className="col-2 text-center balance-span">
                <label>Current Balance</label>
                {cardDetailsLoading ? (
                  <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                    <AtkLoader />
                  </span>
                ) : (
                  <span className=" d-block w-auto ">${currentBalance}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-2"></div> */}
          <div className="col-12">
            <div className="card mt-2">
              <div className="card-header">
                <div className="row  d-flex  align-items-center w-100">
                  <div className="col-6 ">
                    <b className="fs-5">Buy Credits</b>
                  </div>
                </div>
              </div>
              <div className="card-body ">
                <div className="row d-flex justify-content-center">
                  {/* <div className="col-3"></div> */}
                  <div className="" style={{width:"fit-content"}}>
                    {cardDetailsLoading ? (
                      <div className="text-center mt-4">
                        <AtkLoader />
                      </div>
                    ) : (
                      <>
                        {userHasPaymentMethod ? (
                          <>
                            <div
                              className={`col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title ${
                                errorPaymentAmount.length > 0 ? "validation-div" : ""
                              }`}
                            >
                              <label>
                                <b>Credit Amount</b>
                              </label>
                            </div>
                            <div className={`col-lg-12 col-md-12 col-sm-12 mt-2 ${errorPaymentAmount.length > 0 ? "validation-div" : ""}`}>
                              <div className="input-group ">
                                <span className="input-group-text">$</span>
                                <AtkTextField
                                  className="input-field form-control p-0"
                                  type="number"
                                  minimum="1"
                                  id="text"
                                  value={paymentAmount}
                                  onChange={onPaymentAmountChange}
                                  placeholder="Enter credit amount"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-start">
                                <span className="text-danger small">{errorPaymentAmount}</span>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                    <>
                      {userHasPaymentMethod ? (
                        <>
                          <div className="row d-flex align-items-center mt-4">
                          <div className="col-md-12 text-end">
                              {" "}
                              <span className="me-3 text-dark">Your current balance will be <b>${parseFloat(paymentAmount || "0") + parseFloat(currentBalance || "0")}</b></span>
                              {submitLoading ? (
                                <AtkLoader />
                              ) : (
                                <AtkButton className="pf-primary-btn w-auto" label="Buy Credits" type="submit" onClick={handleSubmit}></AtkButton>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>

                    {cardDetailsLoading ? null : userHasPaymentMethod ? null : (
                      <div className="row">
                        <div className="co-12">
                          <AtkMessage messageText="User does not have a payment method for buying credits." />
                        </div>
                        <div className="col-12 mt-4 text-end mt-2">
                          <AtkButton
                            className="pf-primary-btn w-auto mt-3"
                            label="Add Payment Method"
                            type="button"
                            onClick={handleAddPaymentMethod}
                          ></AtkButton>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3 mb-2">
              <div className="card-header">
                <div className="row  d-flex  align-items-center w-100">
                  <div className="col-6 ">
                    <b className="fs-5">Service Pricing</b>
                  </div>
                </div>
              </div>
              <div className="card-body  history-table">
                {servicesListLoading ? (
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <AtkLoader />
                  </div>
                ) : (
                  <DataTable rows={6} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" value={servicesList}>
                    <Column
                      header="Service"
                      field="providerName"
                      style={{ width: "70%" }}
                      body={(rowData) => {
                        return (
                          <span>
                            {rowData.providerName === "FENRIS"
                              ? "Data Enrichment"
                              : rowData.providerName === "VEHICLE-CARRIER-SEARCH"
                              ? "Vehicle Prefill"
                              : rowData.providerName === "PF-SCAN"
                              ? "PremFi (PFA) Scan"
                              : rowData.providerName === "COMMISSION-SCAN"
                              ? "Commission Scan"
                              : rowData.providerName === "NOWCERTS-COI-SCAN"
                              ? "Certificate of Insurance Scan"
                              : rowData.providerName === "ACORD-FORM-SCAN"
                              ? "Acord and Dec pages Scan"
                              : rowData.providerName === "NAIC-CLASSIFICATION"
                              ? "Classification Codes (NAICS)"
                              : rowData.providerName === "VALCHOICE"
                              ? "Carrier Rating Service"
                              : rowData.providerName === "POLICYCOMPARISON"
                              ? "Policy Comparison"
                              : rowData.providerName === "RATE RISK APPETITE"
                              ? "Rate Risk Appetite"
                              : rowData.providerName === "PREMIUM-FINANCE"
                              ? "PremFi"
                              : rowData.providerName}
                          </span>
                        );
                      }}
                    ></Column>
                    <Column header="Cost Per Usage (USD)" body={costTemplate} field="costInCent"></Column>
                    <Column header="Is Available" body={isAvailableTemplate}></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-2"></div> */}
        </div>
      </div>
    </>
  );
}

export default function InjectedStripePaymentCredits({ onTabSelect }: any) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => <AddPaymentCredits stripe={stripe} elements={elements} onTabSelect={onTabSelect} />}
    </ElementsConsumer>
  );
}
