import React from 'react';
import Button, { ButtonProps } from '@atlaskit/button';
// import '@atlaskit/css-reset';

interface AtkButtonProps extends ButtonProps {
  label: string;
  className?: string;
  onClick?: () => void;
  appearance?: 'primary' | 'default' | 'danger' | 'link' | 'subtle' | 'subtle-link';

}

export default function AtkButton(props: AtkButtonProps) {
  const { onClick, label, className,style } = props;

  return (
    <Button
      appearance={props.appearance}
      onClick={onClick}
      className={className}
      shouldFitContainer
      style={style}
    >
      {label}
    </Button>
  );
}
