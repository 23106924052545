import PFScanHistory from "./PFScanHistory";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import PrimaryDetailsPage from "./PrimaryDetailsPage";
import PFScanMiddleHeader from "./PFScanMiddleHeader";
import PFScanSidebar from "./PFScanSidebar";
import BackgroundVideo from "../../common/BackgroundVideo";

export default function () {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showNewPFScan, setShowNewPFScan] = useState(true);
  const [showPFScanHistory, setShowPFScanHistory] = useState(false);
  const hostname = window.location.hostname;

  useEffect(() => {
    if (hostname !== process.env.REACT_APP_Momentum_Url1 && hostname !== process.env.REACT_APP_Momentum_Url2) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
  });
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="pfscan-main-page">
        <Toast ref={toast} />
        <Header pagename="pfscan-landing-page" />
        <div className="content-wrapper">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <PFScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto ">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <PFScanSidebar pagename="pfscan-landing-page" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row   d-flex  align-items-end text-12 position-relative">
                  {showNewPFScan ? (
                    <div className="col-12 p-0">
                      <PrimaryDetailsPage />
                      {/* <PFOcrScan
                      submissionIdFromHistory={submissionIdFromHistory}
                      isSubmissionIdFromHistory={isSubmissionIdFromHistory}
                    /> */}
                    </div>
                  ) : null}
                  {showPFScanHistory ? (
                    <div className="col-12">
                      <PFScanHistory />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
