import { useEffect, useState, useRef } from "react";
import momentumailoading from "../assets/images/Support-Bot/momentum-ai-loading.gif";
import { Guid } from "js-guid";
import AccessDenied from "../assets/images/not-allowed-symbol.svg";
import axios from "axios";
import CommonValues from "../common/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import MomentumLogo from "../assets/images/Support-Bot/momentumAI_Full_Logo_Bk.png";

export default function MomentumAiLoginPage() {
  const [loading, setLoading] = useState(true);
  const toast: any = useRef("");
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const trackClickForGenerateQuote = searchParams.get("trackClickForGenerateQuote");
  const widgetName = searchParams.get("widget");
  const policyid = searchParams.get("policyid");
  const hostname = window.location.hostname;
  useEffect(() => {
    if (
      hostname !== process.env.REACT_APP_Momentum_Url1 &&
      hostname !== process.env.REACT_APP_Momentum_Url2 &&
      hostname !== process.env.REACT_APP_Momentum_Url3 &&
      hostname !== process.env.REACT_APP_Momentum_Url4
    ) {
      navigate("/not-allowed");
    }
  }, [hostname, navigate]);
  useEffect(() => {
    CommonValues.ClearAllValues();
    validateNowcertsToken(token, trackClickForGenerateQuote == null ? false : trackClickForGenerateQuote, policyid == null ? "" : policyid);
  }, []);

  const validateNowcertsToken = (token: any, trackClickForGenerateQuote: any, policyid: any) => {
    setLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/validateNowcertsToken?token=${token}&trackClick=${trackClickForGenerateQuote}&policyid=${policyid}`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.token === "") {
          CommonValues.Logout(navigate);
        } else {
          localStorage.setItem("SocialLogin", "true");
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("userName", response.data.userName);
          localStorage.setItem("RoleId", response.data.roleId);
          localStorage.setItem("NowcertsAgentId", response.data.nowCertsAgentId);
          localStorage.setItem("AvatarName", response.data.avatarName);

          getNcToken(response.data.userName);
          if (window.userGuiding) {
            window.userGuiding.identify(response.data.userId, {
              email: response.data.userName,
              name: response.data.firstName + "" + response.data.lastName,
              created_at: Date.now(),
            });
          } else {
            console.error("UserGuiding is not loaded yet.");
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();
          }
        } else {
          toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting details!", life: 3000 });
        }
      });
  };
  const getNcToken = (ncSetupUserName: any) => {
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      NCUserName: ncSetupUserName,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" && response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          localStorage.setItem("NCAgencyId", response.data.agencyId);

          if (
            widgetName === "ai-support" ||
            widgetName === "ai-scans" ||
            widgetName === "ai-calls" ||
            widgetName === "ai-report" ||
            widgetName === "ai-data-discovery"
          ) {
            getWizardSetupDetails();
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();

          } else if (error.response.status === 409) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: "It look likes you are not registered with NowCerts, Please register first.",
              life: 3000,
            });
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
        }
      });
  };
  const getWizardSetupDetails = () => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          getNowCertsAgentDetails();
          insertWizardDetails(true);
        } else {
          if (
            widgetName === "ai-support" ||
            widgetName === "ai-scans" ||
            widgetName === "ai-calls" ||
            widgetName === "ai-report" ||
            widgetName === "ai-data-discovery"
          ) {
            updateWizardSetupState();
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        setLoading(false);

        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();

          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
        }
      });
  };

  const getNowCertsAgentDetails = () => {
    // clearValues();
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let nowCertsAgencyId = localStorage.getItem("NCAgencyId") ? localStorage.getItem("NCAgencyId") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencyinfo?nowCertsToken=${nowCertsToken}&nowCertsAgencyId=${nowCertsAgencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.agencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipCode,
          agencyCountry: "USA",
          agencyPhone: response.data.phone,
        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        // clearValues();
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();
          } else if (error.response.status === 409) {
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    // if (checkValidation()) {

    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone: agencyData.agencyPhone,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        setLoading(false);
        // setInvalidCredMessage("");

        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", summary: "Error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }
      });
    // }
  };
  const updateWizardSetupState = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.success === true) {
          localStorage.setItem("isSetupCompleted", "true");
          localStorage.removeItem("currentPage");
          if (widgetName === "ai-support") {
            navigate(`/ai-support-assistant/${Guid.newGuid()}`);
          } else if (widgetName === "ai-scans") {
            navigate(`/ai-scans/${Guid.newGuid()}`);
          } else if (widgetName === "ai-calls") {
            navigate(`/ai-call-assistant/${Guid.newGuid()}`);
          } else if (widgetName === "ai-report") {
            navigate("/report-assistant");
          } else if (widgetName === "ai-data-discovery") {
            navigate("/datadiscovery");
          } else {
            navigate("/landingpage");
          }
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
      });
  };
  const insertWizardDetails = (val: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertwizardsetup?IsWizardSetupDone=${val}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.data.isWizardSetupDone) {
          if (widgetName === "ai-support") {
            navigate(`/ai-support-assistant/${Guid.newGuid()}`);
          } else if (widgetName === "ai-scans") {
            navigate(`/ai-scans/${Guid.newGuid()}`);
          } else if (widgetName === "ai-calls") {
            navigate(`/ai-call-assistant/${Guid.newGuid()}`);
          } else if (widgetName === "ai-report") {
            navigate("/report-assistant");
          } else if (widgetName === "ai-data-discovery") {
            navigate("/datadiscovery");
          } else {
            navigate("/landingpage");
          }
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);
        if (error.response !== null) {
          if (error.response.status === 401) {
            CommonValues.ClearAllValues();

          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", summary: "Error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  return (
    <div className="ai-wrapper min-vh-100 d-flex align-items-center justify-content-center">
      <div className="ai-wrapper-container container-fluid position-relative z-1">
        <div className="row g-4 justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="ai-login-box bg-white p-4 rounded shadow text-center">
            {loading ? (
                <>
                  {/* <h1 className="">Momentum AI </h1> */}
                  <img src={MomentumLogo} height={60} />

                  <h3>Launching</h3>
                  <div className="d-flex  justify-content-center">
                    <img src={momentumailoading} height={48} />
                  </div>
                </>
              ) : (
                <>
                  <img src={AccessDenied} height={35} width={35} />
                  <p className="not-authorize-text">
                    <b>You are not authorized</b>
                  </p>
                  <p className="d-block">
                    It seems like you don't have permission to use this portal.
                    <br /> Please sign in with a different account.{" "}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
