import React, { FC, useEffect, useState, useRef } from "react";
import ArchiveLogo from "../../assets/images/archive-icon.png";
import DuplicateLogo from "../../assets/images/duplicate.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ExistingPolicyDetails from "./ExistingPolicyDetails";
import AtkButton from "../../AtlaskitControls/AtkButton";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import moment from "moment";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import SectionMessage from "@atlaskit/section-message";
import { Navigate, useNavigate } from "react-router-dom";
import { toHaveErrorMessage } from "@testing-library/jest-dom/matchers";
import { DataView } from "primereact/dataview";
import { Card } from "primereact/card";

export default function ExistingPolicies(props: any) {
  const navigate = useNavigate();
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [hawkSoftPolicyData, setHawkSoftPolicyData] = useState<any>({});
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [vertaforePolicyData, setVertaforePolicyData] = useState<any>([]);
  const [policies, setPolicies] = useState<any>([]);
  const [indicativePolicies, setIndicativePolicies] = useState<any>([]);
  const [loadingNowCerts, setLoadingNowCerts] = useState(false);
  const [archivePolicyLoader, setArchivePolicyLoader] = useState(false);
  const [indicativedata, setindicativedata] = useState<any>({});
  const [transid, setTransid] = useState("");
  const toast: any = useRef("");
  const archiveRef = useRef<OverlayPanel>(null);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [agencyId, setAgencyId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [dbaName, setDbaName] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [duplicateDataLoading, setDuplicateDataLoading] = useState(false);
  const [policyNumberForDuplicateLoading, setPolicyNumberForDuplicateLoading] = useState("");
  const [businessData, setBusinessData] = useState<any>([]);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<
    { id: any; index: any; code: any; clientName: any; ncCarrierName: any; ncGaName: any, ncCoverageCode: any }[]
  >([]);
  const [selectedHsPolicyData, setSelectedHsPolicyData] = useState([]);
  const [vertaforeWholePolicyData, setVertaforeWholePolicyData] = useState<any>([]);
  const [clientNameForMomentumPolicy, setClientNameForMomentumPolicy] = useState("");
  const [momentumPolicyId, setMomentumPolicyId] = useState("");
  const [enableQuickQuote, setEnableQuickQuote] = useState(false);
  const hostname = window.location.hostname;
  const [enableQuickQuoteLoading, setEnableQuickQuoteLoading] = useState(false);
  const [rows, setRows] = useState(3);
  const [first, setFirst] = useState(0);
  const [isQuotePage, setIsQuotePage] = useState(false);

  const handleClientNameChange = (name: any) => {
    setClientNameForMomentumPolicy(name);
  };
  useEffect(() => {
    if (props.momentumPolicyId != "" && props.momentumPolicyId != null) {
      setMomentumPolicyId(props.momentumPolicyId);
      setTimeout(() => {
        window.history.replaceState(null, "", window.location.pathname);
      }, 500);
    }
    if (props.reuseSavedQuoteId != null && props.reuseSavedQuoteId != "") {
      setShowPolicyDetails(true);
    }
    else {
      if (CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id) {
        getHawksoftClient(props.clientId);
        // getNewOrDuplicatePoliciesList(props.clientId);
      } else if (CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id) {
        getCustomerDetailsAndPolicyDetailsByCustomerId(props.clientId, false, null);
      } else if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
        setTimeout(() => {
          if (props.type == "0") {
            if (props.momentumPolicyId != "" && props.momentumPolicyId != null && props.momentumWidgetName === "premfi") {
              getEnableQuickQuoteResponseById();
            } else if (props.momentumPolicyId != "" && props.momentumPolicyId != null && props.momentumWidgetName === "newpremfi") {
              setShowPolicyDetails(true);
            } else if (props.momentumInsuredId != "" && props.momentumInsuredId != null) {
              getNowCertsPoliciesList(props.momentumInsuredId);
            } else {
              getNowCertsPoliciesList(props.clientId);
            }
          }
          // else {
          //   getNewOrDuplicatePoliciesList(props.clientId);
          // }
        }, 20);
      }
    }
    if (props.type == "1") {
      getNewOrDuplicatePoliciesList(props.clientId);
    }

  }, [props.clientId, props.clientName]);
  const onMultiPolicyClick = (clientId: any) => {
    setShowPolicyDetails(false);
    setMomentumPolicyId("");
    getNowCertsPoliciesList(clientId);
  };
  const getEnableQuickQuoteResponseById = () => {
    setEnableQuickQuoteLoading(true);

    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/enablequickquote`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setEnableQuickQuote(response.data);
        setShowPolicyDetails(true);
        setEnableQuickQuoteLoading(false);
      })
      .catch((error: any) => {
        setEnableQuickQuoteLoading(false);

        setShowPolicyDetails(true);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the preferred vendor list by id" });
          }
        }
      });
  };
  const handleRowSelect = (e: any) => {
    localStorage.removeItem("policyIds");
    if (!(e.originalEvent?.target?.tagName?.toLowerCase() === "img")) {
      if (hawkSoftPolicyData.source === "fileupload") {
        localStorage.setItem("FromUploadFileResponse", "true");
        localStorage.setItem("savedPdfFile", "true");
        localStorage.setItem("savePdfIndicative", "true");
      }

      if (props.type === "0") {
        let policyData = {};

        switch (CommonValues.GetTenantId()) {
          case process.env.REACT_APP_NowCerts_Tenant_Id:
            policyData = {
              PolicyId: e.value.databaseId,
              databaseId: e.value.databaseId,
              PolicyData: nowCertPolicyData,
              SelectedPolicyIndex: nowCertPolicyData.findIndex((element: any) => element.databaseId === e.value.databaseId),
              DisplayOrder: e.value.displayOrder,
              HsPolicyIndex: nowCertPolicyData.findIndex((element: any) => element.databaseId === e.value.databaseId),
            };
            break;
          case process.env.REACT_APP_Hawksoft_Tenant_Id:
            policyData = {
              PolicyId: e.value.id,
              databaseId: e.value.id,
              PolicyData: hawkSoftPolicyData,
              SelectedPolicyIndex: hawkSoftPolicyData.policies.findIndex((element: any) => element.id === e.value.id),
              DisplayOrder: e.value.displayOrder,
              HsPolicyIndex: hawkSoftPolicyData.policies.findIndex((element: any) => element.id === e.value.id),
            };
            break;
          case process.env.REACT_APP_Vertafore_Tenant_Id:
            policyData = {
              PolicyId: e.value.id,
              databaseId: e.value.CustomerId,
              PolicyData: vertaforePolicyData,
              SelectedPolicyIndex: vertaforePolicyData.findIndex((element: any) => element.id === e.value.PolicyId),
              DisplayOrder: e.value.displayOrder,
              HsPolicyIndex: vertaforePolicyData.findIndex((element: any) => element.id === e.value.PolicyId),
            };
            break;
          default:
            break;
        }

        localStorage.setItem("policyData", JSON.stringify(policyData));
        localStorage.setItem("IsIndicative", "false");
      } else {
        const policyData = {
          sqlId: e.value.id,
          PolicyData: e.value,
          SelectedPolicyIndex: 0,
          DisplayOrder: 0,
          HsPolicyIndex: 0,
        };
        localStorage.setItem("policyData", JSON.stringify(policyData));
        localStorage.setItem("IsIndicative", "true");
        localStorage.setItem("GlobalIndicative", "true");
      }

      if (CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id) {
        setSelectedPolicy(e.value.PolicyId);
      } else {
        setSelectedPolicy(e.value.databaseId);
      }

      setTimeout(() => {
        setShowPolicyDetails(true);
      }, 10);
    } else {
      if (props.type === "0") {
        if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
          setPolicyNumberForDuplicateLoading(e.value.databaseId);
        } else {
          setPolicyNumberForDuplicateLoading(e.value.id);
        }
        setDuplicateDataLoading(true);
        props.onDuplicatePolicyClick(e.value, businessData);
      }
    }
  };

  const onSelectMultiplePolicies = (rowData: any, index: any) => {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value=""
            checked={
              CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id
                ? selectedPolicyIds.some((item) => item.id === rowData.databaseId)
                : selectedPolicyIds.some((item) => item.id === rowData.id)
            }
            onChange={() => onSelectedMultiplePolicyChange(rowData, index)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };
  const currentPremiumBody = (rowData: any) => {
    return <>
      {rowData.currentPremium != "" && rowData.currentPremium != null && rowData.currentPremium != 0 ?
        rowData.currentPremium
        :
        rowData.quotedPremium
      }
    </>
  }
  const onSelectedMultiplePolicyChange = (rowData: any, index: any) => {
    const code = rowData.title;
    let isSelected;

    if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
      isSelected = selectedPolicyIds.some((item) => item.id === rowData.databaseId);
    } else {
      isSelected = selectedPolicyIds.some((item) => item.id === rowData.id);
    }
    if (isSelected) {
      let updatedSelectedPolicyIds;
      let policyDataString = localStorage.getItem("policyData");
      let policyData = policyDataString ? JSON.parse(policyDataString) : {};
      let updatedPolicyData;
      if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
        updatedSelectedPolicyIds = selectedPolicyIds.filter((item) => item.id !== rowData.databaseId);
      } else {
        updatedSelectedPolicyIds = selectedPolicyIds.filter((item) => item.id !== rowData.id);
      }
      setSelectedPolicyIds(updatedSelectedPolicyIds);
      localStorage.setItem("policyIds", JSON.stringify(updatedSelectedPolicyIds));

      if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id) {
        updatedPolicyData = policyData.PolicyData.filter((item: any) => item.id !== rowData.databaseId);
      } else if (CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id) {
        updatedPolicyData = policyData.PolicyData.filter((item: any) => item.id !== rowData.id);
      } else {
        updatedPolicyData = policyData.PolicyData.policies.filter((item: any) => item.id !== rowData.id);
      }
      policyData.PolicyData.policies = updatedPolicyData;
      localStorage.setItem("policyData", JSON.stringify(policyData));
    } else {
      if (CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id) {
        let setIndex: number;

        if (window.innerWidth > 767) {
          setIndex = index.rowIndex;
        } else {
          setIndex = index;
        }
        setSelectedPolicyIds([
          ...selectedPolicyIds,
          { id: rowData.id, index: setIndex, code: code, clientName: rowData.clientName, ncCarrierName: "", ncGaName: "", ncCoverageCode: "" },
        ]);
        localStorage.setItem(
          "policyIds",
          JSON.stringify([...selectedPolicyIds, { id: rowData.id, index: setIndex, code, clientName: rowData.clientName }])
        );

        let policyData = {};
        policyData = {
          PolicyId: rowData.id,
          databaseId: vertaforeWholePolicyData.policies.PolicyInfoList[0].CustomerId,
          PolicyData: vertaforePolicyData,
          SelectedPolicyIndex: vertaforePolicyData.findIndex((element: any) => element.id == rowData.id),
          DisplayOrder: rowData.displayOrder,
          HsPolicyIndex: vertaforePolicyData.findIndex((element: any) => element.id == rowData.id),
        };

        localStorage.setItem("policyData", JSON.stringify(policyData));
      } else if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
        let setIndex: number;

        if (window.innerWidth > 767) {
          setIndex = index.rowIndex;
        } else {
          setIndex = index;
        }
        setSelectedPolicyIds([
          ...selectedPolicyIds,
          {
            id: rowData.databaseId,
            index: setIndex,
            code: code,
            clientName: rowData.clientName,
            ncCarrierName: rowData.carrierName,
            ncGaName: rowData.mgaName,
            ncCoverageCode: rowData.linesOfBusiness.length > 1 ? "Commercial Package" : rowData.linesOfBusiness[0]
          },
        ]);
        localStorage.setItem(
          "policyIds",
          JSON.stringify([
            ...selectedPolicyIds,
            {
              id: rowData.databaseId,
              index: setIndex,
              code,
              clientName: rowData.clientName,
              ncCarrierName: rowData.carrierName,
              ncGaName: rowData.mgaName,
              ncCoverageCode: rowData.linesOfBusiness.length > 1 ? "Commercial Package" : rowData.linesOfBusiness[0]
            },
          ])
        );
        let policyData = {};
        policyData = {
          PolicyId: nowCertPolicyData.databaseId,
          databaseId: nowCertPolicyData.databaseId,
          PolicyData: nowCertPolicyData,
          SelectedPolicyIndex: nowCertPolicyData.findIndex((element: any) => element.databaseId == rowData.databaseId),
          DisplayOrder: rowData.displayOrder,
          HsPolicyIndex: nowCertPolicyData.findIndex((element: any) => element.databaseId == rowData.databaseId),
        };

        localStorage.setItem("policyData", JSON.stringify(policyData));
      } else {
        let newHsSelectedPolicies: any = [...selectedHsPolicyData];
        let selectedPolicyonIndx: any;

        if (window.innerWidth > 767) {
          selectedPolicyonIndx = policies[index.rowIndex];
        } else {
          selectedPolicyonIndx = policies[index];
        }
        newHsSelectedPolicies = newHsSelectedPolicies.filter((s: { id: any }) => s.id != selectedPolicyonIndx.id);
        newHsSelectedPolicies.push(selectedPolicyonIndx);
        setSelectedHsPolicyData(newHsSelectedPolicies);

        let policyData = {};
        policyData = {
          PolicyData: {
            id: hawkSoftPolicyData.id,
            addresses: hawkSoftPolicyData.addresses,
            businessProfiles: hawkSoftPolicyData.businessProfiles,
            personalProfiles: hawkSoftPolicyData.personalProfiles,
            policies: newHsSelectedPolicies,
          },
          SelectedPolicyIndex: 0,
          HsPolicyIndex: 0,
        };

        localStorage.setItem("policyData", JSON.stringify(policyData));
        localStorage.setItem("IsIndicative", "false");
        setSelectedPolicyIds([
          ...selectedPolicyIds,
          { id: rowData.id, index: index.rowIndex, code: code, clientName: rowData.clientName, ncCarrierName: "", ncGaName: "", ncCoverageCode: "" },
        ]);
        localStorage.setItem(
          "policyIds",
          JSON.stringify([...selectedPolicyIds, { id: rowData.id, index: index.rowIndex, code: code, clientName: rowData.clientName }])
        );
      }
    }
  };

  const handleSkip = () => {
    if (archiveRef.current) {
      archiveRef.current.hide();
    }
  };
  const handleArchiveToggle = (e: any, rowData: any) => {
    setTransid(rowData.transactionId);
    setTimeout(() => {
      archiveRef.current?.toggle(e);
    }, 100);
  };
  const onClickArchiveQuote = () => {
    // setTransid(data.transactionId);
    setArchivePolicyLoader(true);
    if (archiveRef.current) {
      archiveRef.current.hide();
    }
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/archivequotes/${transid}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response: any) => {
        getNewOrDuplicatePoliciesList(props.clientId);
        setArchivePolicyLoader(false);
        toast.current.show({
          severity: "success", summary: 'Success',
          detail: "Policy Archived Successfully!",
          life: 3000,
        });
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error", summary: 'Error',
              detail: "Unknown error while archiving policy.",
              life: 3000,
            });
          }
        }
      });
  };
  const handleDuplicateRowSelect = (e: any) => {
    if (!(e.originalEvent.target.tagName.toLowerCase() === "img")) {
      localStorage.setItem("IsIndicative", "true");
      setindicativedata(e.value);
      setTimeout(() => {
        setShowPolicyDetails(true);
      }, 10);
    }
  };
  const onBackClick = () => {
    if (isQuotePage) {
      setIsQuotePage(false);
    }
    else {
      localStorage.removeItem("policyIds");
      setSelectedHsPolicyData([]);
      setSelectedPolicyIds([]);
      setMomentumPolicyId("");
      if (props.type == 1) {
        getNewOrDuplicatePoliciesList(props.clientId);
      }
      setShowPolicyDetails(false);
      localStorage.removeItem("IsIndicative");
      localStorage.removeItem("isNewPolicy");
      localStorage.removeItem("currentPage");
      // localStorage.removeItem("policyData");
      if (props.type == 0) {
        let storedData = localStorage.getItem("client");
        let clianttlist = storedData ? JSON.parse(storedData) : null;
        if (clianttlist && CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
          getNowCertsPoliciesList(clianttlist.clientId);
        } else if (CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          getHawksoftClient(clianttlist.clientId);
        }
      }
    }
  };
  const getHawksoftClient = (client: any) => {
    var data = {
      AgencyId: agencyId,
      ClientId: props.clientId,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_HAWKSOFTAPI}/api/Hawksoft/client`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    //setClientLoading(true);
    axios(config)
      .then((response) => {
        setHawkSoftPolicyData(response.data);
        //setSelectedClient(response.data);
        let businessData = [];

        if (response.data.businessProfiles.length > 0) {
          const businessProfile = response.data.businessProfiles[0];
          const businessInfo = {
            businessName: businessProfile.name,
            address: response.data.addresses.length > 0 ? response.data.addresses[0] : "",
            phoneNumber: "",
            email: "",
          };

          businessData.push(businessInfo);

          setBusinessName(response.data.businessProfiles[0].name);
          setBusinessAddress(response.data.addresses[0]);
          setDbaName(response.data.businessProfiles[0].dbaName);
        }
        setBusinessData(businessData);
        if (response.data.personalProfiles[0].contacts.length > 0) {
          response.data.personalProfiles[0].contacts.forEach((contact: any) => {
            if (contact.contactType === "WorkPhone") {
              setBusinessPhoneNumber(contact.data ? contact.data : "");
            }
            if (contact.contactType === "WorkEmail") {
              setBusinessEmail(contact.data ? contact.data : "");
            }
          });
        }

        setPolicies(response.data.policies);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the hawksoft client details";
          }
        }
        //setClientLoading(false);
        //setErrorMessage(errorMessage);
      });
  };

  const getNowCertsPoliciesList = (insuredId: string) => {
    setLoadingNowCerts(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        setNowCertPolicyData(response.data);
        setLoadingNowCerts(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the insureds data.Please try again later.";
          }
        }
        setLoadingNowCerts(false);
      });
  };
  const getNewOrDuplicatePoliciesList = (ClientId: any) => {
    const token = CommonValues.GetToken();
    setLoadingNowCerts(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/indicativepolicy?ClientId=${ClientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoadingNowCerts(false);
        let ArchiveError: any = false;
        let showConfirmation: any = false;
        let updatedArray: any = [];
        response.data.forEach((element: any) => {
          element.linesOfBusiness.forEach((line: any, index: number) => {
            line.otherTaxes = element.taxes[index];
          });
          element.linesOfBusiness.forEach((line: any, index: number) => {
            line.otherFees = element.fees[index];
          });

          updatedArray.push({
            taxes: element.taxes,
            fees: element.fees,
            earnedPercent: element.earnedPercent,
            ams: element.ams,
            effectiveDate: element.effectiveDate,
            policyNumber: element.policyNumber,
            currentPremium: element.currentPremium,
            quotedPremium: element.quotedPremium,
            businessName: element.businessName,
            businessEmail: element.businessEmail,
            businessPhoneNumber: element.businessPhoneNumber,
            showConfirmation: showConfirmation,
            transactionId: element.transactionId,
            id: element.id,
            displayOrder: element.displayOrder,
            title: element.title,
            isArchive: element.isArchive,
            policyTitle: element.policyTitle,
            linesOfBusinessJSON: element.linesOfBusinessJSON,
            locationsJSON: element.locationsJSON,
            businessAddressJSON: element.businessAddressJSON,
            linesOfBusiness: element.linesOfBusiness,
            businessAddress: element.businessAddress,
            terms: element.terms,
            carrier: element.carrier,
            archiveError: ArchiveError,
            brokerFees: element.brokerFees,
            source: element.source,
            generalAgency: element.generalAgency,
            clientId: element.clientId,
          });
        });
        setIndicativePolicies(updatedArray);
      })
      .catch((error: any) => {
        console.log(error);
        setLoadingNowCerts(false);
      });
  };
  const showArchiveIcon = (rowData: any) => {
    return (
      <>
        {rowData.transactionId == transid && archivePolicyLoader ? (
          <div>
            <AtkLoader />
          </div>
        ) : (
          <span className="partner-logo-and-span show-cursor">
            <img src={ArchiveLogo} height="20" width="20" className="me-2" title="Archive policy" alt="Archive" />
          </span>
        )}
      </>
    );
  };
  const DuplicateIconClicked = (rowData: any) => {
    return (
      <>
        {rowData.transactionId == transid && props.onIconClick ? (
          <div>
            <AtkLoader />
          </div>
        ) : (
          <span className="partner-logo-and-span ">
            <img src={DuplicateLogo} height="20" width="20" className="me-2" title="Duplicate policy" alt="Duplicate" />
          </span>
        )}
      </>
    );
  };
  const onNewPolicyClick = () => {
    let policyData = {};
    let storedData = localStorage.getItem("client");
    let clianttlist = storedData ? JSON.parse(storedData) : null;
    if (CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id) {
      policyData = {
        PolicyId: 0,

        PolicyData: {
          addresses: [
            {
              city: hawkSoftPolicyData.addresses[0].city,
              country: hawkSoftPolicyData.addresses[0].country,
              line1: hawkSoftPolicyData.addresses[0].line1,
              line2: hawkSoftPolicyData.addresses[0].line2,
              state: hawkSoftPolicyData.addresses[0].state,
              zipCode: hawkSoftPolicyData.addresses[0].zipCode,
            },
          ],
          businessProfiles: [
            {
              clientid: clianttlist.clientId,
              name: hawkSoftPolicyData && hawkSoftPolicyData.businessProfiles[0] ? hawkSoftPolicyData.businessProfiles[0].name : "",
              phoneNumber: "",
            },
          ],
          personalProfiles: [
            {
              firstName: clianttlist.clientName,
              lastName: "",
            },
          ],
          contacts: hawkSoftPolicyData.personalProfiles,
          policies: [
            {
              carrier: "",
              term: "12",
              effectiveDate: "",
              currentPremium: "",
              quotedPremium: 0,
              policyNumber: "",
              linesOfBusiness: [
                {
                  code: "***",
                  status: "",
                  policyNumber: "",
                  effectiveDate: new Date(),
                },
              ],
            },
          ],
        },
        SelectedPolicyIndex: 0,
        DisplayOrder: "",
        HsPolicyIndex: "",
      };
    } else {
      policyData = {
        PolicyId: 0,

        PolicyData: {
          addresses: [
            {
              city: "",
              country: "",
              line1: "",
              state: "",
              zipCode: "",
            },
          ],
          businessProfiles: [
            {
              clientid: clianttlist.clientId,
              name: "",
              phoneNumber: "",
            },
          ],
          personalProfiles: [
            {
              firstName: clianttlist.clientName,
              lastName: "",
            },
          ],
          contacts: hawkSoftPolicyData.personalProfiles,
          policies: [
            {
              carrier: "",
              term: "12",
              effectiveDate: "",
              currentPremium: "",
              quotedPremium: 0,
              policyNumber: "",
              linesOfBusiness: [
                {
                  code: "***",
                  status: "",
                  policyNumber: "",
                  effectiveDate: new Date(),
                },
              ],
            },
          ],
        },
        SelectedPolicyIndex: 0,
        DisplayOrder: "",
        HsPolicyIndex: "",
      };
    }

    localStorage.setItem("policyData", JSON.stringify(policyData));
    localStorage.setItem("isNewPolicy", "true");
    localStorage.setItem("IsIndicative", "true");
    setShowPolicyDetails(true);
  };

  const effectiveDateFormat = (rowData: any) => {
    const effectiveDate = rowData.effectiveDate;
    if (effectiveDate != null || effectiveDate !== "") {
      return moment.utc(effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY");
    } else {
    }
  };

  const getCustomerDetailsAndPolicyDetailsByCustomerId = (customerId: any, isDuplicateRequested: boolean, duplicatedata: any) => {
    setLoadingNowCerts(true);
    const token = CommonValues.GetToken();
    // if (isDuplicateRequested == false) {
    //   setAms360CustomerLoading(true);
    // }

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/customerpolicies?customerid=${customerId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        // setAms360CustomerLoading(false);
        // setSearch("");
        let clianttlist = {
          clientId: customerId,
          clientName:
            response.data.customer.Customer.FirmName == "" || response.data.customer.Customer.FirmName == null
              ? response.data.customer.Customer.FirstName + " " + response.data.customer.Customer.LastName
              : response.data.customer.Customer.FirmName,
        };

        localStorage.setItem("client", JSON.stringify(clianttlist));
        let ClientName =
          response.data.customer.Customer.FirmName == "" || response.data.customer.Customer.FirmName == null
            ? response.data.customer.Customer.FirstName + " " + response.data.customer.Customer.LastName
            : response.data.customer.Customer.FirmName;
        // setInputValue(ClientName);
        // setClientName(ClientName);
        let policyList: any = [];
        // setVertaforePolicyData(response.data.policies.PolicyInfoList);
        setVertaforeWholePolicyData(response.data);
        response.data.policies.PolicyInfoList.forEach((element: any) => {
          policyList.push({
            policyNumber: element.PolicyNumber,
            displayOrder: 0,
            title: element.PolicyTypeOfBusiness,
            effectiveDate: element.PolicyEffectiveDate,
            id: element.PolicyId,
            clientName: ClientName,
          });
        });

        setBusinessName(response.data.customer.Customer.FirmName);
        setDbaName(response.data.customer.Customer.DoingBusinessAs);
        let policies = {
          policies: policyList,
        };

        setVertaforePolicyData(policyList);

        // setVertaforePolicyData(policies);
        let policyDuplicateData: any = {};
        if (isDuplicateRequested == true) {
          // setIndicativePolicyLoading(true);
          setBusinessName(response.data.customer.Customer.FirmName);
          setBusinessPhoneNumber(response.data.customer.Customer.FirmPhoneNumber);
          let businessAddress: any = {};
          businessAddress.addressType = "";
          businessAddress.city = response.data.customer.Customer.City;
          businessAddress.state = response.data.customer.Customer.State;
          businessAddress.zipCode = response.data.customer.Customer.ZipCode;
          businessAddress.line1 = response.data.customer.Customer.AddressLine1;
          businessAddress.line2 = response.data.customer.Customer.AddressLine2;
          businessAddress.country = "USA";

          setBusinessAddress(businessAddress);
          // setPolicyNumber(duplicatedata.PolicyNumber);
          // setEffectiveDate(duplicatedata.PolicyEffectiveDate);
          policyDuplicateData.BusinessAddress = businessAddress;
          policyDuplicateData.BusinessName = response.data.customer.Customer.FirmName;
          policyDuplicateData.PolicyNumber = duplicatedata.policyNumber;
          policyDuplicateData.EffectiveDate = duplicatedata.effectiveDate;
          policyDuplicateData.PolicyId = duplicatedata.id;
          policyDuplicateData.customerId = customerId;
          policyDuplicateData.title = duplicatedata.title;
          //getCoverageCodeMappingForVertaFore(duplicatedata.title, "desc", 0, [], policyDuplicateData);
          // getPolicyDetailsVertafore(policyDuplicateData);
        }
        // setduplicatePolicyLoader(false);
        setLoadingNowCerts(false);
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 400 && error.response.data.Message != undefined && error.response.data.Message != null) {
            // setErrorMessageInvalidCreds(error.response.data.Message);
          } else {
            errorMessage = "Unknown error while loading the vertafore client details";
          }
        }
        setLoadingNowCerts(false);
        // setErrorMessage(errorMessage);
        // setAms360CustomerLoading(false);
      });
  };
  const onBundleClicked = () => {
    setShowPolicyDetails(true);
  };
  const ItemWithIndex = (item: any, index: number) => ({
    ...item,
    index,
  });
  const nowcertsExistingPolicyTemplate = (item: any) => {
    return (
      <div className="mt-2 mx-auto p-2" key={item.id}>
        <Card>
          <div className="row text-12 mx-auto">
            <div className="col-12 d-flex align-items-center p-1">
              <span>{onSelectMultiplePolicies(item, item.index)}</span>
            </div>
            <div className="row show-cursor" onClick={(e) => handleRowSelect({ value: item, originalEvent: e })}>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Title</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.linesOfBusiness != null || item.linesOfBusiness !== undefined ? item.linesOfBusiness[0] : ""}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Effective Date</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  {item.effectiveDate != null || item.effectiveDate !== "" ? moment.utc(item.effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY") : ""}
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Policy Number</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.policyNumber}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Total Premium</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>${item.totalPremium}</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const hawksoftExistingPolicyTemplate = (item: any) => {
    return (
      <div className="mt-2 mx-auto p-2" key={item.id}>
        <Card>
          <div className="row text-12 mx-auto">
            <div className="col-12 d-flex align-items-center p-1">
              <span>{onSelectMultiplePolicies(item, item.index)}</span>
            </div>
            <div className="row show-cursor" onClick={(e) => handleRowSelect({ value: item, originalEvent: e })}>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Title</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.title}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Effective Date</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  {item.effectiveDate != null || item.effectiveDate !== "" ? moment.utc(item.effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY") : ""}
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Policy Number</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.policyNumber}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Quoted Premium</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>${item.currentPremium}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Business Name</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{businessName}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>DBA Name</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{dbaName}</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const vertaforeExistingPolicyTemplate = (item: any) => {
    return (
      <div className="mt-2 mx-auto p-2" key={item.id}>
        <Card>
          <div className="row text-12 mx-auto">
            <div className="col-12 d-flex align-items-center p-1">
              <span>{onSelectMultiplePolicies(item, item.index)}</span>
            </div>
            <div className="row show-cursor" onClick={(e) => handleRowSelect({ value: item, originalEvent: e })}>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Title</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.title}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Effective Date</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  {item.effectiveDate != null || item.effectiveDate !== "" ? moment.utc(item.effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY") : ""}
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Policy Number</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{item.policyNumber}</span>
              </div>

              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>Business Name</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{businessName}</span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>
                  <b>DBA Name</b>
                </span>
              </div>
              <div className="col-6 d-flex align-items-center p-1">
                <span>{dbaName}</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const newPolicyTemplate = (item: any) => {
    return (
      <div className="mt-2 mx-auto p-2" key={item.id}>
        <Card onClick={(e) => handleRowSelect({ value: item, originalEvent: e })} className="show-cursor">
          <div className="row text-12 mx-auto">
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Title</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.title}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Policy Number</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.policyNumber}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Quoted Premium</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>${item.currentPremium}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Effective Date</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                {item.linesOfBusiness[0].effectiveDate == null
                  ? ""
                  : moment.utc(item.linesOfBusiness[0].effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Business Name</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.businessName}</span>
            </div>

            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Action</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span onClick={(e) => handleArchiveToggle(e, item)}>{showArchiveIcon(item)}</span>{" "}
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const pageStateForBackButton = () => {
    setIsQuotePage(true);
  }
  return (
    <div className="row client-and-policy-title-row">
      <Toast ref={toast} />
      <div className="col-12 d-flex align-items-center justify-content-between  mt-2 mb-2  ">
        <span className="d-flex align-items-center">
          {showPolicyDetails ? (
            <span>
              {" "}
              <AtkButton label="Back" onClick={onBackClick} className="policy-back-btn w-auto  me-2" />
            </span>
          ) : null}

          <span className="policy-type-heading me-2 text-center">{props.type === "1" ? "New Policies" : "Existing Policies"}</span>
          <span className="client-name-span me-2  text-center">
            {" "}
            <b className=" ">
              {props.momentumPolicyId != null && props.momentumPolicyId !== ""
                ? `${clientNameForMomentumPolicy || ""} ${clientNameForMomentumPolicy?.length ?? 0 > 0 ? (props.isProspect ? "(Prospect)" : "(Client)") : ""
                } `
                : `${props.clientName || ""} ${props.clientName?.length ?? 0 > 0 ? (props.isProspect ? "(Prospect)" : "(Client)") : ""} `}
            </b>
          </span>
        </span>
        {/* </div>

      <div className="col-4 d-flex align-items-center justify-content-end mt-2 mb-2"> */}
        {selectedPolicyIds.length > 0 && !showPolicyDetails ? (
          <AtkButton label="Bundle" onClick={onBundleClicked} className="pf-primary-btn w-auto " />
        ) : null}
        {/* <div className="col-2 d-flex align-items-center justify-content-end mt-2 mb-2"> */}
        {showPolicyDetails ? // <span>
          //   {" "}
          //   <AtkButton label="Back" onClick={onBackClick} className="pf-secondary-btn w-auto ms-2" />
          // </span>
          null : (
            <>
              {" "}
              {props.type === "1" ? (
                <div className="float-end mt-2 mb-2 ">
                  <AtkButton label="New Policy" onClick={onNewPolicyClick} className="pf-primary-btn  w-100" />
                </div>
              ) : null}
            </>
          )}
      </div>
      {enableQuickQuoteLoading ? (
        <AtkLoader />
      ) : (
        <>
          {showPolicyDetails ? (
            <div className="col-12 p-0">
              <ExistingPolicyDetails
                isQuotePage={isQuotePage}
                selectedPolicyId={selectedPolicy}
                indicativedata={indicativedata}
                momentumPolicyId={momentumPolicyId}
                type={props.type}
                onClientNameChange={handleClientNameChange}
                enableQuickQuote={enableQuickQuote}
                pageStateForBackButton={pageStateForBackButton}
                reuseSavedQuoteId={props.reuseSavedQuoteId}
              />
            </div>
          ) : (
            <div className="p-0">
              {loadingNowCerts ? (
                <div className="d-flex justify-content-center align-items-center">
                  <AtkLoader />{" "}
                </div>
              ) : (
                <>
                  <div className="col-12  history-table mt-3">
                    {props.type == "0" ? (
                      CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id ? (
                        <>
                          {window.innerWidth > 767 ? (
                            <DataTable
                              value={nowCertPolicyData}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={10}
                              selectionMode="single"
                              selection={selectedPolicy}
                              onSelectionChange={(e) => {
                                handleRowSelect(e);
                              }}
                            >
                              <Column header="" field="" body={(rowData, rowIndex) => onSelectMultiplePolicies(rowData, rowIndex)}></Column>
                              <Column
                                header="Title"
                                field="linesOfBusiness[0]"
                                body={(rowData: any) => {
                                  if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
                                    return rowData.linesOfBusiness[0];
                                  }
                                }}
                              // sortable
                              ></Column>
                              <Column
                                header="Effective Date"
                                field="effectiveDate"
                                body={(rowData: any) => {
                                  if (rowData.effectiveDate != null || rowData.effectiveDate !== "") {
                                    return moment.utc(rowData.effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY");
                                  }
                                }}
                                sortable
                              ></Column>
                              <Column header="Policy Number" field="policyNumber" sortable></Column>
                              <Column
                                header="Total Premium"
                                field="totalPremium"
                                body={(rowData) => <span>${rowData.totalPremium}</span>}
                                sortable
                              ></Column>
                              {/* <Column
                        header="Action"
                        body={(rowData) => {
                          if (duplicateDataLoading && policyNumberForDuplicateLoading == rowData.databaseId) {
                            return <AtkLoader />;
                          } else {
                            return DuplicateIconClicked(rowData);
                          }
                        }}
                      ></Column>{" "} */}
                            </DataTable>
                          ) : (
                            <div className="card-container">
                              <DataView
                                value={nowCertPolicyData.map((item: any, index: number) => ItemWithIndex(item, index))}
                                itemTemplate={nowcertsExistingPolicyTemplate}
                                paginator
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                rows={rows}
                                first={first}
                                onPage={(e) => setFirst(e.first)}
                              />
                            </div>
                          )}
                        </>
                      ) : CommonValues.GetTenantId() == process.env.REACT_APP_Hawksoft_Tenant_Id ? (
                        <>
                          {window.innerWidth > 767 ? (
                            <DataTable
                              value={policies}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={10}
                              selectionMode="single"
                              selection={selectedPolicy}
                              onSelectionChange={(e) => {
                                handleRowSelect(e);
                              }}
                            >
                              <Column header="" field="" body={(rowData, rowIndex) => onSelectMultiplePolicies(rowData, rowIndex)}></Column>
                              <Column header="Title" field="title" sortable></Column>
                              <Column
                                header="Effective Date"
                                field="effectiveDate"
                                sortable
                                body={(rowData: any) => {
                                  if (rowData.effectiveDate != null || rowData.effectiveDate !== "") {
                                    return moment.utc(rowData.effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY");
                                  }
                                }}
                              >
                                {" "}
                              </Column>
                              <Column header="Policy Number" field="policyNumber" sortable></Column>
                              <Column header="Quoted Premium" body={currentPremiumBody} sortable></Column>
                              <Column header="Business Name" body={businessName} field={businessName} sortable></Column>
                              <Column header="DBA Name" body={dbaName} field={dbaName} sortable></Column>
                              {/* <Column
                        header="Action"
                        body={(rowData) => {
                          if (duplicateDataLoading && policyNumberForDuplicateLoading == rowData.id) {
                            return <AtkLoader />;
                          } else {
                            return DuplicateIconClicked(rowData);
                          }
                        }}
                      ></Column>{" "} */}
                            </DataTable>
                          ) : (
                            <div className="card-container">
                              <DataView
                                value={policies.map((item: any, index: number) => ItemWithIndex(item, index))}
                                itemTemplate={hawksoftExistingPolicyTemplate}
                                paginator
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                rows={rows}
                                first={first}
                                onPage={(e) => setFirst(e.first)}
                              />
                            </div>
                          )}
                        </>
                      ) : CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id ? (
                        <>
                          {window.innerWidth > 767 ? (
                            <DataTable
                              value={vertaforePolicyData}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={10}
                              // responsiveLayout="scroll"
                              selectionMode="single"
                              selection={selectedPolicy}
                              onSelectionChange={(e) => {
                                handleRowSelect(e);
                              }}
                            >
                              <Column header="Title" field="title" sortable></Column>
                              <Column header="Effective Date" field="effectiveDate" body={effectiveDateFormat} sortable>
                                {" "}
                              </Column>
                              <Column header="Policy Number" field="policyNumber" sortable></Column>
                              <Column header="Business Name" body={businessName} sortable></Column>
                              <Column header="DBA Name" body={dbaName} sortable></Column>
                              {/* <Column
                        header="Action"
                        body={(rowData) => {
                          if (duplicateDataLoading && policyNumberForDuplicateLoading == rowData.id) {
                            return <AtkLoader />;
                          } else {
                            return DuplicateIconClicked(rowData);
                          }
                        }}
                      ></Column> */}
                            </DataTable>
                          ) : (
                            <div className="card-container">
                              <DataView
                                value={vertaforePolicyData.map((item: any, index: number) => ItemWithIndex(item, index))}
                                itemTemplate={vertaforeExistingPolicyTemplate}
                                paginator
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                rows={rows}
                                first={first}
                                onPage={(e) => setFirst(e.first)}
                              />
                            </div>
                          )}
                        </>
                      ) : null
                    ) : (
                      <>
                        {window.innerWidth > 767 ? (
                          <DataTable
                            value={indicativePolicies}
                            paginator
                            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                            rows={5}
                            // responsiveLayout="scroll"
                            selectionMode="single"
                            selection={selectedPolicy}
                            onSelectionChange={(e) => {
                              handleRowSelect(e);
                            }}
                          >
                            <Column header="Title" field="title" sortable></Column>
                            <Column header="Policy Number" field="policyNumber" sortable></Column>
                            <Column
                              header="Quoted Premium"
                              field="currentPremium"
                              body={(rowData) => <span>${rowData.currentPremium}</span>}
                              sortable
                            ></Column>
                            <Column
                              header="Effective Date"
                              field="effectiveDate"
                              body={(rowData) => (
                                <span>
                                  {rowData.linesOfBusiness[0].effectiveDate == null
                                    ? ""
                                    : moment.utc(rowData.linesOfBusiness[0].effectiveDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
                                </span>
                              )}
                              sortable
                            ></Column>
                            <Column header="Business Name" field="businessName" sortable></Column>
                            <Column
                              header="Action"
                              className=""
                              body={(rowData) => <span onClick={(e) => handleArchiveToggle(e, rowData)}>{showArchiveIcon(rowData)}</span>}
                            ></Column>
                          </DataTable>
                        ) : (
                          <div className="card-container">
                            <DataView
                              value={indicativePolicies.map((item: any, index: number) => ItemWithIndex(item, index))}
                              itemTemplate={newPolicyTemplate}
                              paginator
                              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                              rows={rows}
                              first={first}
                              onPage={(e) => setFirst(e.first)}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
      <OverlayPanel ref={archiveRef} className="menu-panel ">
        <SectionMessage appearance="warning">
          <p>
            <span className=" d-block text-14 text-dark">Are you sure you want to archive this policy?</span>
          </p>
          <div className="text-end mt-2">
            {archiveLoading ? <AtkLoader /> : <AtkButton label="OK" onClick={onClickArchiveQuote} className="pf-secondary-btn w-auto me-2" />}
            <AtkButton label="Cancel" onClick={handleSkip} className="pf-primary-btn w-auto" />
          </div>
        </SectionMessage>
      </OverlayPanel>
    </div>
  );
}