import React, { useEffect, useRef, useState } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function FirstInsuranceFundingSettings() {
  const [loading, setLoading] = useState(false);
  const [firstinsuranceLoading, setFirstInsuranceLoading] = useState(false);
  const [deleteFirstInsuranceLoading, setDeleteFirstInsuranceLoading] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [firstInsuranceUsername, setFirstInsuranceUsername] = useState("");
  const [firstInsurancePassword, setFirstInsurancePassword] = useState("");
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const toast: any = useRef("");

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  const getFirstInsuranceCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setFirstInsuranceUsername("");

          setFirstInsurancePassword("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setFirstInsuranceUsername(response.data.username);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setFirstInsurancePassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the First Insurance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the First Insurance Setting details";
        }
        toast.current.show({ severity: "error", summary: 'Error', detail: { errorMessage } });
        setLoading(false);
      });
  };
  const firstInsuranceCheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");

    if (firstInsuranceUsername == "" || firstInsuranceUsername == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }
    if (firstInsurancePassword == "" || firstInsurancePassword == null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    return formIsValid;
  };
  const onUpdateFirstInsuranceClick = () => {
    if (firstInsuranceCheckValidation()) {
      setFirstInsuranceLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: firstInsuranceUsername,
        Password: firstInsurancePassword,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setFirstInsuranceLoading(false);
          toast.current.show({ severity: "success", summary: 'Success', detail: "First Insurance Settings updated successfully" });
          getFirstInsuranceCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              errorMessage = "It look likes you are not registered with First Insurance, Please register first.";
            } else {
              errorMessage = "Unknown error while updating the First Insurance Settings";
            }
          } else {
            errorMessage = "Unknown error while updating the First Insurance Settings";
          }
          toast.current.show({ severity: "error", summary: 'Error', detail: { errorMessage } });
          setFirstInsuranceLoading(false);
        });
    }
  };
  const onDeleteFirstInsuranceClick = () => {
    setDeleteFirstInsuranceLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteFirstInsuranceLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "First Insurance Settings deleted successfully" });
        getFirstInsuranceCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while deleting the First Insurance Settings";
          }
        } else {
          errorMessage = "Unknown error while deleting the First Insurance Settings";
        }
        toast.current.show({ severity: "error", summary: 'Error', detail: { errorMessage } });

        setDeleteFirstInsuranceLoading(false);
      });
  };
  const onKeyUpdateFirstInsuranceClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateFirstInsuranceClick();
    }
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setFirstInsuranceUsername(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setFirstInsurancePassword(e.target.value);
  };

  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div className="row  mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
         

          <div className="form-group col-4  mb-2">
            <label className="form-label">
              Username
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessageUsername.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="username"
              name="username"
              onChange={onUsernameChange}
              value={firstInsuranceUsername}
              placeholder="Enter user name"
              onKeyDown={onKeyUpdateFirstInsuranceClick}
            />
            <span className="text-danger text-12">{errorMessageUsername}</span>
          </div>

          <div className="form-group col-4  ">
            <label className="form-label">
              Password
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessagePassword.length > 0 ? " col-8 validation-div position-relative" : " col-8 position-relative"}>
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="headerKey"
              name="firstInsuranceSetupPassword"
              onChange={onPasswordChange}
              value={firstInsurancePassword}
              onKeyDown={onKeyUpdateFirstInsuranceClick}
              autoComplete="new-password"
              placeholder="Enter password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div>
          {errorMessagePassword.length > 0 ? (
            <>
              <div className="col-4  mb-2"></div>
              <div className=" col-8  mb-2">
                <span className="text-danger text-12">{errorMessagePassword}</span>
              </div>
            </>
          ) : null}
          <div className="col-12  text-end mb-2 mt-2">
            {deleteFirstInsuranceLoading ? (
              <AtkLoader></AtkLoader>
            ) : (
              <AtkButton label="Reset" onClick={onDeleteFirstInsuranceClick} className="pf-secondary-btn w-auto me-2" />
            )}

            {firstinsuranceLoading ? (
              <AtkLoader></AtkLoader>
            ) : (
              <AtkButton label="Save" onClick={onUpdateFirstInsuranceClick} className="pf-primary-btn w-auto " />
            )}
          </div>
          <div className="col-md-12 mb-2">
            {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using Momentum Credentials" /> : null}
          </div>
        </>
      )}
    </div>
  );
}