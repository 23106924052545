import React from "react";
import SonantSidebar from "./SonantSidebar";
import SonantMiddleHeader from "./SonantMiddleHeader";
import BackgroundVideo from "../common/BackgroundVideo";
import Header from "../common/Header";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IncomingIcon from "../assets/images/Incoming-Call.png";
import OutgoingIcon from "../assets/images/Outgoing-Call.png";
import AtkTextField from "../AtlaskitControls/AtkTextField";

export default function SonantHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [historyloading, setHistoryLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [historydata, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [recordingLoading, setRecordingLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");

  const [transcriptedData, setTranscriptedData] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    getHistoryDetails();
  }, []);
  const getHistoryDetails = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/CallNotification/sonanthistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setHistoryLoading(false);
        setHistoryData(response.data.data);
      })
      .catch((error) => {
        setHistoryLoading(false);
        if (error.response !== null) {
          console.log(error.response);

          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting sonant details",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting sonant details",
            life: 3000,
          });
        }
      });
  };
  const getCallRecordingURL = (tranasctionId: any) => {
    setRecordingLoading(true);
    const token = CommonValues.GetToken();
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/CallNotification/callrecording/${tranasctionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response: any) => {
        setRecordingLoading(false);
        setAudioUrl(response.data.data != null && response.data.data != undefined ? response.data.data.callRecordingURL : "");
      })
      .catch((error: any) => {
        setRecordingLoading(false);
        if (error.response !== null) {
          console.log(error.response);
          
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unknown error while getting call recording",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unknown error while getting call recording",
            life: 3000,
          });
        }
      });
  };
  const actionTemplate = (rowData: any) => {
    return rowData.webhookData?.call_transcript_memory ? (
      <AtkButton label="View" onClick={() => onPreviewClick(rowData)} className="pf-primary-btn w-auto" />
    ) : (
      <span>No transcript available</span>
    );
  };

  const callTypeTemplate = (rowData: any) => {
    if (rowData.callType === "Incoming") {
      return (
        <span>
          <img src={IncomingIcon} alt="Incoming Call" height={20} /> {rowData.callType}
        </span>
      );
    } else if (rowData.callType === "Outgoing") {
      return (
        <span>
          {" "}
          <img src={OutgoingIcon} alt="Outgoing Call" height={20} /> {rowData.callType}
        </span>
      );
    } else {
      return <span>N/A</span>;
    }
  };

  const onPreviewClick = (rowData: any) => {
    setShowPopUp(true);
    setTranscriptedData(rowData.webhookData?.call_transcript_memory || "");
    getCallRecordingURL(rowData.transactionId);
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
  };
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };
  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };
  const handleBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(0, currentTime - 10);
    }
  };
  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(duration, currentTime + 10);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying && audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isPlaying]);
  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="sonant-main-page">
        <Header pagename="sonant-history" />
        <div className="content-wrapper ">
          <BackgroundVideo />
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <SonantMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SonantSidebar pagename="sonant-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                  <div className="row mb-3">
                          <div className="col-6">
                            <span className="p-input-icon-left w-50">
                              <i className="fa fa-search grid-search-icon" />
                              <AtkTextField
                                type="text"
                                id="txtfirstName"
                                name="globalFilter"
                                onChange={(e) => setGlobalFilter(e.target.value)}
                                value={globalFilter}
                                className="input-field"
                                placeholder="Search..."
                              />
                            </span>
                          </div>
                          <div className="col-6 text-end">
                            <AtkButton label="Refresh" onClick={getHistoryDetails} className=" pf-primary-btn w-auto" />
                          </div>
                        </div>
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden mt-5">
                        <AtkLoader />
                      </div>
                    ) : (
                      <>
                      

                        <DataTable
                          value={historydata}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          globalFilter={globalFilter}
                          emptyMessage="No data available."
                        >
                          <Column
                            header="Call Date"
                            field="created"
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.created);
                              var localDate = moment(utcdate).local();
                              return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                            }}
                            sortable
                          ></Column>

                          <Column header="Call Type" field="callType" body={callTypeTemplate} sortable></Column>

                          <Column
                            header="Call Outcome"
                            field="webhookData.call_extracted_info.call_outcome"
                            body={(rowData) => rowData.webhookData?.call_extracted_info?.call_outcome || "N/A"}
                            sortable
                          ></Column>

                          <Column
                            header="Call Follow up Date"
                            field="webhookData.call_extracted_info.call_follow_up_date"
                            body={(rowData) => {
                              if (!rowData.webhookData?.call_extracted_info?.call_follow_up_date) {
                                return <span>N/A</span>;
                              }

                              const utcdate = moment.utc(rowData.webhookData.call_extracted_info.call_follow_up_date);
                              const localDate = utcdate.local().format("MM-DD-YYYY");

                              return <span>{localDate}</span>;
                            }}
                            sortable
                          ></Column>

                          <Column
                            header="Call Follow up Time"
                            field="webhookData.call_extracted_info.call_follow_up_time"
                            body={(rowData) => rowData.webhookData?.call_extracted_info?.call_follow_up_time || "N/A"}
                            sortable
                          ></Column>
                          <Column
                            header="Transcript"
                            field="webhookData.call_transcript_memory"
                            body={actionTemplate}
                            className="text-center"
                          ></Column>
                        </DataTable>
                      </>
                    )}
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="Call Transcript" visible={showPopUp} style={{ width: "60vw", height: "80vh" }} onHide={onHidePopupClicked} draggable={false}>
        <div className="container d-flex flex-column h-100 border rounded p-0">
          <div className="flex-grow-1 overflow-auto  p-3 text-12">
            {transcriptedData.split("\n").map((line, index) => {
              const isBot = line.startsWith("BOT:");
              return (
                <div key={index} className={`col-12 my-2 ${isBot ? "text-start" : "text-end"}`}>
                  <span
                    className={`p-2 rounded d-inline-block ${isBot ? "bg-primary bg-opacity-10 text-dark" : "bg-success bg-opacity-25 text-dark"}`}
                    style={{ maxWidth: "75%" }}
                  >
                    <strong>{isBot ? "BOT:" : "HUMAN:"}</strong> {line.replace(/(BOT:|HUMAN:)/, "")}
                  </span>
                </div>
              );
            })}
          </div>

          {recordingLoading ? (
            <div className="d-flex align-ietms-center justify-content-center">
              <AtkLoader />
            </div>
          ) : (
            <div className=" py-2 bg-light position-sticky bottom-0">
              <div className="d-flex align-items-center px-3">
                <input
                  type="range"
                  min="0"
                  max={duration}
                  value={currentTime}
                  onChange={handleSliderChange}
                  className="flex-grow-1 mt-2"
                  style={{ height: "2px" }}
                />
              </div>

              <div className="d-flex align-items-center justify-content-between py-2 px-3">
                <span className="fw-bold text-12">{formatTime(currentTime)} </span>
                <span className="text-muted text-12">/{formatTime(duration)}</span>

                <div className="d-flex align-items-center flex-grow-1 justify-content-center">
                  <audio ref={audioRef} src={audioUrl} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata} />

                  <i className="fa fa-backward mx-3 show-cursor" onClick={handleBackward} title="Skip 10s backward"></i>

                  <span className="px-3 py-1 rounded d-inline-block bg-primary mx-3 show-cursor" onClick={togglePlayPause}>
                    <i className={`text-light fa ${isPlaying ? "fa-pause" : "fa-play"}`} />
                  </span>

                  <i className="fa fa-forward mx-3 show-cursor" onClick={handleForward} title="Skip 10s forward"></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
