import { ThreeDots } from "react-loader-spinner";

export default function SupportLoader(props: any) {
  return (
    <div className="Loader_color">
      <ThreeDots
        height="35"
        width="45"
        color="#6b46c1"
        // ariaLabel="loading"
      />
    </div>
  );
}