import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommissionScanLogo from "../../src/assets/images/GenerateCommission_TH.svg";
import PFLogo from ".././assets/images/PF_Logo.svg";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AddApps from "../OtherPages/AddApps";
import FenrisLogo from "../assets/images/fenris.svg";
import VehicleLogo from "../assets/images/vehicle2_TH.svg";
import ValchoiceLogo from "../assets/images/valchoice.webp";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import ScanIcon from "../assets/images/scanDocument.svg";
import AtkButton from "../AtlaskitControls/AtkButton";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
import infoImg2 from "../assets/images/info.svg";
import NaicsLogo from "../assets/images/NAICS_logo.png";
import COILogo from "../assets/images/COI_TH.svg";
import IFSLogo from "../assets/images/IFS ICON_TH.svg";
import RabbitSignLogo from "../assets/images/RabbitSignMiniLogo.png";
import HomeIcon from "../assets/images/HomeOwnerLogo_TH.svg";
import PolicyCompareIcon from "../assets/images/PolicyCopmarison.svg";
import PolicySummaryIcon from "../assets/images/PolicySummaryReport.svg";
import MvrLogo from "../assets/images/mvr.png";
import MTMLogo from "../assets/images/MTMLogo.png";
import StarIcon from "@atlaskit/icon/glyph/star";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { Carousel } from "primereact/carousel";
import BackgroundVideo from "../common/BackgroundVideo";
import LeadxLogo from "../assets/images/LeadX 2.svg";
import MacLogo from "../assets/images/ac 1.svg";
import RateLogo from "../assets/images/rate 2.svg";
import { Skeleton } from "primereact/skeleton";
import momentumvideo from "../assets/video/Purple with pattern.mp4";
import IFSLogo1 from "../assets/images/IFS ICON_TH_W.png";
import PFLogo1 from ".././assets/images/PF Logo_w.png";
import RabbitSignLogo1 from "../assets/images/RS_W 1.png";
import OurTools from "../OtherPages/OurTools";
import DLLogo from "../assets/images/DLLogo.svg";
import SonantLogo from "../assets/images/sonant.png";

export default function LandingPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [loading, setLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [showOCR, setShowOCR] = useState(false);
  const appsRef = useRef<OverlayPanel>(null);
  const [nowCertsMessageShow, setNowCertsMessageShow] = useState(false);
  const location = useLocation();
  const hostname = window.location.hostname;
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showInfoDialogOFAcord, setShowInfoDialogOFAcord] = useState(false);
  const [visitedWidgets, setVisitedWidgets] = useState<any>([]);
  const [showInfoDialogOFCOI, setShowInfoDialogOFCOI] = useState(false);
  const [showInfoDialogOFPremfiscan, setShowInfoDialogOFPremfiscan] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showInfoDialogOfCommisionscan, setShowInfoDialogOfCommisionscan] = useState(false);
  const [showInfoDialogOfPremfi, setShowInfoDialogOfPremfi] = useState(false);
  const [userCount, setUserCount] = useState<any>([]);
  const [userCountLoading, setUserCountLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const carouselRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [carouselItems, setCarouselItems] = useState<any>([]);

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (localStorage.getItem("PFSettings") == "true") {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
    if (localStorage.getItem("tenantId") === "0") {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
    getCommonSettings();
    getUserCount();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (
      showVideoDialog ||
      showInfoDialogOFAcord ||
      showInfoDialogOFCOI ||
      showInfoDialogOFPremfiscan ||
      showInfoDialogOfCommisionscan ||
      showInfoDialogOfPremfi
    ) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.body.classList.remove("no-scroll");
  }, [
    showVideoDialog,
    showInfoDialogOFAcord,
    showInfoDialogOFCOI,
    showInfoDialogOFPremfiscan,
    showInfoDialogOfCommisionscan,
    showInfoDialogOfPremfi,
  ]);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 1000);
    setCarouselItems(firstCarousel);
  }, []);
  useEffect(() => {
    if (isVisible && carouselRef.current) {
      carouselRef.current.startAutoplay?.();
    }
  }, [isVisible]);

  // useEffect(() => {
  //   setIsVisible(false);
  //   setCarouselItems([]);

  //   if (carouselRef.current) {
  //     setTimeout(() => {
  //       setIsVisible(true);
  //       setCarouselItems(firstCarousel);
  //     }, 2000);
  //   }

  //   return () => {
  //     setIsVisible(false);
  //     setCarouselItems([]);
  //   };
  // }, []);
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        localStorage.setItem("tenantId", response.data.pfTenantId);
        localStorage.setItem("clientCount", response.data.clientCount);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        setVisitedWidgets(response.data.visitedWidgets);

        if (response.data.pfTenantId == 0) {
          setSettingLoading(false);
        }
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
          if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
            window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          }
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
          setShowMessage(true);
        } else {
          localStorage.setItem("PFSettings", "false");
          setShowMessage(false);
        }

        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          setSettingLoading(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          getVertaforeSettings();
        }
        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          getNowCertsCreds();
        } else {
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getNowCertsCreds = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        if (response.data.ncUserName == null || response.data.ncUserName == null) {
          localStorage.setItem("NowCertsSettings", "false");
          setNowCertsMessageShow(true);
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          setShowOCR(false);
        } else {
          localStorage.setItem("NowCertsSettings", "true");
          setNowCertsMessageShow(false);
          setShowOCR(true);
        }
      })
      .catch((error) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the NowCerts Setting details";
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the NowCerts Setting details";
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getVertaforeSettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.agencyName == "" || response.data.agencyName == null || response.data.userName == "" || response.data.userName == null) {
          localStorage.setItem("VertaforeSetting", "true");
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
        setSettingLoading(false);
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };
  const getUserCount = () => {
    setUserCountLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/usercount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setUserCount(response.data);
        setUserCountLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        setUserCountLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the user count";
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the user count";
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const onNewQuoteClick = () => {
    isVisitedWidget("PremFi");
    navigate("/premiumfinance");
  };
  const onCoiScanClick = () => {
    isVisitedWidget("COIScan");
    navigate("/nowcertcoiscan");
  };
  const onMtmScanClick = () => {
    isVisitedWidget("MTMScan");
    navigate("/mtmscan");
  };
  const onCommissionScanClick = () => {
    isVisitedWidget("CommissionScan");
    navigate("/commissionscan");
  };
  const onSmartScanClick = () => {
    isVisitedWidget("InsuranceFormScan");
    navigate("/smartscan");
  };
  const onFenrisTileClick = () => {
    isVisitedWidget("AMSPreFill");
    navigate("/fenrisprefill");
  };
  const onSettingTileClick = () => {
    navigate("/pfsettings");
  };
  const gotopfsettingPage = () => {
    {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
  };
  const onVehiclesClick = () => {
    isVisitedWidget("VehiclePreFill");
    navigate("/vehicleprefill");
  };
  const onPFSmartScanClick = () => {
    isVisitedWidget("PremFiScan");
    navigate("/pfscan");
  };
  const onAccordInfoClick = () => {
    setShowInfoDialogOFAcord(true);
  };
  const onCOIInfoClick = () => {
    setShowInfoDialogOFCOI(true);
  };
  const onPremfiScanInfoClick = () => {
    setShowInfoDialogOFPremfiscan(true);
  };
  const onCommisionScanInfoClick = () => {
    setShowInfoDialogOfCommisionscan(true);
  };
  const onPremfiInfoClick = () => {
    setShowInfoDialogOfPremfi(true);
  };
  const onAppetiteClick = () => {
    isVisitedWidget("RateRiskAppetite");
    navigate("/appetite");
  };
  const onMacClick = () => {
    window.open("https://app.fusionnowcrm.com/now-certs-auth", "_blank");
  };
  const onRateClick = () => {
    window.open(process.env.REACT_APP_Rate_UI_Url, "_blank");
  };
  const onLeadxClick = () => {
    window.open("https://leadx.fusionnowcrm.com/signin", "_blank");
  };
  const onVideoClick = () => {
    setShowVideoDialog(true);
  };
  const onCloseVideoDialogClick = () => {
    setShowVideoDialog(false);
  };
  const onCloseInfoDialogClick = () => {
    setShowInfoDialogOFAcord(false);
    setShowInfoDialogOFCOI(false);
    setShowInfoDialogOFPremfiscan(false);
    setShowInfoDialogOfCommisionscan(false);
    setShowInfoDialogOfPremfi(false);
  };
  const onNaicsClassificationClick = () => {
    isVisitedWidget("NAICSClassification");
    navigate("/naicscode");
  };
  const onValchoiceClick = () => {
    isVisitedWidget("Valchoice");
    navigate("/valchoice");
  };
  const onPolicyComparisonClick = () => {
    isVisitedWidget("PolicyComparision");
    navigate("/policycomparison/compare");
    localStorage.setItem("PolicyPageStatus", "compare");
  };
  const onPolicyAnalyzerClick = () => {
    isVisitedWidget("PolicyComparision");
    navigate("/policycomparison/summary");
    localStorage.setItem("PolicyPageStatus", "summary");
  };
  const onSonantClick = () => {
    isVisitedWidget("Sonant");
    navigate("/sonant");
  };
  const isVisitedWidget = (widget: any) => {
    const token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/visitedwidget?widget=${widget}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const onRabbitSignClick = () => {
    isVisitedWidget("RabbitSign");
    navigate("/rabbitsign");
  };
  const onDriverLicenseScanClick = () => {
    isVisitedWidget("DriverLicenseScan");
    navigate("/driverlicensescan");
  };
  const onInvoiceScanClick = () => {
    isVisitedWidget("InvoiceScan");
    navigate("/invoicescan");
  };

  const firstCarousel =
    hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2
      ? [
          {
            id: 1,
            title: "PremFi",
            description: "Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once.",
            logo: PFLogo1,
            userCount: userCount.find((uc: any) => uc.api === "Premium Finance Quotes")?.count || 0,
            visitedKey: "PremFi",
            rating: 5,
            onClick: onNewQuoteClick,
            onInfoClick: onPremfiInfoClick,
            isComingSoon: false,
          },
          {
            id: 2,
            title: "Acord and Dec Pages Scan",
            description: "Extracts data from Acord forms and declaration pages to create insured and prospect information.",
            logo: IFSLogo1,
            userCount: userCount.find((uc: any) => uc.api === "NowCerts Smart Scan")?.count || 0,
            visitedKey: "InsuranceFormScan",
            rating: 4,
            onClick: onSmartScanClick,
            onInfoClick: onAccordInfoClick,
            isComingSoon: false,
          },
          {
            id: 3,
            title: "E-Signature",
            description: "Quickly send the same document for signature over and over ",
            logo: RabbitSignLogo1,
            userCount: userCount.find((uc: any) => uc.api === "Rabbit Sign")?.count || 0,
            visitedKey: "RabbitSign",
            rating: 0,
            onClick: onRabbitSignClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
        ]
      : [
          {
            id: 1,
            title: "PremFi",
            description: "Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once.",
            logo: PFLogo1,
            userCount: userCount.find((uc: any) => uc.api === "Premium Finance Quotes")?.count || 0,
            visitedKey: "PremFi",
            rating: 5,
            onClick: onNewQuoteClick,
            onInfoClick: onPremfiInfoClick,
            isComingSoon: false,
          },
        ];

  const cardData2 = [
    {
      id: 1,
      title: "Certificate of Insurance Scan",
      description: "Extracts Certificate of Insurance data and generates a CSV file for easy import of certificate holders.",
      logo: COILogo,
      userCount: userCount.find((uc: any) => uc.api === "NowCerts COI Scans")?.count || 0,
      visitedKey: "COIScan",
      // rating: 4,
      rating: 0,
      onClick: onCoiScanClick,
      onInfoClick: onCOIInfoClick,
      isComingSoon: false,
    },
    {
      id: 2,
      title: "Acord and Dec Pages Scan",
      description: "Extracts data from Acord forms and declaration pages to create insured and prospect information.",
      logo: IFSLogo,
      userCount: userCount.find((uc: any) => uc.api === "NowCerts Smart Scan")?.count || 0,
      visitedKey: "InsuranceFormScan",
      // rating: 4,
      rating: 0,
      onClick: onSmartScanClick,
      onInfoClick: onAccordInfoClick,
      isComingSoon: false,
    },
    {
      id: 3,
      title: "PremFi (PFA) Scan",
      description: "Creates or updates insured and policy information in NowCerts by extracting data from premium finance agreements",
      logo: ScanIcon,
      userCount: userCount.find((uc: any) => uc.api === "PF Smart Scan")?.count || 0,
      visitedKey: "PremFiScan",
      // rating: 3,
      rating: 0,
      onClick: onPFSmartScanClick,
      onInfoClick: onPremfiScanInfoClick,
      isComingSoon: false,
    },
    {
      id: 4,
      title: "Commission Scan",
      description: "Captures data from PDF commission reports and converts it into CSV files, ready for import into Momentum.",
      logo: CommissionScanLogo,
      userCount: userCount.find((uc: any) => uc.api === "Commission Statement")?.count || 0,
      visitedKey: "CommissionScan",
      // rating: 4,
      rating: 0,
      onClick: onCommissionScanClick,
      onInfoClick: onCommisionScanInfoClick,
      isComingSoon: false,
    },
    {
      id: 5,
      title: "Policy Comparison",
      description: "Compare the policy dec page or quote letter, compare multiple policies along with limits and coverages",
      logo: PolicyCompareIcon,
      userCount: userCount.find((uc: any) => uc.api === "Policy Comparison")?.count || 0,
      visitedKey: "PolicyComparision",
      // rating: 3,
      rating: 0,
      onClick: onPolicyComparisonClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 6,
      title: "Policy/Quote Analyzer and Summary",
      description: "Summarize the policy dec pages or quote letter generating concise summaries, extracting key fields into a structured table",
      logo: PolicySummaryIcon,
      userCount: userCount.find((uc: any) => uc.api === "Policy Comparison")?.count || 0,
      visitedKey: "PolicyComparision",
      // rating: 3,
      rating: 0,
      onClick: onPolicyAnalyzerClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 7,
      title: "Driver License Scan",
      description: "Captures data from PDF/IMAGE and converts it into smart forms.",
      logo: DLLogo,
      userCount: userCount.find((uc: any) => uc.api === "Driver License Scan")?.count || 0,
      visitedKey: "DriverLicenseScan",
      rating: 3,
      onClick: onDriverLicenseScanClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 8,
      title: "Invoice Scan",
      description: "Captures data from PDF/Image invoice reports and prepares it for seamless insertion into policy endorsements, ensuring accuracy and efficiency.",
      logo: PolicySummaryIcon,
      userCount: userCount.find((uc: any) => uc.api === "Invoice Scan")?.count || 0,
      visitedKey: "InvoiceScan",
      rating: 3,
      onClick: onInvoiceScanClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
  ];

  const cardData3 = [
    {
      id: 1,
      title: "Data Enrichment",
      description: "Automate your AMS data entry process",
      logo: FenrisLogo,
      userCount: userCount.find((uc: any) => uc.api === "Fenris Prefill")?.count || 0,
      visitedKey: "AMSPreFill",
      // rating: 4,
      rating: 0,
      onClick: onFenrisTileClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 2,
      title: "Classification Codes (NAICS)",
      description: "Append NAICS Codes to your Customer to easily identify the industries of all your clients",
      logo: NaicsLogo,
      userCount: userCount.find((uc: any) => uc.api === "NAICS Classification")?.count || 0,
      visitedKey: "NAICSClassification",
      // rating: 3,
      rating: 0,
      onClick: onNaicsClassificationClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 3,
      title: "Home Owners",
      description: "Instant access to the property data you need",
      logo: HomeIcon,
      userCount: 0,
      // visitedKey: "",
      // onClick: (),
      rating: 0,
      onInfoClick: () => {},
      isComingSoon: true,
    },
    {
      id: 4,
      title: "Carrier Rating Service",
      description: "We provide independent, unbiased ratings on insurance companies",
      logo: ValchoiceLogo,
      userCount: userCount.find((uc: any) => uc.api === "Valchoice Classification")?.count || 0,
      visitedKey: "Valchoice",
      // rating: 3,
      rating: 0,
      onClick: onValchoiceClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 5,
      title: "Vehicle Prefill",
      description: "Quickly populate vehicle information forms",
      logo: VehicleLogo,
      userCount: userCount.find((uc: any) => uc.api === "Vehicle Prefill")?.count || 0,
      visitedKey: "VehiclePreFill",
      // rating: 3,
      rating: 0,
      onClick: onVehiclesClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 6,
      title: "Sonant",
      description: "The AI Receptionist for Insurance Agencies & Brokers",
      logo: SonantLogo,
      userCount: userCount.find((uc: any) => uc.api === "Sonant")?.count || 0,
      visitedKey: "Sonant",
      rating: 3,
      onClick: onSonantClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
  ];
  const premfiWidget = {
    id: 8,
    title: "PremFi",
    description: "Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once.",
    logo: PFLogo,
    userCount: userCount.find((uc: any) => uc.api === "Premium Finance Quotes")?.count || 0,
    visitedKey: "PremFi",
    rating: 0,
    onClick: onNewQuoteClick,
    onInfoClick: onPremfiInfoClick,
    isComingSoon: false,
  };

  const otherWidgets =
    (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2) &&
    CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id &&
    window.innerWidth > 767
      ? [
          {
            id: 1,
            title: "E-Signature",
            description: "Quickly send the same document for signature over and over",
            logo: RabbitSignLogo,
            userCount: userCount.find((uc: any) => uc.api === "Rabbit Sign")?.count || 0,
            visitedKey: "RabbitSign",
            rating: 0,
            onClick: onRabbitSignClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
          {
            id: 2,
            title: "MTM Scan",
            description: "Extracts data from MTM Binder pages to create insured information, along with their vehicle details in NowCerts.",
            logo: MTMLogo,
            userCount: 0,
            visitedKey: "MTMScan",
            rating: 0,
            onClick: onMtmScanClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
          {
            id: 3,
            title: "MVR",
            description: "Accurate MVR insights based on a 5-year driver violation powered by court and public data",
            logo: MvrLogo,
            userCount: 0,
            rating: 0,
            onInfoClick: () => {},
            isComingSoon: true,
          },
          {
            id: 4,
            title: "Rate Risk Appetite",
            description: "Get instant risk appetite insights by simply entering classification code, policy type, and state",
            logo: RateLogo,
            userCount: userCount.find((uc: any) => uc.api === "Appetite")?.count || 0,
            visitedKey: "RateRiskAppetite",
            rating: 0,
            onClick: onAppetiteClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
          {
            id: 5,
            title: "Rate",
            description: "Fast, Reliable Quoting and Binding All Within Your AMS",
            logo: RateLogo,
            // userCount: userCount.find((uc: any) => uc.api === "Rate")?.count || 0,
            userCount: 1399,
            visitedKey: "Rate",
            rating: 0,
            onClick: onRateClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
          {
            id: 6,
            title: "Momentum Automation Center",
            description: "Fusion Automation Center - Put Your Pipeline on Autopilot",
            logo: MacLogo,
            userCount: userCount.find((uc: any) => uc.api === "Mac")?.count || 0,
            visitedKey: "Mac",
            rating: 0,
            onClick: onMacClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
          {
            id: 7,
            title: "LeadX",
            description: "Customized Solutions for Leads Management",
            logo: LeadxLogo,
            userCount: userCount.find((uc: any) => uc.api === "Leadx")?.count || 0,
            visitedKey: "Leadx",
            rating: 0,
            onClick: onLeadxClick,
            onInfoClick: () => {},
            isComingSoon: false,
          },
        ]
      : [];

  const cardData4 = [...otherWidgets, premfiWidget];

  const headingText2 = "Streamline your insurance processes with our suite of powerful AI tools to scan and extract data from documents.";
  const filteredData2 = cardData2.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      headingText2.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const isHeadingVisible2 = headingText2.toLowerCase().includes(searchTerm.toLowerCase()) || filteredData2.length > 0;

  const headingText3 = "Third Party Data Enrichment Integrations";

  const filteredData3 = cardData3.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      headingText3.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const isHeadingVisible3 = headingText3.toLowerCase().includes(searchTerm.toLowerCase()) || filteredData3.length > 0;

  const headingText4 = "Other features";

  const filteredData4 = cardData4.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      headingText4.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const isHeadingVisible4 = headingText4.toLowerCase().includes(searchTerm.toLowerCase()) || filteredData4.length > 0;

  const productTemplate = (card: any) => {
    return (
      <div
        className="card first-carousel-card p-4 mx-auto  "
        onClick={!card.isComingSoon ? card.onClick : undefined}
        style={{ borderRadius: "15px", width: "80%", minHeight: "235px" }}
      >
        <div className="d-flex align-items-center  mb-3">
          <div className="icon-wrapper d-flex justify-content-center align-items-center text-primary  me-2">
            <span className="rounded" style={{ backgroundColor: "#a200ff", padding: "8px" }}>
              <img
                src={card.logo}
                alt={`${card.title} logo`}
                // className="carousel-logo mb-1"

                height={30}
                width={30}
              />
            </span>
          </div>
          <h5 className="mb-0  ">{card.title}</h5>
        </div>
        <p className="text-center text-muted">{card.description}</p>
        <p className="mb-0  text-center">
          {" "}
          {card.isComingSoon ? (
            <span className="badge bg-secondary py-2 px-4 rounded-pill">Coming Soon</span>
          ) : (
            <AtkButton label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"} className="px-4 w-auto pf-primary-btn" />
          )}
        </p>
      </div>
    );
  };

  return (
    <div className="landing-main-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper  landing-content-wrapper only-landing-wrapper mb-1 p-0">
        <div className="">
          <div className="position-relative row   mx-auto  wrapper-below-div d-flex ">
            <div className="video-container">
              <video autoPlay loop muted playsInline className="position-absolute top-0 start-0 w-100 object-fit-cover " style={{ height: "100%" }}>
                <source src={momentumvideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="col-12 position-relative mt-5  mx-auto" style={{ maxWidth: "85vw" }}>
              <h1 className="fw-bold text-light text-center mt-5 z-1" style={{ fontSize: "3.75rem" }}>
                Your Complete Insurance Toolbox
              </h1>
              <div className="mt-4 d-flex flex-wrap justify-content-center text-light">
                <div className="d-flex align-items-center me-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-bot h-5 w-5 text-secondary-light"
                  >
                    <path d="M12 8V4H8"></path>
                    <rect width="16" height="12" x="4" y="8" rx="2"></rect>
                    <path d="M2 14h2"></path>
                    <path d="M20 14h2"></path>
                    <path d="M15 13v2"></path>
                    <path d="M9 13v2"></path>
                  </svg>
                  <span className="ms-1">AI-Powered Tools</span>
                </div>
                <div className="d-flex align-items-center me-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-zap h-5 w-5 text-secondary-light"
                  >
                    <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z"></path>
                  </svg>
                  <span className="ms-1">Enhancement Tools</span>
                </div>
                <div className="d-flex align-items-center me-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-link h-5 w-5 text-secondary-light"
                  >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                  </svg>
                  <span className="ms-1">Integrations</span>
                </div>
              </div>

              {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) && (
                <h2 className="font-bold text-light mb-4 text-center">
                  <div className="row ">
                    {window.innerWidth > 767 ? (
                      <div className="col-12 ">
                        <AtkTextField
                          type="text"
                          id="txtsearchTerm"
                          name="searchTerm"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          value={searchTerm}
                          className="landing-search  d-inline-block  rounded-2"
                          placeholder="Search tools"
                        />
                      </div>
                    ) : null}
                  </div>
                </h2>
              )}
            </div>
            {showMessage && (
              <div className="col-12 position-relative my-3 text-center text-16 text-light ">
                <span>
                  Please{" "}
                  <a
                    onClick={onSettingTileClick}
                    style={{
                      color: "#7ba340",
                    }}
                    className="show-cursor"
                  >
                    choose{" "}
                  </a>{" "}
                  Premium Finance partner(s).
                </span>
              </div>
            )}
            <div
              className={` ps-3 d-flex justify-content-center ${
                showPrimaryAmsMessage || (nowCertsMessageShow && CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id)
                  ? "col-lg-12 col-md-12 col-sm-12  "
                  : "col-lg-12 col-md-12 col-sm-12 "
              } mb-2  ${showMessage ? "flex-column" : ""} `}
            >
              {showPrimaryAmsMessage ? (
                // <div className="row mx-auto  position-relative ">
                <div
                  className="position-relative landing-page-blocks pf-landing-block d-flex align-items-center text-center"
                  style={{ width: "fit-content" }}
                >
                  <span className="text-dark p-2">
                    This version of the website does not support your AMS;
                    <br /> please use the <b>Chrome Extension</b>.
                  </span>
                </div>
              ) : // </div>
              localStorage.getItem("tenantId") === "0" ? (
                <div
                  className="position-relative landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center"
                  style={{ width: "fit-content" }}
                >
                  <span className=" text-dark p-2 text-center">
                    Please{" "}
                    <a onClick={gotopfsettingPage} className="no-primary-ams">
                      select
                    </a>{" "}
                    your primary AMS
                  </span>
                </div>
              ) : nowCertsMessageShow && CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                <div
                  className="position-relative landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center"
                  style={{ width: "fit-content" }}
                >
                  <span className="text-dark p-2">
                    Please{" "}
                    <a onClick={gotopfsettingPage} className="no-primary-ams">
                      provide
                    </a>{" "}
                    your NowCerts credentials to continue.
                  </span>
                </div>
              ) : CommonValues.GetTenantId() === process.env.REACT_APP_Hawksoft_Tenant_Id &&
                (localStorage.getItem("HawksoftAgencyId") === "false" ||
                  localStorage.getItem("HawksoftAgencyId") == null ||
                  localStorage.getItem("HawksoftAgencyId") === "") ? (
                <div
                  className=" position-relative landing-page-blocks pf-landing-block d-flex align-items-center  justify-content-center text-center"
                  style={{ width: "fit-content" }}
                >
                  <span className="text-dark p-2">
                    Please{" "}
                    <a onClick={gotopfsettingPage} className="no-primary-ams">
                      provide{" "}
                    </a>{" "}
                    your HawkSoft agency id to continue.
                  </span>
                </div>
              ) : CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id &&
                (localStorage.getItem("VertaforeSetting") === "true" ||
                  localStorage.getItem("VertaforeSetting") == null ||
                  localStorage.getItem("VertaforeSetting") === "") ? (
                <div
                  className=" position-relative  landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center"
                  style={{ width: "fit-content" }}
                >
                  <span className="text-dark p-2">
                    Please{" "}
                    <a onClick={gotopfsettingPage} className="no-primary-ams">
                      provide{" "}
                    </a>{" "}
                    your AMS360 credentials to continue.
                  </span>
                </div>
              ) : settingLoading ? (
                <div className="d-flex justify-content-center mt-2 mb-2" style={{ minHeight: "200px", width: "100%" }}>
                  <div className="card first-carousel-card p-4 mx-auto  " style={{ borderRadius: "15px", width: "35%", minHeight: "235px" }}>
                    <Skeleton width="100%" className="mb-2 d-block"></Skeleton>
                    <Skeleton width="80%" className="mb-2 d-block"></Skeleton>
                    <Skeleton width="50%" className="mb-2 d-block"></Skeleton>
                    <Skeleton width="30%" className="mb-2 d-block"></Skeleton>
                  </div>
                </div>
              ) : isVisible ? (
                <div className="d-flex justify-content-center mt-2 mx-auto" style={{ minHeight: "200px", maxWidth: "80vw" }}>
                  <Carousel
                    ref={carouselRef}
                    value={carouselItems}
                    numVisible={1}
                    numScroll={1}
                    className={`custom-carousel visible`}
                    autoplayInterval={4000}
                    itemTemplate={productTemplate}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {filteredData2.length == 0 && filteredData3.length == 0 && filteredData4.length == 0 ? (
            <div className="row mt-5 mx-auto">
              <div className="col-5"></div>
              <div className="col-2 text-center text-14">
                <p className="mt-5" style={{ color: "red", textAlign: "center", background: "#fff", padding: "5px 10px", borderRadius: "4px" }}>
                  Oops ! No tools found
                </p>
              </div>
            </div>
          ) : null}
          <div className="row mx-auto" style={{ maxWidth: "80vw", marginTop: "3rem" }}>
            {settingLoading ? null : (
              <>
                {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) &&
                CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id &&
                window.innerWidth > 767 ? null : (
                  <>
                    {isHeadingVisible4 && <p className="landing-heading-text-color mt-2 mb-4 ps-3">{headingText4}</p>}

                    {filteredData4.map((card) => (
                      <div key={card.id} className="landing-page-card-col col-lg-3 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3">
                        <div
                          className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                          onClick={!card.isComingSoon ? card.onClick : undefined}
                        >
                          <div className="row d-flex align-items-center" style={{ minHeight: "20px" }}>
                            <div className="col-7">
                              {card.rating == 0 ? null : (
                                <span className="app-star-ratings">
                                  {[...Array(card.rating)].map((_, i) => (
                                    <StarFilledIcon key={i} label={`star-${i}`} />
                                  ))}
                                  {[...Array(5 - card.rating)].map((_, i) => (
                                    <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                  ))}
                                </span>
                              )}
                            </div>
                            <div className="col-5 text-end">
                              {card.isComingSoon || card.userCount === 0 ? null : (
                                <>
                                  {userCountLoading ? (
                                    <AtkLoader />
                                  ) : (
                                    <small className="me-1 user-count">
                                      <i className="fa fa-user me-1"></i>
                                      {card.userCount}
                                    </small>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <span className={`${card.isComingSoon ? "" : "show-cursor"}`}>
                            <div className="card-header">
                              <img src={card.logo} alt="Service Logo" className="service-logo" />
                              <h2 className="service-title">{card.title}</h2>
                            </div>
                            <p className="service-description">{card.description}</p>
                          </span>
                          <div className="row">
                            {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                              <div className="col-6">
                                <div className="col-6">
                                  <div className="card-buttons">
                                    <img
                                      src={infoImg2}
                                      title="How it works"
                                      className="me-2 show-cursor"
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        card.onInfoClick();
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-6">
                              <div className="card-buttons-2">
                                {card.isComingSoon ? (
                                  <div className="coming-soon-banner">
                                   <span>Coming Soon</span>
                                  </div>
                                ) : (
                                  <AtkButton
                                    label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                    className="pf-primary-btn"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {window.innerWidth < 767 ? <OurTools /> : null}
                  </>
                )}
                {window.innerWidth > 767 ? (
                  <>
                    {showOCR ? (
                      <>
                        {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) &&
                        CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                          <>
                            {isHeadingVisible2 && <p className="landing-heading-text-color mt-2 mb-4 ps-3">{headingText2}</p>}

                            {filteredData2.map((card) => (
                              <div
                                key={card.id}
                                className="landing-page-card-col col-lg-3 col-md-6 col-sm-12  mb-2 d-flex justify-content-center ps-3"
                              >
                                <div
                                  className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                  onClick={!card.isComingSoon ? card.onClick : undefined}
                                >
                                  <div className="row d-flex align-items-center" style={{ minHeight: "20px" }}>
                                    <div className="col-7">
                                      {card.rating == 0 ? null : (
                                        <span className="app-star-ratings">
                                          {[...Array(card.rating)].map((_, i) => (
                                            <StarFilledIcon key={i} label={`star-${i}`} />
                                          ))}
                                          {[...Array(5 - card.rating)].map((_, i) => (
                                            <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                          ))}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-5 text-end">
                                      {card.isComingSoon ? null : (
                                        <>
                                          {userCountLoading ? (
                                            <AtkLoader />
                                          ) : (
                                            <small className="me-1 user-count">
                                              <i className="fa fa-user me-1"></i>
                                              {card.userCount}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <span className={`${card.isComingSoon ? "" : "show-cursor"}`}>
                                    <div className="card-header">
                                      <img src={card.logo} alt="Service Logo" className="service-logo" />
                                      <h2 className="service-title">{card.title}</h2>
                                    </div>
                                    <p className="service-description">{card.description}</p>
                                  </span>
                                  <div className="row">
                                    {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                      <div className="col-6">
                                        <div className="col-6">
                                          <div className="card-buttons">
                                            <img
                                              src={infoImg2}
                                              title="How it works"
                                              className="me-2 show-cursor"
                                              height={20}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                card.onInfoClick();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-6">
                                      <div className="card-buttons-2">
                                        {card.isComingSoon ? (
                                          <div className="coming-soon-banner">
                                         <span>Coming Soon</span>
                                          </div>
                                        ) : (
                                          <AtkButton
                                            label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                            className="pf-primary-btn"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        <OurTools />
                        {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) &&
                        CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                          <>
                            {isHeadingVisible3 && <p className=" landing-heading-text-color mt-2 mb-4 ps-3 ">{headingText3}</p>}

                            {filteredData3.map((card) => (
                              <div
                                key={card.id}
                                className="landing-page-card-col col-lg-3 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3"
                              >
                                <div
                                  className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                  onClick={!card.isComingSoon ? card.onClick : undefined}
                                >
                                  <div className="row d-flex align-items-center" style={{ minHeight: "20px" }}>
                                    <div className="col-7">
                                      {card.rating == 0 ? null : (
                                        <span className="app-star-ratings">
                                          {[...Array(card.rating)].map((_, i) => (
                                            <StarFilledIcon key={i} label={`star-${i}`} />
                                          ))}
                                          {[...Array(5 - card.rating)].map((_, i) => (
                                            <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                          ))}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-5 text-end">
                                      {card.isComingSoon ? null : (
                                        <>
                                          {userCountLoading ? (
                                            <AtkLoader />
                                          ) : (
                                            <small className="me-1 user-count">
                                              <i className="fa fa-user me-1"></i>
                                              {card.userCount}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <span className={`${card.isComingSoon ? "" : "show-cursor"}`}>
                                    <div className="card-header">
                                      <img src={card.logo} alt="Service Logo" className="service-logo" />
                                      <h2 className="service-title">{card.title}</h2>
                                    </div>
                                    <p className="service-description">{card.description}</p>
                                  </span>
                                  <div className="row">
                                    {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                      <div className="col-6">
                                        <div className="col-6">
                                          <div className="card-buttons">
                                            <img
                                              src={infoImg2}
                                              title="How it works"
                                              className="me-2 show-cursor"
                                              height={20}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                card.onInfoClick();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-6">
                                      <div className="card-buttons-2">
                                        {card.isComingSoon ? (
                                          <div className="coming-soon-banner">
                                            <span>Coming Soon</span>
                                          </div>
                                        ) : (
                                          <AtkButton
                                            label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                            className="pf-primary-btn"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {isHeadingVisible4 && <p className="landing-heading-text-color mt-2 mb-4 ps-3">{headingText4}</p>}

                            {filteredData4.map((card) => (
                              <div
                                key={card.id}
                                className="landing-page-card-col col-lg-3 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3"
                              >
                                <div
                                  className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                  onClick={!card.isComingSoon ? card.onClick : undefined}
                                >
                                  <div className="row d-flex align-items-center" style={{ minHeight: "20px" }}>
                                    <div className="col-7">
                                      {card.rating == 0 ? null : (
                                        <span className="app-star-ratings">
                                          {[...Array(card.rating)].map((_, i) => (
                                            <StarFilledIcon key={i} label={`star-${i}`} />
                                          ))}
                                          {[...Array(5 - card.rating)].map((_, i) => (
                                            <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                          ))}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-5 text-end">
                                      {card.isComingSoon || card.userCount === 0 ? null : (
                                        <>
                                          {userCountLoading ? (
                                            <AtkLoader />
                                          ) : (
                                            <small className="me-1 user-count">
                                              <i className="fa fa-user me-1"></i>
                                              {card.userCount}
                                            </small>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <span className={`${card.isComingSoon ? "" : "show-cursor"}`}>
                                    <div className="card-header">
                                      <img src={card.logo} alt="Service Logo" className="service-logo" />
                                      <h2 className="service-title">{card.title}</h2>
                                    </div>
                                    <p className="service-description">{card.description}</p>
                                  </span>
                                  <div className="row">
                                    {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                      <div className="col-6">
                                        <div className="col-6">
                                          <div className="card-buttons">
                                            <img
                                              src={infoImg2}
                                              title="How it works"
                                              className="me-2 show-cursor"
                                              height={20}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                card.onInfoClick();
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-6">
                                      <div className="card-buttons-2">
                                        {card.isComingSoon ? (
                                          <div className="coming-soon-banner">
                                            <span>Coming Soon</span>
                                          </div>
                                        ) : (
                                          <AtkButton
                                            label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                            className="pf-primary-btn"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog visible={showInfoDialogOFAcord} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={IFSLogo} className="me-3" width={40} />
                    Acord and Dec pages Scan
                  </h2>
                </div>
                <ReactPlayer className="react-video-dialog my-3" width="100%" height="350px" url={"https://youtu.be/Sv1ZDzkEabU"} controls={true} />
                <p className="text-14">
                  Extracts data from Acord forms and declaration pages to create insured and prospect information, along with their quote and policy
                  details in NowCerts. This AI-powered tool efficiently processes Acord 125, 126, and 130 forms, as well as declaration pages and
                  quote sheets, streamlining the data extraction process. By automating the creation of accurate insured and prospect records, this
                  tool enhances productivity and reduces manual entry, making it easier for users to manage their insurance information in NowCerts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showInfoDialogOFCOI} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={COILogo} className="me-3" width={40} />
                    Certificate of Insurance Scan
                  </h2>
                </div>
                <ReactPlayer className="react-video-dialog my-3" width="100%" height="350px" url={"https://youtu.be/satgAN6DGac"} controls={true} />
                <p className="text-14">
                  Extracts Certificate of Insurance data and generates a CSV file for easy import of certificate holders. Currently compatible with
                  Acord 25 Certificates, this AI-powered tool simplifies the extraction process by organizing certificate holder information into
                  clear columns, making it easy to import into the NowCerts Data Import tool.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog visible={showInfoDialogOFPremfiscan} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={ScanIcon} className="me-3" width={40} />
                    PremFi (PFA) Scan
                  </h2>
                </div>
                <p className="text-14">
                  Extracts data from premium finance agreements to update or create insured and policy information in NowCerts. This AI-powered tool
                  currently processes agreements from Capital Premium Finance and IPFS, streamlining the data management process and enhancing
                  accuracy. By automating the extraction of key information, it simplifies the creation and updating of insured records, making it
                  easier for users to maintain comprehensive policy details in NowCerts. With many more premium finance agreements to be added in the
                  future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showInfoDialogOfCommisionscan}
        style={{ width: "50vw" }}
        onHide={onCloseInfoDialogClick}
        draggable={false}
        className="infoDialog"
      >
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h3 className="font-weight-600">
                    {" "}
                    <img src={CommissionScanLogo} className="me-3" width={40} />
                    Commission Scan
                  </h3>
                </div>
                <p className="text-14">
                  Captures data from PDF commission reports and converts it into CSV files, ready for import into Momentum. Efficiently processing PDF
                  files streamlines the data extraction process to provide accurate and structured commission data in CSV format.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showInfoDialogOfPremfi} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h3 className="font-weight-600">
                    {" "}
                    <img src={PFLogo} className="me-3" width={40} />
                    PremFi
                  </h3>
                </div>
                <p className="text-14">
                  Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once. Once a quote
                  is accepted, it enables instant, one-click generation of a finance agreement. By automating access to multiple financing options and
                  simplifying agreement generation, this tool enhances productivity and makes premium financing more efficient.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
