import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { useEffect, useState, useRef } from "react";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import AtkButton from "../../AtlaskitControls/AtkButton";
import PFOcrScan from "./PFSOcrScan";
import Toggle from "@atlaskit/toggle";

export default function PrimaryDetailsPage(props: any) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchClientList, setSearchClientList] = useState<any>([]);
  const [searchClientLoading, setSearchClientLoading] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [nowcertsPolicyLoading, setNowcertsPolicyLoading] = useState(false);
  const [nowCertPolicyData, setNowCertPolicyData] = useState<any>([]);
  const [selectedPolicy, setSelectedPolicy] = useState("createNew");
  const [isNewProspectSelected, setIsNewProspectSelected] = useState(true);
  const [isExistingProspectSelected, setIsExistingProspectSelected] = useState(false);
  const [isNewPolicySelected, setIsNewPolicySelected] = useState(true);
  const [isExistingPolicySelected, setIsExistingPolicySelected] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState("createNew");
  const [showOcrSecondPage, setShowOcrSecondPage] = useState(false);
  const toast: any = useRef("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [lineOfBusinessName, setLineOfBusinessName] = useState("");
  const [clientNameError, setClientNameError] = useState("");
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<{ id: any; index: any; code: any; clientName: any }[]>([]);
  useEffect(() => {
    if (search.replaceAll(" ", "") == "") {
      return;
    }
    setSearchClientLoading(true);
    if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
      const searchTimeout = setTimeout(() => {
        getNowCertsClients(search);
      }, 10);
      return () => clearTimeout(searchTimeout);
    }
  }, [search]);
  const checkValidation = () => {
    let formIsValid = true;
    setClientNameError("");

    if (selectedProspect == "useExisting" && clientName.length == 0) {
      setClientNameError("Please select client name");
      formIsValid = false;
    } else {
      setClientNameError("");
    }

    if (isNewPolicySelected == false && isExistingPolicySelected == false) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please select policy action",
          life: 3000,
        });
      }
      formIsValid = false;
    } else if (selectedPolicy == "useExisting" && policyNumber == "" && clientName.length > 0) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please select policy",
          life: 3000,
        });
      }
      formIsValid = false;
    }
    if (isNewProspectSelected == false && isExistingProspectSelected == false) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please select client action",
          life: 3000,
        });
      }
      formIsValid = false;
    }

    return formIsValid;
  };
  const handlePolicyChange = (event: any, value: any) => {
    if (value === "useExisting" && event.target.checked == true) {
      setIsExistingPolicySelected(true);
      setIsNewPolicySelected(false);
    } else if (value === "createNew" && event.target.checked == true) {
      setIsNewPolicySelected(true);
      setIsExistingPolicySelected(false);
    } else {
      setIsNewPolicySelected(false);
      setIsExistingPolicySelected(false);
    }

    setSelectedPolicy(value);
    if (value === "useExisting" && event.target.checked == true) {
      if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && clientName != "") {
        getNowCertsPoliciesList(clientId);
      } else {
        setNowCertPolicyData([]);
      }
    } else {
      setNowCertPolicyData([]);
    }
  };
  const handleProspectChange = (event: any, value: any) => {
    if (value === "useExisting" && event.target.checked == true) {
      setIsExistingProspectSelected(true);
      setIsNewProspectSelected(false);
    } else if (value === "createNew" && event.target.checked == true) {
      if (selectedPolicy === "useExisting") {
        setIsExistingPolicySelected(false);
        setIsNewPolicySelected(true);
      }
      setIsNewProspectSelected(true);
      setIsExistingProspectSelected(false);
    }

    setSelectedProspect(value);
    if (value == "createNew") {
      setSelectedPolicy("createNew");
      setClientName("");
      setClientId("");
    }
  };
  const onClientSearchChange = (value: any) => {
    if (value.length > 255 || selectedProspect !== "useExisting") {
      return;
    }

    setSearch(value);
  };
  const onClientSelect = (client: any) => {
    setSearch("");
    setClientName(client.name);
    setClientId(client.databaseId);
    setClientNameError("");
    if (CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id && selectedProspect == "useExisting") {
      getNowCertsPoliciesList(client.databaseId);
    }
  };
  const onNextButtonClick = () => {
    if (checkValidation()) {
      setShowOcrSecondPage(true);
    } else {
      setShowOcrSecondPage(false);
    }
  };
  const onBackClick = () => {
    setShowOcrSecondPage(false);
  };
  const getNowCertsClients = (query: string) => {
    var data = {
      SearchValue: query,
    };
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setSearchClientList(response.data);
        setSearchClientLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the insureds data.Please try again later",
                life: 3000,
              });
            }
          }
        }
        setSearchClientLoading(false);
      });
  };
  const getNowCertsPoliciesList = (insuredId: string) => {
    setNowcertsPolicyLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insuredpolicies?insuredId=${insuredId}&nowCertsToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          setNowCertPolicyData(response.data);
        } else {
          setNowCertPolicyData([]);
        }
        setNowcertsPolicyLoading(false);
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Unknown error while loading the client policy. Please try again later.",
                life: 3000,
              });
            }
          }
        }
        setNowcertsPolicyLoading(false);
      });
  };
  const onSelectMultiplePolicies = (rowData: any, index: any) => {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value=""
            checked={selectedPolicyIds.some((item) => item.id === rowData.databaseId)}
            onChange={() => onSelectedMultiplePolicyChange(rowData, index)}
            className="show-cursor bundle-checkbox"
          />
        </label>
      </div>
    );
  };
  const onSelectedMultiplePolicyChange = (rowData: any, index: any) => {
    let code = "";
    if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
      code = rowData.linesOfBusiness[0];
    }
    setPolicyNumber(rowData.policyNumber);
    setLineOfBusinessName(code);
    let setIndex: number;

    if (window.innerWidth > 767) {
      setIndex = index.rowIndex;
    } else {
      setIndex = index;
    }
    setSelectedPolicyIds([
      {
        id: rowData.databaseId,
        index: setIndex,
        code: code,
        clientName: rowData.clientName,
      },
    ]);
    // }
  };
  return (
    <>
      <Toast ref={toast} />
      {showOcrSecondPage ? (
        <PFOcrScan
          onBackClick={onBackClick}
          insuredId={clientId}
          existingPolicyId={policyNumber}
          lineOfBusinessName={lineOfBusinessName}
          prospectDetails={selectedProspect == "useExisting" ? clientName : "Create Client"}
          policyDetails={selectedPolicy == "useExisting" ? policyNumber : "Create Policy"}
          nowCertPolicyData={nowCertPolicyData}
        />
      ) : (
        <>
          <div className="row scan-search-row mx-auto d-flex  align-items-center">
            <div className="col-10">
              <div className="row">
                <div className="col-10">
                  <div className="d-flex text-14">
                    <div className="d-flex flex-column me-2">
                      <p className="text-16 fw-bold">Client</p>
                      <div className="col-12">
                        <div className="p-field-checkbox d-flex align-items-center">
                          <label htmlFor="createNew">Create New</label>
                          <Toggle id="createNew" isChecked={isNewProspectSelected} onChange={(e) => handleProspectChange(e, "createNew")} />
                        </div>
                        <div className="p-field-checkbox  d-flex align-items-center">
                          <label htmlFor="useExisting">Use Existing</label>
                          <Toggle id="useExisting" isChecked={isExistingProspectSelected} onChange={(e) => handleProspectChange(e, "useExisting")} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column me-2">
                      <p className="text-16 fw-bold">Policy</p>

                      <div className="col-12">
                        <div className="p-field-checkbox d-flex align-items-center">
                          <label htmlFor="createNew">Create New</label>
                          <Toggle id="createNew" isChecked={isNewPolicySelected} onChange={(e) => handlePolicyChange(e, "createNew")} />
                        </div>
                        <div className="p-field-checkbox  d-flex align-items-center">
                          <label htmlFor="useExisting">Use Existing</label>
                          <Toggle
                            id="useExisting"
                            isChecked={isExistingPolicySelected}
                            onChange={(e) => handlePolicyChange(e, "useExisting")}
                            isDisabled={selectedProspect === "createNew" ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    {selectedProspect == "useExisting" ? (
                      <>
                        <div className=" position-relative mb-2 p-0 ">
                          <p className="text-16 fw-bold ">Select Client </p>

                          <div className={`search-bar flex-column ${clientNameError.length > 0 ? " validation-div " : " "}`}>
                            <AtkTextField
                              type="text"
                              id="client-name"
                              className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 `}
                              name="search"
                              onChange={(e) => {
                                onClientSearchChange(e.target.value);
                              }}
                              value={search}
                              placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                              isDisabled={selectedProspect !== "useExisting"}
                            />
                          </div>
                          {search.replaceAll(" ", "").length > 0 && (
                            <div id="client-search" className=" position-absolute  ">
                              {searchClientLoading ? (
                                <div className="d-flex justify-content-center my-2 client-loader-div">
                                  <AtkLoader />
                                </div>
                              ) : searchClientList.length == 0 ? (
                                <div style={{ padding: "5px", background: "white" }}>
                                  <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                </div>
                              ) : (
                                searchClientList.map((item: any) => (
                                  <div
                                    style={{ cursor: "pointer", width: "100%" }}
                                    onClick={() => {
                                      onClientSelect(item);
                                    }}
                                  >
                                    <a
                                      href="#"
                                      style={{
                                        color: "black",
                                        width: "100%",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.name}
                                    </a>
                                  </div>
                                ))
                              )}
                            </div>
                          )}
                          <span className="text-danger text-12">{clientNameError}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2 text-end">
              <AtkButton label="Next" onClick={onNextButtonClick} className="pf-secondary-btn w-100 " />
            </div>
          </div>

          <div className="row p-3 mt-2">
            {selectedPolicy == "useExisting" && isExistingPolicySelected && clientName.length > 0 ? (
              <>
                <p className=" policy-type-heading">Existing Policies</p>
                <div className="col-12  history-table mt-2">
                  {CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id ? (
                    nowcertsPolicyLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable
                        value={nowCertPolicyData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                        selection={selectedPolicy}
                      >
                        <Column
                          header=""
                          field=""
                          body={(rowData, rowIndex) => onSelectMultiplePolicies(rowData, rowIndex)}
                          bodyClassName="hide-cursor"
                        ></Column>
                        <Column
                          header="Title"
                          field="linesOfBusiness[0]"
                          body={(rowData: any) => {
                            if (rowData.linesOfBusiness != null || rowData.linesOfBusiness !== undefined) {
                              return rowData.linesOfBusiness[0];
                            }
                          }}
                          bodyClassName="hide-cursor"
                        ></Column>
                        <Column
                          header="Effective Date"
                          field="effectiveDate"
                          body={(rowData: any) => {
                            if (rowData.effectiveDate != null || rowData.effectiveDate !== "") {
                              return moment.utc(rowData.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                            }
                          }}
                          bodyClassName="hide-cursor"
                          sortable
                        ></Column>
                        <Column header="Policy Number" field="policyNumber" sortable bodyClassName="hide-cursor"></Column>
                        <Column
                          header="Total Premium"
                          field="totalPremium"
                          body={(rowData) => <span>${rowData.totalPremium}</span>}
                          sortable
                          bodyClassName="hide-cursor"
                        ></Column>
                      </DataTable>
                    )
                  ) : null}
                </div>{" "}
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}