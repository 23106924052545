import { useState, useEffect, useRef } from "react";
import CommonValues, { Logout } from "../../common/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ResponseButton from "./ResponseButton";
import axios from "axios";
import HistoryDetails from "./HistoryDetails";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import DownloadIcon from "../../assets/images/icon _download_.png";
import { saveAs } from "file-saver";
import SendIcon from "@atlaskit/icon/glyph/send";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Card } from "primereact/card";
import MoreBtn from "../../assets/images/continue.png";
import { DataView } from "primereact/dataview";

function ResponseTable(props: any) {
  const [historyData, setHistoryData] = useState<any>([]);
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const [amsLoading, setAmsLoading] = useState(false);
  const [sentToAMS, setSentToAMS] = useState(false);
  const toast: any = useRef("");
  const [transid, setTransid] = useState("");
  const [downloadloading, setDownloadLoading] = useState(false);
  const [amsId, setAmsId] = useState("");
  const [dataTableRows, setDataTableRows] = useState(15);
  const [expandedCard, setExpandedCard] = useState<any>([]);
  const [rows, setRows] = useState(3);
  const [first, setFirst] = useState(0);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setHistoryData(props.tableValue);
  }, [props.tableValue]);

  useEffect(() => {
    updateRowsBasedOnScreenWidth();
    window.addEventListener("resize", updateRowsBasedOnScreenWidth);

    return () => {
      window.removeEventListener("resize", updateRowsBasedOnScreenWidth);
    };
  }, []);
  const updateRowsBasedOnScreenWidth = () => {
    if (window.innerWidth <= 767) {
      setDataTableRows(5);
    } else {
      setDataTableRows(15);
    }
  };
  const onContinueIPFSClicked = (data: any) => {
    window.open(data.documentURL, "_blank");
  };
  const onDownloadClick = (res: any) => {
    var token = CommonValues.GetToken();
    if (res.transactionId != "" && res.transactionId != null) {
      setTransid(res.transactionId);
      setDownloadLoading(true);
      axios({
        url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/agreement/${res.transactionId}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          saveAs(response.data, res.targetPartner + "_" + res.policyNumber);
          toast.current.show({ severity: "success", summary: 'Success', detail: "File downloaded Successfully!", life: 3000 });

          setDownloadLoading(false);
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error", summary: 'Error',

                detail: "Unknown error while downloading the Premium finance Agreement",
                life: 3000,
              });
              errorMessage = "Unknown error while downloading the Premium finance Agreement ";
            }
          }
          setDownloadLoading(false);
        });
    }
  };

  const onSendToAMSClicked = (data: any) => {
    var token = CommonValues.GetToken();
    setAmsId(data.transactionId);
    if (data.transactionId != "" && data.transactionId != null) {
      setAmsLoading(true);
      axios({
        url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/agreement/${data.transactionId}`,
        method: "GET",
        responseType: "blob", // important
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileReader = new FileReader();

          fileReader.onload = function (evt) {
            var binary = "";
            var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            if (data.tenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
              sendToNowCertsAMSAPI(btoa(binary), data);
            }

            if (data.tenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
              sendToAMSAPI(btoa(binary), data);
            }
            if (data.tenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
              setAmsLoading(false);
            }
          };
          fileReader.readAsArrayBuffer(file);
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while saving file to AMS ";
            }
          }
          setAmsLoading(false);
        });
    }
  };
  const sendToAMSAPI = (base64String: any, res: any) => {
    let ProviderName = "";
    let NCPolicyNumber = "";

    const TransactionId = res.transactionId || "";
    const QuoteNumber = res.quoteNumber || "";

    if (res.targetPartner) {
      ProviderName = res.targetPartner;
    }
    if (res.ncPolicyNumber == null || res.ncPolicyNumber == "") {
      NCPolicyNumber = res.policyNumber || "";
    }
    var data = JSON.stringify({
      TransactionId: TransactionId,
      PolicyIndex: res.policyIndex.toString(),
      ClientId: res.clientId.toString(),
      FileData: base64String,
      LogDescription: {
        Description: "File",
        LogDescription: "InsuranceGIG Premium Finance  Import",
        LogBody: `Here’s the document from Premium Finance
                  PF Provider   : ${ProviderName}
                  Quote Number  : ${QuoteNumber}
                  Policy Number : ${NCPolicyNumber}
                  Total Premium : ${res.totalPremium}`,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_HAWKSOFTAPI}/api/Hawksoft/log`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        updateSentToAMSStatus(TransactionId);
        setAmsLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "File saved Successfully!", life: 3000 });
        setSentToAMS(true);
        const updatedHistoryData = historyData.map((item: any) => (item.id === res.id ? { ...item, sentToAMS: true } : item));
        setHistoryData(updatedHistoryData);
      })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while saving file to AMS", life: 3000 });
          }
        }
        setAmsLoading(false);
      });
  };
  const sendToNowCertsAMSAPI = (base64String: any, res: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let NCPolicyNumber = "";
    let ProviderName = "";

    if (res.ncPolicyNumber == null || res.ncPolicyNumber == "") {
      NCPolicyNumber = res.policyNumber || "";
    }

    if (res.targetPartner) {
      ProviderName = res.targetPartner;
    }
    const QuoteNumber = res.quoteNumber || "";
    const NCPolicyId = res.policyId || "";
    const NCInsuredId = res.insuredId || "";
    const TransactionId = res.transactionId || "";

    var data = JSON.stringify({
      TransactionId: TransactionId,
      FileName: NCPolicyNumber + "_" + ProviderName + "_" + QuoteNumber,
      NCPolicyId: NCPolicyId,
      NCInsuredId: NCInsuredId,
      FileData: base64String,
      FolderName: NCPolicyNumber + "_" + ProviderName + "_" + QuoteNumber,
      NowCertsToken: nowCertsToken,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydocs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        updateSentToAMSStatus(TransactionId);
        setAmsLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "File saved Successfully!", life: 3000 });
        setSentToAMS(true);
        const updatedHistoryData = historyData.map((item: any) => (item.id === res.id ? { ...item, sentToAMS: true } : item));
        setHistoryData(updatedHistoryData);
      })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while saving file to AMS", life: 3000 });
          }
        }

        setAmsLoading(false);
      });
  };

  const updateSentToAMSStatus = (TransactionId: any) => {
    var token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/amsimported?transactionId=${TransactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        props.getPremiumFinanceSavedHistory();
      })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating ams sent status ";
          }
        }
      });
  };

  const handleSaveStatusUpdate = (id: any, newStatus: any) => {
    const updatedHistoryData = historyData.map((item: any) => (item.id === id ? { ...item, isSaved: newStatus } : item));
    setHistoryData(updatedHistoryData);
  };

  const handleSendtoAmsUpdate = (id: any, newStatus: any) => {
    const updatedHistoryData = historyData.map((item: any) => (item.id === id ? { ...item, sentToAMS: newStatus } : item));
    setHistoryData(updatedHistoryData);
  };
  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="row mx-auto">
        <div className="col-12   w-100 p-0">
          <div className="history-expanded-block ">
            <div className="row mx-auto">
              <div className="col-lg-10 col-md-12 col-sm-12 details-border ">
                <HistoryDetails data={data} />
              </div>
              {window.innerWidth < 767 && data.targetPartner == "IPFS" ? null : (
                <div className=" col-lg-2 col-md-12 col-sm-12  expanded-row-button-block history-response-btns">
                  <ResponseButton
                    rowData={data}
                    fromHistory={true}
                    agencyname={props.agencyName}
                    getPremiumFinanceSavedHistory={props.getPremiumFinanceSavedHistory}
                    onSaveStatusUpdate={handleSaveStatusUpdate}
                    onSendtoAmsUpdate={handleSendtoAmsUpdate}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showPartnerDetails = (rowData: any) => {
    return (
      <span className="partner-logo-and-span">
        {rowData.logoUrl != null ? <img src={rowData.logoUrl} height="25" width="25" className="me-2" /> : null}
        <span className="display-for-desktop-flex">{rowData.targetPartner}</span>
      </span>
    );
  };

  const showActions = (rowData: any) => {
    return (
      <>
        <div className="history-action-column">
          {rowData.targetPartner == "IPFS" && rowData.documentURL != "" && rowData.documentURL != null ? (
            <div className="col-lg-12  col-md-3 col-sm-6 col-12 d-flex align-items-center justify-content-center  text-center">
              <AtkButton label="Continue" onClick={() => onContinueIPFSClicked(rowData)} className="pf-primary-btn w-auto me-1   my-1 " />
            </div>
          ) : (
            <>
              {rowData.quoteNumber == "0" || rowData.quoteNumber == "" || rowData.quoteNumber == null ? (
                <span className=" text-12 p-1 quote-not-span ">Quote not available.</span>
              ) : (
                <>
                  {rowData.message == "Insurance Company match not found" ? (
                    <span className=" text-12 company-match-span p-1">Insurance Company match not found</span>
                  ) : (
                    <>
                      {rowData.transactionId == transid && downloadloading ? (
                        <AtkLoader />
                      ) : rowData.targetPartner == "IPFS" ? null : (
                        <img src={DownloadIcon} onClick={() => onDownloadClick(rowData)} title="Download" className="show-cursor me-2" />
                      )}

                      {rowData.message == "Insurance Company match not found" ||
                      !rowData.isDocumentAvailable ||
                      rowData.targetPartner == "IPFS" ? null : (
                        <>
                          {rowData.transactionId == amsId && amsLoading ? (
                            <AtkLoader />
                          ) : (
                            <>
                              {rowData.sentToAMS ? (
                                <>
                                  <span className=" text-12">
                                    <i className="fa fa-check sent-to-ams-icon" />
                                    Saved to AMS
                                  </span>
                                </>
                              ) : (
                                <>
                                  {/* {CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id ? null : (
                                <> */}
                                  {rowData.tenantId == process.env.REACT_APP_Vertafore_Tenant_Id ? null : (
                                    <>
                                      {rowData.isIndicative == "true" ? null : (
                                        <span onClick={() => onSendToAMSClicked(rowData)} className="show-cursor" title="Save to AMS Files">
                                          <SendIcon label="Save to AMS Files" />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {/* </>
                          )} */}
                            </>
                          )}
                        </>
                      )}
                      {rowData.isIndicative == "true" ? (
                        <span className=" text-12">
                          <i className="fa fa-info indicative-icon" />
                          Indicative
                        </span>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const formatTotalPremium = (rowData: any) => {
    const totalPremium = rowData.totalPremium;

    if (totalPremium == null || totalPremium === "") {
      return parseFloat("0").toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return parseFloat(totalPremium).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  const formatDownpayment = (rowData: any) => {
    const downPayment = rowData.downPayment;

    if (downPayment == null || downPayment === "") {
      return parseFloat("0").toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return parseFloat(downPayment).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };
  const formatInstallmentAmount = (rowData: any) => {
    const installmentAmount = rowData.installmentAmount;

    if (installmentAmount == null || installmentAmount === "") {
      return parseFloat("0").toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return parseFloat(installmentAmount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };
  const formatEffectiveAPR = (rowData: any) => {
    const effectiveAPR = rowData.effectiveAPR;
    if (effectiveAPR === null || effectiveAPR === "" || effectiveAPR === "0") {
      return `${parseFloat("0").toFixed(2)}%`;
    } else {
      return `${parseFloat(effectiveAPR).toFixed(3)}%`;
    }
  };
  const handleCardClick = (item: any) => {
    setExpandedCard((prevItem: any) => (prevItem && prevItem.id === item.id ? null : item));
  };
  const listTemplate = (item: any) => {
    return (
      <div className="mt-2 mx-auto p-2" key={item.id}>
        <Card>
          <div className="row text-12 mx-auto">
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Quote Number </b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.quoteNumber}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Client Name</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.clientName}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>PF Provider</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{item.targetPartner}</span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Total Premium</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                {item.totalPremium == null || item.totalPremium === ""
                  ? parseFloat("0").toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : parseFloat(item.totalPremium).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Quote Date</b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                {item.quoteDate ? new Date(item.quoteDate).toLocaleDateString("en-US", { month: "numeric", day: "numeric", year: "numeric" }) : null}
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>
                <b>Action </b>
              </span>
            </div>
            <div className="col-6 d-flex align-items-center p-1">
              <span>{showActions(item)}</span>
            </div>
          </div>
          {expandedCard && expandedCard.id === item.id && <div className="expanded-content">{rowExpansionTemplate(item)}</div>}
          {window.innerWidth < 767 && item.targetPartner == "IPFS" ? 
          null
          :
          <div className="col-12 mt-1 d-flex align-items-center justify-content-end">
            <img
              src={MoreBtn}
              className="show-cursor"
              title={expandedCard && expandedCard.id === item.id ? "Show less" : "Show more"}
              onClick={() => handleCardClick(item)}
            />
          </div>
  }
        </Card>
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast} />
      {historyData.length > 0 ? (
        <>
          {window.innerWidth > 767 ? (
            <DataTable
              value={historyData}
              paginator
              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
              rows={dataTableRows}
              expandedRows={expandedRows}
              onRowToggle={(e: any) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
            >
              <Column expander className="expander-height" />
              <Column header="PF Provider" field="targetPartner" body={showPartnerDetails} sortable></Column>
              <Column header="Client Name" field="clientName" sortable></Column>
              <Column header="Total Premium" field="totalPremium" body={formatTotalPremium} sortable></Column>
              <Column header="Down Payment" field="downPayment" body={formatDownpayment} sortable />
              <Column header="Installment Amount" field="installmentAmount" body={formatInstallmentAmount} sortable></Column>
              <Column header="Effective APR" field="effectiveAPR" body={formatEffectiveAPR} sortable></Column>
              <Column header="Action" body={showActions} className=" "></Column>
            </DataTable>
          ) : (
            <div className="card-container">
              <DataView
                value={historyData}
                itemTemplate={listTemplate}
                paginator
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                rows={rows}
                first={first}
                onPage={(e) => setFirst(e.first)}
              />
            </div>
          )}
        </>
      ) : (
        <div className="col-12 mt-2 history-unavailable ">
          <AtkMessage appearance="info" messageText={"No  quotes found"} />
        </div>
      )}
    </>
  );
}
export default ResponseTable;