import React, { FC, useEffect, useState, useRef } from "react";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import CommonValues, { Logout } from "../../../common/utils";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
export default function IpfsSetting() {
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [ipfsPassword, setIpfsPassword] = useState("");
  const [accesscode, setAccesscode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [ipfsLoading, setIpfsLoading] = useState(false);
  const [errorMessageLogin, setErrorMessageLogin] = useState("");
  const [errorMessageAccesscode, setErrorMessageAccesscode] = useState("");
  const [deleteIpfsLoading, setDeleteIpfsLoading] = useState(false);
  const toast: any = useRef("");
  const [passwordType, setPasswordType] = useState("password");

  const onLoginChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setLogin(e.target.value);
  };
  const onAccesscodeChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAccesscode(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setIpfsPassword(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getIpfsCreds();
  }, []);

  const getIpfsCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.status == 204) {
          setLogin("");
          setIpfsPassword("");
          setAccesscode("");
        } else {
          setLogin(response.data.login);
          setIpfsPassword(response.data.password);
          setAccesscode(response.data.accessCode);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the ipfs Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while loading the ipfs Setting details" });
        }
        setErrorMessage(errorMessage);
        setLoading(false);
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessageLogin("");
    setErrorMessageAccesscode("");

    if (login == "" || login == null) {
      formIsValid = false;
      setErrorMessageLogin("Please enter login");
    }

    if (accesscode == "" || accesscode == null) {
      formIsValid = false;
      setErrorMessageAccesscode("Please enter accesscode");
    }

    return formIsValid;
  };
  const onIpfsUpdateClick = () => {
    if (checkValidation()) {
      setIpfsLoading(true);
      let token = CommonValues.GetToken();

      var data = JSON.stringify({
        Login: login,
        Password: ipfsPassword,
        AccessCode: accesscode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setIpfsLoading(false);
          setErrorMessage("");
          setTimeout(() => setSuccessMessage(""), 5000);
          toast.current.show({ severity: "success", summary: 'Success', detail: "IPFS Settings updated successfully", life: 3000 });
          //setSuccessMessage("IPFS Settings updated successfully");
          getIpfsCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              // errorMessage = "It look likes you are not registered with gotoPremiumFinance, Please register first.";
              toast.current.show({
                severity: "error", summary: 'Error',

                detail: "It look likes you are not registered with ipfs, Please register first",
              });
            } else {
              //errorMessage = "Unknown error while updating the gotoPremiumFinance Settings";
              toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the ipfs Settings" });
            }
          } else {
            //errorMessage = "Unknown error while updating the gotoPremiumFinance Settings";
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while updating the ipfs Settings" });
          }

          setErrorMessage(errorMessage);
          setIpfsLoading(false);
        });
    }
  };
  const onRegisterClick = () => {
    window.open("https://www.ipfs.com/contact?utm_campaign=InsuranceGIG&utm_source=browserextension&utm_term=partnership", "_blank");
  };
  const onFindAccessCodeClick = () => {
    window.open("https://www.ipfs.com/account-help-contact-form#web-access-code", "_blank");
  };
  const onIpfsResetClick = () => {
    setErrorMessageAccesscode("");
    setErrorMessageLogin("");
    setDeleteIpfsLoading(true);
    let token = CommonValues.GetToken();

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/ipfscreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteIpfsLoading(false);
        toast.current.show({ severity: "success", summary: 'Success', detail: "IPFS Settings deleted successfully", life: 3000 });
        getIpfsCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            //errorMessage = "Unknown error while deleting the IPFS Settings";
            toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the IPFS Settings" });
          }
        } else {
          //errorMessage = "Unknown error while deleting the IPFS Settings";
          toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while deleting the IPFS Settings" });
        }
        setTimeout(() => setErrorMessage(""), 5000);
        setErrorMessage(errorMessage);
        setDeleteIpfsLoading(false);
      });
  };
  const onKeyUpdateIpfsClick = (e: any) => {
    if (e.keyCode == 13) {
      onIpfsUpdateClick();
    }
  };
  return (
    <div className="row mx-auto">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="pf-setting-col">
          <h4 className="pf-setting-title">IPFS</h4>

          <div className={loading ? "row pf-setting-inputs  mx-auto div-blur-loading" : "row pf-setting-inputs  mx-auto "}>
            {/* {loading ? (
              <div className="d-flex justify-content-center">
                <AtkLoader />
              </div>
            ) : ( */}
            <>
              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Login
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className={errorMessageLogin.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                <AtkTextField
                  type="text"
                  id="login"
                  className="input-field"
                  name="login"
                  onChange={onLoginChange}
                  value={login}
                  placeholder="Enter login"
                />
                <span className="text-danger text-12">{errorMessageLogin}</span>
              </div>

              {/*  <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">Password</label>
              </div>
              <div className="col-8 mt-2 position-relative">
                <AtkTextField
                  type={passwordType}
                  id="password"
                  className="input-field"
                  name="ipfsPassword"
                  onChange={onPasswordChange}
                  value={ipfsPassword}
                  placeholder="Enter password"
                  autoComplete="new-password"
                />
                <span className="eye-icon" onClick={onShowPassword}>
                  {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>*/}

              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Access Code
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className={errorMessageAccesscode.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                <AtkTextField
                  type="text"
                  id="accesscode"
                  className="input-field"
                  name="accesscode"
                  onChange={onAccesscodeChange}
                  value={accesscode}
                  onKeyDown={onKeyUpdateIpfsClick}
                  placeholder="Enter access code"
                />
                <span className="text-danger text-12">{errorMessageAccesscode}</span>
              </div>
            </>
          </div>
          <div className="row mx-auto  setting-btn-position ">
            <div className="col-4 mt-3 mb-1">
              {deleteIpfsLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Reset" onClick={onIpfsResetClick} className="pf-primary-btn  w-100" />}
            </div>
            <div className="col-4 mt-3 mb-1">
              {ipfsLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onIpfsUpdateClick} className="pf-primary-btn  w-100" />}
            </div>
            <div className="col-4 mt-3 mb-1">
              <AtkButton label="Register" onClick={onRegisterClick} className="pf-primary-btn  w-100" />
            </div>
            <div className="col-12 mt-2  mb-2">
              <AtkButton label="Find Access Code" onClick={onFindAccessCodeClick} className="pf-secondary-btn  w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
