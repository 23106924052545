import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { OverlayPanel } from "primereact/overlaypanel";
import CommonValues from "../../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";

export default function DriverLicenseScanDataExtractPage(props: any) {
    const feedbackRef = useRef<OverlayPanel>(null);
    const toast: any = useRef("");
    const navigate = useNavigate();
    const [feedBackData, setFeedBackData] = useState<any>([]);
    const [feedBackError, setFeedBackError] = useState("");
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false)
    const [transactionId, setTransactionId] = useState(props.transactionId)

    const onFeedbackClick = (e: any) => {
        feedbackRef.current?.toggle(e);
    };

    const onHideFeedbackPopup = () => {
        if (feedbackRef.current) {
            feedbackRef.current.hide();
            setFeedBackData([]);
        }
    };
    const onSubmitFeedbackClick = (rowData: any) => {
        if (rowData.feedback == null || rowData.feedback == "") {
            setFeedBackError("Please enter your feedback");
        } else if (rowData.feedback.length > 250) {
            setFeedBackError("Please provide your feedback within 250 character");
        } else {
            setFeedBackError("");
            setFeedbackLoading(true);
            setTransactionId(rowData.transactionId);
            onSaveFeedback(rowData);
        }
    };
    const onSaveFeedback = (rowData: any) => {
        const token = CommonValues.GetToken();
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        let data = {
            TransactionId: transactionId,
            Feedback: feedbackVal,
        };

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/feedback`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", summary: 'Success', detail: "Feedback Submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: null };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);
                setFeedbackLoading(false);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while submitting feedback", life: 3000 });
                    }
                }
            });
    };
    const onSaveRatings = (rowData: any) => {
        const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
        const token = CommonValues.GetToken();
        let data = {
            TransactionId: transactionId,
            IsLiked: rowData.isLiked,
            Feedback: feedbackVal,
        };

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAIDriverLicenseScan/ratings`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                toast.current.show({ severity: "success", summary: 'Success', detail: "Feedback submitted successfully", life: 3000 });
                const feedback = { feedback: "", isLiked: rowData.isLiked };
                setFeedbackLoading(false);
                setFeedBackData(feedback);
                onHideFeedbackPopup();
            })
            .catch((error: any) => {
                console.log(error);
                setFeedbackLoading(false);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", summary: 'Error', detail: "Unknown error while submitting ratings", life: 3000 });
                    }
                }
            });
    };

    const onFeedbackChange = (newFeedback: string) => {
        const feedback = { ...feedBackData, feedback: newFeedback };
        setFeedBackData(feedback);
        if (newFeedback.length == 0 || newFeedback.trim() === "") {
            setFeedBackError("Please enter your feedback");
        } else if (newFeedback.length > 0) {
            setFeedBackError("");
        }
    };
    const onThumbsUpClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === true) {
            liked = null;
        } else {
            liked = true;
        }
        rowData = { ...rowData, isLiked: liked };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };
    const onThumbDownClick = (rowData: any) => {
        let liked: any;
        if (rowData.isLiked === false) {
            liked = null;
        } else {
            liked = false;
        }
        rowData = { ...rowData, isLiked: liked };
        setFeedBackData(rowData);
        onSaveRatings(rowData);
    };

    return (
        <>
            <div
                className="col p-2 ms-2 mt-2"
                style={{
                    border: "1px solid darkgrey",
                    borderRadius: "30px",
                    minHeight: "66vh",
                }}
            >               
               <Toast ref={toast} />
                <div className="row  my-2 mx-auto">
                    <div className="col-12">
                        <span className="text-14 ">
                            <b>Driver Information</b>
                        </span>
                    </div>
                </div>
                {props.isUpdateInNowCerts == true ?
                    <div className="row smartscan-block  mx-auto ">
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>First Name</label>
                            <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                value={props.firstName}
                                isDisabled={true}
                            />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Last Name</label>
                            <AtkTextField className="input-field" type="text" id="lastName" value={props.lastName} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Date of Birth</label>
                            <AtkTextField className="input-field" type="text" id="dob" value={props.dateOfBirth} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Driving License Number</label>
                            <AtkTextField className="input-field" type="text" id="licenseNumber" value={props.drivingLicenseNumber} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Issue Date</label>
                            <AtkTextField className="input-field" type="text" id="issueDate" value={props.issueDate} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Expiration Date</label>
                            <AtkTextField className="input-field" type="text" id="expirationDate" value={props.expirationDate} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Address Line 1</label>
                            <AtkTextField className="input-field" type="text" id="address" value={props.addressLine1} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>City</label>
                            <AtkTextField className="input-field" type="text" id="address" value={props.city} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>State</label>
                            <AtkTextField className="input-field" type="text" id="state" value={props.state} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>ZipCode</label>
                            <AtkTextField className="input-field" type="text" id="address" value={props.zipCode} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Driving License Class</label>
                            <AtkTextField className="input-field" type="text" id="licenseClass" value={props.drivingLicenseClass} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Endorsements</label>
                            <AtkTextField className="input-field" type="text" id="endorsements" value={props.endorsements} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Restrictions</label>
                            <AtkTextField className="input-field" type="text" id="restrictions" value={props.restrictions} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Sex</label>
                            <AtkTextField className="input-field" type="text" id="sex" value={props.sex} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Height</label>
                            <AtkTextField className="input-field" type="text" id="height" value={props.height} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Weight</label>
                            <AtkTextField className="input-field" type="text" id="weight" value={props.weight} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Eye Color</label>
                            <AtkTextField className="input-field" type="text" id="eyeColor" value={props.eyeColor} isDisabled={true} />
                        </div>
                        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                            <label>Hair Color</label>
                            <AtkTextField className="input-field" type="text" id="hairColor" value={props.hairColor} isDisabled={true} />
                        </div>
                    </div>
                    :
                    <>
                        <div className="row smartscan-block  mx-auto ">
                            <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2 ${props.firstNameErrorMessage.length > 0 ? "validation-div" : ""}`}>
                                <label>First Name</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="text"
                                    value={props.firstName}
                                    onChange={(e) => props.onChangeFirstName(e.target.value)}
                                    placeholder={props.firstName == "" ? "Enter first name":props.firstName}
                                />
                                <span className="text-danger text-12">{props.firstNameErrorMessage}</span>
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Last Name</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="lastName"
                                    value={props.lastName}
                                    onChange={(e) => props.onChangeLastName(e.target.value)}
                                    placeholder={props.lastName == "" ? "Enter last name":props.lastName}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Date of Birth</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="dob"
                                    value={props.dateOfBirth}
                                    onChange={(e) => props.onChangeDateOfBirth(e.target.value)}
                                    placeholder={props.dateOfBirth == "" ? "Enter date of birth":props.dateOfBirth}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Driving License Number</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="licenseNumber"
                                    value={props.drivingLicenseNumber}
                                    onChange={(e) => props.onChangeDrivingLicenseNumber(e.target.value)}
                                    placeholder={props.drivingLicenseNumber == "" ? "Enter driving license number":props.drivingLicenseNumber}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Issue Date</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="issueDate"
                                    value={props.issueDate}
                                    onChange={(e) => props.onChangeIssueDate(e.target.value)}
                                    placeholder={props.issueDate == "" ? "Enter issue date":props.issueDate}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Expiration Date</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="expirationDate"
                                    value={props.expirationDate}
                                    onChange={(e) => props.onChangeExpirationDate(e.target.value)}
                                    placeholder={props.expirationDate == "" ? "Enter expiration date":props.expirationDate}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Address Line 1</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="address"
                                    value={props.addressLine1}
                                    onChange={(e) => props.onChangeAddressLine1(e.target.value)}
                                    placeholder={props.addressLine1 == "" ? "Enter address line1":props.addressLine1}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>City</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="address"
                                    value={props.city}
                                    onChange={(e) => props.onChangeCity(e.target.value)}
                                    placeholder={props.city == "" ? "Enter city":props.city}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>State</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="state"
                                    value={props.state}
                                    onChange={(e) => props.onChangeState(e.target.value)}
                                    placeholder={props.state == "" ? "Enter state":props.state}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>ZipCode</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="address"
                                    value={props.zipCode}
                                    onChange={(e) => props.onChangeZipCode(e.target.value)}
                                    placeholder={props.zipCode == "" ? "Enter zip code":props.zipCode}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Driving License Class</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="licenseClass"
                                    value={props.drivingLicenseClass}
                                    onChange={(e) => props.onChangeDrivingLicenseClass(e.target.value)}
                                    placeholder={props.drivingLicenseClass == "" ? "Enter driving license class":props.drivingLicenseClass}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Endorsements</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="endorsements"
                                    value={props.endorsements}
                                    onChange={(e) => props.onChangeEndorsements(e.target.value)}
                                    placeholder={props.endorsements == "" ? "Enter endorsements":props.endorsements}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Restrictions</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="restrictions"
                                    value={props.restrictions}
                                    onChange={(e) => props.onChangeRestrictions(e.target.value)}
                                    placeholder={props.restrictions == "" ? "Enter restrictions":props.restrictions}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Sex</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="sex"
                                    value={props.sex}
                                    onChange={(e) => props.onChangeSex(e.target.value)}
                                    placeholder={props.sex == "" ? "Enter sex":props.sex}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Height</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="height"
                                    value={props.height}
                                    onChange={(e) => props.onChangeHeight(e.target.value)}
                                    placeholder={props.height == "" ? "Enter height":props.height}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Weight</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="weight"
                                    value={props.weight}
                                    onChange={(e) => props.onChangeWeight(e.target.value)}
                                    placeholder={props.weight == "" ? "Enter weight":props.weight}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Eye Color</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="eyeColor"
                                    value={props.eyeColor}
                                    onChange={(e) => props.onChangeEyeColor(e.target.value)}
                                    placeholder={props.eyeColor == "" ? "Enter eye color":props.eyeColor}
                                />
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 mt-2">
                                <label>Hair Color</label>
                                <AtkTextField
                                    className="input-field"
                                    type="text"
                                    id="hairColor"
                                    value={props.hairColor}
                                    onChange={(e) => props.onChangeHairColor(e.target.value)}
                                    placeholder={props.hairColor == "" ? "Enter hair color":props.hairColor}
                                />
                            </div>
                        </div>
                        <div className="row mx-auto d-flex align-items-center">
                            <div className="col-4 position-relative mb-2 p-0">
                                {
                                    props.showSearchClient && (props.insuredId == undefined || props.insuredId == null || props.insuredId == "") ?
                                        <>
                                            <div className={`search-bar flex-column ${props.insuredClientNameError.length > 0 ? " validation-div " : " "}`}>
                                                <label>
                                                    Select Client <span className="text-danger "> *</span>
                                                </label>
                                                <AtkTextField
                                                    className="input-field"
                                                    type="text"
                                                    id="insuredSearch"
                                                    value={props.insuredSearch}
                                                    onChange={(e) => {
                                                        props.onClientSearchChangeForInsured(e.target.value);
                                                    }}
                                                    placeholder={props.clientName.length > 0 ? props.clientName : "Enter client name"}
                                                />
                                            </div>
                                            {props.insuredSearch.replaceAll(" ", "").length > 0 && (
                                                <div id="client-search" className=" position-absolute  ">
                                                    {props.searchClientLoading ? (
                                                        <div className="d-flex justify-content-center my-2 client-loader-div">
                                                            <AtkLoader />
                                                        </div>
                                                    ) : props.searchClientList.length == 0 ? (
                                                        <div style={{ padding: "5px", background: "white" }}>
                                                            <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                                        </div>
                                                    ) : (
                                                        props.searchClientList.map((item: any) => (
                                                            <div
                                                                style={{ cursor: "pointer", width: "100%" }}
                                                                onClick={() => {
                                                                    props.onClientSelectForInsured(item);
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        width: "100%",
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            )}
                                            <span className="text-danger text-12">{props.insuredClientNameError}</span>
                                        </>
                                        :
                                        <span className="d-flex align-items-center"><i className="fa fa-check sent-to-ams-icon"></i>Insured details available</span>
                                }
                            </div>
                            <div className="col-8 text-end my-2">
                                {props.submitLoading ? (
                                    <div className="d-inline-block me-2 ">
                                        <AtkLoader />
                                    </div>) :
                                    <>
                                        {props.isUploadAgain ?
                                            <>
                                                {props.formHistoryPage == true ? null :
                                                    <>
                                                        <AtkButton label="Upload Again" onClick={props.onUploadAgain} className="pf-secondary-btn w-auto me-2" />

                                                        <AtkButtonIconBefore
                                                            label="Feedback"
                                                            onClick={(e: any) => {
                                                                onFeedbackClick(e);
                                                            }}
                                                            className="pf-secondary-btn w-auto me-2 "
                                                        />
                                                    </>}
                                            </>
                                            :
                                            <AtkButton label="Add Driver" onClick={props.onSubmit} className="pf-secondary-btn w-auto me-2" />
                                        }
                                    </>

                                }
                            </div>
                            <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                                <div className="row mx-auto d-flex align-items-center mb-2">
                                    <div className="col-12 feedback-header">
                                        <h4>Feedback</h4>
                                    </div>
                                    <div className={"form-group col-12 mb-2 text-12"}>
                                        <textarea
                                            name="feedback"
                                            onChange={(e) => onFeedbackChange(e.target.value)}
                                            value={feedBackData.feedback}
                                            className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                                            rows={4}
                                            placeholder="Enter your feedback here"
                                        />
                                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                                    </div>
                                    <div className="col-4 d-flex justify-content-evenly">
                                        <i
                                            className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                                                          ${feedBackData.isLiked == true ? " active" : " "}`}
                                            aria-hidden="true"
                                            onClick={(e) => onThumbsUpClick(feedBackData)}
                                        ></i>
                                        <i
                                            className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                                            ${feedBackData.isLiked == false ? " active" : " "}`}
                                            aria-hidden="true"
                                            onClick={(e) => onThumbDownClick(feedBackData)}
                                        ></i>
                                    </div>
                                    <div className="col-4 text-end p-2 pe-0  ">
                                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                                    </div>
                                    <div className="col-4 text-end p-2  ">
                                        {feedbackLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                <AtkLoader />
                                            </div>
                                        ) : (
                                            <AtkButton
                                                label="Submit"
                                                onClick={() => onSubmitFeedbackClick(feedBackData)}
                                                className="pf-secondary-btn w-auto "
                                            />
                                        )}
                                    </div>
                                </div>
                            </OverlayPanel>
                        </div>
                    </>
                }
            </div>
        </>
    )
}